<template>
  <page-loader :isLoading="pageLoading"></page-loader>
  <div class="box">
    <h1 class="title">Quotes</h1>
    <hr />
    <button class="button is-primary" @click.stop.prevent="openCurrencyModal">
      Create a New Quote
    </button>
    <div class="search">
      <div class="columns mt-3">
        <div class="column is-2">
          <div class="field">
            <input
              type="number"
              class="input"
              placeholder="Quote Number"
              v-model="search_quote_id"
            />
          </div>
        </div>
        <div class="column is-2">
          <v-select
            placeholder="Loading Point"
            v-model="search_loading_point"
            :options="loading_points"
          ></v-select>
        </div>
        <div class="column is-2">
          <v-select
            placeholder="Destination"
            v-model="search_destination"
            :options="destinations"
          ></v-select>
        </div>
        <div class="column is-2">
          <button
            type="submit"
            class="button is-primary"
            @click.stop.prevent="search"
          >
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
          <button
            class="button is-danger ml-2"
            @click.stop.prevent="clearSearch"
            v-if="
              this.search_quote_id ||
              this.search_loading_point ||
              this.search_destination
            "
          >
            <i class="fa-solid fa-xmark"></i>
          </button>
        </div>
      </div>
    </div>
    <div class="table-wraper">
      <table class="table mt-5 is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>Quote#</th>
            <th>Currency</th>
            <th>Client</th>
            <th>From</th>
            <th>To</th>
            <th>Created At</th>
            <th>Last Update</th>
            <th>Created By</th>
            <th width="50px">Edit</th>
            <th width="50px">View</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="quotes.length <= 0">
            <td colspan="10" class="has-text-centered">No Quotes</td>
          </tr>
          <tr v-for="q in quotes" :key="q._id">
            <td>{{ q.quote_id }}</td>
            <td>{{ q.currency }}</td>
            <td v-if="q.client">{{ q.client.company }}</td>
            <td v-else>Shared</td>
            <td>{{ q.loading_point }}</td>
            <td>{{ q.destination }}</td>
            <td>{{ q.created_at }}</td>
            <td
              v-tippy="{
                content: `Updated By: ${q.updated_by}`,
                placement: 'bottom',
              }"
            >
              {{ q.updated_at }}
            </td>
            <td>{{ q.created_by }}</td>
            <td>
              <router-link :to="`/admin/dashboard/quotes/id/${q._id}`"
                ><button class="button is-small is-warning">
                  <i class="fa-solid fa-pen-to-square"></i></button
              ></router-link>
            </td>
            <td>
              <router-link :to="`/admin/dashboard/quotes/view/${q._id}`"
                ><button class="button is-small">
                  <i class="fa-solid fa-eye"></i></button
              ></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div :class="`modal ${currencyModalOpen ? 'is-active' : ''}`">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-content">
        <div class="box">
          <div class="notification is-danger" v-if="errorMessage">
            {{ errorMessage }}
          </div>
          <label class="label">Select Currency</label>
          <v-select
            placeholder="Currency"
            v-model="currency"
            :options="currencies"
          ></v-select>
          <label class="label mt-4">Loading Point</label>
          <v-select
            placeholder="Loading Point"
            v-model="loading_point"
            :options="loading_points"
          ></v-select>
          <label class="label mt-4">Destination</label>
          <v-select
            placeholder="Destination"
            v-model="destination"
            :options="destinations"
          ></v-select>
          <label
            class="label mt-4"
            v-tippy="{
              placement: 'top',
              content:
                'Clients who ship to the same destination and share the same rates could be grouped under one quote, this will allow you to update one quote instead of updating many of them, eventually, saving you a lot of time ^_- ',
            }"
            >Will this quote be shared with more than 1 client?</label
          >
          <div class="control">
            <label class="radio">
              <input type="radio" v-model="shared" :value="true" />
              Yes
            </label>
            <label class="radio">
              <input type="radio" v-model="shared" :value="false" />
              No
            </label>
          </div>
          <div v-if="shared === false">
            <label class="label mt-4">Select a Client</label>
            <v-select
              placeholder="Client"
              v-model="client"
              :options="clients"
            ></v-select>
          </div>
          <div>
            <button
              :class="`button is-primary mt-5 ${isLoading ? 'is-loading' : ''}`"
              @click.stop.prevent="handleAdd"
            >
              Create
            </button>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeModal"
      ></button>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";

export default {
  data() {
    return {
      isLoading: false,
      pageLoading: false,
      currencyModalOpen: false,
      currencies: [],
      clients: [],
      currency: null,
      errorMessage: "",
      quotes: [],
      shared: null,
      client: null,
      destinations: [],
      loading_points: [],
      destination: null,
      loading_point: null,
      search_quote_id: null,
      search_loading_point: null,
      search_destination: null,
    };
  },
  created() {
    this.$axios
      .post("/api/retrieve_user_settings", {}, { headers: this.$authHeader() })
      .then((res) => {
        this.currencies = res.data.settings.currencies;
      })
      .catch(() => {});
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        let a = [];
        res.data.clients.map((v) => {
          a.push({ label: v.company, id: v._id });
        });
        this.clients = a;
      })
      .catch(() => {});
    this.$axios
      .post(
        "/api/retrieve_selections",
        {
          type: ["Destinations", "Loading Points"],
        },
        { headers: this.$authHeader() }
      )
      .then((res) => {
        res.data.selections.map((s) => {
          if (s.type == "Destinations") {
            this.destinations.push(s.value);
          }
          if (s.type == "Loading Points") {
            this.loading_points.push(s.value);
          }
        });
        this.destinations.unshift("ALL DESTINATIONS");
      });
    this.fetchQuotes();
  },
  methods: {
    openCurrencyModal() {
      this.currencyModalOpen = true;
    },
    handleAdd() {
      if (
        !this.currency ||
        this.shared == null ||
        !this.loading_point ||
        !this.destination
      ) {
        this.errorMessage = "Please fill out all the required fields";
        return;
      } else if (this.shared === false && !this.client) {
        this.errorMessage = "Please fill out all the required fields";
        return;
      }
      this.isLoading = true;
      // create the Quote
      this.$axios
        .post(
          "/api/add_quote",
          {
            currency: this.currency,
            shared: this.shared,
            client: this.client,
            loading_point: this.loading_point.trim(),
            destination: this.destination.trim(),
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.isLoading = false;
          this.$router.push("/admin/dashboard/quotes/id/" + res.data.id);
        })
        .catch((err) => {
          alert(err.response.data.msg);
          this.isLoading = false;
        });
    },
    closeModal() {
      this.currencyModalOpen = false;
      this.currency = "";
    },
    fetchQuotes() {
      let q = {};
      if (this.search_quote_id) {
        q.quote_id = this.search_quote_id;
      }
      if (this.search_loading_point) {
        q.loading_point = this.search_loading_point;
      }
      if (this.search_destination) {
        q.destination = this.search_destination;
      }
      (this.pageLoading = true),
        this.$axios
          .post("/api/quotes/all", q, { headers: this.$authHeader() })
          .then((res) => {
            this.pageLoading = false;
            this.quotes = res.data.quote.map((q) => {
              q.created_at = dayjs(q.created_at).format("YYYY-MM-DD hh:mm A");
              q.updated_at = dayjs(q.updated_at).format("YYYY-MM-DD hh:mm A");
              return q;
            });
          })
          .catch((err) => {
            alert(err.response.data.msg);
            this.pageLoading = false;
          });
    },
    search() {
      this.fetchQuotes();
    },
    clearSearch() {
      (this.search_quote_id = null),
        (this.search_loading_point = null),
        (this.search_destination = null);
      this.fetchQuotes();
    },
  },
};
</script>
