import { createRouter, createWebHistory } from "vue-router";
import main_routes from "@/router/main_routes";
import admin_routes from "@/router/admin_routes";
import client_routes from "@/router/client_routes";
import store from "@/store";
import axios from 'axios'
import authHeader from "@/service/auth-header";
const routes = [...main_routes.main_routes, ...admin_routes.admin_routes, ...client_routes.client_routes];
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});


router.beforeEach((to) => {
  // check if client is trying to view admin pages
  if (to.meta.requiresAdmin && store.state.authUser.user_type == "client") {
    return {
      path: "/access_denied",
    };
  }
  // in case no logged in user
  if (to.meta.requiresAuth && !store.state.authUser) {
    return {
      path: "/login",
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  }
  // in case no token
  if (to.meta.requiresAuth && !localStorage.getItem("token")) {
    return {
      path: "/login",
      // save the location we were at to come back later
      query: { redirect: to.fullPath },
    };
  }
  if (to.meta.requiresAuth) {
    axios.get('/api/check_loggedin_user', { headers: authHeader() }).then(res => {
      store.dispatch("authenticateUser", res.data.authUser);
    }).catch(err => {
      if (err.response.data.msg == "Token Expired" || err.response.data.msg == "Invalid Token") {
        localStorage.removeItem('token');
        return {
          path: "/login",
          // save the location we were at to come back later
          query: { redirect: to.fullPath },
        };
      }
    });
  }
});
export default router;
