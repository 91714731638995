<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Reset Password for {{ client_name }}</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <form>
          <message-alert
            v-if="errorAlert"
            messageType="error"
            :msg="errorMessage"
          />
          <message-alert v-if="successAlert" :msg="successMessage" />
          <div class="columns">
            <div class="column">
              <input
                class="input"
                type="password"
                v-model="password"
                placeholder="New password"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <input
                class="input"
                type="password"
                v-model="confirm_password"
                placeholder="Confirm password"
              />
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-primary" @click="resetPassowrd">Reset</button>
        <button class="button" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    client_name: String,
    client_id: String,
  },
  data() {
    return {
      password: "",
      confirm_password: "",
      errorAlert: false,
      successAlert: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closed");
    },
    resetPassowrd() {
      if (!this.password || !this.confirm_password) {
        this.successAlert = false;
        this.errorAlert = true;
        this.errorMessage = "Please fill out all the fields";
        return;
      }
      this.$axios
        .post(
          "/api/reset_client_password",
          {
            client_id: this.$props.client_id,
            password: this.password,
            confirm_password: this.confirm_password,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.password = "";
          this.confirm_password = "";
          this.errorAlert = false;
          this.successAlert = true;
          this.successMessage = res.data.msg;
        })
        .catch((err) => {
          this.successAlert = false;
          this.errorAlert = true;
          this.errorMessage = err.response.data.msg;
        });
    },
  },
};
</script>
