<template>
  <div class="filter mt-5">
    <h1 class="subtitle">Filter</h1>
    <div class="columns is-variable is-1">
      <div class="column">
        <v-select
          :options="currencies"
          placeholder="Currency"
          v-model="currency"
        ></v-select>
      </div>
      <div class="column">
        <input
          type="number"
          class="input"
          placeholder="Payment ID"
          v-model="transaction_number"
        />
      </div>
      <div class="column">
        <input
          type="number"
          class="input"
          placeholder="Amount"
          v-model="amount"
        />
      </div>
      <div class="column">
        <DatePicker
          v-model="date_from"
          placeholder="Date (from)"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
      <div class="column">
        <DatePicker
          v-model="date_to"
          placeholder="Date (to)"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
      <div class="column">
        <v-select
          :options="clients"
          placeholder="Client"
          v-model="client"
        ></v-select>
      </div>
      <div class="column">
        <v-select
          :options="[
            'Cash',
            'Check',
            'Credit Card',
            'Debit Card',
            'Mobile Payment',
            'Elctronic Bank Transfer',
            'Wire Transfer',
          ]"
          placeholder="Payment Method"
          v-model="payment_method"
        ></v-select>
      </div>
      <div class="column">
        <v-select
          :options="['payment', 'credit']"
          placeholder="Payment Type"
          v-model="type"
        ></v-select>
      </div>
    </div>
    <button class="button is-primary is-small" @click.stop="search">
      Search</button
    ><button class="button is-danger is-small ml-2" @click="reset">
      Clear
    </button>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      amount: null,
      transaction_number: null,
      date_from: null,
      date_to: null,
      client: null,
      payment_method: null,
      type: null,
      clients: [],
      currencies: [],
      currency: null,
    };
  },
  created() {
    // fetch required selections
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        // fetch currencies
        this.$axios
          .post(
            "/api/retrieve_user_settings",
            {},
            { headers: this.$authHeader() }
          )
          .then((res) => {
            this.currencies = res.data.settings.currencies;
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      });
    // fill state with query values (to save filter after a page refresh)
    if (this.$route.query.amount) {
      this.amount = this.$route.query.amount;
    }
    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }
    if (this.$route.query.client_id) {
      this.client = {
        label: this.$route.query.client_company,
        code: this.$route.query.client_id,
      };
    }
    if (this.$route.query.payment_method) {
      this.payment_method = this.$route.query.payment_method;
    }
    if (this.$route.query.currency) {
      this.currency = this.$route.query.currency;
    }
    if (this.$route.query.transaction_number) {
      this.transaction_number = this.$route.query.transaction_number;
    }
    if (this.$route.query.date_from) {
      this.date_from = dayjs(this.$route.query.date_from).format("YYYY-MM-DD");
    }
    if (this.$route.query.date_to) {
      this.date_to = dayjs(this.$route.query.date_to).format("YYYY-MM-DD");
    }
    // fetch clients
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.clients.map((c) => {
          this.clients.push({ code: c._id, label: c.company });
        });
      });
  },
  methods: {
    search() {
      let q = {};
      if (this.transaction_number) {
        q.transaction_number = this.transaction_number;
      }
      if (this.amount) {
        q.amount = this.amount;
      }
      if (this.type) {
        q.type = this.type;
      }
      if (this.currency) {
        q.currency = this.currency;
      }
      if (this.payment_method) {
        q.payment_method = this.payment_method;
      }
      if (this.client) {
        q.client_id = this.client.code;
        q.client_company = this.client.label;
      }
      if (this.date_from) {
        q.date_from = dayjs(this.date_from).format("YYYY-MM-DD");
      }
      if (this.date_to) {
        q.date_to = dayjs(this.date_to).format("YYYY-MM-DD");
      }
      this.$router.push({
        path: "/admin/dashboard/payments",
        query: q,
      });
      this.$emit("search", q);
    },
    reset() {
      this.$router.push({
        path: "/admin/dashboard/payments",
        query: {},
      });
      this.$emit("search", {});
      (this.type = null),
        (this.transaction_number = null),
        (this.payment_method = null),
        (this.amount = null),
        (this.currency = null),
        (this.date_from = null),
        (this.date_to = null),
        (this.client = null);
    },
  },
};
</script>
