<template>
  <div class="mt-5" style="border: none">
    <h1 class="subtitle">Needs Attention</h1>
    <div class="columns">
      <div class="column">
        <router-link to="/admin/dashboard/messages">
          <div class="box">
            <h1 class="subtitle">
              Unread Messages <i class="fa-solid fa-envelope mr-1"></i>
            </h1>
            <h1 class="title has-text-left has-text-danger">
              {{ messageCount }}
            </h1>
          </div>
        </router-link>
      </div>
      <div class="column">
        <router-link to="/admin/dashboard/dispatch">
          <div class="box">
            <h1 class="subtitle">
              To be Dispatched
              <i class="fa-solid fa-truck-arrow-right mr-1"></i>
            </h1>
            <h1 class="title has-text-left has-text-danger">
              {{ tobe_dispatched }}
            </h1>
          </div></router-link
        >
      </div>
      <div class="column">
        <router-link to="/admin/dashboard/loading_lists">
          <div class="box">
            <h1 class="subtitle">
              Pending Loading Lists
              <i class="fa-solid fa-clipboard-list mr-1"></i>
            </h1>
            <h1 class="title has-text-left has-text-danger">
              {{ pending_lists }}
            </h1>
          </div>
        </router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      messageCount: 0,
      tobe_dispatched: 0,
      pending_lists: 0,
    };
  },
  created() {
    // get messages count
    this.$axios
      .get("/api/get_messages_count_admin", { headers: this.$authHeader() })
      .then((res) => {
        this.messageCount = res.data.count;
      });
    // get messages count
    this.$axios
      .get("/api/get_tobe_dispatched_count_admin", {
        headers: this.$authHeader(),
      })
      .then((res) => {
        this.tobe_dispatched = res.data.count;
      });
    // get pending loading lists count
    this.$axios
      .get("/api/get_pending_lists_count_admin", {
        headers: this.$authHeader(),
      })
      .then((res) => {
        this.pending_lists = res.data.count;
      });
  },
};
</script>
<style scoped>
.subtitle {
  text-align: left;
}
.box {
  background: #fff;
  color: #fff;
  cursor: pointer;
  transition: 300ms;
  text-align: center;
  border-radius: 5px;
  border-color: #eee;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 4px solid #18ae93;
}
.columns .box:hover {
  background-color: #f9f9f9;
}
.box.subtitle {
  color: #666;
}
</style>
