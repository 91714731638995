<template>
  <div class="box">
    <page-loader :isLoading="isLoading"></page-loader>
    <router-link to="/admin/dashboard/loading_lists/add"
      ><button
        class="button is-primary"
        v-tippy="{ placement: 'right', content: 'Add Loading List' }"
      >
        <i class="fa-solid fa-plus"></i></button
    ></router-link>
    <div class="tabs is-centered">
      <ul>
        <li>
          <router-link to="/admin/dashboard/loading_lists/"
            >Pending</router-link
          >
        </li>
        <li class="is-active">
          <a>Ready for Loading</a>
        </li>
        <li>
          <router-link to="/admin/dashboard/loading_lists/rejected"
            >Rejected</router-link
          >
        </li>
        <li>
          <router-link to="/admin/dashboard/loading_lists/loaded"
            >Loaded</router-link
          >
        </li>
      </ul>
    </div>
    <div class="columns filter mt-6">
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="List ID"
          v-model="list_id"
          @keyup.enter="handleSearch"
        />
      </div>
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="Booking Number"
          v-model="booking_number"
          @keyup.enter="handleSearch"
        />
      </div>
      <div class="column">
        <v-select
          placeholder="Loading Point"
          v-model="loading_point"
          :options="loading_points"
        ></v-select>
      </div>
      <div class="column">
        <v-select
          :options="destinations"
          placeholder="Destination"
          v-model="destination"
        ></v-select>
      </div>
      <div class="column">
        <button class="button is-primary mr-2" @click="handleSearch">
          Search
        </button>
        <button class="button is-danger" @click="clearSearch">Clear</button>
      </div>
    </div>
    <div class="has-text-right">
      <router-link to="/admin/dashboard/loading_lists_overview/ready"
        ><button class="button is-warning right">Overview</button></router-link
      >
    </div>
    <nav
      class="pagination is-small is-centered mt-6"
      role="navigation"
      aria-label="pagination"
    >
      <br />

      <!-- PREV Page-->
      <a
        class="pagination-previous"
        v-if="hasPrevPage"
        @click.prevent="changePage(prevPage)"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <a class="pagination-previous" disabled v-if="!hasPrevPage"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <!-- NEXT Page-->
      <a
        class="pagination-next"
        @click.prevent="changePage(nextPage)"
        v-if="hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <a class="pagination-next" disabled v-if="!hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <ul class="pagination-list">
        <!-- FIRST Page-->
        <li>
          <a
            class="pagination-link"
            aria-label="Goto page 1"
            @click.prevent="changePage(1)"
            >First</a
          >
        </li>
        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- CURRENT Page-->
          <a
            class="pagination-link is-current"
            aria-label="Page 46"
            aria-current="page"
            >{{ page }}</a
          >
        </li>

        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- LAST Page-->
          <a
            class="pagination-link"
            aria-label="Goto page 86"
            @click.prevent="changePage(totalPages)"
            >Last</a
          >
        </li>
      </ul>
    </nav>
    <p>Total:{{ totalAutos }}</p>
    <div class="table-wraper">
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th width="10px">Info</th>
            <th width="60px">List ID</th>
            <th>Client</th>
            <th>Ports</th>
            <th>Booking</th>
            <th>Notes</th>
            <th>Description</th>
            <th>Bkng. Conf.</th>
            <th width="60px">Print</th>
            <th width="60px">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="lists && lists.length == 0">
            <td colspan="10" class="has-text-centered">No Available Lists</td>
          </tr>
          <tr v-for="l in lists" :key="l._id">
            <td
              :title="`${l.history.map((h) => {
                return h + '\n';
              })}`"
              class="has-text-centered is-clickable"
            >
              <i class="fa-solid fa-circle-info has-text-info"></i>
            </td>
            <td>{{ l.list_id }}</td>
            <td class="has-text-weight-bold">
              <span v-if="l.client">
                {{ l.client.display_name || l.client.company }}
              </span>
            </td>
            <td>
              <p>From: {{ l.loading_point }}</p>
              <p>To: {{ l.destination }}</p>
              <p>Terminal Cut-off: {{ l.cut_off }}</p>
            </td>
            <td>
              <p>
                Booking#
                <strong class="has-text-danger">{{ l.booking_number }}</strong>
              </p>
              <p>Container# {{ l.container_number }}</p>
              <p>Size: {{ l.size }}</p>
              <p>Lines: {{ l.shipping_line }}</p>
            </td>
            <td>
              <article class="message is-warning is-small">
                <div class="message-body" v-if="l.notes">
                  {{ l.notes }}
                </div>
              </article>
            </td>
            <td class="nowrap">
              <div v-for="auto in l.autos" :key="auto._id">
                <p class="item">
                  <strong>{{
                    `${auto.year} ${auto.make} ${auto.model}`
                  }}</strong
                  >{{ ` VIN: ${auto.vin} LOT#${auto.lot || ""}` }}
                </p>
              </div>
              <div v-for="part in l.parts" :key="part._id">
                <p class="item">
                  <strong>{{
                    `${part.description} ID#${part.part_id}`
                  }}</strong>
                </p>
              </div>
            </td>
            <td>
              <span class="icon">
                <a
                  v-if="l.booking_confirmation"
                  :href="`/api/files/${l.booking_confirmation}`"
                  target="__blank"
                  ><i class="fa-solid fa-paperclip is-size-5"></i
                ></a>
              </span>
            </td>
            <td>
              <button @click="handlePrint(l)" class="button">
                <i class="fa-solid fa-print"></i>
              </button>
            </td>
            <td>
              <div class="dropdown is-hoverable is-right">
                <div class="dropdown-trigger">
                  <button
                    class="button is-primary is-small"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu3"
                  >
                    <span>Actions</span>
                    <span class="icon is-small">
                      <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                  <div class="dropdown-content">
                    <router-link
                      :to="`/admin/dashboard/loading_lists/edit/${l._id}`"
                      class="dropdown-item"
                      ><i class="fa-solid fa-edit"></i> Edit</router-link
                    >
                    <a
                      class="dropdown-item has-text-primary"
                      @click.stop.prevent="markLoaded(l._id)"
                      ><i class="fa-solid fa-square-check mr-1"></i> Loaded</a
                    >
                    <a
                      class="dropdown-item"
                      @click.stop.prevent="markPending(l._id)"
                      ><i class="fa-solid fa-pause mr-1"></i> Move to Pending</a
                    >
                    <a
                      class="dropdown-item has-text-danger"
                      @click.stop.prevent="deleteList(l._id)"
                      ><i class="fa-regular fa-trash-can"></i> Delete</a
                    >
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
import { jsPDF } from "jspdf";

export default {
  data() {
    return {
      lists: [],
      isLoading: false,
      list_id: null,
      booking_number: null,
      loading_point: null,
      destination: null,
      loading_points: [],
      destinations: [],
      page: this.$route.query.page || 1,
      prevPage: null,
      hasPrevPage: null,
      totalAutos: null,
      nextPage: null,
      hasNextPage: null,
      totalPages: null,
    };
  },
  created() {
    if (this.$route.query.list_id) {
      this.list_id = this.$route.query.list_id;
    }
    if (this.$route.query.booking_number) {
      this.booking_number = this.$route.query.booking_number;
    }
    if (this.$route.query.loading_point) {
      this.loading_point = this.$route.query.loading_point;
    }
    if (this.$route.query.destination) {
      this.destination = this.$route.query.destination;
    }
    this.fetchData();
    // Get Selections
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        let destinations = [];
        let loading_points = [];
        res.data.selections.map((s) => {
          if (s.type == "Loading Points") {
            loading_points.push(s.value);
          }
          if (s.type == "Destinations") {
            destinations.push(s.value);
          }
          this.destinations = destinations;
          this.loading_points = loading_points;
        });
      });
  },
  watch: {
    page(p) {
      this.fetchData();
    },
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      let url = "/api/retrieve_loading_lists/ready?";
      if (this.page) url = url + "page=" + this.page + "&";
      if (this.list_id) url = url + "list_id=" + this.list_id + "&";
      if (this.booking_number)
        url = url + "booking_number=" + this.booking_number + "&";
      if (this.loading_point)
        url = url + "loading_point=" + this.loading_point + "&";
      if (this.destination) url = url + "destination=" + this.destination + "&";
      this.$axios
        .get(url, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.lists = res.data.lists.map((l) => {
            l.created_at = dayjs(l.created_at).format("YYYY-MM-DD hh:mm A");
            if (l.cut_off) {
              l.cut_off = dayjs(l.cut_off).add(1, "day").format("YYYY-MM-DD");
            }
            return l;
          });
          this.totalPages = res.data.totalPages;
          this.totalAutos = res.data.totalAutos;
          this.nextPage = res.data.nextPage;
          this.prevPage = res.data.prevPage;
          this.hasNextPage = res.data.hasNextPage;
          this.hasPrevPage = res.data.hasPrevPage;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    handleSearch() {
      let q = {};
      if (this.list_id) {
        q.list_id = this.list_id;
      }
      if (this.booking_number) {
        q.booking_number = this.booking_number;
      }
      if (this.loading_point) {
        q.loading_point = this.loading_point;
      }
      if (this.destination) {
        q.destination = this.destination;
      }
      this.$router.push({
        path: "/admin/dashboard/loading_lists/ready",
        query: q,
      });
      this.fetchData();
    },
    clearSearch() {
      (this.list_id = null),
        (this.loading_point = null),
        (this.destination = null),
        (this.booking_number = null);
      this.fetchData();
    },
    deleteList(id) {
      if (confirm("Are you sure?")) {
        this.$axios
          .get(`/api/delete_loading_list/${id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchData();
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    },
    // move to pending
    markPending(id) {
      if (confirm("Are you sure?")) {
        this.$axios
          .get(`/api/move_list_to_pending/${id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchData();
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    },
    // move to loaded
    markLoaded(id) {
      this.$axios
        .get(`/api/mark_loaded/${id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.fetchData();
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
    // Change Page
    changePage(number) {
      this.page = number;
    },
    handlePrint(list) {
      let doc = new jsPDF();
      doc.setFontSize(14);

      let y = 10;
      let newLine = () => {
        y += 10;
      };
      doc.text(`Loading List ID: ${list.list_id}`, 10, y);
      newLine();
      doc.line(10, y, 200, y);
      newLine();
      doc.text(`Client: ${list.client.company || ""}`, 10, y);
      newLine();
      doc.text(`From: ${list.loading_point || ""}`, 10, y);
      newLine();
      doc.text(`POD: ${list.destination || ""}`, 10, y);
      newLine();
      doc.text(`Booking Number: ${list.booking_number || ""}`, 10, y);
      newLine();
      doc.text(`Container Number: ${list.container_number || ""}`, 10, y);
      newLine();
      doc.text(`Container Size: ${list.size || ""}`, 10, y);
      newLine();
      doc.text(`Shipping Lines: ${list.shipping_line || ""}`, 10, y);
      newLine();
      doc.line(10, y, 200, y);
      newLine();
      doc.text(`Items:`, 10, y);
      newLine();
      doc.setTextColor("red");
      list.autos.map((a) => {
        doc.text(
          `-${a.year || ""} ${a.make} ${a.model || ""} vin#${a.vin} Lot#${
            a.lot || ""
          }`,
          10,
          y
        );
        newLine();
      });
      list.parts.map((p) => {
        doc.text(`-${p.description} ID#${p.part_id} QTY:${p.qty || ""}`, 10, y);
        newLine();
      });
      newLine();
      newLine();

      doc.setTextColor("black");
      doc.text(`Notes:`, 10, y);
      newLine();
      doc.text(`${list.notes || ""}`, 10, y);
      newLine();
      doc.autoPrint();
      doc.output("dataurlnewwindow");
    },
  },
};
</script>
<style scoped>
.container {
  max-width: 1900px !important;
}
.table td {
  font-size: 00.8rem;
}
.item {
  border-bottom: 1px dashed #ddd;
  max-width: fit-content;
}
</style>
