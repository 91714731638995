import LoginPage from "@/views/LoginPage";
import PageNotFound from "@/views/PageNotFound";
import AccessDenied from "@/views/AccessDenied";

const routes = [
  // Landing Page
  {
    path: "/",
    redirect: (to) => {
      return { path: "/login" }
    }
  },
  // Signup page
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    component: PageNotFound,
    meta: {
      requiresAuth: false,
    },
  },
  {
    // Access Denied Page
    path: "/access_denied",
    name: "AccessDenied",
    component: AccessDenied,
  },
];

export default {
  main_routes: routes,
};
