<template>
  <div class="box">
    <page-loader :isLoading="isLoading"></page-loader>
    <div class="tabs is-centered">
      <ul>
        <li class="is-active"><a>Pending</a></li>
        <li>
          <router-link to="/client/dashboard/loading_lists/ready"
            >Ready for Loading</router-link
          >
        </li>
        <li>
          <router-link to="/client/dashboard/loading_lists/rejected"
            >Rejected</router-link
          >
        </li>
        <li>
          <router-link to="/client/dashboard/loading_lists/loaded"
            >Loaded</router-link
          >
        </li>
      </ul>
    </div>
    <router-link to="/client/dashboard/loading_lists/add"
      ><button class="button is-primary">New Loading List</button></router-link
    >
    <div class="columns mt-6">
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="List ID"
          v-model="list_id"
          @keyup.enter="handleSearch"
        />
      </div>
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="Booking Number"
          v-model="booking_number"
          @keyup.enter="handleSearch"
        />
      </div>
      <div class="column">
        <v-select
          placeholder="Loading Point"
          v-model="loading_point"
          :options="loading_points"
        ></v-select>
      </div>
      <div class="column">
        <v-select
          :options="destinations"
          placeholder="Destination"
          v-model="destination"
        ></v-select>
      </div>
      <div class="column">
        <button class="button is-primary mr-2" @click="handleSearch">
          Search
        </button>
        <button class="button is-danger" @click="clearSearch">Clear</button>
      </div>
    </div>
    <!-- Paginating-->
    <nav
      class="pagination is-small is-centered mt-6"
      role="navigation"
      aria-label="pagination"
    >
      <!-- PREV Page-->
      <a
        class="pagination-previous"
        v-if="hasPrevPage"
        @click.prevent="changePage(prevPage)"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <a class="pagination-previous" disabled v-if="!hasPrevPage"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <!-- NEXT Page-->
      <a
        class="pagination-next"
        @click.prevent="changePage(nextPage)"
        v-if="hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <a class="pagination-next" disabled v-if="!hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <ul class="pagination-list">
        <!-- FIRST Page-->
        <li>
          <a
            class="pagination-link"
            aria-label="Goto page 1"
            @click.prevent="changePage(1)"
            >First</a
          >
        </li>
        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- CURRENT Page-->
          <a
            class="pagination-link is-current"
            aria-label="Page 46"
            aria-current="page"
            >{{ page }}</a
          >
        </li>

        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- LAST Page-->
          <a
            class="pagination-link"
            aria-label="Goto page 86"
            @click.prevent="changePage(totalPages)"
            >Last</a
          >
        </li>
      </ul>
    </nav>
    <p>Total:{{ totalAutos }}</p>
    <div class="table-wraper">
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th width="10px">Info</th>
            <th width="60px">List ID</th>
            <th>Ports</th>
            <th>Booking</th>
            <th>Notes</th>
            <th>Description</th>
            <th>Bkng. Conf.</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="lists && lists.length == 0">
            <td colspan="9" class="has-text-centered">No Available Lists</td>
          </tr>
          <tr v-for="l in lists" :key="l._id">
            <td
              :title="`${l.history.map((h) => {
                return h + '\n';
              })}`"
              class="has-text-centered is-clickable"
            >
              <i class="fa-solid fa-circle-info has-text-info"></i>
            </td>
            <td>{{ l.list_id }}</td>
            <td class="nowrap">
              <p>From: {{ l.loading_point }}</p>
              <p>To: {{ l.destination }}</p>
              <p>Terminal Cut-off: {{ l.cut_off }}</p>
            </td>
            <td class="nowrap">
              <p>
                Booking#
                <strong class="has-text-danger">{{ l.booking_number }}</strong>
              </p>
              <p>Container# {{ l.container_number }}</p>
              <p>Size: {{ l.size }}</p>
              <p>Lines: {{ l.shipping_line }}</p>
            </td>
            <td>
              <article class="message is-warning is-small">
                <div class="message-body" v-if="l.notes">
                  {{ l.notes }}
                </div>
              </article>
            </td>
            <td class="nowrap">
              <div v-for="auto in l.autos" :key="auto._id">
                <p class="item">
                  <strong>{{
                    `${auto.year} ${auto.make} ${auto.model}`
                  }}</strong
                  >{{ ` VIN: ${auto.vin} LOT#${auto.lot || ""}` }}
                </p>
              </div>
              <div v-for="part in l.parts" :key="part._id">
                <p class="item">
                  <strong>{{
                    `${part.description} ID#${part.part_id}`
                  }}</strong>
                </p>
              </div>
            </td>
            <td>
              <span class="icon">
                <a
                  v-if="l.booking_confirmation"
                  :href="`/api/files/${l.booking_confirmation}`"
                  target="__blank"
                  ><i class="fa-solid fa-paperclip is-size-5"></i
                ></a>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      lists: [],
      isLoading: false,
      list_id: null,
      booking_number: null,
      loading_point: null,
      destination: null,
      loading_points: [],
      destinations: [],
      rejectionModal: false,
      id: null,
      reason: null,
      page: this.$route.query.page || 1,
      prevPage: null,
      hasPrevPage: null,
      totalAutos: null,
      nextPage: null,
      hasNextPage: null,
      totalPages: null,
    };
  },
  watch: {
    page(p) {
      this.fetchData();
    },
  },
  created() {
    if (this.$route.query.list_id) {
      this.list_id = this.$route.query.list_id;
    }
    if (this.$route.query.booking_number) {
      this.booking_number = this.$route.query.booking_number;
    }
    if (this.$route.query.loading_point) {
      this.loading_point = this.$route.query.loading_point;
    }
    if (this.$route.query.destination) {
      this.destination = this.$route.query.destination;
    }
    this.fetchData();
    // Get Selections
    this.$axios
      .post(
        "/api/retrieve_selections_client",
        {},
        { headers: this.$authHeader() }
      )
      .then((res) => {
        let destinations = [];
        let loading_points = [];
        res.data.selections.map((s) => {
          if (s.type == "Loading Points") {
            loading_points.push(s.value);
          }
          if (s.type == "Destinations") {
            destinations.push(s.value);
          }
          this.destinations = destinations;
          this.loading_points = loading_points;
        });
      });
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      let url = "/api/retrieve_loading_lists_client/pending?";
      if (this.page) url = url + "page=" + this.page + "&";
      if (this.list_id) url = url + "list_id=" + this.list_id + "&";
      if (this.booking_number)
        url = url + "booking_number=" + this.booking_number + "&";
      if (this.loading_point)
        url = url + "loading_point=" + this.loading_point + "&";
      if (this.destination) url = url + "destination=" + this.destination + "&";
      this.$axios
        .get(url, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.lists = res.data.lists.map((l) => {
            l.created_at = dayjs(l.created_at).format("YYYY-MM-DD hh:mm A");
            if (l.cut_off) {
              l.cut_off = dayjs(l.cut_off).add(1, "day").format("YYYY-MM-DD");
            }
            return l;
          });
          this.totalPages = res.data.totalPages;
          this.totalAutos = res.data.totalAutos;
          this.nextPage = res.data.nextPage;
          this.prevPage = res.data.prevPage;
          this.hasNextPage = res.data.hasNextPage;
          this.hasPrevPage = res.data.hasPrevPage;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    handleSearch() {
      let q = {};
      if (this.list_id) {
        q.list_id = this.list_id;
      }
      if (this.booking_number) {
        q.booking_number = this.booking_number;
      }
      if (this.loading_point) {
        q.loading_point = this.loading_point;
      }
      if (this.destination) {
        q.destination = this.destination;
      }
      this.$router.push({
        path: "/client/dashboard/loading_lists/",
        query: q,
      });
      this.fetchData();
    },
    clearSearch() {
      (this.list_id = null),
        (this.loading_point = null),
        (this.destination = null),
        (this.booking_number = null);
      this.fetchData();
    },
    deleteList(id) {
      if (confirm("Are you sure?")) {
        this.$axios
          .get(`/api/delete_loading_list/${id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchData();
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    },
    openRejectionModal(id) {
      this.rejectionModal = true;
      this.id = id;
    },
    rejectList() {
      if (!this.reason) {
        alert("Please provide a reason");
        return;
      }
      if (confirm("Are you sure?")) {
        this.$axios
          .post(
            `/api/reject_loading_list/${this.id}`,
            { reason: this.reason },
            {
              headers: this.$authHeader(),
            }
          )
          .then((res) => {
            this.fetchData();
            (this.id = null),
              (this.reason = null),
              (this.rejectionModal = false);
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    },
    closeModal() {
      (this.id = null), (this.reason = null);
      this.rejectionModal = false;
    },
    approveList(id) {
      this.$axios
        .get(`/api/approve_loading_list/${id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.fetchData();
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
    // Change Page
    changePage(number) {
      this.page = number;
    },
  },
};
</script>

<style scoped>
.container {
  max-width: 1900px !important;
}
.table td {
  font-size: 0.8rem;
}
.item {
  border-bottom: 1px dashed #ddd;
  max-width: fit-content;
}
.v-select {
  background-color: #fff;
}
.vs__search::placeholder {
  color: #ddd;
}
.v-select .vs__selected {
  background-color: #00d2be;
  padding: 2px 5px;
  line-height: 15px;
  color: #fff;
}
.vs__dropdown-toggle {
  height: 40px;
}
</style>
