<template>
  <admin-side-nav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <admin-top-nav />
    <div class="mt-6 p-5">
      <div>
        <h1 class="title">Overview</h1>
        <hr />
        <AnnouncemntsNotification />
        <NeedsAttention />
      </div>
      <LoadingPoints />
      <hr />
      <LoadingStats />
    </div>
  </div>
</template>
<script>
import AdminSideNav from "@/components/nav/AdminSideNav.vue";
import AdminTopNav from "@/components/nav/AdminTopNav.vue";
import LoadingPoints from "./LoadingPoints.vue";
import AnnouncemntsNotification from "@/components/messages/AnnouncemntsNotification.vue";
import NeedsAttention from "./NeedsAttention.vue";
import LoadingStats from "./LoadingStats.vue";
export default {
  components: {
    AdminSideNav,
    AdminTopNav,
    AnnouncemntsNotification,
    LoadingPoints,
    NeedsAttention,
    LoadingStats,
  },
};
</script>
<style scoped></style>
