<template>
  <page-loader :isLoading="isLoading"></page-loader>
  <div class="box">
    <h1 class="title">Dispatch</h1>
    <hr />
    <!-- Page Navigation-->
    <div class="tabs is-centered">
      <ul>
        <li>
          <router-link to="/admin/dashboard/dispatch/">
            <i class="fa-regular fa-hourglass mr-3"></i>To be Dispatched
          </router-link>
        </li>
        <li>
          <router-link to="/admin/dashboard/dispatch/to_be_picked_up"
            ><i class="fa-solid fa-clipboard-check mr-3"></i>Ready for Pick
            up</router-link
          >
        </li>
        <li>
          <router-link to="/admin/dashboard/dispatch/on_the_way"
            ><i class="fa-regular fa-paper-plane mr-3"></i>On the
            way</router-link
          >
        </li>
        <li>
          <router-link to="/admin/dashboard/dispatch/delivered"
            ><i class="fa-solid fa-check mr-3"></i>Delivered</router-link
          >
        </li>
        <li class="is-active">
          <a><i class="fa-solid fa-people-group mr-3"></i>Transporters</a>
        </li>
      </ul>
    </div>
    <div class="bolck">
      <button class="button button is-primary" @click.prevent="openAddModal">
        Add Transporter
      </button>
    </div>
    <hr />
    <p class="block">Total : {{ 0 }}</p>
    <!-- Table-->
    <div class="table-wraper">
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Company</th>
            <th>Phone</th>
            <th>Email</th>
            <th width="120px"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="transporter in transporters" :key="transporter._id">
            <td>{{ transporter.company }}</td>
            <td>{{ transporter.phone }}</td>
            <td>{{ transporter.email }}</td>
            <td>
              <button
                class="button is-small"
                @click.stop.prevent="openEditModal(transporter)"
              >
                <i class="fa-solid fa-pen-to-square"></i>
              </button>
              <button
                class="button is-small is-danger ml-2"
                @click.stop.prevent="deleteTransporter(transporter._id)"
              >
                <i class="fa-solid fa-trash-can"></i>
              </button>
            </td>
          </tr>
          <tr v-if="transporters && transporters.length == 0">
            <td colspan="8" class="has-text-centered">No Data</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Add New Transporter Modal-->
    <div :class="`modal ${addModalOpen ? 'is-active' : ''}`">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">New Transporter</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>
            <div class="block">
              <div v-if="error" class="notification is-danger">{{ error }}</div>
              <div v-if="success" class="notification is-primary">
                {{ success }}
              </div>
            </div>
            <div class="block">
              <label class="label"
                >Company <b class="has-text-danger">*</b>
              </label>
              <input
                type="text"
                placeholder="Company"
                class="input"
                v-model="company"
              />
            </div>
            <div class="block">
              <label class="label">Email</label>
              <input
                type="email"
                placeholder="Email"
                class="input"
                v-model="email"
              />
            </div>
            <div class="block">
              <label class="label">Phone</label>
              <input
                type="text"
                placeholder="Phone Number"
                class="input"
                v-model="phone"
              />
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            @click.stop.prevent="addTransporter"
          >
            Submit
          </button>
          <button class="button" @click="closeModal">Cancel</button>
        </footer>
      </div>
    </div>
    <!-- Edit  Transporter Modal-->
    <div :class="`modal ${editModalopen ? 'is-active' : ''}`">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Edit Transporter</p>
          <button
            class="delete"
            aria-label="close"
            @click="closeModal"
          ></button>
        </header>
        <section class="modal-card-body">
          <form>
            <div class="block">
              <div v-if="error" class="notification is-danger">{{ error }}</div>
              <div v-if="success" class="notification is-primary">
                {{ success }}
              </div>
            </div>
            <div class="block">
              <label class="label"
                >Company <b class="has-text-danger">*</b>
              </label>
              <input
                type="text"
                placeholder="Company"
                class="input"
                v-model="edit_company"
              />
            </div>
            <div class="block">
              <label class="label">Email</label>
              <input
                type="email"
                placeholder="Email"
                class="input"
                v-model="edit_email"
              />
            </div>
            <div class="block">
              <label class="label">Phone</label>
              <input
                type="text"
                placeholder="Phone Number"
                class="input"
                v-model="edit_phone"
              />
            </div>
          </form>
        </section>
        <footer class="modal-card-foot">
          <button
            class="button is-success"
            @click.stop.prevent="editTransporter"
          >
            <i class="fa-regular fa-floppy-disk mr-1"></i>Save
          </button>
          <button class="button" @click="closeModal">Cancel</button>
        </footer>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      transporters: [],
      isLoading: false,
      addModalOpen: false,
      editModalopen: false,
      company: null,
      email: null,
      phone: null,
      error: null,
      success: null,
      edit_company: null,
      edit_phone: null,
      edit_email: null,
      edit_id: null,
    };
  },
  watch: {
    page() {
      this.$router.push(`?page=${this.page}`);
      this.fetchData();
    },
  },
  created() {
    // fetch data
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$axios
        .get("/api/retrieve_transporters", {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.transporters = res.data.transporters;
          this.isLoading = false;
        })
        .catch((err) => {
          alert(err.response.data.msg);
          this.isLoading = false;
        });
    },
    closeModal() {
      this.addModalOpen = false;
      this.editModalopen = false;
      // reset data
      (this.company = null),
        (this.email = null),
        (this.phone = null),
        (this.error = null),
        (this.success = null),
        (this.edit_company = null),
        (this.edit_phone = null),
        (this.edit_email = null),
        (this.edit_id = null);
      this.fetchData();
    },
    openAddModal() {
      this.addModalOpen = true;
    },
    openEditModal(t) {
      this.editModalopen = true;
      this.edit_id = t._id;
      this.edit_company = t.company;
      (this.edit_phone = t.phone), (this.edit_email = t.email);
    },
    addTransporter() {
      // validate
      if (!this.company) {
        (this.success = null), (this.error = "Company name is required");
        return;
      }
      // send request to add transporter
      this.$axios
        .post(
          "/api/add_transporter",
          {
            company: this.company,
            email: this.email,
            phone: this.phone,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          (this.error = null), (this.success = res.data.msg);
          // reset data
          (this.company = null), (this.email = null), (this.phone = null);
        })
        .catch((err) => {
          (this.success = null), (this.error = err.response.data.msg);
        });
    },
    deleteTransporter(id) {
      if (confirm("Are you sure?")) {
        this.$axios
          .get(`/api/delete_transporter/${id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchData();
          })
          .catch((err) => {});
      }
    },
    editTransporter() {
      // validate
      if (!this.edit_company) {
        (this.success = null), (this.error = "Company name is required");
        return;
      }
      // send request to add transporter
      this.$axios
        .post(
          `/api/edit_transporter/${this.edit_id}`,
          {
            company: this.edit_company,
            email: this.edit_email,
            phone: this.edit_phone,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          (this.error = null), (this.success = res.data.msg);
        })
        .catch((err) => {
          (this.success = null), (this.error = err.response.data.msg);
        });
    },
  },
};
</script>
<style scoped></style>
