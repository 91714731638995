<template>
  <form class="filter mt-3">
    <h1 class="subtitle">
      Filters
      <button
        class="button is-small is-rounded is-pulled-right"
        type="button"
        @click.prevent="this.$store.commit('expand_container_filter')"
      >
        Expand Filters <i class="fa-solid fa-angles-down ml-2"></i>
      </button>
    </h1>

    <div class="columns is-variable is-1">
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="Container Number"
          v-model="container_number"
          @keyup.enter="search"
        />
      </div>
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="Booking Number"
          v-model="booking_number"
          @keyup.enter="search"
        />
      </div>
      <div class="column">
        <DatePicker
          v-model="loading_date_from"
          placeholder="Loading date (From)"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
      <div class="column">
        <DatePicker
          v-model="loading_date_to"
          placeholder="Loading date (to)"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
      <div class="column">
        <v-select
          placeholder="Client"
          :options="clients"
          v-model="client"
        ></v-select>
      </div>
    </div>
    <div
      :class="`${!this.$store.state.containerFilterExpanded ? 'hidden' : ''}`"
    >
      <div :class="`columns is-variable is-1`">
        <div class="column">
          <v-select
            placeholder="Loading Point"
            :options="loading_points"
            v-model="loading_point"
          ></v-select>
        </div>
        <div class="column">
          <v-select
            placeholder="Destination"
            :options="destinations"
            v-model="destination"
          ></v-select>
        </div>
        <div class="column">
          <v-select
            placeholder="Custom 1"
            :options="customs1"
            v-model="custom1"
          ></v-select>
        </div>
        <div class="column">
          <v-select
            placeholder="Custom 2"
            :options="customs2"
            v-model="custom2"
          ></v-select>
        </div>
        <div class="column">
          <v-select
            placeholder="Shipping Lines"
            :options="lines"
            v-model="shipping_line"
          ></v-select>
        </div>
      </div>
    </div>
    <div class="mt-4">
      <button class="button is-primary is-small" @click.prevent="search">
        Search
      </button>
      <button class="button is-danger ml-3 is-small" @click.prevent="reset">
        Clear
      </button>
    </div>
  </form>
</template>
<script>
import ShippingLines from "@/components/helpers/ShippingLines";
import dayjs from "dayjs";
export default {
  data() {
    return {
      loading_date_from: null,
      loading_date_to: null,
      client: null,
      custom1: null,
      custom2: null,
      loading_point: null,
      destination: null,
      shipping_line: null,
      container_number: null,
      booking_number: null,
      loading_points: [],
      destinations: [],
      clients: [],
      customs1: [],
      customs2: [],
      lines: ShippingLines,
    };
  },
  methods: {
    search() {
      let q = {};
      if (this.container_number) {
        q.container_number = this.container_number;
      }
      if (this.booking_number) {
        q.booking_number = this.booking_number;
      }
      if (this.shipping_line) {
        q.shipping_line = this.shipping_line;
      }
      if (this.loading_point) {
        q.loading_point = this.loading_point;
      }
      if (this.destination) {
        q.destination = this.destination;
      }

      if (this.custom1) {
        q.custom1 = this.custom1;
      }
      if (this.custom2) {
        q.custom2 = this.custom2;
      }
      if (this.client) {
        q.client_id = this.client.code;
        q.client_company = this.client.label;
      }
      if (this.loading_date_from) {
        q.loading_date_from = dayjs(this.loading_date_from).format(
          "YYYY-MM-DD"
        );
      }
      if (this.loading_date_to) {
        q.loading_date_to = dayjs(this.loading_date_to).format("YYYY-MM-DD");
      }
      this.$router.push({
        path: "/admin/dashboard/containers",
        query: q,
      });
      this.$emit("search", q);
    },
    reset() {
      this.$router.push({
        path: "/admin/dashboard/containers",
        query: {},
      });
      this.$emit("search", {});
      (this.container_number = null),
        (this.booking_number = null),
        (this.loading_date_from = null),
        (this.loading_date_to = null),
        (this.custom1 = null),
        (this.custom2 = null),
        (this.loading_point = null),
        (this.destination = null),
        (this.shipping_line = null),
        (this.client = null);
    },
  },

  created() {
    // fill state with query values (to save filter after a page refresh)
    if (this.$route.query.booking_number) {
      this.booking_number = this.$route.query.booking_number;
    }
    if (this.$route.query.container_number) {
      this.container_number = this.$route.query.container_number;
    }
    if (this.$route.query.client_id) {
      this.client = {
        label: this.$route.query.client_company,
        code: this.$route.query.client_id,
      };
    }
    if (this.$route.query.shipping_line) {
      this.shipping_line = this.$route.query.shipping_line;
    }

    if (this.$route.query.loading_point) {
      this.loading_point = this.$route.query.loading_point;
    }
    if (this.$route.query.destination) {
      this.destination = this.$route.query.destination;
    }
    this.status = this.$route.query.status;
    if (this.$route.query.loading_date_from) {
      this.loading_date_from = dayjs(
        this.$route.query.loading_date_from
      ).format("YYYY-MM-DD");
    }
    if (this.$route.query.loading_date_to) {
      this.loading_date_to = dayjs(this.$route.query.loading_date_to).format(
        "YYYY-MM-DD"
      );
    }
    if (this.$route.query.custom1) {
      this.custom1 = this.$route.query.custom1;
    }
    if (this.$route.query.custom2) {
      this.custom2 = this.$route.query.custom2;
    }

    // fetch selections
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.selections.map((s) => {
          if (s.type == "Custom Field 1") this.customs1.push(s.value);
          if (s.type == "Custom Field 2") this.customs2.push(s.value);
          if (s.type == "Loading Points") this.loading_points.push(s.value);
          if (s.type == "Destinations") this.destinations.push(s.value);
        });
      });
    // fetch clients
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.clients.map((c) => {
          this.clients.push({ code: c._id, label: c.company });
        });
      });
  },
};
</script>
<style scoped>
.box {
  border-radius: 0;
}
</style>
