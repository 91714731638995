<template>
  <page-loader :isLoading="isLoading"></page-loader>
  <div class="box quote-container">
    <form>
      <h1 class="subtitle">Quote</h1>
      <p style="color: #f14668" v-if="errorMessage">{{ errorMessage }}</p>
      <div class="controls">
        <button class="button is-danger" @click.stop.prevent="deleteQuote">
          <i class="fa-solid fa-trash mr-1"></i>Delete
        </button>
        <button
          class="button is-warning ml-2"
          v-if="shared"
          @click.stop.prevent="openLinkedClients"
        >
          <i class="fa-solid fa-users mr-1"></i>Linked Clients
        </button>
      </div>
      <hr />
      <div class="columns">
        <div class="column is-4">
          <label class="label">Notes</label>
          <textarea
            class="textarea"
            placeholder="Notes"
            rows="2"
            v-model="quote.notes"
            @keyup="notesChanged"
          ></textarea>
          <button
            class="button is-small is-primary mt-2"
            v-if="changed"
            @click.stop.prevent="updateQuote"
          >
            Save
          </button>
        </div>
        <div class="column">
          <label class="label">From</label>
          {{ quote.loading_point }}
        </div>
        <div class="column">
          <label class="label">To</label>
          {{ quote.destination }}
        </div>
        <div class="column">
          <label class="label">Quote Number</label>
          {{ quote.quote_id }}
        </div>
        <div class="column" v-if="client">
          <label class="label">Client</label>
          {{ client }}
        </div>
        <div class="column">
          <label class="label">Quote Currency</label>
          {{ quote.currency }}
        </div>
        <div class="column">
          <label class="label">Created</label>
          {{ quote.created_at }}
        </div>
        <div class="column">
          <label class="label">Created By</label>
          {{ quote.created_by }}
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <table class="table is-fullwidth is-hoverable">
            <thead>
              <tr class="has-background-light">
                <th>Shipping Lines</th>
                <th>Size</th>
                <th style="min-width: 500px">Description</th>
                <th style="width: 150px">Amount</th>
                <th style="width: 50px">Remove</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="d in details" :key="d._id">
                <td>{{ d.shipping_lines }}</td>
                <td>{{ d.size }}</td>
                <td>{{ d.description }}</td>
                <td>
                  {{ d.amount }}
                  <span class="has-text-danger">{{ quote.currency }}</span>
                </td>
                <td>
                  <i
                    class="fa-solid fa-circle-minus delete-amount"
                    @click.stop.prevent="deleteDetail(d._id)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      <hr />
      <AddDetailToQuote :id="id" @fetch="fetchData" />
    </form>
    <QuoteLinkedClients @close="closeModal" v-if="viewClients" :id="id" />
  </div>
</template>
<script>
import dayjs from "dayjs";
import AddDetailToQuote from "./AddDetailToQuote";
import QuoteLinkedClients from "@/components/modals/QuoteLinkedClients.vue";
export default {
  components: { AddDetailToQuote, QuoteLinkedClients },
  data() {
    return {
      id: this.$route.params.id,
      isLoading: true,
      quote: {},
      details: [],
      changed: false,
      errorMessage: "",
      shared: null,
      client: null,
      viewClients: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$axios
        .post(`/api/quotes/${this.id}`, {}, { headers: this.$authHeader() })
        .then((res) => {
          this.shared = res.data.quote[0].shared;
          this.client = res.data.quote[0].client
            ? res.data.quote[0].client.company
            : null;
          this.quote = res.data.quote[0];
          this.quote.created_at = dayjs(this.quote.created_at).format(
            "YYYY-MM-DD"
          );
          this.details = res.data.quote[0].details;
          this.isLoading = false;
        })
        .catch((err) => {
          alert(err.response.data.msg);
          this.isLoading = false;
        });
    },
    deleteDetail(id) {
      if (confirm("Are you sure!")) {
        this.$axios
          .post(
            "/api/delete_detail_from_quote/",
            { quote_id: this.id, detail_id: id },
            {
              headers: this.$authHeader(),
            }
          )
          .then((res) => {
            this.fetchData();
          })
          .catch((err) => {
            alert(err.response.data.msg);
            this.isLoading = false;
          });
      }
    },
    notesChanged() {
      this.changed = true;
    },
    updateQuote() {
      this.isLoading = true;
      this.$axios
        .post(
          "/api/update_quote",
          { id: this.id, notes: this.quote.notes },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.isLoading = false;
          this.changed = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.errorMessage = err.response.data.msg;
        });
    },
    deleteQuote() {
      if (confirm("Are you sure you want to delete this quote?")) {
        this.isLoading = true;
        this.$axios
          .post(
            "/api/delete_quote/",
            { id: this.id },
            { headers: this.$authHeader() }
          )
          .then((res) => {
            this.$router.push("/admin/dashboard/quotes/");
          })
          .catch((err) => {
            this.isLoading = false;
            this.errorMessage = err.response.data.msg;
          });
      }
    },
    openLinkedClients() {
      this.viewClients = true;
    },
    closeModal() {
      this.viewClients = false;
    },
  },
};
</script>
<style scoped>
* {
  font-size: 0.9rem !important;
}

.table {
  font-size: 13px;
}
.select,
.input,
select {
  height: 40px;
}
.subtitle {
  text-align: center;
}
.delete-amount {
  color: #f14668;
  font-size: 25px;
  cursor: pointer;
  display: block;
  text-align: center;
}
</style>
