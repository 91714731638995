<template>
  <div class="modal is-active">
    <page-loader :isLoading="isLoading"></page-loader>
    <div class="modal-background" @click="$emit('closeModal')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Linked Clients</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('closeModal')"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="notification">
          <strong>{{ contact.name }}</strong>
          <p v-if="contact.contact">{{ contact.contact }}</p>
          <p v-if="contact.country">
            {{ contact.city }}, {{ contact.country }}
          </p>
          <p v-if="contact.email">{{ contact.email }}</p>
          <p v-if="contact.phone">{{ contact.phone }}</p>
          <p v-if="contact.tax_id">TAX ID{{ contact.tax_id }}</p>
        </div>
        <label class="label">Linked Clients:</label>
        <table class="table is-fullwidth">
          <thead>
            <tr class="has-background-light">
              <th>Name</th>
              <th>Company Name</th>
              <th>Display Name</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="activeClients.length <= 0">
              <td colspan="4" style="text-align: center">No Linked Clients</td>
            </tr>
            <tr v-for="client in activeClients" :key="client._id">
              <td>{{ client.name }}</td>
              <td>{{ client.company }}</td>
              <td>{{ client.display_name }}</td>
              <td>
                <button
                  class="button is-small is-danger"
                  @click.stop.prevent="handleRemove(client._id)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <hr />
        <label class="label">Link New Client:</label>
        <table class="table is-fullwidth is-hoverable">
          <thead>
            <tr class="has-background-light">
              <th>Select</th>
              <th>Company Name</th>
              <th>Name</th>
              <th>Display Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="clients.length <= 0">
              <td colspan="4" style="text-align: center">No Clients</td>
            </tr>
            <tr v-else v-for="client in clients" :key="client._id">
              <td>
                <input type="checkbox" :value="client._id" v-model="selected" />
              </td>
              <td>{{ client.company }}</td>
              <td>{{ client.name }}</td>
              <td>{{ client.display_name }}</td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click.stop.prevent="handleSubmit">
          Save changes
        </button>
        <button class="button" @click="$emit('closeModal')">Cancel</button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
    contact: Object,
  },
  data() {
    return {
      isLoading: true,
      clients: [],
      activeClients: [],
      selected: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$axios
        .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
        .then((res) => {
          let array1 = res.data.clients;
          // get linked clients
          this.$axios
            .post(
              "/api/retrieve_contacts",
              { id: this.id },
              { headers: this.$authHeader() }
            )
            .then((res) => {
              this.isLoading = false;
              this.activeClients = res.data.contacts[0].clients;
              // compare arrays and remove dup
              let array2 = this.activeClients.map((e) => e._id);
              array1 = array1.filter((val) => {
                return !array2.includes(val._id);
              });
              this.clients = array1;
            })
            .catch((err) => {
              this.isLoading = false;
              alert(err.data.response.msg);
            });
        })
        .catch((err) => {
          this.isLoading = false;
          alert(err.data.response.msg);
        });
    },
    handleSubmit() {
      this.isLoading = true;
      this.$axios
        .post(
          "/api/add_clients_to_contacts",
          { selected: this.selected, id: this.id },
          { headers: this.$authHeader() }
        )
        .then(() => {
          this.selected = [];
          this.fetchData();
        })
        .catch((err) => {
          this.isLoading = false;
          alert(err.data.response.msg);
        });
    },
    handleRemove(id) {
      this.isLoading = true;
      this.$axios
        .post(
          "/api/remove_client_from_contacts",
          { id: this.id, client: id },
          { headers: this.$authHeader() }
        )
        .then(() => {
          this.fetchData();
        })
        .catch((err) => {
          this.isLoading = false;
          alert(err.data.response.msg);
        });
    },
  },
};
</script>
<style scoped>
td,
th {
  font-size: 13px;
}
</style>
