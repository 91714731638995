<template>
  <div class="overview-container">
    <page-loader :isLoading="isLoading"></page-loader>
    <i class="fa-solid fa-circle-xmark close-button" @click="close"></i>
    <div class="container">
      <button
        class="button is-warning mb-2"
        @click.stop.prevent="print"
        v-tippy="{ content: 'Print' }"
      >
        <i class="fa-solid fa-print"></i>
      </button>

      <table class="table is-fullwidth is-bordered" id="list">
        <thead>
          <tr>
            <th>#</th>
            <th>List ID</th>
            <th>Client</th>
            <th>From</th>
            <th>To</th>
            <th>Booking Nr.</th>
            <th>Container Nr.</th>
            <th>Vehicles</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(l, index) in lists" :key="l._id">
            <td>{{ index + 1 }}</td>
            <td>{{ l.list_id }}</td>
            <td>{{ l.client.company }}</td>
            <td>{{ l.loading_point }}</td>
            <td>{{ l.destination }}</td>
            <td class="has-text-danger">{{ l.booking_number }}</td>
            <td>{{ l.container_number }}</td>
            <td>
              {{ l.autos.length }}
            </td>
            <td>{{ l.status }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
window.$ = window.jQuery = require("jquery");
const printThis = require("@/assets/printThis");
export default {
  data() {
    return {
      isLoading: true,
      total_lists: 0,
      total_items: 0,
      lists: [],
    };
  },
  created() {
    this.fetchData();
    this.isLoading = false;
  },
  methods: {
    close() {
      this.$router.go(-1);
    },
    fetchData() {
      this.$axios
        .get(`/api/retrieve_loading_lists/${this.$route.params.type}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.lists = res.data.lists.sort((a, b) => {
            return a.destination.localeCompare(b.destination);
          });
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    print() {
      let pageTitle = document.title;
      window.$("#list").printThis({
        beforePrint: () => {
          document.title = `Lists`;
        },
        afterPrint: () => {
          document.title = pageTitle;
        },
      });
    },
  },
};
</script>
<style scoped>
.overview-container {
  background: #f9f9f9;
  min-height: 100vh;
  width: 100%;
  position: relative;
  padding: 20px;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 20px;
}
.container {
  max-width: 1200 !important;
}
</style>
