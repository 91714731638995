<template>
  <page-loader :isLoading="pageLoading"></page-loader>
  <div class="box p-6">
    <router-link to="/admin/dashboard/autos/">
      <button class="button mb-6">
        <i class="fa-solid fa-angles-left mr-1"></i>Go Back to Autos
      </button>
    </router-link>
    <h1 class="title">Edit Auto</h1>
    <hr />
    <div class="notification is-danger" v-if="error">{{ error }}</div>
    <div class="notification is-primary" v-if="success">{{ success }}</div>
    <!-- VEHICLE INFO-->
    <div class="columns">
      <div class="column">
        <strong class="has-text-primary">Vehicle Information</strong>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Vin <span class="has-text-danger">*</span></label>
        <input
          type="text"
          class="input vin"
          placeholder="VIN"
          v-model="vin"
          maxlength="17"
        />
        <button
          class="button mt-1 is-warning"
          v-if="vin"
          @click.stop.prevent="decode"
        >
          Decode
          <i class="fa-solid fa-barcode ml-2"></i>
        </button>
      </div>
      <div class="column">
        <label class="label">Make <span class="has-text-danger">*</span></label>
        <v-select v-model="make" :options="makes" placeholder="Make"></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Model <span class="has-text-danger">*</span></label
        >
        <v-select
          taggable
          v-model="model"
          :options="models"
          placeholder="Model (manually type if nothing shows)"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Year <span class="has-text-danger">*</span></label>
        <v-select v-model="year" :options="years" placeholder="Year"></v-select>
      </div>
      <div class="column">
        <label class="label">Type <span class="has-text-danger">*</span></label>
        <v-select
          :options="[
            'Automobile',
            'Motorcycle',
            'Boat',
            'Heavy Vehicle',
            'ATV',
            'RV',
            'Trailer',
          ]"
          v-model="vehicle_type"
          placeholder="Vehicle Type"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Color</label>
        <v-select
          v-model="color"
          :options="colors"
          placeholder="Color"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <label class="label">Engine</label>
        <input
          type="text"
          class="input"
          placeholder="Engine"
          v-model="engine"
        />
      </div>
    </div>
    <!-- SALE INFO-->
    <div class="columns">
      <div class="column">
        <hr />
        <strong class="has-text-primary">Sale Info</strong>
        <div class="notification is-warning" v-if="client">
          <p><strong>Client Notes:</strong></p>
          {{ client.notes ? client.notes : "N/A" }}
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Buyer Number</label>
        <v-select
          :options="buyers"
          placeholder="Buyer Number"
          v-model="buyer_number"
          taggable
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Client <span class="has-text-danger">*</span></label
        >
        <v-select
          v-model="client"
          :options="clients"
          :disabled="
            status == 'Loaded' ? true : invoices.length > 0 ? true : false
          "
          placeholder="Client"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Lot</label>
        <input type="text" class="input" placeholder="Lot" v-model="lot" />
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <label class="label">Auction</label>
        <v-select
          :options="auctions"
          placeholder="Auction"
          v-model="auction"
        ></v-select>
      </div>
      <div class="column is-one-third">
        <label class="label"
          >Purchase Date <span class="has-text-danger">*</span></label
        >
        <DatePicker
          v-model="purchase_date"
          placeholder="Pick a Date"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
      <div class="column is-one-third">
        <label class="label">Location</label>
        <v-select
          :options="cities"
          placeholder="Location"
          v-model="city"
        ></v-select>
        <label class="checkbox">
          <input type="checkbox" :value="true" v-model="isSubLot" />
          Sublot
        </label>
      </div>
    </div>
    <div class="columns">
      <div class="column" v-if="isSubLot">
        <label class="label">Sublot</label>
        <textarea
          class="textarea"
          rows="2"
          v-model="sublot_location"
        ></textarea>
      </div>
    </div>
    <!-- SHIPPING INFO-->
    <div class="columns">
      <div class="column">
        <hr />
        <strong class="has-text-primary">Shipping Info</strong>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label"
          >Loading Point <span class="has-text-danger">*</span></label
        >
        <v-select
          :options="loading_points"
          :disabled="status == 'Loaded' ? true : false"
          placeholder="Loading Point"
          v-model="loading_point"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Destination <span class="has-text-danger">*</span></label
        >
        <v-select
          :options="destinations"
          :disabled="status == 'Loaded' ? true : false"
          placeholder="Destination"
          v-model="destination"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Shipping Method <span class="has-text-danger">*</span></label
        >
        <v-select
          :options="['RoRo', 'Container', 'Air']"
          placeholder="Shipping Method"
          :disabled="status == 'Loaded' ? true : false"
          v-model="shipping_method"
        ></v-select>
      </div>
    </div>
    <!-- OTHER INFO-->
    <div class="columns">
      <div class="column">
        <hr />
        <strong class="has-text-primary">Other</strong>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Title Status</label>
        <v-select
          :options="['Present', 'Pending', 'No Title']"
          placeholder="Title Status"
          v-model="title_status"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Keys</label>
        <v-select
          :options="['Present', 'No Keys']"
          placeholder="Keys"
          v-model="keys"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Vehicle Status <span class="has-text-danger">*</span></label
        >
        <v-select
          :disabled="status == 'Loaded' ? true : false"
          :options="['New purchase', 'Dispatched', 'At terminal']"
          placeholder="Vehicle Status"
          v-model="status"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Title Received On</label>
        <DatePicker
          v-model="title_received"
          placeholder="Pick a Date"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
      <div class="column">
        <label class="label">Custom 1</label>
        <v-select
          :options="customs1"
          placeholder="Custom Field 1"
          v-model="custom1"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Custom 2</label>
        <v-select
          :options="customs2"
          placeholder="Custom Field 2"
          v-model="custom2"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <label class="label">Arrived at Terminal on</label>
        <DatePicker
          v-model="delivery_date"
          placeholder="Pick a Date"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
      <div class="column">
        <label class="label"
          >Delivery <span class="has-text-danger">*</span></label
        >
        <div class="control">
          <label class="radio">
            <input
              type="radio"
              name="answer"
              v-model="self_delivery"
              :value="true"
            />
            Self Delivery
          </label>
          <label class="radio">
            <input
              type="radio"
              name="answer"
              v-model="self_delivery"
              :value="false"
            />
            To Be Dispatched
          </label>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Notes</label>
        <textarea
          v-model="notes"
          class="textarea"
          placeholder="Notes"
        ></textarea>
      </div>
    </div>
    <!-- UPLOAD DOCUMENTS-->
    <div class="columns">
      <div class="column">
        <hr />
        <strong class="has-text-primary">Documents</strong>
      </div>
    </div>
    <!-- SELECTED FILES TABLE-->
    <div class="columns">
      <table
        class="table is-fullwidth docs is-bordered"
        v-if="files.length > 0"
      >
        <thead>
          <tr class="has-background-primary">
            <th>Type</th>
            <th>File Name</th>
            <th width="100px">Remove</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in files" :key="file.name">
            <td>{{ file.file_type }}</td>
            <td>{{ file.name }}</td>
            <td>
              <button
                class="button is-danger is-small"
                @click="handleRemoveFile(file.name)"
              >
                <i class="fa-regular fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- UPLOAD MODAL -->
    <div :class="`modal ${docsModalOpen ? 'is-active' : ''}`">
      <div class="modal-background" @click="closeDocsModal"></div>
      <div class="modal-content">
        <div class="box">
          <h1 class="subtitle">Upload</h1>
          <hr />
          <v-select
            placeholder="Type"
            v-model="file_type"
            taggable
            :options="['Report', 'BOS', 'Gate Pass', 'Title']"
          ></v-select>
          <div class="file mt-4" v-if="file_type">
            <label class="file-label">
              <input class="file-input" type="file" @change="handleDocUpload" />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label"> Choose a file… </span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeDocsModal"
      ></button>
    </div>
    <div class="columns">
      <div class="column">
        <button class="button" @click="openDocsModal">
          Upload <i class="fa-solid fa-arrow-up-from-bracket ml-2"></i>
        </button>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <p class="mb-2">Uploaded Documents:</p>
        <p v-for="d in uploadedDocs" :key="d.document">
          <a class="link" target="__blank" :href="`/api/files/${d.document}`"
            >{{ d.type }} <i class="fa-solid fa-file"></i
          ></a>
          <i
            class="fa-solid fa-trash-can ml-4 has-text-danger"
            style="cursor: pointer"
            @click="deleteFile(d.document, 'document')"
          ></i>
        </p>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <hr />
        <strong class="has-text-primary">Images</strong>
      </div>
    </div>
    <div class="columns">
      <div class="column is-3">
        <div class="file is-boxed is-warning">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              @change="handleThumbnailUpload"
            />
            <span class="file-cta">
              <span class="file-icon"> <i class="fa-solid fa-image"></i> </span>
              <span class="file-label">Thumbnail</span>
            </span>
          </label>
        </div>
      </div>
      <div class="column is-3">
        <div class="file is-boxed is-warning">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              multiple
              @change="handleImages"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fa-solid fa-images"></i>
              </span>
              <span class="file-label">Images</span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <p class="mb-2">Uploaded images:</p>
    <p>
      <button
        class="button mb-3 is-danger"
        @click.stop.prevent="deleteFile(i, 'all_images')"
        v-if="uploadedImages && uploadedImages.length > 0"
      >
        Delete All Images
      </button>
    </p>
    <figure class="image is-128x128" v-for="i in uploadedImages" :key="i">
      <img :src="`/api/files/${i}`" />
      <button
        class="button is-danger"
        @click.stop.prevent="deleteFile(i, 'image')"
      >
        <i class="fa-solid fa-trash-can"></i>
      </button>
    </figure>
    <hr />
    <button class="button is-primary" @click.stop.prevent="handleSubmit">
      Submit
    </button>
  </div>
</template>
<script>
import colors from "@/components/helpers/colors";
import makes from "@/components/helpers/makes";
import getYears from "@/components/helpers/getYears";
import Compressor from "compressorjs";
import dayjs from "dayjs";
export default {
  data() {
    return {
      isSubLot: false,
      subLotLocation: null,
      vin: null,
      models: [],
      make: null,
      model: null,
      years: [],
      pageLoading: true,
      year: null,
      color: null,
      error: null,
      engine: null,
      vehicle_type: null,
      clients: [],
      client: null,
      auctions: [],
      cities: [],
      destinations: [],
      loading_points: [],
      destination: null,
      loading_point: null,
      shipping_method: null,
      title_status: null,
      keys: null,
      status: null,
      self_delivery: null,
      city: null,
      lot: null,
      buyer_number: null,
      sublot_location: null,
      delivery_date: null,
      weight: null,
      customs1: [],
      customs2: [],
      custom1: null,
      custom2: null,
      purchase_date: null,
      title_received: null,
      auction: null,
      buyers: [],
      buyersList: [],
      files: [],
      file_types: [],
      thumbnail: null,
      images: [],
      changed: false,
      file_type: null,
      docsModalOpen: false,
      success: false,
      uploadedDocs: [],
      uploadedImages: [],
      notes: null,
      invoices: [],
    };
  },
  computed: {
    makes() {
      return makes;
    },
    colors() {
      return colors;
    },
  },
  watch: {
    // UPDATE MODEL WHEN MAKE CHANGES
    make(selected_make, prev) {
      if (selected_make !== prev) {
        this.model = null;
      }
      if (selected_make) {
        this.$axios
          .get(
            `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${selected_make.toLowerCase()}?format=json`
          )
          .then((res) => {
            this.models = res.data.Results.map((m) => m.Model_Name);
          });
      }
      if (!selected_make) {
        this.models = [];
      }
    },
  },
  // RETRIEVE REQUIRED FIELDS
  created() {
    //get auto's information
    this.fetchAuto();
    // generate Years
    this.years = getYears();
    // get clients
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        let a = [];
        res.data.clients.map((c) => {
          a.push({ label: c.company, code: c._id, notes: c.notes });
        });
        this.clients = a;
      });
    // Get Selections
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        let auctions = [];
        let destinations = [];
        let cities = [];
        let loading_points = [];
        res.data.selections.map((s) => {
          if (s.type == "Auctions") {
            auctions.push(s.value);
          }
          if (s.type == "Loading Points") {
            loading_points.push(s.value);
          }
          if (s.type == "Destinations") {
            destinations.push(s.value);
          }
          if (s.type == "Cities") {
            cities.push(s.value);
          }
          if (s.type == "Custom Field 1") {
            this.customs1.push(s.value);
          }
          if (s.type == "Custom Field 2") {
            this.customs2.push(s.value);
          }
          this.auctions = auctions;
          this.cities = cities;
          this.destinations = destinations;
          this.loading_points = loading_points;
        });
      });
    // get buyers
    this.$axios
      .post("/api/retrieve_buyers", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.buyers.map((b) => {
          this.buyers.push(b.buyer_number);
        });
        this.buyersList = res.data.buyers;
      });
  },
  methods: {
    // decode vin
    decode() {
      this.pageLoading = true;
      this.error = null;
      this.$axios
        .get(
          `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${this.vin}?format=json`
        )
        .then((res) => {
          // convert to lower case
          let make = res.data.Results[0].Make.toLowerCase();
          make = make.charAt(0).toUpperCase() + make.slice(1);
          this.make = make;
          // get models (to double check tt=hat models are pulled before changing state)
          this.$axios
            .get(
              `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${this.make}?format=json`
            )
            .then((data) => {
              this.pageLoading = false;
              this.models = data.data.Results.map((m) => m.Model_Name);
              this.model = res.data.Results[0].Model;
              this.year = Number(res.data.Results[0].ModelYear);
              this.engine = `${res.data.Results[0].EngineCylinders}Cyls / ${res.data.Results[0].DisplacementL}L`;
              // fillout vehicle type
              if (res.data.Results[0].VehicleType == "MOTORCYCLE") {
                this.vehicle_type = "Motorcycle";
              }
              if (
                res.data.Results[0].VehicleType == "PASSENGER CAR" ||
                res.data.Results[0].VehicleType ==
                  "MULTIPURPOSE PASSENGER VEHICLE (MPV)"
              ) {
                this.vehicle_type = "Automobile";
              }
              if (res.data.Results[0].VehicleType == "TRAILER") {
                this.vehicle_type = "Trailer";
              }
              if (res.data.Results[0].GVWR) {
                this.weight =
                  res.data.Results[0].GVWR.split("(")[1].split(" ")[0] + "kg";
              }
              if (!res.data.Results[0].GVWR) {
                this.weight = null;
              }
            })
            .catch((err) => {
              this.make = null;
              this.pageLoading = false;
              this.error =
                "Failed to decode this VIN, please double-check or enter the information manually";
            });
        })
        .catch((err) => {
          this.pageLoading = false;
          this.make = null;
          this.error =
            "Failed to decode this VIN, please double-check or enter the information manually";
        });
    },
    // Handle Documents Upload
    openDocsModal() {
      this.docsModalOpen = true;
    },
    closeDocsModal() {
      this.docsModalOpen = false;
      this.file_type = null;
    },
    handleDocUpload(e) {
      let file = e.target.files[0];
      let type = file.type.split("/")[1];
      if (type == "jpeg" || type == "pdf" || type == "png") {
        this.file_types.push(this.file_type + "/" + file.name);
        file.file_type = this.file_type || "N/A";
        this.files.push(file);
        this.file_type = null;
        this.closeDocsModal();
      } else {
        return alert("Only images and PDF files are allowed");
      }
    },
    handleRemoveFile(file_name) {
      this.files = this.files.filter((f) => {
        return f.name !== file_name;
      });
    },
    // Handle Images Upload
    async handleImages(e) {
      this.pageLoading = true;
      let files = e.target.files;
      this.images = [];
      let i = [];
      function compress(img) {
        return new Promise((resolve, reject) => {
          new Compressor(img, {
            quality: 0.8,
            maxWidth: 2500,
            success(res) {
              resolve(res);
            },
          });
        });
      }
      await Promise.all(
        Array.from(files).map(async (f) => {
          if (
            f.type.split("/")[1] == "png" ||
            f.type.split("/")[1] == "jpg" ||
            f.type.split("/")[1] == "jpeg"
          ) {
            let img = await compress(f);
            i.push(img);
          } else {
            this.images = [];
            return alert("Only images and PDF files are allowed");
          }
        })
      );
      this.images = i;
      this.pageLoading = false;
    },
    // HANDLE THUMBNAIL UPLOAD
    handleThumbnailUpload(e) {
      let file = e.target.files[0];
      let self = this;
      if (
        file.type.split("/")[1] == "png" ||
        file.type.split("/")[1] == "jpg" ||
        file.type.split("/")[1] == "jpeg"
      ) {
        new Compressor(file, {
          quality: 1,
          maxWidth: 100,
          success(res) {
            self.thumbnail = res;
          },
        });
      } else {
        this.thumbnail = null;
        return alert("Only images and PDF files are allowed");
      }
    },
    // HANDLE FORM SUBMIT
    handleSubmit() {
      // validate inputs
      if (
        !this.vin ||
        !this.year ||
        !this.make ||
        !this.model ||
        !this.vehicle_type ||
        !this.client ||
        !this.purchase_date ||
        !this.loading_point ||
        !this.destination ||
        !this.shipping_method ||
        !this.status ||
        this.self_delivery == null
      ) {
        this.success = null;
        window.scrollTo(0, 0);
        return (this.error = "Please fill out all the required fields");
      }
      this.pageLoading = true;
      // get files
      let fd = new FormData();
      // get Images
      this.files.map((f) => fd.append("documents", f));
      this.images.map((f) => fd.append("images", f));
      fd.append("thumbnail", this.thumbnail);
      fd.append("file_types[]", this.file_types);
      fd.append("vin", this.vin.trim().toUpperCase());
      fd.append("make", this.make);
      fd.append("model", this.model);
      fd.append("year", this.year);
      fd.append("vehicle_type", this.vehicle_type);
      if (this.color) {
        fd.append("color", this.color);
      }
      if (this.engine) {
        fd.append("engine", this.engine);
      }
      if (this.weight) {
        fd.append("weight", this.weight);
      }
      if (this.buyer_number) {
        fd.append("buyer_number", this.buyer_number);
      }
      fd.append("client", this.client.code);
      if (this.lot) {
        fd.append("lot", this.lot);
      }
      if (this.auction) {
        fd.append("auction", this.auction);
      }
      if (this.purchase_date) {
        fd.append(
          "purchase_date",
          dayjs(this.purchase_date).format("YYYY-MM-DD")
        );
      }
      if (this.delivery_date) {
        fd.append(
          "delivery_date",
          dayjs(this.delivery_date).format("YYYY-MM-DD")
        );
      }
      if (this.city) {
        fd.append("city", this.city);
      }
      fd.append("sublot", this.isSubLot);
      fd.append("id", this.$route.params.id);
      if (this.sublot_location) {
        fd.append("sublot_location", this.sublot_location);
      }
      fd.append("loading_point", this.loading_point);
      fd.append("destination", this.destination);
      fd.append("shipping_method", this.shipping_method);
      if (this.title_status) {
        fd.append("title_status", this.title_status);
      }
      if (this.keys) {
        fd.append("keys", this.keys);
      }
      if (this.notes) {
        fd.append("notes", this.notes);
      }
      fd.append("status", this.status);

      if (this.title_received) {
        fd.append(
          "title_received",
          dayjs(this.title_received).format("YYYY-MM-DD")
        );
      }

      if (this.custom1) {
        fd.append("custom1", this.custom1);
      }
      if (this.custom2) {
        fd.append("custom2", this.custom2);
      }
      fd.append("self_delivery", this.self_delivery);
      let headers = this.$authHeader();
      // changing the headers to include files
      headers["Content-Type"] = "multipart/form-data";
      this.$axios
        .post("/api/edit_auto", fd, {
          headers,
        })
        .then((res) => {
          this.pageLoading = false;
          this.error = null;
          window.scrollTo(0, 0);
          this.success = res.data.msg;
          // reset state
          (this.files = []),
            (this.thumbnail = null),
            (this.images = []),
            (this.changed = false),
            ((this.file_types = []), (this.file_type = null));
          this.$router.push("/admin/dashboard/autos/");
        })
        .catch((err) => {
          this.pageLoading = false;
          this.success = null;
          window.scrollTo(0, 0);
          this.error = err.response.data.msg;
        });
    },
    fetchAuto() {
      this.images = [];
      this.$axios
        .post(
          "/api/retrieve_auto",
          { id: this.$route.params.id },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          let a = res.data.auto;
          this.vin = a.vin;
          this.make = a.make;
          this.year = a.year;
          this.vehicle_type = a.vehicle_type;
          this.color = a.color;
          this.engine = a.engine;
          this.weight = a.weight;
          this.notes = a.notes;
          this.buyer_number = a.buyer_number;
          this.client = {
            code: a.client._id,
            label: a.client.company,
            notes: a.client.notes,
          };
          this.lot = a.lot;
          this.invoices = a.invoices;
          this.auction = a.auction;
          this.purchase_date = new Date(dayjs(a.purchase_date).add(1, "day"));
          this.city = a.city;
          this.isSubLot = a.sublot;
          this.sublot_location = a.sublot_location;
          this.loading_point = a.loading_point;
          this.destination = a.destination;
          this.shipping_method = a.shipping_method;
          this.status = a.status;
          this.title_status = a.title_status;
          this.keys = a.keys;
          this.uploadedImages = a.images;
          this.uploadedDocs = a.documents;
          if (a.title_received) {
            this.title_received = new Date(
              dayjs(a.title_received).add(1, "day")
            );
          }
          if (a.delivery_date) {
            this.delivery_date = new Date(dayjs(a.delivery_date).add(1, "day"));
          }
          this.custom1 = a.custom1;
          this.custom2 = a.custom2;
          this.self_delivery = a.self_delivery;
          this.$axios
            .get(
              `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${this.make}?format=json`
            )
            .then((data) => {
              this.model = a.model;
              this.pageLoading = false;
            });
        });
    },
    // delete image or file
    deleteFile(file, type) {
      this.$axios
        .post(
          "/api/autos/delete_file",
          {
            auto_id: this.$route.params.id,
            type,
            file,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.pageLoading = true;
          this.fetchAuto();
        });
    },
  },
};
</script>
<style scoped>
.container {
  background: #fff;
}

.modal .box {
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  height: 300px;
}
.docs th {
  color: #fff;
}
.docs {
  text-align: center;
}
.docs td {
  font-weight: bold;
}
.vin {
  text-transform: uppercase;
}
.image {
  width: 70px;
  height: 70px;
  border: 1px solid #ddd;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.image:hover .button {
  display: block;
}
.image img {
  width: 100%;
  height: 100%;
}
.image .button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
  transition: 500ms;
  opacity: 0.9;
}
</style>
