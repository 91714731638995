<template>
  <div class="invoice-container p-6">
    <page-loader :isLoading="pageLoading"></page-loader>
    <div class="invoice-header">
      <h1 class="subtitle">Payment Statement</h1>
      <hr />
      <i
        class="fa-regular fa-circle-xmark close-button is-size-3 is-clickable"
        @click="$router.go(-1)"
      ></i>
      <div class="notification is-warning" v-if="transaction.locked">
        <i class="fa-solid fa-lock"></i> This payment is locked, no changes are
        allowed. Only account owner can unlock it.
      </div>
      <div class="notification is-danger" v-if="error">{{ error }}</div>
    </div>
    <div class="columns" style="overflow: auto">
      <div class="column">
        <div class="actions p-4 box-shadow">
          <button
            @click.stop="openInvoices"
            v-if="!transaction.locked"
            class="button is-primary"
            v-tippy="{ content: 'Apply Payment' }"
          >
            <i class="fa-solid fa-plus"></i>
          </button>
          <button
            class="button is-warning ml-3"
            @click.stop.prevent="print"
            v-tippy="{ content: 'Print Payment' }"
          >
            <i class="fa-solid fa-print"></i>
          </button>
          <button
            @click.stop="deletePayment"
            class="button is-danger ml-3"
            v-tippy="{ content: 'Delete Payment' }"
          >
            <i class="fa-solid fa-trash-can"></i>
          </button>
          <button
            @click.stop="lockPayment"
            v-if="!transaction.locked"
            class="button is-info ml-3"
            v-tippy="{ content: 'Lock Payment' }"
          >
            <i class="fa-solid fa-lock"></i>
          </button>
          <button
            v-if="transaction.locked"
            @click.stop="unlockPayment"
            class="button is-primary ml-3"
            v-tippy="{ content: 'Unlock Payment' }"
          >
            <i class="fa-solid fa-lock-open"></i>
          </button>
        </div>
        <div :id="`invoice`" class="invoice box-shadow p-6 mt-4">
          <img
            :src="`/api/files/${this.$store.state.authUser.logo}`"
            alt="logo"
            class="logo"
          />
          <h1 class="subtitle has-text-right">Payment Statement</h1>
          <hr />
          <h1>Printed At: {{ printDate }}</h1>
          <h1>ID: {{ transaction.transaction_number }}</h1>
          <table class="payment_info">
            <tr>
              <th colspan="4" class="has-text-centered">Payment Details</th>
            </tr>
            <tr>
              <td>
                <p><strong>Date</strong></p>
                <p>{{ transaction.date }}</p>
              </td>
              <td>
                <p><strong>Currency</strong></p>
                <p>({{ transaction.currency }})</p>
              </td>
              <td>
                <p><strong>Client</strong></p>
                <p>{{ transaction.client.company }}</p>
              </td>
              <td>
                <p><strong>Payment Method</strong></p>
                <p>{{ transaction.payment_method }}</p>
              </td>
            </tr>
            <tr>
              <td>
                <p><strong>Type</strong></p>
                <p>{{ transaction.type }}</p>
              </td>
              <td>
                <p><strong>Amount</strong></p>
                <p>{{ transaction.amount }}</p>
              </td>
              <td>
                <p><strong>Used</strong></p>
                <p>{{ this.used }}</p>
              </td>
              <td>
                <p><strong>Unallocated</strong></p>
                <p>{{ this.unallocated }}</p>
              </td>
            </tr>
            <tr>
              <th colspan="4" class="has-text-centered">Notes</th>
            </tr>
            <tr>
              <td colspan="4">{{ transaction.notes }}</td>
            </tr>
          </table>
          <table class="mt-6">
            <thead>
              <tr>
                <th width="100px">Invoice Nr.</th>
                <th>Description</th>
                <th width="100px">Applied</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="p in transaction.payments"
                :key="p._id"
                class="is-relative"
              >
                <td>{{ p.invoice.invoice_number }}</td>
                <td>{{ p.description }}</td>
                <td>
                  {{ p.amount
                  }}<i
                    class="fa-solid fa-trash-can remove"
                    @click.stop="deleteRecord(p._id)"
                  ></i>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
  <InvoicesModal
    :transaction_id="id"
    @closed="
      () => {
        this.invoicesModal = false;
        this.fetchTransaction();
      }
    "
    :client_id="transaction.client._id"
    :currency="transaction.currency"
    :amount="transaction.amount"
    :payments="transaction.payments"
    v-if="invoicesModal"
  />
</template>
<script>
import dayjs from "dayjs";
window.$ = window.jQuery = require("jquery");
import InvoicesModal from "./InvoicesModal.vue";
const printThis = require("@/assets/printThis");
export default {
  data() {
    return {
      id: this.$route.params.id,
      error: null,
      pageLoading: false,
      transaction: {},
      unallocated: 0,
      used: 0,
      invoicesModal: false,
    };
  },
  computed: {
    printDate() {
      return dayjs().format("YYYY-MM-DD");
    },
  },
  created() {
    this.fetchTransaction();
  },
  components: { InvoicesModal },
  methods: {
    fetchTransaction() {
      (this.unallocated = 0), (this.used = 0);
      this.$axios
        .get(`/api/retrieve_transaction/${this.id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.transaction = res.data.transaction;
          this.transaction.payments = res.data.transaction.payments.map((p) => {
            if (p.invoice.auto) {
              p.description = `${p.invoice.auto.year} ${p.invoice.auto.make} ${p.invoice.auto.model} vin#${p.invoice.auto.vin}`;
            }
            if (p.invoice.container) {
              p.description = `Container Nr:${p.invoice.container.container_number} Booking Nr:${p.invoice.container.booking_number}`;
            }
            if (p.invoice.part) {
              p.description = `${p.invoice.part.description} qty:${p.invoice.part.qty}`;
            }
            this.used += p.amount;
            return p;
          });
          this.unallocated = (this.transaction.amount - this.used).toFixed(2);
          if (res.data.transaction.date) {
            this.transaction.date = dayjs(res.data.transaction.date)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
    addField() {
      let amount = this.amount;
      let description = this.description;
      // 1 validate
      if (isNaN(amount)) {
        return (this.error = "Amount is not valid");
      }
      if (this.amount == 0 || this.amount < 0) {
        return (this.error = "Amount is not valid");
      }
      if (!this.amount || !this.description) {
        return (this.error = "Please fill out all the required fields");
      }
      if (this.description == "Other" && !this.other) {
        return (this.error = "Please fill out all the required fields");
      }
      if (this.description == "Other") {
        description = this.other;
      }
      this.$axios
        .post(
          `/api/add_invoice_field/${this.id}`,
          {
            amount: (Math.round(this.amount * 100) / 100).toFixed(2),
            description,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.error = null;
          this.fetchInvoice();
          (this.amount = null), (this.other = null), (this.description = null);
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
    lockPayment() {
      if (confirm("Are you sure?")) {
        // delete
        this.$axios
          .get(`/api/lock_payment/${this.id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchTransaction();
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      }
    },
    unlockPayment() {
      if (confirm("Are you sure?")) {
        // delete
        this.$axios
          .get(`/api/unlock_payment/${this.id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchTransaction();
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      }
    },
    print() {
      let pageTitle = document.title;
      window.$("#invoice").printThis({
        beforePrint: () => {
          document.title = `Payment#${this.transaction.transaction_number}`;
        },
        afterPrint: () => {
          document.title = pageTitle;
        },
      });
    },
    deletePayment() {
      if (confirm("Are you sure?")) {
        // delete
        this.$axios
          .get(`/api/delete_transaction/${this.id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.$router.push("/admin/dashboard/payments");
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      }
    },
    openInvoices() {
      this.invoicesModal = true;
    },
    deleteRecord(id) {
      if (confirm("Are you sure?")) {
        this.$axios
          .get(`/api/delete_payment/${id}/${this.id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchTransaction();
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      }
    },
  },
};
</script>
<style scoped>
.invoice-container {
  background: #f9f9f9;
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.invoice {
  min-height: 900px;
  width: 1000px;
  border-radius: 10px;
  font-size: 0.8rem;
  color: #000;
  margin: auto;
  background: #fff;
}

.invoice table,
.actions table {
  border-collapse: collapse;
  width: 100%;
}
.invoice td,
.invoice th,
.actions td,
.actions th {
  border: 1px solid rgb(206, 206, 206);
  padding: 5px;
  color: #000;
}
.invoice th,
.actions th {
  background: #eee;
}
.desc td {
  border: none;
  border-bottom: 1px dashed #ddd;
}
.actions {
  border-radius: 5px;
  background: #393a3d;
  border: 1px solid #ddd;
  max-width: 1000px;
  margin: auto;
}
.columns {
  max-width: 2500px;
  margin: auto;
}
strong {
  color: #000;
}
.info td {
  border: none;
  border-bottom: 1px solid #ddd;
}
.field i {
  display: none;
}
.field:hover i {
  display: inline-block;
}
</style>
<style>
@media print {
  @page {
    margin: 15px;
  }
  .invoice {
    box-shadow: none !important;
  }
  body {
    background: #fff;
  }
}
.payment_info td {
  border: none !important;
  text-align: center !important;
}
.payment_info tr {
  border-bottom: 1px dashed #ddd;
}
.remove {
  position: absolute;
  right: 10px;
  top: 10px;
  color: red;
  cursor: pointer;
  display: none;
}
tr:hover .remove {
  display: inline-block;
}
</style>
