<template>
  <div class="box">
    <h1 class="title">Containers</h1>
    <hr />

    <ContainersTable />
  </div>
</template>
<script>
import ContainersTable from "./ContainersTable.vue";
export default {
  data() {
    return {};
  },
  components: { ContainersTable },
  methods: {},
};
</script>
