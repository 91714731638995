<template>
  <div class="box">
    <router-link to="/admin/dashboard/clients/">
      <button class="button mb-6">
        <i class="fa-solid fa-angles-left mr-1"></i>Go Back to Clients
      </button>
    </router-link>
    <h1 class="title">Add Client</h1>
    <hr />
    <form>
      <!-- Alert Messages -->
      <MessageAlert
        :msg="this.errorMessage"
        messageType="error"
        v-if="this.errorAlert"
      />
      <MessageAlert
        :msg="this.successMessage"
        messageType="success"
        v-if="this.successAlert"
      />
      <div class="column">
        <div class="field">
          <label class="label">Full Name <span>*</span> </label>
          <input
            type="text"
            class="input"
            v-model="name"
            placeholder="Enter client's full name"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Username <span>*</span></label>
          <input
            type="text"
            class="input"
            v-model="username"
            placeholder="Select a username for the client"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Email <span>*</span></label>
          <input
            type="email"
            class="input"
            v-model="email"
            placeholder="example@example.com"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Company <span>*</span></label>
          <input
            type="text"
            class="input"
            v-model="company"
            placeholder="Client's company name"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Display Name </label>
          <input
            type="text"
            class="input"
            v-model="display_name"
            placeholder="Optional (will be used as a shorthand for company)"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Country</label>
          <div class="select is-fullwidth">
            <select v-model="country">
              <CountriesList />
            </select>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">State</label>
          <input
            type="text"
            class="input"
            v-model="state"
            placeholder="State"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Street Address</label>
          <input
            type="text"
            class="input"
            v-model="address"
            placeholder="Street address"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">City</label>
          <input type="text" class="input" v-model="city" placeholder="City" />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Postal Code</label>
          <input
            type="text"
            class="input"
            v-model="postal_code"
            placeholder="Postal Code / Zip"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Phone Number</label>
          <input
            type="text"
            class="input"
            v-model="phone"
            placeholder="Phone number"
          />
        </div>
      </div>
      <div class="column">
        <div
          class="field"
          v-tippy="{
            content:
              'Please include the company name and full address, this will be shown in the invoices and quotes for this client.',
            placement: 'top',
          }"
        >
          <label class="label">Billing Info</label>
          <textarea
            class="textarea has-fixed-size"
            v-model="billing_address"
            placeholder="Billing Info"
          ></textarea>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Notes</label>
          <textarea
            class="textarea has-fixed-size"
            v-model="notes"
            placeholder="Notes about the client"
          ></textarea>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">VIP Client</label>
          <label class="checkbox mr-4">
            <input type="checkbox" v-model="vip_client" :value="true" />
            Yes
          </label>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Active Currencies <span>*</span></label>
          <label
            class="checkbox mr-4"
            v-for="currency in allCurrencies"
            :key="currency"
          >
            <input type="checkbox" v-model="currencies" :value="currency" />
            {{ currency }}
          </label>
        </div>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <div class="field">
              <label class="label">Password <span>(required)</span></label>
              <input
                type="password"
                class="input"
                v-model="password"
                placeholder="Paswword"
              />
            </div>
          </div>
          <div class="column">
            <div class="field">
              <label class="label"
                >Confirm Password <span>(required)</span></label
              >
              <input
                type="password"
                class="input"
                v-model="confirm_password"
                placeholder="Confirm the password"
              />
            </div>
          </div>
        </div>
      </div>

      <div class="column">
        <button
          :class="`button is-primary ${isLoading ? 'is-loading' : ''}`"
          @click.prevent="handleSubmit"
        >
          Submit
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import CountriesList from "@/components/forms/CountriesList.vue";
import MessageAlert from "@/components/messages/MessageAlert.vue";
export default {
  data() {
    return {
      name: "",
      email: "",
      company: "",
      username: "",
      country: "",
      state: "",
      city: "",
      address: "",
      phone: "",
      notes: "",
      postal_code: "",
      billing_address: "",
      vip_client: false,
      password: "",
      confirm_password: "",
      errorAlert: false,
      successAlert: false,
      errorMessage: "",
      successMessage: "",
      display_name: "",
      currencies: [],
      isLoading: false,
      allCurrencies: [],
    };
  },
  components: { CountriesList, MessageAlert },
  methods: {
    handleSubmit() {
      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
      this.isLoading = !this.isLoading;
      // Validate the form

      // validate username
      const usernameRegex = /^[a-z0-9_.]+$/;
      if (!usernameRegex.test(this.username)) {
        this.errorAlert = true;
        this.errorMessage =
          "Username can't contain spaces or special characters.";
        window.scrollTo(0, 0);
        this.isLoading = !this.isLoading;
        return;
      }
      //check for required inputs
      if (
        !this.password ||
        !this.confirm_password ||
        !this.email ||
        !this.username ||
        !this.name ||
        !this.company ||
        this.currencies.length == 0
      ) {
        this.errorAlert = true;
        this.errorMessage = "Please fill out the required inputs.";
        window.scrollTo(0, 0);
        this.isLoading = !this.isLoading;
        return;
      }
      //1-check for password
      else if (this.password !== this.confirm_password) {
        this.errorAlert = true;
        this.errorMessage = "Passwords don't match";
        window.scrollTo(0, 0);
        this.isLoading = !this.isLoading;
        return;
      }

      // validate email
      else if (!validateEmail(this.email)) {
        this.errorAlert = true;
        this.errorMessage = "Please Enter a valid email address.";
        window.scrollTo(0, 0);
        this.isLoading = !this.isLoading;
        return;
      } else {
        // submit the request
        this.$axios
          .post("/api/add_client", this.$data, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            // Client Was Created
            this.errorAlert = false;
            this.successAlert = true;
            this.successMessage = res.data.msg;
            this.name = "";
            this.email = "";
            this.username = "";
            this.country = "";
            this.state = "";
            this.city = "";
            this.postal_code = "";
            this.phone = "";
            this.notes = "";
            this.password = "";
            this.confirm_password = "";
            this.billing_address = "";
            this.company = "";
            this.display_name = "";
            this.address = "";
            this.vip_client = false;
            window.scrollTo(0, 0);
            this.isLoading = !this.isLoading;
          })
          .catch((err) => {
            this.errorAlert = true;
            this.errorMessage = err.response.data.msg;
            window.scrollTo(0, 0);
            this.isLoading = !this.isLoading;
          });
      }
    },
    fetchData() {
      this.$axios
        .post(
          "/api/retrieve_user_settings",
          {},
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.allCurrencies = res.data.settings.currencies;
        })
        .catch((err) => {
          this.errorAlert = true;
          this.successAlert = false;
          this.errorMessage = err.response.data.msg;
        });
    },
  },
  mounted() {
    this.fetchData();
  },
};
</script>
<style scoped>
span {
  color: rgb(236, 56, 56);
  font-weight: lighter;
}
</style>
