let Sizes;
export default Sizes = [
  "20'",
  "40' Standard",
  "40' HC",
  "45' HC",
  "48' HC",
  "53' HC",
  "Open Top",
  "Refrigerated",
];
