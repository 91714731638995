<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <div class="box">
        <h1 class="subtitle">Change Receiver</h1>
        <hr />
        <v-select
          placeholder="Receiver"
          :options="receivers"
          v-model="changedReceiver"
        ></v-select>
        <button
          class="button is-primary is-small mt-3"
          @click.stop="handleSubmit"
        >
          Submit
        </button>
        <hr />
        <h1 class="subtitle is-clickable" @click="toggleReceivers">
          Receivers List <i class="fa-solid fa-arrow-down has-text-primary"></i>
        </h1>
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <div v-if="showReceivers">
          <div class="field has-addons">
            <div class="control">
              <input
                class="input"
                type="text"
                placeholder="Receiver's name"
                v-model="new_receiver"
              />
            </div>
            <div class="control">
              <a
                class="button is-primary"
                style="height: 35px"
                @click="handleAdd"
              >
                Add
              </a>
            </div>
          </div>
          <table class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>Receiver</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="r in filteredReceivers" :key="r.code">
                <td>
                  <span>{{ r.label }}</span>
                  <span class="is-pulled-right">
                    <span>
                      <i
                        @click.stop="handleDelete(r.code)"
                        class="fa-solid fa-trash-can has-text-danger ml-3 is-clickable is-size-6"
                      ></i
                    ></span>
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>
<script>
export default {
  props: {
    auto_id: String,
  },
  data() {
    return {
      receivers: [],
      new_receiver: null,
      error: null,
      showReceivers: false,
      changedReceiver: null,
      showUpdateModal: false,
      filteredReceivers: [],
    };
  },
  created() {
    this.fetchReceivers();
  },
  methods: {
    toggleReceivers() {
      this.showReceivers = !this.showReceivers;
    },
    handleAdd() {
      if (!this.new_receiver) {
        return (this.error = "Receiver name is mandatory");
      }
      // send request to backend to add receiver
      this.$axios
        .get(`/api/add_receiver/${this.new_receiver}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.error = null;
          this.new_receiver = null;
          this.fetchReceivers();
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
    fetchReceivers() {
      this.$axios
        .get("/api/get_receivers", { headers: this.$authHeader() })
        .then((res) => {
          this.receivers = [];
          this.receivers = res.data.receivers.map((r) => {
            return { label: r.name, code: r._id, beingEdited: false };
          });
          this.receivers.unshift({ label: "Unset", code: "unset" });
          this.filteredReceivers = [];
          this.filteredReceivers = this.receivers.filter((r) => {
            return r.code !== "unset";
          });
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
    handleDelete(id) {
      if (confirm("Are you sure?")) {
        this.$axios
          .get(`/api/delete_receiver/${id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchReceivers();
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      }
    },
    handleSubmit() {
      if (!this.changedReceiver) {
        return (this.error = "Please select a receiver");
      }
      this.$axios
        .get(
          `/api/add_receiver_to_vehicle/${this.auto_id}/${this.changedReceiver.code}`,
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.$emit("close");
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
  },
};
</script>
<style scoped>
.modal,
.modal-content {
  overflow: visible !important;
}
.r {
  padding: 3px;
}
.r:focus {
  outline: 1px solid #ddd;
}
</style>
