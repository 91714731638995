<template>
  <div :class="`modal is-active`" id="client-info">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{ client.company }}
          <i
            v-if="client.vip_client"
            class="fa-solid fa-crown ml-3 has-text-warning"
            title="VIP Client"
          ></i>
        </p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth is-bordered">
              <tr>
                <th>Name</th>
                <td>{{ client.name }}</td>
              </tr>
              <tr>
                <th>Username</th>
                <td>{{ client.username }}</td>
              </tr>
              <tr>
                <th>Email</th>
                <td>{{ client.email }}</td>
              </tr>
              <tr>
                <th>Display Name</th>
                <td>{{ client.display_name }}</td>
              </tr>
              <tr>
                <th>Country</th>
                <td>{{ client.country }}</td>
              </tr>
              <tr>
                <th>State</th>
                <td>{{ client.state }}</td>
              </tr>
              <tr>
                <th>City</th>
                <td>{{ client.city }}</td>
              </tr>
              <tr>
                <th>Address</th>
                <td>{{ client.address }}</td>
              </tr>
              <tr>
                <th>Postal Code / Zip</th>
                <td>{{ client.postal_code }}</td>
              </tr>
              <tr>
                <th>Phone Number</th>
                <td>{{ client.phone }}</td>
              </tr>
              <tr>
                <th>Billing Info</th>
                <td style="white-space: pre-line">
                  {{ client.billing_address }}
                </td>
              </tr>
              <tr>
                <th>Notes</th>
                <td style="white-space: pre-line">{{ client.notes }}</td>
              </tr>
              <tr>
                <th>VIP Client</th>
                <td>{{ client.vip_client ? "Yes" : "No" }}</td>
              </tr>
              <tr>
                <th>Active Currencies</th>
                <td>
                  <div
                    class="tag is-warning mr-3"
                    v-for="c in client.currencies"
                    :key="c"
                  >
                    {{ c }}
                  </div>
                </td>
              </tr>
              <tr>
                <th>Created At</th>
                <td>{{ client.created_at }}</td>
              </tr>
              <tr>
                <th>Last Update</th>
                <td>{{ client.updated_at }}</td>
              </tr>
            </table>
          </div>
          <div class="column is-4">
            <div class="card has-text-centered box">
              <div class="card-content">
                <p class="title">Total Autos</p>
                <p class="subtitle has-text-danger">
                  {{ total_autos }}
                </p>
              </div>
              <page-loader :isLoading="loading" />
            </div>
            <div class="card has-text-centered mt-5 box">
              <div class="card-content">
                <p class="title">Joined</p>
                <p class="subtitle has-text-danger">
                  {{ client_for }} days ago
                </p>
              </div>
            </div>
            <div class="quotes">
              <p><strong>Quotes</strong></p>
              <p v-for="q in client.quotes" :key="q._id">
                <a :href="`/admin/dashboard/quotes/view/${q._id}`">{{
                  `#${q.quote_id} (${q.loading_point} => ${q.destination})`
                }}</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      client_for: 0,
      total_autos: 0,
      loading: true,
    };
  },
  props: {
    client: Object,
  },
  methods: {
    closeModal() {
      this.$emit("closed");
    },
  },
  created() {
    // count days joined
    if (this.$props.client.created_at) {
      let split_date = this.$props.client.created_at.split("/");
      const date1 = dayjs();
      const date2 = dayjs(`${split_date[1]}-${split_date[0]}-${split_date[2]}`);
      this.client_for = date1.diff(date2, "days");
    }
    // get autos count
    this.$axios
      .get(`/api/retrieve_client_stats/${this.$props.client._id}`, {
        headers: this.$authHeader(),
      })
      .then((res) => {
        this.total_autos = res.data.autos_count;
        this.loading = false;
      })
      .catch((err) => {
        this.loading = false;
      });
  },
};
</script>
<style scoped>
th {
  background: unset !important;
}
table {
  border: none;
}
.modal-card {
  min-width: 1200px;
}
.quotes a:hover {
  text-decoration: underline;
}
</style>
