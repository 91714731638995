<template>
  <div class="box">
    <router-link
      to="/admin/dashboard/shippers_consignees?contact_type=Consignee"
    >
      <button class="button mb-6">
        <i class="fa-solid fa-angles-left mr-1"></i>Go Back to Shipping
        Information
      </button>
    </router-link>
    <h1 class="subtitle is-4">Add Shipping Information</h1>
    <hr />
    <form>
      <div class="notification is-danger" v-if="error">{{ errorMessage }}</div>
      <div class="notification is-primary" v-if="success">
        {{ successMessage }}
      </div>

      <div class="columns">
        <div class="column">
          <label class="label">Type <span class="required">*</span></label>
          <div class="select is-fullwidth">
            <select v-model="contact_type">
              <option value="Consignee">Consignee</option>
              <option value="Shipper">Shipper</option>
              <option value="Notify">Notify</option>
              <option value="Forwarding Agent">Forwarding Agent</option>
            </select>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Name <span class="required">*</span></label>
          <input
            v-model="name"
            type="text"
            class="input"
            placeholder="Company Name"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Contact</label>
          <input
            type="text"
            class="input"
            placeholder="Contact"
            v-model="contact"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Country <span class="required">*</span></label>
          <div class="select is-fullwidth">
            <select v-model="country">
              <countries-list />
            </select>
          </div>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">City <span class="required">*</span></label>
          <input type="text" class="input" placeholder="City" v-model="city" />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Address <span class="required">*</span></label>
          <input
            type="text"
            class="input"
            placeholder="Street Address"
            v-model="address"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Zip / Postal Code</label>
          <input
            type="text"
            class="input"
            placeholder="Zip or Postal Code"
            v-model="postal_code"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">VAT Number or Tax ID</label>
          <input
            type="text"
            class="input"
            placeholder="Tax ID"
            v-model="tax_id"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Additional Info</label>
          <input
            type="text"
            class="input"
            placeholder="Additional Information"
            v-model="additional_info"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Email/s</label>
          <input
            type="email"
            class="input"
            placeholder="example@example.com"
            v-model="email"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Phone/s</label>
          <input
            type="text"
            class="input"
            placeholder="Phone Number"
            v-model="phone"
          />
        </div>
      </div>
      <hr />
      <button
        :class="`button is-primary ${isLoading ? 'is-loading' : ''}`"
        @click.stop.prevent="submit"
      >
        Submit
      </button>
    </form>
  </div>
</template>
<script>
import CountriesList from "@/components/forms/CountriesList.vue";
export default {
  data() {
    return {
      contact_type: "Consignee",
      name: "",
      contact: "",
      country: "",
      city: "",
      address: "",
      postal_code: "",
      tax_id: "",
      additional_info: "",
      email: "",
      phone: "",
      error: false,
      success: false,
      errorMessage: "",
      successMessage: "",
      isLoading: false,
    };
  },
  components: { CountriesList },
  methods: {
    submit() {
      this.isLoading = true;
      // validate form
      if (!this.name || !this.country || !this.city || !this.address) {
        this.isLoading = false;
        this.error = true;
        this.errorMessage = "Please fill out all the required inputs.";
        window.scrollTo(0, 0);
        return;
      }
      // Send request to server
      this.$axios
        .post("/api/add_contact", this.$data, { headers: this.$authHeader() })
        .then((res) => {
          this.isLoading = false;
          this.success = true;
          this.error = false;
          this.successMessage = res.data.msg;
          (this.contact_type = "Consignee"),
            (this.name = ""),
            (this.contact = ""),
            (this.country = ""),
            (this.city = ""),
            (this.address = ""),
            (this.postal_code = ""),
            (this.tax_id = ""),
            (this.additional_info = ""),
            (this.email = ""),
            (this.phone = "");
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.isLoading = false;
          this.success = false;
          this.error = true;
          this.errorMessage = err.response.data.msg;
          window.scrollTo(0, 0);
        });
    },
  },
};
</script>
<style scoped>
.required {
  color: #f14668;
}
</style>
