<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Add Selection Field</p>
        <button class="delete" aria-label="close" @click="closeModal"></button>
      </header>
      <section class="modal-card-body">
        <form @submit.prevent="">
          <message-alert
            v-if="errorAlert"
            messageType="error"
            :msg="errorMessage"
          />
          <message-alert v-if="successAlert" :msg="successMessage" />
          <div class="columns">
            <div class="column">
              <div class="select is-fullwidth">
                <select v-model="type">
                  <option value="">Select One</option>
                  <option>Destinations</option>
                  <option>Loading Points</option>
                  <option>Auctions</option>
                  <option>Cities</option>
                  <option>Custom Field 1</option>
                  <option>Custom Field 2</option>
                </select>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <textarea
                v-model="value"
                class="textarea"
                placeholder="Value (Note: to add more than one, use ; to separate them)"
              ></textarea>
            </div>
          </div>
        </form>
      </section>
      <footer class="modal-card-foot">
        <button
          :class="`button is-primary ${isLoading ? 'is-loading' : ''}`"
          @click.prevent="addField"
        >
          Add
        </button>
        <button class="button" @click="closeModal">Cancel</button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      type: "",
      value: "",
      isLoading: false,
      errorAlert: false,
      successAlert: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  methods: {
    closeModal() {
      this.$emit("closed");
    },
    addField() {
      if (!this.type || !this.value) {
        this.errorAlert = true;
        this.errorMessage = "Please fill out all of the inputs";
        return;
      }
      this.isLoading = true;
      this.$axios
        .post(
          "/api/add_selections",
          {
            type: this.type,
            value: this.value,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.type = "";
          this.value = "";
          this.errorAlert = false;
          this.successAlert = true;
          this.successMessage = res.data.msg;
          this.isLoading = false;
        })
        .catch((err) => {
          this.successAlert = false;
          this.errorAlert = true;
          this.errorMessage = err.response.data.msg;
          this.isLoading = false;
        });
    },
  },
};
</script>
