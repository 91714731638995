<template>
  <page-loader :isLoading="isLoading"></page-loader>
  <div class="container">
    <h1 class="title">Edit Cargo</h1>
    <hr />
    <div class="notification is-danger" v-if="error">{{ error }}</div>
    <div class="notification is-primary" v-if="success">{{ success }}</div>

    <div class="columns">
      <div class="column">
        <label class="label"
          >Description <span class="has-text-danger">*</span></label
        >
        <input
          type="text"
          class="input"
          placeholder="Description"
          v-model="description"
        />
      </div>
      <div class="column">
        <label class="label">Qty <span class="has-text-danger">*</span></label>
        <input
          type="number"
          class="input"
          placeholder="QTY"
          step="1"
          v-model="qty"
        />
      </div>
      <div class="column">
        <label class="label"
          >Client <span class="has-text-danger">*</span></label
        >
        <v-select
          placeholder="Client"
          v-model="client"
          :options="clients"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Delivery Date <span class="has-text-danger">*</span></label
        >
        <DatePicker
          v-model="delivery_date"
          placeholder="Pick a Date"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label"
          >Loading Point <span class="has-text-danger">*</span></label
        >
        <v-select
          placeholder="Loading Point"
          v-model="loading_point"
          :options="loading_points"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Destination <span class="has-text-danger">*</span></label
        >
        <v-select
          placeholder="Destination"
          v-model="destination"
          :options="destinations"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Tracking Number</label>
        <input
          type="text"
          class="input"
          placeholder="Tracking Number"
          v-model="tracking_number"
        />
      </div>
      <div class="column"></div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Notes</label>
        <textarea class="textarea" v-model="notes"></textarea>
      </div>
    </div>
    <p class="mb-2">Uploaded images:</p>
    <p>
      <button
        class="button mb-3 is-danger"
        @click.stop.prevent="deleteFile(i, 'all_images')"
        v-if="uploaded_images && uploaded_images.length > 0"
      >
        Delete All Images
      </button>
    </p>
    <figure class="image is-128x128" v-for="i in uploaded_images" :key="i">
      <img :src="`/api/files/${i}`" />
      <button
        class="button is-danger"
        @click.stop.prevent="deleteFile(i, 'image')"
      >
        <i class="fa-solid fa-trash-can"></i>
      </button>
    </figure>
    <hr />
    <div class="columns">
      <div class="column is-3">
        <div class="file is-boxed is-warning">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              multiple
              @change="handleImages"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fa-solid fa-images"></i>
              </span>
              <span class="file-label">Upload Images</span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <hr />
    <button class="button is-primary" @click.stop.prevent="handleSubmit">
      Submit
    </button>
  </div>
</template>
<script>
import Compressor from "compressorjs";
import dayjs from "dayjs";
export default {
  data() {
    return {
      description: null,
      qty: null,
      client: null,
      delivery_date: null,
      tracking_number: null,
      loading_point: null,
      destination: null,
      destinations: [],
      loading_points: [],
      clients: [],
      images: [],
      notes: null,
      success: null,
      error: null,
      part_id: this.$route.params.id,
      isLoading: false,
    };
  },
  created() {
    // fetch required selections
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.selections.map((s) => {
          if (s.type === "Loading Points") this.loading_points.push(s.value);
          if (s.type === "Destinations") this.destinations.push(s.value);
        });
      });

    // fetch clients
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.clients.map((c) => {
          this.clients.push({ code: c._id, label: c.company });
        });
      });

    //fetch Part info
    this.fetchData();
  },
  methods: {
    // handle submit
    handleSubmit() {
      //validate
      if (
        !this.description ||
        !this.qty ||
        !this.client ||
        !this.delivery_date ||
        !this.loading_point ||
        !this.destination
      ) {
        this.success = null;
        return (this.error = "Please fill out the required inputs");
      }
      // send request
      let fd = new FormData();
      if (this.description) fd.append("description", this.description);
      if (this.qty) fd.append("qty", this.qty);
      if (this.client) fd.append("client", this.client.code);
      if (this.delivery_date) fd.append("delivery_date", this.delivery_date);
      if (this.tracking_number)
        fd.append("tracking_number", this.tracking_number);
      if (this.loading_point) fd.append("loading_point", this.loading_point);
      if (this.destination) fd.append("destination", this.destination);
      if (this.notes) fd.append("notes", this.notes);
      this.images.map((f) => fd.append("images", f));

      this.$axios
        .post(`/api/parts/edit/${this.part_id}`, fd, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.error = null;
          this.images = [];
          this.fetchData();
          return (this.success = res.data.msg);
        })
        .catch((err) => {
          this.success = null;
          return (this.error = err.response.data.msg);
        });
    },
    // Handle Images Upload
    handleImages(e) {
      let files = e.target.files;
      this.images = [];
      let i = [];
      Array.from(files).map((f) => {
        if (
          f.type.split("/")[1] == "png" ||
          f.type.split("/")[1] == "jpg" ||
          f.type.split("/")[1] == "jpeg"
        ) {
          new Compressor(f, {
            quality: 0.7,
            maxWidth: 800,
            success(res) {
              i.push(res);
            },
          });
        } else {
          this.images = [];
          return alert("Only images and PDF files are allowed");
        }
      });
      this.images = i;
    },
    fetchData() {
      this.isLoading = true;
      this.$axios
        .post(
          "/api/parts/retrieve_parts",
          { part_id: this.part_id },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.description = res.data.parts[0].description;
          this.qty = res.data.parts[0].qty;
          if (res.data.parts[0].client) {
            this.client = {
              code: res.data.parts[0].client._id,
              label: res.data.parts[0].client.company,
            };
          }
          this.delivery_date = dayjs(res.data.parts[0].delivery_date).add(
            1,
            "day"
          );

          this.tracking_number = res.data.parts[0].tracking_number;
          this.loading_point = res.data.parts[0].loading_point;
          this.destination = res.data.parts[0].destination;
          this.notes = res.data.parts[0].notes;
          this.uploaded_images = res.data.parts[0].images;
          this.isLoading = false;
        })
        .catch((err) => {
          this.error = err.response.data.msg;
          this.isLoading = false;
        });
    },
    deleteFile(file, type) {
      if (confirm("Are you sure?")) {
        this.$axios
          .post(
            "/api/parts/delete_image",
            { file, type, part_id: this.part_id },
            { headers: this.$authHeader() }
          )
          .then((done) => {
            this.fetchData();
          })
          .catch((err) => {
            return (this.error = err.response.data.msg);
          });
      }
    },
  },
};
</script>
<style scoped>
.image {
  width: 100px;
  height: 100px;
  border: 1px solid #ddd;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.image:hover .button {
  display: block;
}
.image img {
  width: 100%;
  height: 100%;
}
.image .button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
  transition: 500ms;
  opacity: 0.9;
}
</style>
