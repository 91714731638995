<template>
  <div class="box p-6">
    <page-loader :isLoading="isLoading" />
    <router-link to="/admin/dashboard/containers/">
      <button class="button mb-6">
        <i class="fa-solid fa-angles-left mr-1"></i>Go Back to Containers
      </button>
    </router-link>
    <h1 class="title">New Container</h1>
    <hr />
    <div class="notification is-danger" v-if="error">{{ error }}</div>
    <div class="notification is-success" v-if="success">{{ success }}</div>
    <div class="columns">
      <div class="column">
        <label class="label"
          >Loading Point <b class="has-text-danger">*</b></label
        >
        <v-select
          placeholder="Loading Point"
          :options="loading_points"
          v-model="loading_point"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Destination <b class="has-text-danger">*</b></label
        >
        <v-select
          placeholder="Destination"
          :options="destinations"
          v-model="destination"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label"
          >Booking Number <b class="has-text-danger">*</b></label
        >
        <input
          type="text"
          placeholder="Booking Number"
          class="input"
          v-model="booking_number"
        />
      </div>
      <div class="column">
        <label class="label"
          >Container Number <b class="has-text-danger">*</b></label
        >
        <input
          type="text"
          placeholder="Container Number"
          class="input"
          v-model="container_number"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Client <b class="has-text-danger">*</b></label>
        <v-select
          placeholder="Client"
          :options="clients"
          v-model="client"
          :disabled="invoices.length > 0 ? true : false"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Shipping Line</label>
        <v-select
          placeholder="Shipping Line"
          :options="shipping_lines"
          v-model="shipping_line"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Size</label>
        <v-select
          placeholder="Container Size"
          :options="sizes"
          v-model="size"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Seal</label>
        <input
          type="text"
          placeholder="Seal Number"
          class="input"
          v-model="seal"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Custom 1</label>
        <v-select
          placeholder="Custom 1"
          :options="customs1"
          v-model="custom1"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Custom 2</label>
        <v-select
          placeholder="Custom 2"
          :options="customs2"
          v-model="custom2"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label"
          >Loading Date <b class="has-text-danger">*</b>
        </label>
        <DatePicker
          v-model="loading_date"
          placeholder="Pick a Date"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
      <div class="column">
        <label class="label">ETA</label>
        <DatePicker
          v-model="eta"
          placeholder="Pick a Date"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <label class="label">Notes</label>
        <textarea
          class="textarea"
          placeholder="Loading List Notes"
          v-model="notes"
        ></textarea>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <h1 class="subtitle has-text-primary">Uploads</h1>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Loading Pictures</label>
        <div class="file is-small">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              multiple
              @change="handleImages"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Upload</span>
            </span>
          </label>
        </div>
      </div>
      <div class="column">
        <label class="label">Booking Confirmation</label>
        <div class="file is-small">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              multiple
              @change="handleFileUpload"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Upload </span>
            </span>
          </label>
        </div>
        <p v-if="current_booking_confirmation">
          <a
            target="_blank"
            :href="`/api/files/${current_booking_confirmation}`"
            >View current file</a
          ><a
            class="has-text-danger ml-1"
            @click.stop.prevent="deleteFile(f, 'booking_confirmation')"
            >delete</a
          >
        </p>
      </div>
      <div class="column">
        <label class="label">Telex</label>
        <div class="file is-small">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              multiple
              @change="handleTelex"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Upload </span>
            </span>
          </label>
        </div>
        <p v-if="current_telex">
          <a target="_blank" :href="`/api/files/${current_telex}`"
            >View current file</a
          ><a
            class="has-text-danger ml-1"
            @click.stop.prevent="deleteFile(f, 'telex')"
            >delete</a
          >
        </p>
      </div>
      <div class="column">
        <label class="label">BOL</label>
        <div class="file is-small">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              multiple
              @change="handleBOL"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Upload </span>
            </span>
          </label>
        </div>
        <p v-if="current_bol">
          <a target="_blank" :href="`/api/files/${current_bol}`"
            >View current file</a
          ><a
            class="has-text-danger ml-1"
            @click.stop.prevent="deleteFile(f, 'bol')"
            >delete</a
          >
        </p>
      </div>
      <div class="column">
        <label class="label">Custom Declaration</label>
        <div class="file is-small">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              multiple
              @change="handleDeclaration"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Upload </span>
            </span>
          </label>
        </div>
        <p v-if="current_declaration">
          <a target="_blank" :href="`/api/files/${current_declaration}`"
            >View current file</a
          >
          <a
            class="has-text-danger ml-1"
            @click.stop.prevent="deleteFile(f, 'custom_declaration')"
            >delete</a
          >
        </p>
      </div>
      <div class="column">
        <label class="label">Other files</label>
        <div class="file is-small">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              multiple
              @change="handleOther"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label"> Upload </span>
            </span>
          </label>
        </div>
        <div v-if="current_other_files.length > 0">
          <p v-for="f in current_other_files" :key="f">
            <a target="_blank" :href="`/api/files/${f}`">View current file</a>
            <a
              class="has-text-danger ml-1"
              @click.stop.prevent="deleteFile(f, 'other_files')"
              >delete</a
            >
          </p>
        </div>
      </div>
    </div>
    <hr />
    <p class="mb-2">Uploaded images:</p>
    <p>
      <button
        class="button mb-3 is-danger"
        @click.stop.prevent="deleteFile(i, 'all_images')"
        v-if="uploadedImages && uploadedImages.length > 0"
      >
        Delete All Images
      </button>
    </p>
    <figure class="image is-128x128" v-for="i in uploadedImages" :key="i">
      <img :src="`/api/files/${i}`" />
      <button
        class="button is-danger"
        @click.stop.prevent="deleteFile(i, 'image')"
      >
        <i class="fa-solid fa-trash-can"></i>
      </button>
    </figure>
    <hr />
    <label class="checkbox mb-4">
      <input type="checkbox" v-model="view_all" />
      Show All Clients
    </label>
    <h1 class="subtitle has-text-primary">Autos</h1>
    <table
      class="table is-fullwidth is-bordered has-text-centered"
      v-if="selected_autos && selected_autos.length > 0"
    >
      <tr>
        <th class="has-text-centered" colspan="3">Selected Autos</th>
      </tr>
      <tr v-for="a in selected_autos" :key="a._id">
        <td width="100px">
          <img
            class="thumbnail"
            :src="`/api/files/${a.thumbnail}`"
            alt="auto thumbnail"
            v-if="a.thumbnail"
          />
          <img
            class="thumbnail"
            :src="`/api/files/no_photo.png`"
            alt="auto thumbnail"
            v-if="!a.thumbnail"
          />
        </td>
        <td>
          <strong>{{ `${a.year} ${a.make} ${a.model}` }}</strong>
          <p>VIN: {{ a.vin }}</p>
          <p>Lot:{{ a.lot }}</p>
        </td>
        <td width="50px">
          <button class="button is-danger is-small" @click="removeAuto(a._id)">
            Remove
          </button>
        </td>
      </tr>
    </table>
    <div class="columns">
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="vin (full or last 6)"
          v-model="search_vin"
          @keyup.enter="fetchData"
        />
      </div>
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="Lot#"
          v-model="search_lot"
          @keyup.enter="fetchData"
        />
      </div>
      <div class="column">
        <button class="button is-primary" @click="fetchData">Search</button>
      </div>
    </div>
    <table class="table is-fullwidth is-hoverable is-bordered">
      <page-loader :isLoading="autosLoading"></page-loader>
      <thead>
        <tr>
          <th width="20px"></th>
          <th width="70px">Img</th>
          <th>Client</th>
          <th>Auto</th>
          <th>Vin</th>
          <th>Lot</th>
          <th>Arrived Terminal</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="autos_list.length == 0">
          <td colspan="7" class="has-text-centered">No Available Data</td>
        </tr>
        <tr v-for="a in autos_list" :key="a._id">
          <td><input type="checkbox" @click="addAuto(a)" /></td>
          <td>
            <img
              class="thumbnail"
              :src="`/api/files/${a.thumbnail}`"
              alt="auto thumbnail"
              v-if="a.thumbnail"
            />
            <img
              class="thumbnail"
              :src="`/api/files/no_photo.png`"
              alt="auto thumbnail"
              v-if="!a.thumbnail"
            />
          </td>
          <td>{{ a.client.company }}</td>
          <td>{{ `${a.year} ${a.make} ${a.model}` }}</td>
          <td>{{ a.vin }}</td>
          <td>{{ a.lot }}</td>
          <td>{{ a.delivery_date }}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <h1 class="subtitle has-text-primary">Parts & Other Cargo</h1>
    <table
      class="table is-fullwidth is-bordered has-text-centered"
      v-if="selected_parts && selected_parts.length > 0"
    >
      <tr>
        <th class="has-text-centered" colspan="3">Selected Parts</th>
      </tr>
      <tr v-for="a in selected_parts" :key="a._id">
        <td width="100px">
          <img
            class="thumbnail"
            :src="`/api/files/${a.images[0]}`"
            alt="auto thumbnail"
            v-if="a.images && a.images.length > 0"
          />
          <img
            class="thumbnail"
            :src="`/api/files/no_photo.png`"
            alt="auto thumbnail"
            v-if="!a.images[0]"
          />
        </td>
        <td>
          <strong>{{ a.description }}</strong>
          <p>ID: {{ a.part_id }}</p>
          <p>QTY:{{ a.qty }}</p>
        </td>
        <td width="50px">
          <button class="button is-danger is-small" @click="removePart(a._id)">
            Remove
          </button>
        </td>
      </tr>
    </table>
    <table class="table is-fullwidth is-bordered">
      <page-loader :isLoading="partsLoading"></page-loader>
      <thead>
        <tr>
          <th width="20px"></th>
          <th width="70px">Img</th>
          <th>Client</th>
          <th>Description</th>
          <th>ID</th>
          <th>QTY</th>
          <th>Arrived Terminal</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="parts_list.length == 0">
          <td colspan="7" class="has-text-centered">No Available Data</td>
        </tr>
        <tr v-for="a in parts_list" :key="a._id">
          <td><input type="checkbox" @click="addPart(a)" /></td>
          <td>
            <img
              class="thumbnail"
              :src="`/api/files/${a.images[0]}`"
              alt="auto thumbnail"
              v-if="a.images && a.images.length > 0"
            />
            <img
              class="thumbnail"
              :src="`/api/files/no_photo.png`"
              alt="auto thumbnail"
              v-if="!a.images[0]"
            />
          </td>
          <td>{{ a.client.company }}</td>
          <td>{{ a.description }}</td>
          <td>{{ a.part_id }}</td>
          <td>{{ a.qty }}</td>
          <td>{{ a.delivery_date }}</td>
        </tr>
      </tbody>
    </table>
    <button
      :class="`button is-primary ${buttonLoading ? 'is-loading' : ''}`"
      @click.stop.prevent="handleSubmit"
    >
      Submit
    </button>
  </div>
</template>
<script>
import Sizes from "@/components/helpers/ContainerSizes";
import Lines from "@/components/helpers/ShippingLines";
import dayjs from "dayjs";
import Compressor from "compressorjs";

export default {
  data() {
    return {
      page_loading: false,
      loading_points: [],
      loading_point: null,
      destinations: [],
      destination: null,
      booking_number: null,
      container_number: null,
      client: null,
      clients: [],
      isLoading: false,
      shipping_lines: Lines,
      shipping_line: null,
      sizes: Sizes,
      size: null,
      notes: null,
      autos_list: [],
      selected_autos: [],
      parts_list: [],
      selected_parts: [],
      selected_client: null,
      view_all: true,
      autosLoading: false,
      search_vin: null,
      search_lot: null,
      partsLoading: false,
      error: null,
      success: null,
      customs1: [],
      customs2: [],
      custom1: null,
      custom2: null,
      telex: null,
      bol: null,
      other_files: [],
      booking_confirmation: null,
      loading_images: [],
      seal: null,
      loading_date: new Date(),
      eta: null,
      custom_declaration: null,
      id: this.$route.params.id,
      current_booking_confirmation: null,
      current_telex: null,
      current_bol: null,
      current_declaration: null,
      current_other_files: [],
      uploadedImages: [],
      buttonLoading: false,
      invoices: [],
    };
  },
  watch: {
    loading_point() {
      this.fetchData();
    },
    destination() {
      this.fetchData();
    },
    client(c) {
      if (c) {
        this.selected_client = c.code;
      } else {
        this.selected_client = null;
      }
      this.fetchData();
    },
    view_all() {
      this.fetchData();
    },
  },
  created() {
    // fetch required selections
    // get clients
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        let a = [];
        res.data.clients.map((c) => {
          a.push({ label: c.company, code: c._id });
        });
        this.clients = a;
      });
    // Get Selections
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        let destinations = [];
        let loading_points = [];
        res.data.selections.map((s) => {
          if (s.type == "Loading Points") {
            loading_points.push(s.value);
          }
          if (s.type == "Destinations") {
            destinations.push(s.value);
          }
          if (s.type == "Custom Field 1") {
            this.customs1.push(s.value);
          }
          if (s.type == "Custom Field 2") {
            this.customs2.push(s.value);
          }
          this.destinations = destinations;
          this.loading_points = loading_points;
        });
      });

    this.fetchContainer();
  },
  methods: {
    // fetch Autos & Parts
    fetchData() {
      let query = { status: "dsfsdf54545454" };
      let parts_query = { client: "jklsdjfl555j00sdlfkjsdlkjflk" };
      if (this.loading_point && this.destination) {
        query = {
          status: "At terminal",
          destination: this.destination,
          loading_point: this.loading_point,
        };
        parts_query = {
          destination: this.destination,
          loading_point: this.loading_point,
        };
      }
      if (this.selected_client && !this.view_all) {
        query.client = this.selected_client;
        parts_query.client = this.selected_client;
      }
      if (this.search_vin) {
        query.vin = this.search_vin;
      }
      if (this.search_lot) {
        query.lot = this.search_lot;
      }
      // fetch autos
      this.autosLoading = true;
      this.$axios
        .post(
          "/api/retrieve_autos_loading_list",
          {
            query,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          let autos = [];
          res.data.autos.map((a) => {
            let selected = false;
            if (a.delivery_date) {
              a.delivery_date = dayjs(a.delivery_date)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            this.selected_autos.map((s) => {
              if (s._id == a._id) selected = true;
            });
            if (!selected) {
              autos.push(a);
            }
          });
          this.autos_list = autos;
          this.autosLoading = false;
        })
        .catch((err) => {
          this.autosLoading = false;
        });
      // fetch parts
      this.partsLoading = true;
      this.$axios
        .post(
          "/api/retrieve_parts_loading_list",
          { query: parts_query },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          let parts = [];
          res.data.parts.map((a) => {
            let selected = false;
            if (a.delivery_date) {
              a.delivery_date = dayjs(a.delivery_date)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            this.selected_parts.map((s) => {
              if (s._id == a._id) selected = true;
            });
            if (!selected) {
              parts.push(a);
            }
          });
          this.parts_list = parts;
          this.partsLoading = false;
        })
        .catch((err) => {
          this.partsLoading = false;
        });
    },
    // add auto to selected array
    addAuto(auto) {
      this.selected_autos.push(auto);
      this.fetchData();
    },
    fetchContainer() {
      // get container info
      this.$axios
        .get(`/api/retrieve_container/${this.$route.params.id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          let c = res.data.container;
          this.loading_point = c.loading_point;
          this.destination = c.destination;
          this.booking_number = c.booking_number;
          this.container_number = c.container_number;
          if (c.client) {
            this.client = { code: c.client._id, label: c.client.company };
          }
          this.shipping_line = c.shipping_line;
          this.size = c.size;
          this.seal = c.seal;
          this.invoices = c.invoices;
          this.custom1 = c.custom1;
          this.custom2 = c.custom2;
          if (c.loading_date) {
            this.loading_date = new Date(dayjs(c.loading_date).add(1, "day"));
          }
          if (c.eta) {
            this.eta = new Date(dayjs(c.eta).add(1, "day"));
          }

          this.notes = c.notes;
          this.selected_autos = c.autos;
          this.selected_parts = c.parts;
          this.uploadedImages = c.loading_images;
          this.current_booking_confirmation = c.booking_confirmation;
          this.current_telex = c.telex;
          this.current_bol = c.bol;
          this.current_declaration = c.custom_declaration;
          this.current_other_files = c.other_files;
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
    removeAuto(id) {
      this.pageLoading = true;
      this.$axios
        .get(`/api/remove_auto_from_container/${this.id}/${id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.selected_autos = this.selected_autos.filter((a) => {
            return a._id !== id;
          });
          this.fetchData();
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
        });
    },
    // add part to selected array
    addPart(part) {
      this.selected_parts.push(part);
      this.fetchData();
    },
    removePart(id) {
      this.pageLoading = true;
      this.$axios
        .get(`/api/remove_part_from_container/${this.id}/${id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.selected_parts = this.selected_parts.filter((a) => {
            return a._id !== id;
          });
          this.fetchData();
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
        });
    },
    handleFileUpload(e) {
      let file = e.target.files[0];
      if (file) {
        if (
          file.type.split("/")[1] == "png" ||
          file.type.split("/")[1] == "pdf" ||
          file.type.split("/")[1] == "jpg" ||
          file.type.split("/")[1] == "jpeg"
        ) {
          this.booking_confirmation = file;
        } else {
          alert("This file type is not allowed");
        }
      } else {
        this.booking_confirmation = null;
      }
    },
    handleBOL(e) {
      let file = e.target.files[0];
      if (file) {
        if (
          file.type.split("/")[1] == "png" ||
          file.type.split("/")[1] == "pdf" ||
          file.type.split("/")[1] == "jpg" ||
          file.type.split("/")[1] == "jpeg"
        ) {
          this.bol = file;
        } else {
          alert("This file type is not allowed");
        }
      } else {
        this.bol = null;
      }
    },
    handleDeclaration(e) {
      let file = e.target.files[0];
      if (file) {
        if (
          file.type.split("/")[1] == "png" ||
          file.type.split("/")[1] == "pdf" ||
          file.type.split("/")[1] == "jpg" ||
          file.type.split("/")[1] == "jpeg"
        ) {
          this.custom_declaration = file;
        } else {
          alert("This file type is not allowed");
        }
      } else {
        this.custom_declaration = null;
      }
    },
    handleTelex(e) {
      let file = e.target.files[0];
      if (file) {
        if (
          file.type.split("/")[1] == "png" ||
          file.type.split("/")[1] == "pdf" ||
          file.type.split("/")[1] == "jpg" ||
          file.type.split("/")[1] == "jpeg"
        ) {
          this.telex = file;
        } else {
          alert("This file type is not allowed");
        }
      } else {
        this.telex = null;
      }
    },
    handleOther(e) {
      let files = e.target.files;
      if (files) {
        let filesArray = [];
        Array.from(files).map((file) => {
          if (
            file.type.split("/")[1] == "png" ||
            file.type.split("/")[1] == "pdf" ||
            file.type.split("/")[1] == "jpg" ||
            file.type.split("/")[1] == "jpeg"
          ) {
            filesArray.push(file);
          } else {
            return alert(file.name + " This file type is not allowed");
          }
        });
        this.other_files = filesArray;
      } else {
        this.other_files = null;
      }
    },
    // Handle Images Upload
    async handleImages(e) {
      let files = e.target.files;
      this.isLoading = true;
      this.loading_images = [];
      let i = [];
      function compress(img) {
        return new Promise((resolve, reject) => {
          new Compressor(img, {
            quality: 0.8,
            maxWidth: 2500,
            success(res) {
              resolve(res);
            },
          });
        });
      }
      await Promise.all(
        Array.from(files).map(async (f) => {
          if (
            f.type.split("/")[1] == "png" ||
            f.type.split("/")[1] == "jpg" ||
            f.type.split("/")[1] == "jpeg"
          ) {
            let img = await compress(f);
            i.push(img);
          } else {
            this.loading_images = [];
            return alert("Only images and PDF files are allowed");
          }
        })
      );
      this.loading_images = i;
      this.isLoading = false;
    },
    // handle form submit
    handleSubmit() {
      // validate inputs
      if (
        !this.loading_point ||
        !this.destination ||
        !this.client ||
        !this.container_number ||
        !this.booking_number ||
        !this.loading_date
      ) {
        this.error = "Please fill out all the required inputs";
        this.success = null;
        window.scrollTo(0, 0);
        return;
      }
      this.buttonLoading = true;
      // gen FormData
      let fd = new FormData();
      this.loading_images.map((f) => fd.append("loading_images", f));
      this.other_files.map((f) => fd.append("other_files", f));
      if (this.loading_point) {
        fd.append("loading_point", this.loading_point);
      }
      if (this.destination) fd.append("destination", this.destination);
      if (this.booking_number) fd.append("booking_number", this.booking_number);
      if (this.container_number)
        fd.append("container_number", this.container_number);
      if (this.client) fd.append("client", this.client.code);
      if (this.shipping_line) {
        fd.append("shipping_line", this.shipping_line);
      }
      if (this.size) fd.append("size", this.size);
      if (this.seal) fd.append("seal", this.seal);
      if (this.custom_declaration)
        fd.append("custom_declaration", this.custom_declaration);
      if (this.custom1) fd.append("custom1", this.custom1);
      if (this.custom2) fd.append("custom2", this.custom2);
      if (this.notes) fd.append("notes", this.notes);
      if (this.loading_date)
        fd.append(
          "loading_date",
          dayjs(this.loading_date).format("YYYY-MM-DD")
        );
      if (this.eta) {
        fd.append("eta", dayjs(this.eta).format("YYYY-MM-DD"));
      }
      if (this.booking_confirmation)
        fd.append("booking_confirmation", this.booking_confirmation);
      if (this.bol) fd.append("bol", this.bol);
      if (this.telex) fd.append("telex", this.telex);
      if (this.selected_autos)
        fd.append("autos", JSON.stringify(this.selected_autos));
      if (this.selected_parts)
        fd.append("parts", JSON.stringify(this.selected_parts));
      let headers = this.$authHeader();
      // changing the headers to include files
      headers["Content-Type"] = "multipart/form-data";
      // send request to backend
      this.$axios
        .post(`/api/edit_container/${this.$route.params.id}`, fd, { headers })
        .then((res) => {
          (this.error = null), (this.success = res.data.msg);
          // reset data
          (this.loading_images = []),
            (this.booking_confirmation = null),
            (this.telex = null),
            (this.bol = null),
            (this.custom_declaration = null),
            (this.other_files = []);
          this.fetchContainer();
          window.scrollTo(0, 0);
          this.buttonLoading = false;
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          (this.success = null), (this.error = err.response.data.msg);
          this.buttonLoading = false;
        });
    },
    // delete image or file
    deleteFile(file, type) {
      if (confirm("Are you sure you want to delete?")) {
        this.$axios
          .post(
            "/api/containers/delete_file",
            {
              container_id: this.$route.params.id,
              type,
              file,
            },
            { headers: this.$authHeader() }
          )
          .then((res) => {
            this.pageLoading = true;
            this.fetchContainer();
          });
      }
    },
  },
};
</script>
<style scoped>
.thumbnail {
  max-width: 70px;
}
.table {
  position: relative;
}
.table td {
  font-size: 0.8rem !important;
}
.search td {
  border: none;
}
.image {
  width: 70px;
  height: 70px;
  border: 1px solid #ddd;
  display: inline-block;
  margin-right: 10px;
  position: relative;
}
.image:hover .button {
  display: block;
}
.image img {
  width: 100%;
  height: 100%;
}
.image .button {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: none;
  transition: 500ms;
  opacity: 0.9;
}
</style>
