<template>
  <client-side-nav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <client-top-nav />
    <div class="mt-6">
      <page-loader :isLoading="isLoading" />
      <div class="box">
        <h1 class="subtitle">Payments</h1>
        <hr />
        <nav class="level" v-if="this.$route.query.currency">
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Total</p>
              <p class="title">{{ total }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Used</p>
              <p class="title">{{ used }}</p>
            </div>
          </div>
          <div class="level-item has-text-centered">
            <div>
              <p class="heading">Unallocated</p>
              <p class="title">{{ unallocated }}</p>
            </div>
          </div>
        </nav>
        <PaymentsFilter @search="fetchTransactions" />
        <hr />
        <!-- Paginating-->
        <nav
          class="pagination is-small is-centered"
          role="navigation"
          aria-label="pagination"
        >
          <!-- PREV Page-->
          <a
            class="pagination-previous"
            v-if="hasPrevPage"
            @click.prevent="changePage(prevPage)"
            ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
          >
          <a class="pagination-previous" disabled v-if="!hasPrevPage"
            ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
          >
          <!-- NEXT Page-->
          <a
            class="pagination-next"
            @click.prevent="changePage(nextPage)"
            v-if="hasNextPage"
            >Next page <i class="fa-solid fa-arrow-right ml-2"></i
          ></a>
          <a class="pagination-next" disabled v-if="!hasNextPage"
            >Next page <i class="fa-solid fa-arrow-right ml-2"></i
          ></a>
          <ul class="pagination-list">
            <!-- FIRST Page-->
            <li>
              <a
                class="pagination-link"
                aria-label="Goto page 1"
                @click.prevent="changePage(1)"
                >First</a
              >
            </li>
            <li><span class="pagination-ellipsis">&hellip;</span></li>
            <li>
              <!-- CURRENT Page-->
              <a
                class="pagination-link is-current"
                aria-label="Page 46"
                aria-current="page"
                >{{ page }}</a
              >
            </li>

            <li><span class="pagination-ellipsis">&hellip;</span></li>
            <li>
              <!-- LAST Page-->
              <a
                class="pagination-link"
                aria-label="Goto page 86"
                @click.prevent="changePage(totalPages)"
                >Last</a
              >
            </li>
          </ul>
        </nav>
        <p>Total: {{ totalTransactions }}</p>
        <div class="table-wraper">
          <table class="table is-fullwidth is-hoverable">
            <thead>
              <tr>
                <th>ID</th>
                <th>Date</th>
                <th>Client</th>
                <th>Payment Method</th>
                <th>Type</th>
                <th>Notes</th>
                <th>Currency</th>
                <th class="has-text-centered">Amount</th>
                <th class="has-text-centered">Used</th>
                <th class="has-text-centered">Unallocated</th>
              </tr>
            </thead>
            <tbody>
              <tr
                class="is-clickable"
                v-for="p in payments"
                :key="p._id"
                @click="
                  () => {
                    this.$router.push(`/client/dashboard/transaction/${p._id}`);
                  }
                "
              >
                <td
                  v-tippy="{
                    content: `created at ${p.created_at} by ${p.created_by}`,
                  }"
                >
                  {{ p.transaction_number }}
                </td>
                <td>{{ p.date }}</td>
                <td>{{ p.client.company }}</td>
                <td>{{ p.payment_method }}</td>
                <td>{{ p.type }}</td>
                <td>{{ p.notes }}</td>
                <td>
                  <span class="has-text-danger">({{ p.currency }})</span>
                </td>
                <td class="has-text-centered">
                  <b>{{ p.amount }} </b>
                </td>
                <td class="has-text-centered">{{ p.used }}</td>
                <td class="has-text-centered">{{ p.unallocated }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ClientSideNav from "../ClientSideNav.vue";
import ClientTopNav from "../ClientTopNav.vue";
import PaymentsFilter from "./PaymentsFilter.vue";
import dayjs from "dayjs";
export default {
  data() {
    return {
      newPaymentModalOpen: false,
      payments: [],
      total: 0,
      used: 0,
      unallocated: 0,
      page: this.$route.query.page || 1,
      prevPage: null,
      hasPrevPage: null,
      totalTransactions: null,
      nextPage: null,
      hasNextPage: null,
      totalPages: null,
      isLoading: false,
      error: null,
    };
  },
  created() {
    this.fetchTransactions();
  },
  watch: {
    page(p) {
      this.fetchTransactions();
    },
  },
  components: { ClientSideNav, ClientTopNav, PaymentsFilter },
  methods: {
    fetchTransactions(q) {
      this.isLoading = true;
      (this.used = 0), (this.unallocated = 0), (this.total = 0);
      if (!q) {
        q = this.$route.query;
      }
      this.$axios
        .post(
          "/api/retrieve_transactions_client",
          { page: Number(this.page), query: q },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.error = null;
          this.payments = res.data.transactions.map((t) => {
            t.used = 0;
            t.payments.map((p) => {
              t.used += p.amount;
            });
            t.unallocated = (t.amount - t.used).toFixed(2);
            t.date = dayjs(t.date).add(1, "day").format("YYYY-MM-DD");
            if (t.created_at) {
              t.created_at = dayjs(t.created_at).format("YYYY-MM-DD hh:mm A");
            }
            return t;
          });
          function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
          this.total = numberWithCommas(res.data.payments_total);
          this.unallocated = numberWithCommas(
            res.data.unallocated_total.toFixed(2)
          );
          this.used = numberWithCommas(res.data.used_total);
          this.totalPages = res.data.totalPages;
          this.totalTransactions = res.data.totalPayments;
          this.nextPage = res.data.nextPage;
          this.prevPage = res.data.prevPage;
          this.hasNextPage = res.data.hasNextPage;
          this.hasPrevPage = res.data.hasPrevPage;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = err.response.data.msg;
        });
    },
    // Change Page
    changePage(number) {
      this.page = number;
      this.$router.push(`/client/dashboard/payments?page=${number}`);
    },
  },
};
</script>
<style scoped>
.level {
  border: 1px solid #ddd;
  padding: 20px;
}
td {
  vertical-align: middle !important;
}
</style>
