import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import pageLoader from "@/components/helpers/pageLoader";
import MessageAlert from "@/components/messages/MessageAlert";
import "vue-select/dist/vue-select.css";
import "@/assets/main.scss";
import "@/assets/main.js";
import axios from "axios";
import authHeader from "./service/auth-header";
import vSelect from "vue-select";
import VueTippy from "vue-tippy";
import VueEasyLightbox from 'vue-easy-lightbox'
import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

const app = createApp(App);
app.component("pageLoader", pageLoader);
app.component("MessageAlert", MessageAlert);
app.component("v-select", vSelect);
app.component('DatePicker', Datepicker);


app.config.globalProperties.$axios = axios;
app.config.globalProperties.$authHeader = authHeader;

app.use(store).use(router).use(VueTippy).use(VueEasyLightbox).mount("#app");
