<template>
  <div class="box p-6">
    <page-loader :isLoading="pageLoading"></page-loader>
    <router-link to="/admin/dashboard/loading_lists/">
      <button class="button mb-6">
        <i class="fa-solid fa-angles-left mr-1"></i>Go Back to Loading Lists
      </button>
    </router-link>
    <h1 class="title">Edit Loading List</h1>
    <hr />
    <div class="notification is-danger" v-if="error">{{ error }}</div>
    <div class="notification is-success" v-if="success">{{ success }}</div>
    <div class="columns">
      <div class="column">
        <label class="label"
          >Loading Point <b class="has-text-danger">*</b></label
        >
        <v-select
          placeholder="Loading Point"
          :options="loading_points"
          v-model="loading_point"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Destination <b class="has-text-danger">*</b></label
        >
        <v-select
          placeholder="Destination"
          :options="destinations"
          v-model="destination"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Booking Number</label>
        <input
          type="text"
          placeholder="Booking Number"
          class="input"
          v-model="booking_number"
        />
      </div>
      <div class="column">
        <label class="label">Container Number (if applicable)</label>
        <input
          type="text"
          placeholder="Container Number"
          class="input"
          v-model="container_number"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Client <b class="has-text-danger">*</b></label>
        <v-select
          placeholder="Client"
          :options="clients"
          v-model="client"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Shipping Line</label>
        <v-select
          placeholder="Shipping Line"
          :options="shipping_lines"
          v-model="shipping_line"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Size</label>
        <v-select
          placeholder="Container Size"
          :options="sizes"
          v-model="size"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Terminal Cut-off</label>
        <DatePicker
          v-model="cut_off"
          placeholder="Pick a Date"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Notes</label>
        <textarea
          class="textarea"
          placeholder="Loading List Notes"
          v-model="notes"
        ></textarea>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Booking Confirmation</label>
        <input type="file" @change="handleFileUpload" />
        <p class="mt-4" v-if="current_booking_confirmation">
          <a
            :href="`/api/files/${current_booking_confirmation}`"
            target="_blank"
            >view current confirmation</a
          >
        </p>
      </div>
    </div>
    <hr />
    <label class="checkbox mb-4">
      <input type="checkbox" v-model="view_all" />
      Show All Clients
    </label>
    <h1 class="subtitle has-text-primary">Autos</h1>
    <table
      class="table is-fullwidth is-bordered has-text-centered"
      v-if="selected_autos && selected_autos.length > 0"
    >
      <tr>
        <th class="has-text-centered" colspan="3">Selected Autos</th>
      </tr>
      <tr v-for="a in selected_autos" :key="a._id">
        <td width="100px">
          <img
            class="thumbnail"
            :src="`/api/files/${a.thumbnail}`"
            alt="auto thumbnail"
            v-if="a.thumbnail"
          />
          <img
            class="thumbnail"
            :src="`/api/files/no_photo.png`"
            alt="auto thumbnail"
            v-if="!a.thumbnail"
          />
        </td>
        <td>
          <strong>{{ `${a.year} ${a.make} ${a.model}` }}</strong>
          <p>VIN: {{ a.vin }}</p>
          <p>Lot:{{ a.lot }}</p>
        </td>
        <td width="50px">
          <button class="button is-danger is-small" @click="removeAuto(a._id)">
            Remove
          </button>
        </td>
      </tr>
    </table>
    <div class="columns">
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="vin (full or last 6)"
          v-model="search_vin"
          @keyup.enter="fetchData"
        />
      </div>
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="Lot#"
          v-model="search_lot"
          @keyup.enter="fetchData"
        />
      </div>
      <div class="column">
        <button class="button is-primary" @click="fetchData">Search</button>
      </div>
    </div>
    <table class="table is-fullwidth is-hoverable is-bordered">
      <page-loader :isLoading="autosLoading"></page-loader>
      <thead>
        <tr>
          <th width="20px"></th>
          <th width="70px">Img</th>
          <th>Client</th>
          <th>Auto</th>
          <th>Vin</th>
          <th>Lot</th>
          <th>Arrived Terminal</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="autos_list.length == 0">
          <td colspan="7" class="has-text-centered">No Available Data</td>
        </tr>
        <tr v-for="a in autos_list" :key="a._id">
          <td><input type="checkbox" @click="addAuto(a)" /></td>
          <td>
            <img
              class="thumbnail"
              :src="`/api/files/${a.thumbnail}`"
              alt="auto thumbnail"
              v-if="a.thumbnail"
            />
            <img
              class="thumbnail"
              :src="`/api/files/no_photo.png`"
              alt="auto thumbnail"
              v-if="!a.thumbnail"
            />
          </td>
          <td>{{ a.client.company }}</td>
          <td>{{ `${a.year} ${a.make} ${a.model}` }}</td>
          <td>{{ a.vin }}</td>
          <td>{{ a.lot }}</td>
          <td>{{ a.delivery_date }}</td>
        </tr>
      </tbody>
    </table>
    <hr />
    <h1 class="subtitle has-text-primary">Parts & Other Cargo</h1>
    <table
      class="table is-fullwidth is-bordered has-text-centered"
      v-if="selected_parts && selected_parts.length > 0"
    >
      <tr>
        <th class="has-text-centered" colspan="3">Selected Parts</th>
      </tr>
      <tr v-for="a in selected_parts" :key="a._id">
        <td width="100px">
          <img
            class="thumbnail"
            :src="`/api/files/${a.images[0]}`"
            alt="auto thumbnail"
            v-if="a.images && a.images.length > 0"
          />
          <img
            class="thumbnail"
            :src="`/api/files/no_photo.png`"
            alt="auto thumbnail"
            v-if="!a.images[0]"
          />
        </td>
        <td>
          <strong>{{ a.description }}</strong>
          <p>ID: {{ a.part_id }}</p>
          <p>QTY:{{ a.qty }}</p>
        </td>
        <td width="50px">
          <button class="button is-danger is-small" @click="removePart(a._id)">
            Remove
          </button>
        </td>
      </tr>
    </table>
    <table class="table is-fullwidth is-bordered">
      <page-loader :isLoading="partsLoading"></page-loader>
      <thead>
        <tr>
          <th width="20px"></th>
          <th width="70px">Img</th>
          <th>Client</th>
          <th>Description</th>
          <th>ID</th>
          <th>QTY</th>
          <th>Arrived Terminal</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="parts_list.length == 0">
          <td colspan="7" class="has-text-centered">No Available Data</td>
        </tr>
        <tr v-for="a in parts_list" :key="a._id">
          <td><input type="checkbox" @click="addPart(a)" /></td>
          <td>
            <img
              class="thumbnail"
              :src="`/api/files/${a.images[0]}`"
              alt="auto thumbnail"
              v-if="a.images && a.images.length > 0"
            />
            <img
              class="thumbnail"
              :src="`/api/files/no_photo.png`"
              alt="auto thumbnail"
              v-if="!a.images[0]"
            />
          </td>
          <td>{{ a.client.company }}</td>
          <td>{{ a.description }}</td>
          <td>{{ a.part_id }}</td>
          <td>{{ a.qty }}</td>
          <td>{{ a.delivery_date }}</td>
        </tr>
      </tbody>
    </table>
    <button class="button is-primary" @click.stop.prevent="handleSubmit">
      Save
    </button>
  </div>
</template>
<script>
import Sizes from "@/components/helpers/ContainerSizes";
import Lines from "@/components/helpers/ShippingLines";
import dayjs from "dayjs";
export default {
  data() {
    return {
      loading_points: [],
      loading_point: null,
      destinations: [],
      destination: null,
      booking_number: null,
      container_number: null,
      client: null,
      clients: [],
      shipping_lines: Lines,
      shipping_line: null,
      sizes: Sizes,
      size: null,
      cut_off: null,
      notes: null,
      booking_confirmation: null,
      autos_list: [],
      selected_autos: [],
      parts_list: [],
      selected_parts: [],
      selected_client: null,
      view_all: true,
      autosLoading: false,
      search_vin: null,
      search_lot: null,
      partsLoading: false,
      error: null,
      success: null,
      id: this.$route.params.id,
      current_booking_confirmation: null,
      pageLoading: true,
    };
  },
  watch: {
    loading_point() {
      this.fetchData();
    },
    destination() {
      this.fetchData();
    },
    client(c) {
      if (c) {
        this.selected_client = c.code;
      } else {
        this.selected_client = null;
      }
      this.fetchData();
    },
    view_all() {
      this.fetchData();
    },
  },
  created() {
    //fetch list info
    this.$axios
      .get(`/api/get_loading_list/${this.id}`, {
        headers: this.$authHeader(),
      })
      .then((res) => {
        let list = res.data.list;
        this.loading_point = list.loading_point;
        this.destination = list.destination;
        this.booking_number = list.booking_number;
        this.container_number = list.container_number;
        this.client = { code: list.client._id, label: list.client.company };
        this.shipping_line = list.shipping_line;
        this.size = list.size;
        if (list.cut_off) {
          this.cut_off = new Date(dayjs(list.cut_off).add(1, "day"));
        }
        this.notes = list.notes;
        this.selected_autos = list.autos;
        this.selected_parts = list.parts;
        this.current_booking_confirmation = list.booking_confirmation;
        this.pageLoading = false;
      })
      .catch((err) => {
        this.pageLoading = false;
        alert(err.response.data.msg);
      });
    // fetch required selections
    // get clients
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        let a = [];
        res.data.clients.map((c) => {
          a.push({ label: c.company, code: c._id });
        });
        this.clients = a;
      });
    // Get Selections
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        let destinations = [];
        let loading_points = [];
        res.data.selections.map((s) => {
          if (s.type == "Loading Points") {
            loading_points.push(s.value);
          }
          if (s.type == "Destinations") {
            destinations.push(s.value);
          }
          this.destinations = destinations;
          this.loading_points = loading_points;
        });
      });
  },
  methods: {
    // setch Autos & Parts
    fetchData() {
      let query = { status: "dsfsdf54545454" };
      let parts_query = { client: "jklsdjfl555j00sdlfkjsdlkjflk" };
      if (this.loading_point && this.destination) {
        query = {
          status: "At terminal",
          destination: this.destination,
          loading_point: this.loading_point,
        };
        parts_query = {
          destination: this.destination,
          loading_point: this.loading_point,
        };
      }
      if (this.selected_client && !this.view_all) {
        query.client = this.selected_client;
        parts_query.client = this.selected_client;
      }
      if (this.search_vin) {
        query.vin = this.search_vin;
      }
      if (this.search_lot) {
        query.lot = this.search_lot;
      }
      // fetch autos
      this.autosLoading = true;
      this.$axios
        .post(
          "/api/retrieve_autos_loading_list",
          {
            query,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          let autos = [];
          res.data.autos.map((a) => {
            let selected = false;
            if (a.delivery_date) {
              a.delivery_date = dayjs(a.delivery_date)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            this.selected_autos.map((s) => {
              if (s._id == a._id) selected = true;
            });
            if (!selected) {
              autos.push(a);
            }
          });
          this.autos_list = autos;
          this.autosLoading = false;
        })
        .catch((err) => {
          this.autosLoading = false;
        });
      // fetch parts
      this.partsLoading = true;
      this.$axios
        .post(
          "/api/retrieve_parts_loading_list",
          { query: parts_query },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          let parts = [];
          res.data.parts.map((a) => {
            let selected = false;
            if (a.delivery_date) {
              a.delivery_date = dayjs(a.delivery_date)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            this.selected_parts.map((s) => {
              if (s._id == a._id) selected = true;
            });
            if (!selected) {
              parts.push(a);
            }
          });
          this.parts_list = parts;
          this.partsLoading = false;
        })
        .catch((err) => {
          this.partsLoading = false;
        });
    },
    // add auto to selected array
    addAuto(auto) {
      this.selected_autos.push(auto);
      this.fetchData();
    },
    removeAuto(id) {
      this.pageLoading = true;
      this.$axios
        .get(`/api/remove_auto_from_list/${this.id}/${id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.selected_autos = this.selected_autos.filter((a) => {
            return a._id !== id;
          });
          this.fetchData();
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
        });
    },
    // add part to selected array
    addPart(part) {
      this.selected_parts.push(part);
      this.fetchData();
    },
    removePart(id) {
      this.pageLoading = true;
      this.$axios
        .get(`/api/remove_part_from_list/${this.id}/${id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.selected_parts = this.selected_parts.filter((a) => {
            return a._id !== id;
          });
          this.fetchData();
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
        });
    },
    handleFileUpload(e) {
      let file = e.target.files[0];
      if (file) {
        if (
          file.type.split("/")[1] == "png" ||
          file.type.split("/")[1] == "pdf" ||
          file.type.split("/")[1] == "jpg" ||
          file.type.split("/")[1] == "jpeg"
        ) {
          this.booking_confirmation = file;
        } else {
          alert("This file type is not allowed");
        }
      } else {
        this.booking_confirmation = null;
      }
    },
    // handle form submit
    handleSubmit() {
      // validate inputs
      if (!this.loading_point || !this.destination || !this.client) {
        this.error = "Please fill out all the required inputs";
        this.success = null;
        window.scrollTo(0, 0);
        return;
      }
      // gen FormData
      let fd = new FormData();
      if (this.loading_point) {
        fd.append("loading_point", this.loading_point);
      }
      if (this.destination) fd.append("destination", this.destination);
      if (this.booking_number) fd.append("booking_number", this.booking_number);
      if (this.container_number)
        fd.append("container_number", this.container_number);
      if (this.client) fd.append("client", this.client.code);
      if (this.shipping_line) fd.append("shipping_line", this.shipping_line);
      if (this.size) fd.append("size", this.size);
      if (this.cut_off)
        fd.append("cut_off", dayjs(this.cut_off).format("YYYY-MM-DD"));
      if (this.notes) fd.append("notes", this.notes);
      if (this.booking_confirmation)
        fd.append("booking_confirmation", this.booking_confirmation);
      if (this.selected_autos)
        fd.append("autos", JSON.stringify(this.selected_autos));
      if (this.selected_parts)
        fd.append("parts", JSON.stringify(this.selected_parts));
      let headers = this.$authHeader();
      // changing the headers to include files
      headers["Content-Type"] = "multipart/form-data";
      // send request to backend
      this.$axios
        .post(`/api/loading_lists/edit/${this.id}`, fd, { headers })
        .then((res) => {
          (this.error = null), (this.success = res.data.msg);
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          window.scrollTo(0, 0);
          (this.success = null), (this.error = err.response.data.msg);
        });
    },
  },
};
</script>
<style scoped>
.thumbnail {
  max-width: 70px;
}
.table {
  position: relative;
}
.table td {
  font-size: 0.8rem !important;
}
.search td {
  border: none;
}
</style>
