<template>
  <div class="box">
    <ClientInfoModal
      v-if="modalOpen"
      :client="currentlyOpened"
      @closed="closeModal"
    />
    <ChangePasswordModal
      v-if="passwordModalOpen"
      :isActive="passwordModalOpen"
      :client_id="changePassword.client_id"
      :client_name="changePassword.client_company"
      @closed="closeModal"
    />
    <h1 class="title">Clients</h1>
    <hr />
    <MessageAlert v-if="errorAlert" messageType="error" :msg="errorMessage" />
    <MessageAlert v-else-if="successAlert" :msg="successMessage" />
    <router-link to="/admin/dashboard/clients/add"
      ><button class="button is-primary">Add New Client</button></router-link
    >
    <pageLoader :isLoading="isLoading" />
    <div class="table-wraper">
      <table class="mt-5 table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Company</th>
            <th>Name</th>
            <th>Email</th>
            <th>Username</th>
            <th>Phone</th>
            <th style="width: 100px">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="filteredItems.length <= 0">
            <td class="no-clients" colspan="6">No Clients</td>
          </tr>
          <tr
            v-for="client in filteredItems"
            :key="client.id"
            class="is-clickable"
          >
            <td @click="viewClient($event, client._id)">
              <span class="has-text-weight-bold"> {{ client.company }} </span>
              <i
                v-if="client.vip_client"
                class="fa-solid fa-crown ml-3"
                title="VIP Client"
              ></i>
            </td>
            <td @click="viewClient($event, client._id)">{{ client.name }}</td>
            <td @click="viewClient($event, client._id)">{{ client.email }}</td>
            <td @click="viewClient($event, client._id)">
              {{ client.username }}
            </td>
            <td @click="viewClient($event, client._id)">{{ client.phone }}</td>
            <td>
              <div class="dropdown is-hoverable is-right">
                <div class="dropdown-trigger">
                  <button
                    class="button is-primary is-small"
                    aria-haspopup="true"
                    aria-controls="dropdown-menu3"
                  >
                    <span>Actions</span>
                    <span class="icon is-small">
                      <i class="fas fa-angle-down" aria-hidden="true"></i>
                    </span>
                  </button>
                </div>
                <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                  <div class="dropdown-content">
                    <router-link
                      :to="`/admin/dashboard/clients/edit/${client._id}`"
                      class="dropdown-item"
                      ><i class="fas fa-edit mr-1" aria-hidden="true"></i> Edit
                    </router-link>
                    <a
                      class="dropdown-item"
                      @click="resetPassword(client._id, client.company)"
                      ><i class="fas fa-lock-open mr-1" aria-hidden="true"></i>
                      Reset Password
                    </a>
                    <a class="dropdown-item" @click="deleteClient(client._id)"
                      ><i class="fas fa-trash mr-1" aria-hidden="true"></i>
                      Delete
                    </a>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ClientInfoModal from "@/views/Clients/ClientInfoModal.vue";
import ChangePasswordModal from "@/views/Clients/ChangePasswordModal.vue";

import dayjs from "dayjs";

export default {
  data() {
    return {
      search: "",
      clients: [],
      isLoading: true,
      modalOpen: false,
      passwordModalOpen: false,
      currentlyOpened: {},
      errorMessage: "",
      successMessage: "",
      errorAlert: false,
      successAlert: false,
      changePassword: {
        client_id: "",
        client_company: "",
      },
    };
  },
  components: { ClientInfoModal, ChangePasswordModal },
  computed: {
    filteredItems() {
      return this.clients.filter((o) =>
        Object.keys(o).some((k) =>
          o[k].toString().toLowerCase().includes(this.search.toLowerCase())
        )
      );
    },
  },
  mounted() {
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        this.clients = res.data.clients;
        this.isLoading = !this.isLoading;
      })
      .catch((err) => {
        this.errorAlert = true;
        this.successAlert = false;
        this.errorMessage = err.response.data.msg;
        this.isLoading = !this.isLoading;
      });
  },
  methods: {
    viewClient(e, id) {
      this.isLoading = !this.isLoading;
      this.$axios
        .post(
          "/api/retrieve_clients",
          { client_id: id },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.currentlyOpened = res.data.clients[0];
          this.currentlyOpened.created_at = dayjs(
            res.data.clients[0].created_at
          ).format("DD/MM/YYYY (h:m A)");
          this.currentlyOpened.updated_at = dayjs(
            res.data.clients[0].updated_at
          ).format("DD/MM/YYYY (h:m A)");
          this.modalOpen = true;
          this.isLoading = !this.isLoading;
        })
        .catch((err) => {
          this.errorAlert = true;
          this.successAlert = false;
          this.errorMessage = err.response.data.msg;
          this.isLoading = !this.isLoading;
        });
    },
    closeModal() {
      this.currentlyOpened = {};
      this.modalOpen = false;
      this.passwordModalOpen = false;
    },
    deleteClient(id) {
      if (confirm("Are you sure you want to delete this client?") == true) {
        //confirmed
        this.$axios
          .post(
            "/api/delete_client",
            { client_id: id },
            { headers: this.$authHeader() }
          )
          .then(() => {
            var filtered = this.clients.filter(function (el) {
              return el._id != id;
            });
            this.clients = filtered;
            this.successAlert = true;
            this.successMessage = "Client Deleted!";
            setTimeout(() => {
              this.successAlert = false;
              this.successMessage = "";
            }, 3000);
          })
          .catch((err) => {
            this.errorAlert = true;
            this.errorMessage = err.response.data.msg;
            setTimeout(() => {
              this.errorAlert = false;
              this.errorMessage = "";
            }, 3000);
          });
      }
    },
    resetPassword(id, company) {
      this.passwordModalOpen = true;
      this.changePassword.client_id = id;
      this.changePassword.client_company = company;
    },
  },
};
</script>
<style scoped>
#search {
  float: right;
  max-width: 300px;
  margin-bottom: 20px;
}
.no-clients {
  text-align: center;
  background: #fff;
  cursor: auto;
}
.pointer {
  cursor: pointer;
}
.fa-crown {
  color: gold;
  cursor: help;
}
</style>
