<template>
  <client-side-nav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <client-top-nav />
    <page-loader :isLoading="isLoading"></page-loader>
    <div class="box mt-6">
      <h1 class="subtitle">Forms & Documents</h1>
      <hr />
      <table class="table mt-5 is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Document Name</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="files.length === 0">
            <td colspan="4" class="has-text-centered">No Files</td>
          </tr>
          <tr v-for="file in files" :key="file._id">
            <td>
              <a target="_blank" :href="`/api/files/${file.path}`"
                ><i class="fa-regular fa-file mr-2"></i>{{ file.name }}</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import ClientSideNav from "./ClientSideNav.vue";
import ClientTopNav from "./ClientTopNav.vue";
export default {
  components: { ClientSideNav, ClientTopNav },
  data() {
    return {
      uploadModal: false,
      name: "",
      file: {},
      shared: "No",
      error: false,
      success: false,
      errorMessage: "",
      successMessage: "",
      files: [],
      isLoading: true,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$axios
        .post("/api/retrieve_files_client", {}, { headers: this.$authHeader() })
        .then((res) => {
          this.files = res.data.files;
          this.isLoading = false;
        })
        .catch((err) => {
          alert(err.response.datat.msg);
        });
    },
  },
};
</script>
<style scoped>
.box {
  overflow: auto;
}
.open {
  font-size: 1.2rem;
}
a:hover {
  text-decoration: underline;
}
</style>
