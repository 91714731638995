<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <div class="box">
        <page-loader :isLoading="page_loading"></page-loader>
        <h1 class="subtitle">Updates</h1>
        <hr />
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <div v-if="updates.length > 0">
          <div v-for="u in updates" :key="u.body" class="mt-2">
            <span
              >{{ u.date }} By
              <span class="has-text-danger">{{ u.author }}</span></span
            >
            <article class="message is-dark">
              <div class="message-body">
                {{ u.body }}
              </div>
            </article>
          </div>
        </div>
        <div v-else>
          <p>No Updates!</p>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    auto: Object,
  },
  data() {
    return {
      body: null,
      auto_id: this.$props.auto._id,
      isLoading: false,
      error: null,
      raw_updates: [],
      hidden: true,
      page_loading: true,
    };
  },
  created() {
    // fetch updates
    this.$axios
      .post(
        "/api/retrieve_auto_client",
        { id: this.$props.auto._id },
        { headers: this.$authHeader() }
      )
      .then((res) => {
        this.raw_updates = res.data.auto.updates;
        this.page_loading = false;
      });
  },
  computed: {
    updates() {
      let u = [];
      // return empty array if no updates
      if (!this.raw_updates) {
        return u;
      }
      // change date format
      u = this.raw_updates.map((update) => {
        update.date = dayjs(update.date).format("YYYY-MM-DD hh:mm A");
        return update;
      });
      return u;
    },
  },
  methods: {},
};
</script>
<style scoped>
span {
  font-size: 0.8rem;
  color: #666;
}
.message-body {
  background: #fcf8e3;
  color: #000;
}
</style>
