<template>
  <div class="invoice-container p-6">
    <page-loader :isLoading="pageLoading"></page-loader>
    <div class="invoice-header">
      <h1 class="subtitle">Auto Invoice</h1>
      <hr />
      <i
        class="fa-regular fa-circle-xmark close-button is-size-3 is-clickable"
        @click="$router.go(-1)"
      ></i>
      <div class="notification is-warning" v-if="invoice.locked">
        <i class="fa-solid fa-lock"></i> This invoice is locked, no changes are
        allowed. Only account owner can unlock it.
      </div>
      <div class="notification is-danger" v-if="error">{{ error }}</div>
    </div>
    <button
      class="button is-warning"
      @click.stop.prevent="print"
      v-tippy="{ content: 'Print Invoice' }"
    >
      <i class="fa-solid fa-print"></i>
    </button>

    <div :id="`invoice`" class="invoice box-shadow p-6">
      <img
        :src="`/api/files/${this.$store.state.authUser.logo}`"
        alt="logo"
        class="logo"
      />
      <h1 class="title has-text-right">Invoice</h1>
      <table style="max-width: 300px" class="is-pulled-right mb-2">
        <tr>
          <td><strong>Invoice Nr</strong></td>
          <td>{{ invoice.invoice_number }}</td>
        </tr>
        <tr>
          <td><strong>Invoice Date</strong></td>
          <td>{{ invoice.invoice_date }}</td>
        </tr>
        <tr>
          <td><strong>Due Date</strong></td>
          <td>{{ invoice.due_date }}</td>
        </tr>
        <tr>
          <td><strong>Print Date</strong></td>
          <td>{{ printDate }}</td>
        </tr>
      </table>
      <table class="has-text-centered mt-3">
        <tr>
          <th>From</th>
          <th>To</th>
        </tr>
        <tr>
          <td>
            <p
              style="white-space: pre-line"
              v-html="invoice.user ? invoice.user.settings.invoice_address : ''"
            ></p>
          </td>
          <td>
            <p
              style="white-space: pre-line"
              v-html="invoice.client.billing_address"
            ></p>
          </td>
        </tr>
      </table>
      <table class="has-text-centered mt-3 info">
        <tr>
          <th colspan="4">Auto Details</th>
        </tr>
        <tr>
          <td><strong>Make</strong></td>
          <td class="has-text-left">
            {{
              `${invoice.auto.year} ${invoice.auto.make} ${invoice.auto.model}`
            }}
          </td>
          <td><strong>Loading Point</strong></td>
          <td>{{ invoice.auto.loading_point }}</td>
        </tr>
        <tr>
          <td><strong>Vin</strong></td>
          <td class="has-text-left">{{ invoice.auto.vin }}</td>
          <td><strong>Destination</strong></td>
          <td>{{ invoice.auto.destination }}</td>
        </tr>
        <tr>
          <td><strong>Lot#</strong></td>
          <td class="has-text-left">{{ invoice.auto.lot }}</td>
          <td><strong>Auction</strong></td>
          <td>{{ invoice.auto.auction }}</td>
        </tr>
        <tr>
          <td><strong>City</strong></td>
          <td class="has-text-left">
            {{ invoice.auto.city }}
            <p v-if="invoice.auto.sublot">
              Sublot: {{ invoice.auto.sublot_location }}
            </p>
          </td>
          <td><strong>Buyer Nr.</strong></td>
          <td>{{ invoice.auto.buyer_number }}</td>
        </tr>
      </table>
      <table class="mt-5 has-text-centered">
        <thead>
          <tr>
            <th>Description</th>
            <th width="150px">Amount</th>
          </tr>
        </thead>
        <tbody class="desc">
          <tr v-for="f in invoice.fields" :key="f._id" class="field">
            <td>
              {{ f.description }}
            </td>
            <td>{{ f.amount }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="has-text-right" style="border: none">
              <strong
                >Total (<span class="has-text-danger">{{
                  invoice.currency
                }}</span
                >):</strong
              >
            </td>
            <td style="border: none">
              <strong> {{ invoice_total }}</strong>
            </td>
          </tr>
          <tr>
            <td class="has-text-right" style="border: none">
              <strong
                class="is-clickable"
                @click="
                  () => {
                    this.paymentsModalOpen = true;
                  }
                "
                >Payments:</strong
              >
            </td>
            <td style="border: none">
              <strong>-{{ payments_total }}</strong>
            </td>
          </tr>
          <tr>
            <td class="has-text-right" style="border: none">
              <strong>Balance :</strong>
            </td>
            <td style="border: none">
              <strong> {{ balance }}</strong>
            </td>
          </tr>
        </tfoot>
      </table>
      <hr />
      <h1 class="subtitle has-text-centered">Thank you for your business!</h1>
    </div>
  </div>
  <PaymentsModal
    :payments="invoice.payments"
    v-if="paymentsModalOpen"
    @closed="
      () => {
        this.paymentsModalOpen = false;
      }
    "
  />
</template>
<script>
import dayjs from "dayjs";
window.$ = window.jQuery = require("jquery");
import PaymentsModal from "./PaymentsModal";
const printThis = require("@/assets/printThis");
export default {
  data() {
    return {
      id: this.$route.params.id,
      invoice_fields: [],
      invoice: {},
      invoice_total: 0,
      payments_total: 0,
      balance: 0,
      error: null,
      pageLoading: false,
      paymentsModalOpen: false,
    };
  },
  computed: {
    printDate() {
      return dayjs().format("YYYY-MM-DD");
    },
  },
  created() {
    this.fetchInvoice();
  },
  components: { PaymentsModal },
  methods: {
    fetchInvoice() {
      this.pageLoading = true;
      this.invoice_total = 0;
      this.payments_total = 0;
      this.balance = 0;
      this.$axios
        .get(`/api/retrieve_auto_invoice_client/${this.id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.invoice = res.data.invoice;
          if (res.data.invoice.created) {
            this.invoice.invoice_date = dayjs(res.data.invoice.created)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
          if (res.data.invoice.due_date) {
            this.invoice.due_date = dayjs(res.data.invoice.due_date)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
          this.invoice.payments.map((p) => {
            this.payments_total += p.amount;
          });
          if (this.invoice) {
            this.invoice.fields.map((f) => {
              this.invoice_total += f.amount;
            });
            this.balance = (this.invoice_total - this.payments_total).toFixed(
              2
            );
          }
          this.$axios
            .post(
              `/api/retrieve_quotes_for_invoice/`,
              {
                client: this.invoice.client._id,
                loading_point: this.invoice.auto.loading_point,
                destination: this.invoice.auto.destination,
                currency: this.invoice.currency,
              },
              { headers: this.$authHeader() }
            )
            .then((res) => {
              this.quotes = res.data.quotes;
            })
            .catch((err) => {
              console.log(err);
            });
          this.pageLoading = false;
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
    print() {
      let pageTitle = document.title;
      window.$("#invoice").printThis({
        beforePrint: () => {
          document.title = `Invoice#${this.invoice.invoice_number}`;
        },
        afterPrint: () => {
          document.title = pageTitle;
        },
      });
    },
  },
};
</script>
<style scoped>
.invoice-container {
  background: #f9f9f9;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow: auto;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.invoice {
  min-height: 900px;
  width: 900px;
  border-radius: 10px;
  font-size: 0.8rem;
  color: #000;
  margin: auto;
  background: #fff;
}

.invoice table,
.actions table {
  border-collapse: collapse;
  width: 100%;
}
.invoice td,
.invoice th,
.actions td,
.actions th {
  border: 1px solid rgb(206, 206, 206);
  padding: 5px;
  color: #000;
}
.invoice th,
.actions th {
  background: #eee;
}
.desc td {
  border: none;
  border-bottom: 1px dashed #ddd;
}
.actions {
  min-height: 500px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #ddd;
}
.columns {
  max-width: 2500px;
  margin: auto;
}
strong {
  color: #000;
}
.info td {
  border: none;
  border-bottom: 1px solid #ddd;
}
</style>
<style>
@media print {
  @page {
    margin: 15px;
  }
  .invoice {
    box-shadow: none !important;
  }
  body {
    background: #fff;
  }
}
</style>
