<template>
  <div class="box">
    <h1 class="title">Other Cargo</h1>
    <hr />
    <router-link to="/admin/dashboard/parts/add"
      ><button class="button is-primary mb-3">Add</button></router-link
    >
    <div>
      <div class="columns">
        <div class="column">
          <input
            type="text"
            class="input"
            placeholder="Part ID"
            v-model="part_number"
            @keyup.enter="handleSearch"
          />
        </div>
        <div class="column">
          <v-select
            placeholder="Client"
            v-model="client"
            :options="clients"
          ></v-select>
        </div>
        <div class="column">
          <input
            type="text"
            class="input"
            placeholder="Tracking#"
            v-model="tracking_number"
            @keyup.enter="handleSearch"
          />
        </div>
        <div class="column">
          <v-select
            placeholder="Loading Point"
            v-model="loading_point"
            :options="loading_points"
          ></v-select>
        </div>
        <div class="column">
          <v-select
            placeholder="Destination"
            v-model="destination"
            :options="destinations"
          ></v-select>
        </div>
        <div class="column is-2">
          <button class="button is-primary" @click.stop.prevent="handleSearch">
            <i class="fa-solid fa-magnifying-glass ml-1"></i>
          </button>
          <button
            class="button is-danger ml-2"
            @click.stop.prevent="resetFilters"
          >
            Clear
          </button>
        </div>
      </div>
    </div>
    <PartsTable :filter="filter" />
  </div>
</template>
<script>
import PartsTable from "./PartsTable";
export default {
  data() {
    return {
      loading_points: [],
      destinations: [],
      clients: [],
      part_number: null,
      client: null,
      loading_point: null,
      destination: null,
      tracking_number: null,
      filter: {},
    };
  },
  components: { PartsTable },
  created() {
    // fetch required selections
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.selections.map((s) => {
          if (s.type === "Loading Points") this.loading_points.push(s.value);
          if (s.type === "Destinations") this.destinations.push(s.value);
        });
      });

    // fetch clients
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.clients.map((c) => {
          this.clients.push({ code: c._id, label: c.company });
        });
      });

    // check for query
    if (this.$route.query.loading_point)
      this.loading_point = this.$route.query.loading_point;
    if (this.$route.query.destination)
      this.destination = this.$route.query.destination;
    if (this.$route.query.tracking_number)
      this.tracking_number = this.$route.query.tracking_number;
    if (this.$route.query.part_number)
      this.part_number = this.$route.query.part_number;
    if (this.$route.query.client_id && this.$route.query.client_company)
      this.client = {
        code: this.$route.query.client_id,
        label: this.$route.query.client_company,
      };
    this.filter = this.$route.query;
  },
  methods: {
    handleSearch() {
      let q = {};
      if (this.part_number) q.part_number = this.part_number;
      if (this.client) q.client_id = this.client.code;
      if (this.client) q.client_company = this.client.label;
      if (this.tracking_number) q.tracking_number = this.tracking_number;
      if (this.loading_point) q.loading_point = this.loading_point;
      if (this.destination) q.destination = this.destination;
      this.$router.push({ query: q, path: "/admin/dashboard/parts" });
      this.filter = q;
    },
    resetFilters() {
      (this.part_number = null),
        (this.client = null),
        (this.tracking_number = null),
        (this.loading_point = null),
        (this.destination = null);
      this.filter = {};
      this.$router.push({ path: "/admin/dashboard/parts" });
    },
  },
};
</script>
