<template>
  <page-loader :isLoading="isLoading"></page-loader>
  <hr />
  <div class="notification is-danger" v-if="error">{{ error }}</div>
  <div class="notification is-primary" v-if="success">{{ success }}</div>
  <p class="mt-1">Total: {{ totalParts }}</p>
  <!-- Paginating-->
  <nav
    class="pagination is-small is-centered"
    role="navigation"
    aria-label="pagination"
  >
    <!-- PREV Page-->
    <a
      class="pagination-previous"
      v-if="hasPrevPage"
      @click.prevent="changePage(prevPage)"
      ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
    >
    <a class="pagination-previous" disabled v-if="!hasPrevPage"
      ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
    >
    <!-- NEXT Page-->
    <a
      class="pagination-next"
      @click.prevent="changePage(nextPage)"
      v-if="hasNextPage"
      >Next page <i class="fa-solid fa-arrow-right ml-2"></i
    ></a>
    <a class="pagination-next" disabled v-if="!hasNextPage"
      >Next page <i class="fa-solid fa-arrow-right ml-2"></i
    ></a>
    <ul class="pagination-list">
      <!-- FIRST Page-->
      <li>
        <a
          class="pagination-link"
          aria-label="Goto page 1"
          @click.prevent="changePage(1)"
          >First</a
        >
      </li>
      <li><span class="pagination-ellipsis">&hellip;</span></li>
      <li>
        <!-- CURRENT Page-->
        <a
          class="pagination-link is-current"
          aria-label="Page 46"
          aria-current="page"
          >{{ page }}</a
        >
      </li>

      <li><span class="pagination-ellipsis">&hellip;</span></li>
      <li>
        <!-- LAST Page-->
        <a
          class="pagination-link"
          aria-label="Goto page 86"
          @click.prevent="changePage(totalPages)"
          >Last</a
        >
      </li>
    </ul>
  </nav>
  <div class="table-wraper">
    <table class="table is-fullwidth mt-3 box-shadow is-hoverable">
      <thead>
        <tr>
          <th>ID</th>
          <th>Imgs</th>
          <th>Client</th>
          <th>Description</th>
          <th>QTY</th>
          <th>Delivery Date</th>
          <th>From/To</th>
          <th>Shipping Info</th>
          <th>Tracking#</th>
          <th>Notes</th>
          <th>Invoices</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="parts.length == 0">
          <td colspan="12" class="has-text-centered">No Available Data</td>
        </tr>
        <tr v-for="part in parts" :key="part._id">
          <td>{{ part.part_id }}</td>
          <td>
            <figure class="thumbnail">
              <img
                :src="`/api/files/${part.images[0] || 'no_photo.png'}`"
                @click="() => showImg(0, part.images)"
              />
            </figure>
          </td>
          <td>
            <span v-if="part.client">{{
              part.client.display_name || part.client.company
            }}</span>
          </td>
          <td>{{ part.description }}</td>
          <td>{{ part.qty }}</td>
          <td>{{ part.delivery_date }}</td>
          <td>
            From :{{ part.loading_point }} <br />
            To : {{ part.destination }}
          </td>
          <td>
            {{
              part.inLoadingList && part.loading_list
                ? `Loading List#` + part.loading_list.list_id
                : ""
            }}
          </td>
          <td>{{ part.tracking_number }}</td>
          <td>{{ part.notes }}</td>
          <td>
            <p v-for="invoice in part.invoices" :key="invoice._id">
              <router-link :to="`/client/dashboard/part_invoice/${invoice._id}`"
                ><span class="has-text-dark">{{ invoice.invoice_number }}</span>
                <span class="has-text-danger"> ({{ invoice.currency }})</span>
              </router-link>
            </p>
          </td>
        </tr>
        <vue-easy-lightbox
          :visible="lightboxVisible"
          :imgs="images"
          :index="index"
          @hide="hidePics"
        ></vue-easy-lightbox>
      </tbody>
    </table>
  </div>
  <AddInvoiceModal
    v-if="viewNewInvoice"
    :part_id="invoicePartId"
    @close="
      () => {
        this.viewNewInvoice = false;
        this.invoicePartId = null;
      }
    "
  />
</template>
<script>
import dayjs from "dayjs";
export default {
  components: {},
  props: {
    filter: Object,
  },
  data() {
    return {
      parts: [],
      isLoading: false,
      error: null,
      success: null,
      page: this.$route.query.page || 1,
      prevPage: null,
      hasPrevPage: null,
      totalParts: null,
      nextPage: null,
      hasNextPage: null,
      totalPages: null,
      index: 0,
      lightboxVisible: false,
      invoicePartId: null,
      viewNewInvoice: false,
      images: [],
    };
  },
  watch: {
    page(p) {
      this.fetchParts(this.filter);
    },
    filter(filter) {
      this.fetchParts(filter);
    },
  },
  created() {
    this.fetchParts(this.filter);
  },

  methods: {
    fetchParts(filter) {
      let request_body = filter;
      request_body.page = this.page;
      this.isLoading = true;
      this.$axios
        .post("/api/parts/retrieve_parts_client", request_body, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.parts = res.data.parts.map((p) => {
            if (p.delivery_date) {
              p.delivery_date = dayjs(p.delivery_date)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            return p;
          });
          this.totalPages = res.data.totalPages;
          this.totalParts = res.data.totalParts;
          this.nextPage = res.data.nextPage;
          this.prevPage = res.data.prevPage;
          this.hasNextPage = res.data.hasNextPage;
          this.hasPrevPage = res.data.hasPrevPage;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    // Delete Part
    handleDelete(id) {
      if (confirm("Are you sure?")) {
        this.$axios
          .get(`/api/parts/delete/${id}`, { headers: this.$authHeader() })
          .then((res) => {
            (this.error = null), (this.success = res.data.msg);
            this.fetchParts(this.filter);
          })
          .catch((err) => {
            (this.success = null), (this.error = err.response.data.msg);
          });
      }
    },
    // Change Page
    changePage(number) {
      this.page = number;
      const query = {
        ...this.$route.query,
        page: number,
      };
      this.$router.push({ path: "/client/dashboard/parts", query });
    },
    showImg(index, images) {
      this.images = images.map((i) => {
        return document.location.origin + "/api/files/" + i;
      });
      this.index = index;
      this.lightboxVisible = true;
    },
    hidePics() {
      this.lightboxVisible = false;
    },
    openInvoiceModal(id) {
      this.invoicePartId = id;
      this.viewNewInvoice = true;
    },
  },
};
</script>
<style scoped>
.is-current {
  background: #00d1b2 !important;
  border: none;
}
.table td {
  font-size: 0.8rem !important;
}
.thumbnail {
  width: 64px;
  cursor: pointer;
}
.new_invoice {
  cursor: pointer;
  font-weight: bold;
}
.new_invoice i {
  color: #00d1b2;
}
</style>
