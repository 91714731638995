<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('closed')"></div>
    <div class="modal-content">
      <div class="box">
        <page-loader :isLoading="isLoading"></page-loader>
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <label class="label">Select One</label>
        <v-select
          :options="contacts"
          placeholder="Select One"
          v-model="info"
        ></v-select>
        <button
          class="button mt-4 is-primary"
          @click.stop.prevent="handleSubmit"
        >
          Save
        </button>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('closed')"
    ></button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      contacts: [],
      error: null,
      isLoading: false,
      info: null,
    };
  },
  props: {
    type: String,
  },
  created() {
    this.fetchData(this.$props.type);
  },
  methods: {
    fetchData(contact_type) {
      this.isLoading = true;

      this.$axios
        .post(
          "/api/retrieve_contacts",
          {
            contact_type,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          res.data.contacts.map((c) => {
            this.contacts.push({ code: c._id, label: c.name });
          });
          this.isLoading = false;
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
    handleSubmit() {
      if (!this.info) {
        return (this.error = "Please fill out the required fields");
      }
      let i;
      if (this.info) {
        i = this.info.code;
      }
      this.isLoading = true;
      this.$axios
        .post(
          `/api/edit_shipping_info_container/${this.$route.params.id}`,
          { info: i, contact_type: this.$props.type },
          { headers: this.$authHeader() }
        )
        .then((done) => {
          this.isLoading = false;
          this.error = null;
          this.$emit("closed");
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
  },
};
</script>
<style scoped>
.modal-content,
.box {
  overflow: visible !important;
}
</style>
