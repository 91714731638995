<template>
  <page-loader :isLoading="isLoading"></page-loader>
  <div class="box">
    <h1 class="title">Dispatch</h1>
    <hr />
    <!-- Page Navigation-->
    <div class="tabs is-centered">
      <ul>
        <li>
          <router-link to="/admin/dashboard/dispatch/"
            ><i class="fa-regular fa-hourglass mr-3"></i>To be
            Dispatched</router-link
          >
        </li>
        <li>
          <router-link to="/admin/dashboard/dispatch/to_be_picked_up"
            ><i class="fa-solid fa-clipboard-check mr-3"></i>Ready for Pick
            up</router-link
          >
        </li>

        <li class="is-active">
          <a> <i class="fa-regular fa-paper-plane mr-3"></i>On the way </a>
        </li>
        <li>
          <router-link to="/admin/dashboard/dispatch/delivered"
            ><i class="fa-solid fa-check mr-3"></i>Delivered</router-link
          >
        </li>
        <li>
          <router-link to="/admin/dashboard/dispatch/transporters"
            ><i class="fa-solid fa-people-group mr-3"></i
            >Transporters</router-link
          >
        </li>
      </ul>
    </div>
    <p class="block">Total : {{ totalAutos }}</p>
    <!-- Paginating-->
    <nav
      class="pagination is-small is-centered"
      role="navigation"
      aria-label="pagination"
    >
      <!-- PREV Page-->
      <a
        class="pagination-previous"
        v-if="hasPrevPage"
        @click.prevent="changePage(prevPage)"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <a class="pagination-previous" disabled v-if="!hasPrevPage"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <!-- NEXT Page-->
      <a
        class="pagination-next"
        @click.prevent="changePage(nextPage)"
        v-if="hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <a class="pagination-next" disabled v-if="!hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <ul class="pagination-list">
        <!-- FIRST Page-->
        <li>
          <a
            class="pagination-link"
            aria-label="Goto page 1"
            @click.prevent="changePage(1)"
            >First</a
          >
        </li>
        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- CURRENT Page-->
          <a
            class="pagination-link is-current"
            aria-label="Page 46"
            aria-current="page"
            >{{ page }}</a
          >
        </li>

        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- LAST Page-->
          <a
            class="pagination-link"
            aria-label="Goto page 86"
            @click.prevent="changePage(totalPages)"
            >Last</a
          >
        </li>
      </ul>
    </nav>
    <!-- Table-->
    <div class="table-wraper">
      <table class="table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Client</th>
            <th>Vehicle</th>
            <th>VIN</th>
            <th>LOT</th>
            <th>Auction</th>
            <th>Location</th>
            <th>Notes</th>
            <th>Transporter</th>
            <th>Cancel Order</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="auto in autos" :key="auto._id">
            <td>{{ auto.client.display_name || auto.client.company }}</td>
            <td>{{ `${auto.year} ${auto.make} ${auto.model}` }}</td>
            <td>{{ auto.vin }}</td>
            <td>{{ auto.lot }}</td>
            <td>{{ auto.auction }}</td>
            <td>
              {{ auto.city }}
              <br />
              <span
                class="tag is-danger"
                v-if="auto.sublot"
                v-tippy="{
                  content: auto.sublot_location,
                  placement: 'top',
                }"
                >Sublot</span
              >
            </td>
            <td>
              <article class="message is-warning is-small">
                <div class="message-body" v-if="auto.notes">
                  {{ auto.notes }}
                </div>
              </article>
            </td>
            <td class="has-text-weight-bold">
              <span
                v-tippy="{ content: `Dispatched at ${auto.dispatched_date}` }"
              >
                {{ auto.transporter }}
              </span>
            </td>
            <td>
              <button
                class="button is-danger is-small is-rounded"
                @click.stop.prevent="cancelOrder(auto._id)"
              >
                <i class="fa-solid fa-circle-xmark mr-2"></i>Cancel
              </button>
            </td>
          </tr>
          <tr v-if="autos && autos.length == 0">
            <td colspan="9" class="has-text-centered">No Data</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      autos: [],
      page: this.$route.query.page || 1,
      prevPage: null,
      hasPrevPage: null,
      totalAutos: null,
      nextPage: null,
      hasNextPage: null,
      totalPages: null,
      isLoading: false,
    };
  },
  watch: {
    page() {
      this.$router.push(`?page=${this.page}`);
      this.fetchData();
    },
  },
  created() {
    // fetch data
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$axios
        .post(
          "/api/retrieve_autos_on_the_way",
          { page: this.page },
          {
            headers: this.$authHeader(),
          }
        )
        .then((res) => {
          this.autos = res.data.autos.map((a) => {
            if (a.dispatched_date) {
              a.dispatched_date = dayjs(a.dispatched_date).format(
                "YYYY-MM-DD hh:mm A"
              );
            }
            return a;
          });
          this.totalPages = res.data.totalPages;
          this.totalAutos = res.data.totalAutos;
          this.nextPage = res.data.nextPage;
          this.prevPage = res.data.prevPage;
          this.hasNextPage = res.data.hasNextPage;
          this.hasPrevPage = res.data.hasPrevPage;
          this.isLoading = false;
        })
        .catch((err) => {
          alert(err.response.data.msg);
          this.isLoading = false;
        });
    },
    // Change Page
    changePage(number) {
      this.page = number;
    },
    changeStatus(id) {
      this.$axios
        .get(`/api/change_to_picked_up/${id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.fetchData();
        });
    },
    cancelOrder(id) {
      if (confirm("Are you sure?")) {
        this.$axios
          .get(`/api/cancel_dispatch_order/${id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchData();
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    },
  },
};
</script>
<style scoped>
tbody tr {
  min-height: 50px;
}
</style>
