<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('closeModal')"></div>
    <div class="modal-content">
      <div class="box">
        <h1 class="subtitle">Messages</h1>
        <hr />
        <table class="table is-fullwidth is-bordered">
          <tr class="has-background-light">
            <th>Auto</th>
            <th>VIN</th>
            <th>Lot</th>
            <th>Client</th>
          </tr>
          <tr>
            <td>{{ `${auto.year} ${auto.make} ${auto.model}` }}</td>
            <td>{{ auto.vin }}</td>
            <td>{{ auto.lot }}</td>
            <td>{{ auto.client.company }}</td>
          </tr>
        </table>
        <hr />
        <page-loader :isLoading="isLoading"></page-loader>
        <div class="messages">
          <article
            :class="`message ${m.by_admin ? 'is-warning' : ''} is-small`"
            v-for="m in messages"
            :key="m._id"
          >
            <div class="message-header">
              <p>{{ m.author }} wrote:</p>
            </div>
            <div class="message-body">
              {{ m.body }}
              <br />
              <a
                v-if="m.attachment"
                :href="`/api/files/${m.attachment}`"
                target="_blank"
                class="has-text-link"
                >Attachment <i class="fa-solid fa-paperclip ml-1"></i
              ></a>
            </div>
            <span class="m-1">{{ m.created_at }}</span>
          </article>
          <hr />
          <div class="notification is-danger" v-if="error">{{ error }}</div>
          <a @click.prevent="() => (this.hidden = !this.hidden)"
            >New Message
            <i
              :class="`fa-solid  ml-1 ${
                hidden ? 'fa-arrow-down' : 'fa-arrow-up'
              }`"
            ></i
          ></a>
          <div v-if="!hidden">
            <div class="block">
              <v-select
                :options="[
                  'Accounting',
                  'Titles',
                  'Bookings',
                  'Loading',
                  'Pictures',
                  'Documentation',
                  'Other',
                ]"
                v-model="department"
                placeholder="Department"
                style="max-width: 300px"
              ></v-select>
            </div>
            <textarea
              class="textarea mt-2"
              placeholder="Your Message"
              v-model="body"
            ></textarea>
            <input type="file" @change="handleUpload" />
            <hr />
            <button
              class="button is-primary mt-2"
              @click.stop.prevent="handleSubmit"
            >
              Send <i class="fa-solid fa-paper-plane ml-3"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('closeModal')"
    ></button>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    auto: Object,
  },
  data() {
    return {
      body: null,
      attachment: null,
      error: null,
      auto_id: this.auto._id,
      messages: [],
      isLoading: true,
      hidden: true,
      department: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    handleUpload(e) {
      let file = e.target.files[0];
      if (
        file.type.split("/")[1] == "pdf" ||
        file.type.split("/")[1] == "jpg" ||
        file.type.split("/")[1] == "png" ||
        file.type.split("/")[1] == "jpeg"
      ) {
        this.attachment = file;
        return;
      }
      this.error = "This file type is not allowed";
      this.attachment = null;
      return;
    },
    fetchData() {
      this.$axios
        .post(
          "/api/autos/retrieve_messages_client",
          { auto_id: this.auto_id },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.messages = res.data.messages.map((m) => {
            m.created_at = dayjs(m.created_at).format("YYYY-MM-DD HH:MM A");
            return m;
          });
          this.isLoading = false;
        });
    },
    //submit data
    handleSubmit() {
      // validate
      if (!this.body) {
        return (this.error = "Message is required!");
      }
      if (!this.department) {
        return (this.error = "Please select a department");
      }
      let headers = this.$authHeader();
      // changing the headers to include files
      headers["Content-Type"] = "multipart/form-data";
      this.$axios
        .post(
          "/api/autos/add_message_client",
          {
            body: this.body,
            department: this.department,
            auto_id: this.auto_id,
            attachment: this.attachment,
            client: this.$props.auto.client._id,
          },
          { headers }
        )
        .then((res) => {
          // reset state
          (this.department = null),
            (this.body = null),
            (this.attachment = null);
          this.fetchData();
          this.$emit("changed");
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
  },
};
</script>
<style scoped>
.message .message-header p {
  color: #fff;
}
.is-warning .message-header p {
  color: #333;
}
</style>
