<template>
  <div class="modal is-active">
    <div class="modal-background" @click="() => $emit('closed')"></div>
    <div class="modal-content">
      <div class="box">
        <h2>Dispatch</h2>
        <hr />
        <p class="block tag is-large">
          {{ `${auto.year} ${auto.make} ${auto.model} VIN#${auto.vin}` }}
        </p>
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <div class="field">
          <label class="label"
            >Transporter <b class="has-text-danger">*</b></label
          >
          <v-select
            placeholder="Transporter"
            v-model="transporter"
            :options="transporters"
            taggable
          ></v-select>
        </div>
        <div class="field">
          <label class="label">Transporter Email</label>
          <input
            type="text"
            class="input"
            placeholder="Email"
            v-model="email"
          />
        </div>
        <div class="field">
          <label class="label">CC:</label>
          <input type="text" class="input" placeholder="Email" v-model="cc" />
        </div>
        <div class="field">
          <label class="label"
            >Auto Location <b class="has-text-danger">*</b></label
          >
          <textarea
            class="textarea"
            rows="2"
            placeholder="Auto Location"
            v-model="location"
          ></textarea>
        </div>
        <div class="field">
          <label class="label"
            >Delivery Location <b class="has-text-danger">*</b></label
          >
          <textarea
            class="textarea"
            rows="2"
            placeholder="Delivery Location"
            v-model="delivery_location"
          ></textarea>
        </div>
        <div class="field">
          <label class="label">Notes for Transporter</label>
          <textarea
            class="textarea"
            rows="2"
            placeholder="Notes"
            v-model="notes"
          ></textarea>
        </div>
        <div class="field">
          <label class="label">Include Documents</label>
          <label
            style="display: block; width: fit-content"
            class="checkbox"
            v-for="doc in auto.documents"
            :key="doc.document"
          >
            <input type="checkbox" :value="doc" v-model="documents" />
            {{ doc.type }}
          </label>
        </div>
        <div class="field">
          <label
            class="label"
            v-tippy="{
              content:
                'if yes, the transporter will receive an email with the pick up details.',
            }"
            ><span>Send the pick up order by email</span>
          </label>
          <div class="control">
            <label class="radio">
              <input type="radio" :value="true" v-model="email_order" />
              Yes
            </label>
            <label class="radio">
              <input type="radio" :value="false" v-model="email_order" />
              No
            </label>
          </div>
        </div>
        <button
          :class="`button is-primary ${loading ? 'is-loading' : ''}`"
          @click.stop.prevent="handleSubmit"
        >
          Dispatch
        </button>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="() => $emit('closed')"
    ></button>
  </div>
</template>
<script>
export default {
  props: {
    auto: Object,
  },
  data() {
    return {
      currencies: [],
      transporters: [],
      transporter: null,
      email: null,
      cc: null,
      location: null,
      delivery_location: null,
      notes: null,
      documents: [],
      error: null,
      loading: false,
      email_order: true,
    };
  },
  watch: {
    transporter(t) {
      if (t && t.email) {
        this.email = t.email;
      } else {
        this.email = null;
      }
      if (t && t.phone) {
        this.phone = t.phone;
      } else {
        this.phone = null;
      }
    },
  },
  created() {
    //
    this.delivery_location = this.$props.auto.loading_point;
    if (this.$props.auto.city || this.$props.auto.sublot_location) {
      this.location = `${
        this.$props.auto.city ? `City : ${this.$props.auto.city}` : ""
      } \n${
        this.$props.auto.sublot
          ? `Sublot : ${this.$props.auto.sublot_location}`
          : ""
      }`;
    }
    this.notes = `${
      this.$props.auto.auction ? `Auction : ${this.$props.auto.auction}` : ""
    } ${this.$props.auto.lot ? ` / Lot : ${this.$props.auto.lot}` : ""}`;
    // fetch user currencies
    this.$axios
      .post("/api/retrieve_user_settings", {}, { headers: this.$authHeader() })
      .then((res) => {
        this.currencies = res.data.settings.currencies;
      })
      .catch(() => {});
    // fetch transporters
    this.$axios
      .get("/api/retrieve_transporters", {
        headers: this.$authHeader(),
      })
      .then((res) => {
        res.data.transporters.map((t) => {
          this.transporters.push({
            code: t.company,
            label: t.company,
            email: t.email,
            phone: t.phone,
          });
        });
      })
      .catch((err) => {});
  },
  methods: {
    handleSubmit() {
      // validate
      if (!this.transporter || !this.location || !this.delivery_location) {
        this.error = "Please fill out all the required inuts";
        return;
      }
      this.$axios
        .post(
          `/api/submit_dispatch/${this.$props.auto._id}`,
          {
            transporter: this.transporter,
            email: this.email,
            email_order: this.email_order,
            location: this.location,
            delivery_location: this.delivery_location,
            rate: this.rate,
            notes: this.notes,
            documents: this.documents,
            cc: this.cc,
          },
          {
            headers: this.$authHeader(),
          }
        )
        .then((res) => {
          this.$emit("dispatched");
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
  },
};
</script>
<style scoped></style>
