<template>
  <div class="invoice-container p-6">
    <page-loader :isLoading="pageLoading"></page-loader>
    <div class="invoice-header">
      <h1 class="subtitle">Other Cargo Invoice</h1>
      <hr />
      <i
        class="fa-regular fa-circle-xmark close-button is-size-3 is-clickable"
        @click="$router.go(-1)"
      ></i>
      <div class="notification is-warning" v-if="invoice.locked">
        <i class="fa-solid fa-lock"></i> This invoice is locked, no changes are
        allowed. Only account owner can unlock it.
      </div>
      <div class="notification is-danger" v-if="error">{{ error }}</div>
    </div>
    <div class="columns" style="overflow: auto">
      <div class="column is-5">
        <div class="actions p-4 box-shadow">
          <h1 class="subtitle">Invoice Actions</h1>
          <button
            class="button is-warning"
            @click.stop.prevent="print"
            v-tippy="{ content: 'Print Invoice' }"
          >
            <i class="fa-solid fa-print"></i>
          </button>
          <button
            @click.stop="deleteInvoice"
            class="button is-danger ml-3"
            v-tippy="{ content: 'Delete Invoice' }"
          >
            <i class="fa-solid fa-trash-can"></i>
          </button>
          <button
            v-if="!invoice.locked"
            @click.stop="lockInvoice"
            class="button is-info ml-3"
            v-tippy="{ content: 'Lock Invoice' }"
          >
            <i class="fa-solid fa-lock"></i>
          </button>
          <button
            v-if="invoice.locked"
            @click.stop="unlockInvoice"
            class="button is-primary ml-3"
            v-tippy="{ content: 'Unlock Invoice' }"
          >
            <i class="fa-solid fa-lock-open"></i>
          </button>
          <hr />
          <div>
            <h1 class="subtitle has-text-primary">Due Date</h1>
            <div class="field is-horizontal">
              <DatePicker
                v-model="due_date"
                placeholder="Pick a Date"
                format="yyyy-MM-dd"
                :enableTimePicker="false"
                :autoApply="true"
              />
              <button @click.stop.prevent="handleDateChange">Update</button>
            </div>
            <h1 class="subtitle has-text-primary">Add Field</h1>
            <label class="label">Amount</label>
            <input
              type="number"
              class="input"
              placeholder="Amount"
              v-model="amount"
            />
            <label class="label mt-4">Description</label>
            <v-select
              v-model="description"
              placeholder="Description"
              :options="invoice_fields"
            ></v-select>
            <div v-if="showOther">
              <label class="label mt-4">Other</label>
              <textarea
                class="textarea"
                placeholder="Other"
                v-model="other"
              ></textarea>
            </div>
            <button
              class="button is-primary mt-3"
              @click.stop.prevent="addField"
            >
              Add
            </button>
            <hr />
            <h1 class="subtitle has-text-primary">Client Quotes</h1>
            <table v-for="q in quotes" :key="q._id" class="mt-4">
              <tr>
                <th colspan="2" class="has-text-centered">
                  Quote#{{ q.quote_id }} - (<span class="has-text-danger">{{
                    q.currency
                  }}</span
                  >)
                  <br />
                  {{ q.loading_point }} => {{ q.destination }}
                </th>
              </tr>
              <tr>
                <td><strong>Description</strong></td>
                <td><strong>Amount</strong></td>
              </tr>
              <tr v-for="d in q.details" :key="d._id">
                <td>
                  {{ d.shipping_lines ? `${d.shipping_lines} / ` : null
                  }}{{ d.size ? `${d.size} /` : null }}
                  {{ d.description ? `${d.description} ` : null }}
                </td>
                <td>{{ d.amount }}</td>
              </tr>
              <tr>
                <td
                  colspan="2"
                  style="background-color: #fcf8e3"
                  class="has-text-centered"
                >
                  <strong>Quote Notes</strong>
                  <br />
                  {{ q.notes }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
      <div class="column">
        <div :id="`invoice`" class="invoice box-shadow p-6">
          <img
            :src="`/api/files/${this.$store.state.authUser.logo}`"
            alt="logo"
            class="logo"
          />
          <h1 class="title has-text-right">Invoice</h1>
          <table style="max-width: 300px" class="is-pulled-right mb-2">
            <tr>
              <td><strong>Invoice Nr</strong></td>
              <td>{{ invoice.invoice_number }}</td>
            </tr>
            <tr>
              <td><strong>Invoice Date</strong></td>
              <td>{{ invoice.invoice_date }}</td>
            </tr>
            <tr>
              <td><strong>Due Date</strong></td>
              <td>{{ invoice.due_date }}</td>
            </tr>
            <tr>
              <td><strong>Print Date</strong></td>
              <td>{{ printDate }}</td>
            </tr>
          </table>
          <table class="has-text-centered mt-3">
            <tr>
              <th>From</th>
              <th>To</th>
            </tr>
            <tr>
              <td>
                <p
                  style="white-space: pre-line"
                  v-html="
                    invoice.user ? invoice.user.settings.invoice_address : ''
                  "
                ></p>
              </td>
              <td>
                <p
                  style="white-space: pre-line"
                  v-html="invoice.client.billing_address"
                ></p>
              </td>
            </tr>
          </table>
          <table class="has-text-centered mt-3 info">
            <tr>
              <th colspan="4">Details</th>
            </tr>
            <tr>
              <td><strong>Description</strong></td>
              <td class="has-text-left">
                {{ `${invoice.part.description}` }}
              </td>
              <td><strong>Qty</strong></td>
              <td>{{ invoice.part.qty }}</td>
            </tr>
            <tr>
              <td><strong>Part ID</strong></td>
              <td class="has-text-left">{{ invoice.part.part_id }}</td>
              <td><strong>Tracking Nr.</strong></td>
              <td>{{ invoice.part.tracking_number }}</td>
            </tr>
            <tr>
              <td><strong>Loading Point</strong></td>
              <td class="has-text-left">{{ invoice.part.loading_point }}</td>
              <td><strong>Destination</strong></td>
              <td>{{ invoice.part.destination }}</td>
            </tr>
          </table>
          <table class="mt-5 has-text-centered">
            <thead>
              <tr>
                <th>Description</th>
                <th width="150px">Amount</th>
              </tr>
            </thead>
            <tbody class="desc">
              <tr v-for="f in invoice.fields" :key="f._id" class="field">
                <td>
                  <i
                    @click.stop="deleteField(f._id)"
                    class="fa-solid fa-trash-can is-pulled-left has-text-danger is-clickable"
                  ></i
                  >{{ f.description }}
                </td>
                <td>{{ f.amount }}</td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td class="has-text-right" style="border: none">
                  <strong
                    >Total (<span class="has-text-danger">{{
                      invoice.currency
                    }}</span
                    >):</strong
                  >
                </td>
                <td style="border: none">
                  <strong> {{ invoice_total }}</strong>
                </td>
              </tr>
              <tr>
                <td class="has-text-right" style="border: none">
                  <strong
                    class="is-clickable"
                    @click="
                      () => {
                        this.paymentsModalOpen = true;
                      }
                    "
                    >Payments:</strong
                  >
                </td>
                <td style="border: none">
                  <strong>-{{ payments_total }}</strong>
                </td>
              </tr>
              <tr>
                <td class="has-text-right" style="border: none">
                  <strong>Balance :</strong>
                </td>
                <td style="border: none">
                  <strong> {{ balance }}</strong>
                </td>
              </tr>
            </tfoot>
          </table>
          <hr />
          <h1 class="subtitle has-text-centered">
            Thank you for your business!
          </h1>
        </div>
      </div>
    </div>
  </div>
  <PaymentsModal
    :payments="invoice.payments"
    v-if="paymentsModalOpen"
    @closed="
      () => {
        this.paymentsModalOpen = false;
      }
    "
  />
</template>
<script>
import dayjs from "dayjs";
window.$ = window.jQuery = require("jquery");
const printThis = require("@/assets/printThis");
import PaymentsModal from "./PaymentsModal";
export default {
  data() {
    return {
      due_date: null,
      invoice_date: null,
      amount: null,
      description: null,
      other: null,
      showOther: false,
      id: this.$route.params.id,
      invoice_fields: [],
      invoice: {},
      invoice_total: 0,
      payments_total: 0,
      balance: 0,
      error: null,
      pageLoading: false,
      quotes: [],
      paymentsModalOpen: false,
    };
  },
  computed: {
    printDate() {
      return dayjs().format("YYYY-MM-DD");
    },
  },
  watch: {
    description(v) {
      if (v == "Other") {
        this.showOther = true;
      } else {
        this.showOther = false;
      }
    },
  },
  created() {
    this.fetchInvoice();
    this.$axios
      .post("/api/retrieve_user_settings", {}, { headers: this.$authHeader() })
      .then((res) => {
        this.invoice_fields = res.data.settings.invoice_fields || [];
        this.invoice_fields.push("Other");
      })
      .catch(() => {});
  },
  components: { PaymentsModal },
  methods: {
    fetchInvoice() {
      this.pageLoading = true;
      this.invoice_total = 0;
      this.payments_total = 0;
      this.balance = 0;
      this.$axios
        .get(`/api/retrieve_auto_invoice/${this.id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.invoice = res.data.invoice;
          if (res.data.invoice.created) {
            this.invoice.invoice_date = dayjs(res.data.invoice.created)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
          if (res.data.invoice.due_date) {
            this.invoice.due_date = dayjs(res.data.invoice.due_date)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
          if (this.invoice) {
            this.invoice.fields.map((f) => {
              this.invoice_total += f.amount;
            });
            this.invoice.payments.map((p) => {
              this.payments_total += p.amount;
            });
            this.balance = (this.invoice_total - this.payments_total).toFixed(
              2
            );
          }
          this.$axios
            .post(
              `/api/retrieve_quotes_for_invoice/`,
              {
                client: this.invoice.client._id,
                loading_point: this.invoice.part.loading_point,
                destination: this.invoice.part.destination,
                currency: this.invoice.currency,
              },
              { headers: this.$authHeader() }
            )
            .then((res) => {
              this.quotes = res.data.quotes;
            })
            .catch((err) => {
              console.log(err);
            });
          this.pageLoading = false;
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
    addField() {
      let amount = this.amount;
      let description = this.description;
      // 1 validate
      if (isNaN(amount)) {
        return (this.error = "Amount is not valid");
      }
      if (this.amount == 0 || this.amount < 0) {
        return (this.error = "Amount is not valid");
      }
      if (!this.amount || !this.description) {
        return (this.error = "Please fill out all the required fields");
      }
      if (this.description == "Other" && !this.other) {
        return (this.error = "Please fill out all the required fields");
      }
      if (this.description == "Other") {
        description = this.other;
      }
      this.$axios
        .post(
          `/api/add_invoice_field/${this.id}`,
          {
            amount: (Math.round(this.amount * 100) / 100).toFixed(2),
            description,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.error = null;
          this.fetchInvoice();
          (this.amount = null), (this.other = null), (this.description = null);
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
    handleDateChange() {
      if (!this.due_date) {
        return;
      }
      this.$axios
        .post(
          `/api/update_due_date/${this.id}`,
          { due_date: dayjs(this.due_date).format("YYYY-MM-DD HH:MM") },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.due_date = null;
          this.fetchInvoice();
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
    deleteField(id) {
      if (confirm("Are you sure?")) {
        // delete
        this.$axios
          .post(
            "/api/delete_invoice_field",
            { invoice_id: this.id, field_id: id },
            { headers: this.$authHeader() }
          )
          .then((res) => {
            this.error = null;
            this.fetchInvoice();
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      }
    },
    deleteInvoice() {
      if (confirm("Are you sure?")) {
        // delete
        this.$axios
          .get(`/api/delete_invoice/${this.id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.$router.go(-1);
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      }
    },
    lockInvoice() {
      if (confirm("Are you sure?")) {
        // delete
        this.$axios
          .get(`/api/lock_invoice/${this.id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchInvoice();
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      }
    },
    unlockInvoice() {
      if (confirm("Are you sure?")) {
        // delete
        this.$axios
          .get(`/api/unlock_invoice/${this.id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchInvoice();
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      }
    },
    print() {
      let pageTitle = document.title;
      window.$("#invoice").printThis({
        beforePrint: () => {
          document.title = `Invoice#${this.invoice.invoice_number}`;
        },
        afterPrint: () => {
          document.title = pageTitle;
        },
      });
    },
  },
};
</script>
<style scoped>
.invoice-container {
  background: #f9f9f9;
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.invoice {
  min-height: 900px;
  width: 900px;
  border-radius: 10px;
  font-size: 0.8rem;
  color: #000;
  margin: auto;
  background: #fff;
}

.invoice table,
.actions table {
  border-collapse: collapse;
  width: 100%;
}
.invoice td,
.invoice th,
.actions td,
.actions th {
  border: 1px solid rgb(206, 206, 206);
  padding: 5px;
  color: #000;
}
.invoice th,
.actions th {
  background: #eee;
}
.desc td {
  border: none;
  border-bottom: 1px dashed #ddd;
}
.actions {
  min-height: 500px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #ddd;
}
.columns {
  max-width: 2500px;
  margin: auto;
}
strong {
  color: #000;
}
.info td {
  border: none;
  border-bottom: 1px solid #ddd;
}
.field i {
  display: none;
}
.field:hover i {
  display: inline-block;
}
</style>
<style>
@media print {
  @page {
    margin: 15px;
  }
  .invoice {
    box-shadow: none !important;
  }
  body {
    background: #fff;
  }
}
</style>
