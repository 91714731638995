<template>
  <page-loader :isLoading="isLoading" />
  <containers-filter @search="handleSearch" />
  <hr />
  <div class="notification is-danger" v-if="error">{{ error }}</div>
  <!-- Paginating-->
  <nav
    class="pagination is-small is-centered"
    role="navigation"
    aria-label="pagination"
  >
    <!-- PREV Page-->
    <a
      class="pagination-previous"
      v-if="hasPrevPage"
      @click.prevent="changePage(prevPage)"
      ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
    >
    <a class="pagination-previous" disabled v-if="!hasPrevPage"
      ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
    >
    <!-- NEXT Page-->
    <a
      class="pagination-next"
      @click.prevent="changePage(nextPage)"
      v-if="hasNextPage"
      >Next page <i class="fa-solid fa-arrow-right ml-2"></i
    ></a>
    <a class="pagination-next" disabled v-if="!hasNextPage"
      >Next page <i class="fa-solid fa-arrow-right ml-2"></i
    ></a>
    <ul class="pagination-list">
      <!-- FIRST Page-->
      <li>
        <a
          class="pagination-link"
          aria-label="Goto page 1"
          @click.prevent="changePage(1)"
          >First</a
        >
      </li>
      <li><span class="pagination-ellipsis">&hellip;</span></li>
      <li>
        <!-- CURRENT Page-->
        <a
          class="pagination-link is-current"
          aria-label="Page 46"
          aria-current="page"
          >{{ page }}</a
        >
      </li>

      <li><span class="pagination-ellipsis">&hellip;</span></li>
      <li>
        <!-- LAST Page-->
        <a
          class="pagination-link"
          aria-label="Goto page 86"
          @click.prevent="changePage(totalPages)"
          >Last</a
        >
      </li>
    </ul>
  </nav>
  <div class="table-wraper">
    <table class="table is-fullwidth is-hoverable">
      <thead>
        <tr>
          <th>Imgs</th>
          <th>Bkng/Cntr</th>
          <th>Info</th>
          <th>From-To</th>
          <th>Dates</th>
          <th>Description</th>
          <th>Released</th>
          <th>Files</th>
          <th>Dock Receipt</th>
          <th>Invoices</th>
        </tr>
      </thead>
      <tbody>
        <tr v-if="containers.length == 0">
          <td colspan="14" class="has-text-centered">No Data</td>
        </tr>
        <tr v-for="c in containers" :key="c._id">
          <td>
            <figure class="thumbnail is-clickable">
              <img
                :src="`/api/files/${c.loading_images[0] || 'no_photo.png'}`"
                @click="() => openImages(c.loading_images, c._id)"
              />
            </figure>
          </td>

          <td>
            <p class="has-text-danger">{{ c.booking_number }}</p>
            <p>{{ c.container_number }}</p>
          </td>

          <td>
            <p>Lines: {{ c.shipping_line }}</p>
            <p>Seal: {{ c.seal }}</p>
          </td>
          <td>
            <p>{{ c.loading_point }} => {{ c.destination }}</p>
            <div v-if="c.notes">
              <span
                class="tag is-warning"
                v-tippy="{
                  content: c.notes,
                  placement: 'top',
                }"
                >Notes</span
              >
            </div>
          </td>
          <td class="nowrap">
            <p>Loaded : {{ c.loading_date }}</p>
            <p>ETA : {{ c.eta }}</p>
            <p>Date Released : {{ c.released_date }}</p>
          </td>
          <td class="nowrap">
            <div v-for="auto in c.autos" :key="auto._id">
              <p class="item">
                <strong>{{ `${auto.year} ${auto.make} ${auto.model}` }}</strong
                >{{ ` VIN: ${auto.vin} LOT#${auto.lot || ""}` }}
              </p>
            </div>
            <div v-for="part in c.parts" :key="part._id">
              <p class="item">
                <strong>{{ `${part.description} ID#${part.part_id}` }}</strong>
              </p>
            </div>
          </td>
          <td>
            <b v-if="c.telex" class="has-text-primary">YES</b>
            <b v-if="!c.telex" class="has-text-danger">NO</b>
          </td>
          <td class="nowrap">
            <p>
              <a :href="`/api/files/${c.telex}`" v-if="c.telex" target="_blank">
                <i class="fa-regular fa-file"></i> Release
              </a>
            </p>
            <p>
              <a :href="`/api/files/${c.bol}`" v-if="c.bol" target="_blank">
                <i class="fa-regular fa-file"></i> BOL
              </a>
            </p>
            <p>
              <a
                :href="`/api/files/${c.booking_confirmation}`"
                v-if="c.booking_confirmation"
                target="_blank"
              >
                <i class="fa-regular fa-file"></i> Bkng Conf
              </a>
            </p>
            <p>
              <a
                :href="`/api/files/${c.custom_declaration}`"
                v-if="c.custom_declaration"
                target="_blank"
              >
                <i class="fa-regular fa-file"></i> Declaration
              </a>
            </p>
            <span v-if="c.other_files">
              <p v-for="f in c.other_files" :key="f">
                <a :href="`/api/files/${f}`" target="_blank"
                  ><i class="fa-regular fa-file"></i> other
                </a>
              </p>
            </span>
          </td>
          <td>
            <router-link
              :to="`/client/dashboard/containers/dock_receipt/${c._id}`"
              ><button class="button is-small" v-if="c.dock_receipt">
                View
              </button></router-link
            >
          </td>
          <td class="nowrap">
            <p v-for="invoice in c.invoices" :key="invoice._id">
              <router-link
                :to="`/client/dashboard/container_invoice/${invoice._id}`"
                ><span class="has-text-dark">{{ invoice.invoice_number }}</span>
                <span class="has-text-danger"> ({{ invoice.currency }})</span>
              </router-link>
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- Paginating-->
  <nav
    class="pagination is-small is-centered mt-6"
    role="navigation"
    aria-label="pagination"
  >
    <!-- PREV Page-->
    <a
      class="pagination-previous"
      v-if="hasPrevPage"
      @click.prevent="changePage(prevPage)"
      ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
    >
    <a class="pagination-previous" disabled v-if="!hasPrevPage"
      ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
    >
    <!-- NEXT Page-->
    <a
      class="pagination-next"
      @click.prevent="changePage(nextPage)"
      v-if="hasNextPage"
      >Next page <i class="fa-solid fa-arrow-right ml-2"></i
    ></a>
    <a class="pagination-next" disabled v-if="!hasNextPage"
      >Next page <i class="fa-solid fa-arrow-right ml-2"></i
    ></a>
    <ul class="pagination-list">
      <!-- FIRST Page-->
      <li>
        <a
          class="pagination-link"
          aria-label="Goto page 1"
          @click.prevent="changePage(1)"
          >First</a
        >
      </li>
      <li><span class="pagination-ellipsis">&hellip;</span></li>
      <li>
        <!-- CURRENT Page-->
        <a
          class="pagination-link is-current"
          aria-label="Page 46"
          aria-current="page"
          >{{ page }}</a
        >
      </li>

      <li><span class="pagination-ellipsis">&hellip;</span></li>
      <li>
        <!-- LAST Page-->
        <a
          class="pagination-link"
          aria-label="Goto page 86"
          @click.prevent="changePage(totalPages)"
          >Last</a
        >
      </li>
    </ul>
  </nav>

  <ImagesModal
    @closed="closeImages"
    :images="this.images"
    v-if="imagesOpen"
    :container_id="openedContainer"
  />
</template>
<script>
import ImagesModal from "./ImagesModal.vue";
import ContainersFilter from "./ContainersFilter.vue";

import dayjs from "dayjs";
export default {
  data() {
    return {
      containers: [],
      page: this.$route.query.page || 1,
      prevPage: null,
      hasPrevPage: null,
      totalContainers: null,
      nextPage: null,
      hasNextPage: null,
      totalPages: null,
      isLoading: false,
      error: null,
      images: [],
      imagesOpen: false,
      // filteres
      container_number: null,
      booking_number: null,
      loading_date_from: null,
      loading_date_to: null,
      client: null,
      loading_point: null,
      destination: null,
      custom1: null,
      custom2: null,
      shipping_line: null,
      invoiceContainerId: null,
      viewNewInvoice: false,
      openedContainer: null,
    };
  },
  components: {
    ImagesModal,
    ContainersFilter,
  },
  created() {
    this.fetchData();
  },
  watch: {
    page(p) {
      this.fetchData();
    },
  },
  methods: {
    fetchData(q) {
      this.isLoading = true;
      if (!q) {
        q = this.$route.query;
      }
      // fetch containers
      this.$axios
        .post(
          "/api/retrieve_containers_client",
          { page: Number(this.page), query: q },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.error = null;
          this.containers = res.data.containers.map((a) => {
            if (a.loading_date) {
              a.loading_date = dayjs(a.loading_date)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            if (a.eta) {
              a.eta = dayjs(a.eta).add(1, "day").format("YYYY-MM-DD");
            }
            if (a.released_date) {
              a.released_date = dayjs(a.released_date)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            if (a.created_at) {
              a.created_at = dayjs(a.created_at).format("YYYY-MM-DD hh:mm A");
            }
            return a;
          });
          this.totalPages = res.data.totalPages;
          this.totalAutos = res.data.totalAutos;
          this.nextPage = res.data.nextPage;
          this.prevPage = res.data.prevPage;
          this.hasNextPage = res.data.hasNextPage;
          this.hasPrevPage = res.data.hasPrevPage;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = err.response.data.msg;
        });
    },
    // Change Page
    changePage(number) {
      this.page = number;
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, page: number },
      });
    },
    openImages(images, id) {
      this.images = images;
      this.imagesOpen = true;
      this.openedContainer = id;
    },
    closeImages() {
      this.imagesOpen = false;
      this.images = [];
      this.openedContainer = null;
    },
    toggleSi(id) {
      this.$axios
        .get(`/api/toggle_si/${id}`, { headers: this.$authHeader() })
        .then((res) => {
          this.fetchData();
        })
        .catch((err) => {
          this.error(err.response.data.msg);
        });
    },
    handleSearch(q) {
      this.fetchData(q);
    },
  },
};
</script>
<style scoped>
table * {
  font-size: 0.8rem;
}
.thumbnail {
  max-width: 60px;
}
table a:hover {
  text-decoration: underline;
}
.dropdown-item * {
  text-decoration: none !important;
  color: #666 !important;
}
.dropdown-item:hover * {
  text-decoration: none !important;
  color: #666;
}
td {
  vertical-align: middle;
}
td p,
td b {
  color: #666;
}
</style>
