<template>
  <div :class="`loader-wrapper ${isLoading ? 'is-active' : ''}`">
    <div class="loader is-loading"></div>
  </div>
</template>
<script>
export default {
  props: {
    isLoading: Boolean,
  },
};
</script>
