<template>
  <admin-side-nav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <admin-top-nav />
    <page-loader :isLoading="isLoading"></page-loader>
    <div class="box mt-6">
      <h1 class="subtitle">Forms & Documents</h1>
      <hr />
      <button class="button is-primary" @click="openUploadModal">
        Upload New <i class="fa-solid fa-cloud-arrow-up ml-3"></i>
      </button>
      <table class="table mt-5 is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Document Name</th>
            <th style="width: 200px">Shared with Clients</th>
            <th style="width: 50px">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="files.length === 0">
            <td colspan="4" class="has-text-centered">No Files</td>
          </tr>
          <tr v-for="file in files" :key="file._id">
            <td>
              <a target="_blank" :href="`/api/files/${file.path}`">{{
                file.name
              }}</a>
            </td>
            <td>{{ file.shared }}</td>

            <td>
              <button
                class="button is-small is-danger"
                v-tippy="{
                  content: `Delete`,
                  placement: 'left',
                }"
                @click.stop.prevent="deleteFile(file._id)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>

      <!-- Upload Modal -->
      <div :class="`modal ${uploadModal ? 'is-active' : ''}`">
        <div class="modal-background" @click="closeModal"></div>
        <div class="modal-content">
          <div class="box">
            <form>
              <h2>Upload New File</h2>
              <hr />
              <div class="notification is-danger" v-if="error">
                {{ errorMessage }}
              </div>
              <div class="notification is-success" v-if="success">
                {{ successMessage }}
              </div>
              <div class="columns">
                <div class="column">
                  <label class="label"
                    >File Name <span style="color: red">*</span></label
                  >
                  <input
                    type="text"
                    class="input"
                    placeholder="File Name"
                    v-model="name"
                  />
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <label class="label"
                    >Select File <span style="color: red">*</span></label
                  >

                  <div class="file has-name is-fullwidth">
                    <label class="file-label">
                      <input
                        class="file-input"
                        type="file"
                        @change="handleUpload"
                      />
                      <span class="file-cta">
                        <span class="file-icon">
                          <i class="fas fa-upload"></i>
                        </span>
                        <span class="file-label"> Choose a file… </span>
                      </span>
                      <span class="file-name">
                        {{ file.name }}
                      </span>
                    </label>
                  </div>
                </div>
              </div>
              <div class="columns">
                <div class="column">
                  <div class="control">
                    <label class="label">Share With Clients</label>
                    <label class="radio">
                      <input type="radio" v-model="shared" value="Yes" />
                      Yes
                    </label>
                    <label class="radio">
                      <input type="radio" v-model="shared" value="No" />
                      No
                    </label>
                  </div>
                </div>
              </div>
              <button
                class="button is-primary"
                @click.stop.prevent="handleSubmit"
              >
                Upload
              </button>
            </form>
          </div>
        </div>
        <button
          class="modal-close is-large"
          aria-label="close"
          @click="closeModal"
        ></button>
      </div>
    </div>
  </div>
</template>
<script>
import AdminSideNav from "@/components/nav/AdminSideNav.vue";
import AdminTopNav from "@/components/nav/AdminTopNav.vue";
export default {
  components: { AdminSideNav, AdminTopNav },
  data() {
    return {
      uploadModal: false,
      name: "",
      file: {},
      shared: "No",
      error: false,
      success: false,
      errorMessage: "",
      successMessage: "",
      files: [],
      isLoading: true,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    openUploadModal() {
      this.uploadModal = true;
    },
    closeModal() {
      this.uploadModal = false;
      this.error = false;
      this.success = false;
      this.file = {};
      this.shared = "No";
      this.name = "";
      this.fetchData();
    },
    handleUpload(e) {
      let file = e.target.files[0];
      if (file.type.split("/")[1] !== "pdf") {
        this.success = false;
        this.error = true;
        this.errorMessage = "Only PDF files are allowed";
        this.file = {};
        return;
      }
      this.success = false;
      this.error = false;
      this.file = e.target.files[0];
    },
    handleSubmit() {
      if (!this.name || !this.file.name) {
        this.success = false;
        this.error = true;
        this.errorMessage = "Please fill out all the required fileds";
        return;
      }
      let headers = this.$authHeader();
      // changing the headers to include files
      headers["Content-Type"] = "multipart/form-data";
      this.$axios
        .post(
          "/api/upload_file",
          { name: this.name, shared: this.shared, file: this.file },
          { headers }
        )
        .then((res) => {
          this.success = true;
          this.error = false;
          this.successMessage = res.data.msg;
          this.name = "";
          this.file = {};
          this.shared = "No";
        })
        .catch((err) => {
          this.success = false;
          this.error = true;
          this.errorMessage = err.response.data.msg;
        });
    },
    fetchData() {
      this.$axios
        .post("/api/retrieve_files", {}, { headers: this.$authHeader() })
        .then((res) => {
          this.files = res.data.files;
          this.isLoading = false;
        })
        .catch((err) => {
          alert(err.response.datat.msg);
        });
    },
    deleteFile(id) {
      if (confirm("Are you sure you want to delete?")) {
        this.isLoading = true;
        this.$axios
          .post("/api/delete_file", { id }, { headers: this.$authHeader() })
          .then((res) => {
            this.isLoading = false;
            this.fetchData();
          })
          .catch((err) => {
            this.isLoading = false;
            alert(err.response.data.msg);
          });
      }
    },
  },
};
</script>
<style scoped>
.box {
  overflow: auto;
}
.open {
  font-size: 1.2rem;
}
a:hover {
  text-decoration: underline;
}
</style>
