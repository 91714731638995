import AdminDashboard from "@/views/Overview/AdminDashboard";
import AppSettings from "@/views/Settings/AppSettings";
import ClientsPage from "@/views/Clients/ClientsPage";
import ClientsList from "@/views/Clients/ClientsList";
import AddClient from "@/views/Clients/AddClient";
import EditClient from "@/views/Clients/EditClient";
import AutosPage from "@/views/Autos/AutosPage";
import AutosList from "@/views/Autos/AutosList";
import SelectionFields from "@/views/Settings/SelectionFields";
import InvoiceSettings from "@/views/Settings/InvoiceSettings";
import UploadLogo from "@/components/forms/UploadLogo";
import ShippersConsignees from "@/views/Shipping-Information/ShippersConsignees";
import ShippersConsigneesList from "@/views/Shipping-Information/ShippersConsigneesList";
import AddShippingInformation from "@/views/Shipping-Information/AddShippingInformation";
import EditShippingInformation from "@/views/Shipping-Information/EditShippingInformation";
import AddAnnouncement from "@/views/Announcements/AddAnnouncement";
import EditAnnouncement from "@/views/Announcements/EditAnnouncement";
import AnnouncementsPage from "@/views/Announcements/AnnouncementsPage";
import AnnouncementsList from "@/views/Announcements/AnnouncementsList";
import FormsDocs from "@/views/FormsDocs";
import QuotesPage from "@/views/Quotes/QuotesPage";
import EditQuote from "@/views/Quotes/EditQuote";
import ViewQuote from "@/views/Quotes/ViewQuote";
import QuotesList from "@/views/Quotes/QuotesList";
import AdminsList from "@/views/Admins/AdminsList";
import AdminsPage from "@/views/Admins/AdminsPage";
import AddAdmin from "@/views/Admins/AddAdmin";
import EditAdmin from "@/views/Admins/EditAdmin";
import AddAuto from "@/views/Autos/AddAuto";
import EditAuto from "@/views/Autos/EditAuto";
import BuyersPage from '@/views/Buyers/BuyersPage';
import BuyersList from '@/views/Buyers/BuyersList';
import DeliveryReceipt from '@/views/Autos/DeliveryReceipt'
import AutoReport from '@/views/Autos/AutoReport'
import PartsPage from '@/views/Parts/PartsPage'
import AddPart from '@/views/Parts/AddPart'
import EditPart from '@/views/Parts/EditPart'
import PartsList from '@/views/Parts/PartsList'
import MessagesPage from '@/views/Messages/MessagesPage'
import MessagesList from '@/views/Messages/MessagesList'
import LoadingListsPage from '@/views/LoadingLists/LoadingListsPage'
import NewLoadingList from '@/views/LoadingLists/NewLoadingList'
import PendingLists from '@/views/LoadingLists/PendingLists'
import ReadyLists from '@/views/LoadingLists/ReadyLists'
import RejectedLists from '@/views/LoadingLists/RejectedLists'
import LoadedLists from '@/views/LoadingLists/LoadedLists'
import EditLoadingList from '@/views/LoadingLists/EditLoadingList'
import DispatchPage from '@/views/Dispatch/DispatchPage'
import ToBeDispatched from '@/views/Dispatch/ToBeDispatched'
import TransportersList from '@/views/Dispatch/TransportersList'
import ToBePickedUp from '@/views/Dispatch/ToBePickedUp'
import OnTheWay from '@/views/Dispatch/OnTheWay'
import DeliveredAutos from '@/views/Dispatch/DeliveredAutos'
import ContainersPage from '@/views/Containers/ContainersPage'
import ContainersList from '@/views/Containers/ContainersList'
import TrackingPage from '@/views/Containers/TrackingPage'
import AddContainer from '@/views/Containers/AddContainer'
import EditContainer from '@/views/Containers/EditContainer'
import DockReceipt from '@/views/Containers/DockReceipt'
import AutoInvoice from '@/views/Balance/AutoInvoice'
import PartInvoice from '@/views/Balance/PartInvoice'
import BalancePage from '@/views/Balance/BalancePage'
import ContainerInvoice from '@/views/Balance/ContainerInvoice'
import PaymentsList from '@/views/Payments/PaymentsList'
import PaymentStatement from '@/views/Payments/PaymentStatement'
import ProfilePage from '@/views/Profile/ProfilePage'
import ListOverview from '@/views/LoadingLists/ListOverview'
// Admin Routes
// Contain all code for Admin side Navigations
const routes = [
  // Admin Dashboard Page
  {
    path: "/admin/dashboard/overview",
    name: "AdminDashboard",
    component: AdminDashboard,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // App Settings Page
  {
    path: "/admin/dashboard/settings",
    name: "AppSettings",
    component: AppSettings,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "selections", component: SelectionFields },
      { path: "invoices", component: InvoiceSettings },
      { path: "logo", component: UploadLogo },
    ],
  },
  // Manage Clients Routes
  {
    path: "/admin/dashboard/clients",
    name: "Clients",
    component: ClientsPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: ClientsList },
      { path: "add", component: AddClient },
      { path: "edit/:id", component: EditClient },
    ],
  },
  // Autos Page
  {
    path: "/admin/dashboard/autos",
    name: "Autos",
    component: AutosPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [{ path: "", component: AutosList },
    { path: "add", component: AddAuto },
    { path: "edit/:id", component: EditAuto },
    { path: "delivery_receipt/:id", component: DeliveryReceipt },
    { path: "condition_report/:id", component: AutoReport }],
  },
  // Shippers & consignees page
  {
    path: "/admin/dashboard/shippers_consignees",
    name: "Shippers_Consignees",
    component: ShippersConsignees,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: ShippersConsigneesList },
      {
        path: "add",
        component: AddShippingInformation,
      },
      {
        path: "edit",
        component: EditShippingInformation,
      },
    ],
  },
  // Announcements
  {
    path: "/admin/dashboard/announcements",
    name: "Announcements",
    component: AnnouncementsPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: AnnouncementsList },
      {
        path: "add",
        component: AddAnnouncement,
      },
      {
        path: "edit",
        component: EditAnnouncement,
      },
    ],
  },
  // Announcements
  {
    path: "/admin/dashboard/forms",
    name: "FormsDocs",
    component: FormsDocs,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Quotes
  {
    path: "/admin/dashboard/quotes",
    name: "Quotes",
    component: QuotesPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: QuotesList },
      { path: "id/:id", component: EditQuote },
      { path: "view/:id", component: ViewQuote },
    ],
  },
  // Admins
  {
    path: "/admin/dashboard/admins",
    name: "Admins",
    component: AdminsPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: AdminsList },
      { path: "add", component: AddAdmin },
      { path: "edit/:id", component: EditAdmin }
    ],
  },
  // Buyer Accounts
  {
    path: "/admin/dashboard/buyers",
    name: "Buyers",
    component: BuyersPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: BuyersList },
    ],
  },
  // Parts
  {
    path: "/admin/dashboard/parts",
    name: "Parts",
    component: PartsPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: PartsList },
      { path: "add", component: AddPart },
      { path: "edit/:id", component: EditPart },
    ],
  },
  // Messages
  {
    path: "/admin/dashboard/messages",
    name: "Messages",
    component: MessagesPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: MessagesList },
    ],
  },
  // Loading Lists
  {
    path: "/admin/dashboard/loading_lists",
    name: "LoadingLists",
    component: LoadingListsPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: PendingLists },
      { path: "add", component: NewLoadingList },
      { path: "edit/:id", component: EditLoadingList },
      { path: "pending", component: PendingLists },
      { path: "ready", component: ReadyLists },
      { path: "rejected", component: RejectedLists },
      { path: "loaded", component: LoadedLists },
    ],
  },
   // Loading Lists Overview
   {
    path: "/admin/dashboard/loading_lists_overview/:type",
    name: "ListOverview",
    component: ListOverview,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Dispatch
  {
    path: "/admin/dashboard/dispatch",
    name: "Dispatch",
    component: DispatchPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: ToBeDispatched },
      { path: "transporters", component: TransportersList },
      { path: "to_be_picked_up", component: ToBePickedUp },
      { path: "on_the_way", component: OnTheWay },
      { path: "delivered", component: DeliveredAutos },
    ],
  },
  // Containers
  {
    path: "/admin/dashboard/containers",
    name: "Containers",
    component: ContainersPage,
    meta: { requiresAuth: true, requiresAdmin: true },
    children: [
      { path: "", component: ContainersList },
      { path: "add", component: AddContainer },
      { path: "edit/:id", component: EditContainer },
      { path: "shipping_instructions/:id", component: DockReceipt },
    ],
  },
  // Auto Invoice
  {
    path: "/admin/dashboard/auto_invoice/:id",
    name: "AutoInvoice",
    component: AutoInvoice,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Part Invoice
  {
    path: "/admin/dashboard/part_invoice/:id",
    name: "PartInvoice",
    component: PartInvoice,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Container Invoice
  {
    path: "/admin/dashboard/container_invoice/:id",
    name: "ContainerInvoice",
    component: ContainerInvoice,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Payments
  {
    path: "/admin/dashboard/payments",
    name: "PaymentsPage",
    component: PaymentsList,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Payment Statement
  {
    path: "/admin/dashboard/transaction/:id",
    name: "PaymentStatement",
    component: PaymentStatement,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Balance
  {
    path: "/admin/dashboard/balance",
    name: "BalancePage",
    component: BalancePage,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Container Tracking Page
  {
    path: "/admin/dashboard/containers/tracking/:container_number",
    name: "TrackingPage",
    component: TrackingPage,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  // Profile Page
  {
    path: "/admin/dashboard/profile",
    name: "ProfilePage",
    component: ProfilePage,
    meta: { requiresAuth: true, requiresAdmin: true },
  }
];

export default {
  admin_routes: routes,
};
