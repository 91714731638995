<template>
  <div class="box">
    <h1 class="title">Admins</h1>
    <hr />
    <page-loader :isLoading="pageLoading"></page-loader>
    <router-link to="/admin/dashboard/admins/add"
      ><button class="button is-primary mt-5">New</button></router-link
    >
    <div class="notification is-danger mt-1" v-if="error">{{ error }}</div>
    <div class="notification is-primary mt-1" v-if="success">{{ success }}</div>
    <div class="table-wraper">
      <table class="table mt-6 is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th>Username</th>
            <th>Name</th>
            <th>Email</th>
            <th>Created At</th>
            <th>Access</th>
            <th width="200px">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-if="admins.length === 0">
            <td colspan="6">No Data</td>
          </tr>
          <tr v-for="admin in admins" :key="admin._id">
            <td>{{ admin.username }}</td>
            <td>{{ admin.name }}</td>
            <td>{{ admin.email }}</td>
            <td>{{ admin.created_at }}</td>
            <td>
              <a class="is-link" @click.stop.prevent="viewAccess(admin.access)"
                >View Permissions</a
              >
            </td>
            <td class="nowrap">
              <button
                class="button is-small is-danger"
                @click.stop.prevent="handleDelete(admin._id)"
                v-tippy="{
                  content: `Delete`,
                  placement: 'left',
                }"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
              <router-link :to="`/admin/dashboard/admins/edit/${admin._id}`">
                <button
                  class="button is-small ml-3"
                  v-tippy="{
                    content: `Edit`,
                    placement: 'left',
                  }"
                >
                  <i class="fa-solid fa-pen-to-square"></i>
                </button>
              </router-link>
              <button
                class="button is-info is-small ml-3"
                v-tippy="{
                  content: `Change Password`,
                  placement: 'left',
                }"
                @click.stop.prevent="changePassword(admin._id, admin.name)"
              >
                <i class="fa-solid fa-lock"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div :class="`modal ${modalOpen ? 'is-active' : ''}`">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-content">
        <div class="box">
          <h1 class="subtitle">Permissions</h1>
          <hr />
          <span class="tag is-medium m-1" v-for="a in access_list" :key="a">{{
            a
          }}</span>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeModal"
      ></button>
    </div>
    <ChangePasswordAdmin
      v-if="changePassowrdModal"
      :admin_id="admin_id"
      :admin_name="admin_name"
      @closed="closeModal"
    />
  </div>
</template>
<script>
import dayjs from "dayjs";
import ChangePasswordAdmin from "@/views/Admins/ChangePasswordAdmin.vue";

export default {
  components: { ChangePasswordAdmin },
  data() {
    return {
      admins: [],
      error: null,
      access_list: null,
      modalOpen: false,
      pageLoading: false,
      success: null,
      changePassowrdModal: false,
      admin_id: null,
      admin_name: null,
    };
  },
  created() {
    this.fetchAdmins();
  },
  methods: {
    fetchAdmins() {
      this.pageLoading = true;
      this.$axios
        .post("/api/retrieve_admins", {}, { headers: this.$authHeader() })
        .then((res) => {
          this.admins = res.data.admins.map((a) => {
            a.created_at = dayjs(a.created_at).format("YYYY-MM-DD hh:mm A");
            return a;
          });
          this.pageLoading = false;
        })
        .catch((err) => {
          this.error = err.response.data.msg;
          this.pageLoading = false;
        });
    },
    viewAccess(list) {
      this.modalOpen = true;
      this.access_list = list;
    },
    closeModal() {
      this.modalOpen = false;
      this.access_list = null;
      this.changePassowrdModal = false;
      this.admin_id = null;
      this.admin_name = null;
    },
    handleDelete(id) {
      if (confirm("Are you sure you want to delete this admin?")) {
        this.pageLoading = true;
        this.$axios
          .post("/api/delete_admin", { id }, { headers: this.$authHeader() })
          .then((res) => {
            this.success = res.data.msg;
            this.pageLoading = false;
            this.error = null;
            this.fetchAdmins();
          })
          .catch((err) => {
            this.error = err.response.data.msg;
            this.success = null;
            this.pageLoading = false;
          });
      }
    },
    changePassword(id, name) {
      this.changePassowrdModal = true;
      this.admin_id = id;
      this.admin_name = name;
    },
  },
};
</script>
<style scoped>
.table {
  text-align: center;
}
</style>
