<template>
  <div class="modal is-active">
    <div
      class="modal-background"
      @click="
        () => {
          $emit('closed');
        }
      "
    ></div>
    <div class="modal-content">
      <div class="box has-text-centered">
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <div class="notification is-primary" v-if="success">{{ success }}</div>
        <div class="block">
          <div class="field has-addons">
            <div class="control">
              <input
                class="input"
                style="height: 36px"
                type="text"
                placeholder="Email"
                v-model="email"
              />
            </div>
            <div class="control">
              <a
                :class="`button is-info ${isLoading ? 'is-loading' : ''}`"
                @click.stop="sendMail"
              >
                Send container info
              </a>
            </div>
          </div>
        </div>
        <a
          target="_blank"
          :href="`/api/downloadcontainerimages/${container_id}`"
          ><button>Download Images</button></a
        >
        <br />
        <br />
        <img
          :src="`/api/files/${img}`"
          class="image is-96x96 img"
          v-for="(img, index) in images"
          :key="img"
          @click="() => showImg(index, images)"
        />
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="
        () => {
          $emit('closed');
        }
      "
    ></button>
  </div>
  <vue-easy-lightbox
    :visible="lightboxVisible"
    :imgs="loading_images"
    :index="index"
    @hide="hidePics"
  ></vue-easy-lightbox>
</template>
<script>
export default {
  props: {
    images: Array,
    container_id: String,
  },
  data() {
    return {
      index: 0,
      lightboxVisible: false,
      loading_images: [],
      isLoading: false,
      email: null,
      error: null,
      success: null,
    };
  },
  methods: {
    showImg(index, images) {
      this.loading_images = images.map((i) => {
        return document.location.origin + "/api/files/" + i;
      });
      this.index = index;
      this.lightboxVisible = true;
    },
    hidePics() {
      this.lightboxVisible = false;
    },
    sendMail() {
      if (!this.email) {
        return (this.error = "Email is required");
      }
      this.isLoading = true;
      this.$axios
        .get(
          `/api/email_container_info/${this.$props.container_id}/${this.email}`,
          {
            headers: this.$authHeader(),
          }
        )
        .then((res) => {
          this.isLoading = false;
          this.error = null;
          this.success = res.data.msg;
        })
        .catch((err) => {
          this.success = false;
          this.error = err.response.data.msg;
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.img {
  display: inline-block;
  margin: 5px;
  cursor: pointer;
}
.img:hover {
  border: 2px solid #00b9a1;
}
</style>
