<template>
  <div class="box">
    <router-link to="/admin/dashboard/admins/">
      <button class="button mb-6">
        <i class="fa-solid fa-angles-left mr-1"></i>Go Back to Admins
      </button>
    </router-link>
    <h1 class="title">Edit Admin</h1>
    <hr />
    <page-loader :isLoading="pageLoading"></page-loader>
    <form>
      <div class="notification is-danger" v-if="error">{{ error }}</div>
      <div class="notification is-success" v-if="success">{{ success }}</div>

      <div class="columns">
        <div class="column">
          <label class="label"
            >Full Name <b class="has-text-danger">*</b>
          </label>
          <input
            class="input"
            type="text"
            placeholder="Full Name"
            v-model="name"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Email <b class="has-text-danger">*</b></label>
          <input
            class="input"
            type="email"
            placeholder="Email"
            v-model="email"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Username <b class="has-text-danger">*</b></label>
          <input
            class="input"
            type="text"
            placeholder="Username"
            style="text-transform: lowercase"
            v-model="username"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label">Access:</label>
          <hr />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="checkbox">
            <input
              type="checkbox"
              id="grant-full-access"
              @change="grantFullAccess"
              :checked="fullAccessChecked"
            />
            Grant Full Access
          </label>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label"
            >Autos <i class="fa-solid fa-car ml-1 has-text-danger"></i
          ></label>
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="access"
              value="view_autos"
              :disabled="disabled"
            />
            <span>View</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="add_autos"
              :disabled="disabled"
            />
            <span>Add</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="edit_autos"
              :disabled="disabled"
            />
            <span>Edit</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="delete_autos"
              :disabled="disabled"
            />
            <span>Delete</span>
          </label>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label"
            >Containers <i class="fa-solid fa-box ml-1 has-text-danger"></i
          ></label>
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="access"
              value="view_containers"
              :disabled="disabled"
            />
            <span>View</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="add_containers"
              :disabled="disabled"
            />
            <span>Add</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="edit_containers"
              :disabled="disabled"
            />
            <span>Edit</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="delete_containers"
              :disabled="disabled"
            />
            <span>Delete</span>
          </label>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label"
            >Loading Lists
            <i class="fa-solid fa-clipboard-list ml-1 has-text-danger"></i
          ></label>
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="access"
              value="view_loading_lists"
              :disabled="disabled"
            />
            <span>View</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="add_loading_lists"
              :disabled="disabled"
            />
            <span>Add</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="edit_loading_lists"
              :disabled="disabled"
            />
            <span>Edit</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="delete_loading_lists"
              :disabled="disabled"
            />
            <span>Delete</span>
          </label>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label"
            >Clients <i class="fa-solid fa-users ml-1 has-text-danger"></i
          ></label>
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="access"
              value="view_clients"
              :disabled="disabled"
            />
            <span>View</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="add_clients"
              :disabled="disabled"
            />
            <span>Add</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="edit_clients"
              :disabled="disabled"
            />
            <span>Edit</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="delete_clients"
              :disabled="disabled"
            />
            <span>Delete</span>
          </label>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label"
            >Shipping Information
            <i class="fa-solid fa-users-between-lines ml-1 has-text-danger"></i
          ></label>
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="access"
              value="view_shipping_information"
              :disabled="disabled"
            />
            <span>View</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="add_shipping_information"
              :disabled="disabled"
            />
            <span>Add</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="edit_shipping_information"
              :disabled="disabled"
            />
            <span>Edit</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="delete_shipping_information"
              :disabled="disabled"
            />
            <span>Delete</span>
          </label>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <label class="label"
            >Accounting
            <i class="fa-solid fa-money-bill ml-1 has-text-danger"></i
          ></label>
          <label class="checkbox">
            <input
              type="checkbox"
              v-model="access"
              value="view_invoices"
              :disabled="disabled"
            />
            <span>Access</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="add_invoices"
              :disabled="disabled"
            />
            <span>Add</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="edit_invoices"
              :disabled="disabled"
            />
            <span>Edit</span>
          </label>
          <label class="checkbox ml-4">
            <input
              type="checkbox"
              v-model="access"
              value="delete_invoices"
              :disabled="disabled"
            />
            <span>Delete</span>
          </label>
        </div>
      </div>

      <hr />
      <div
        :class="`button is-primary ${isLoading ? 'is-loading' : ''}`"
        @click.stop.prevent="handleSubmit"
      >
        Submit
      </div>
    </form>
  </div>
</template>
<script>
export default {
  data() {
    return {
      id: this.$route.params.id,
      isLoading: false,
      access: [],
      name: null,
      email: null,
      username: null,
      disabled: false,
      error: null,
      success: null,
      fullAccessChecked: false,
      pageLoading: true,
    };
  },
  created() {
    this.$axios
      .post(
        "/api/retrieve_admins",
        { id: this.id },
        { headers: this.$authHeader() }
      )
      .then((res) => {
        this.name = res.data.admins[0].name;
        this.email = res.data.admins[0].email;
        this.username = res.data.admins[0].username;
        this.access = res.data.admins[0].access;
        this.pageLoading = false;
      })
      .catch((err) => {
        this.error = err.response.data.msg;
      });
  },
  methods: {
    handleSubmit() {
      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
      this.isLoading = true;
      // check required fields
      if (!this.name || !this.email || !this.username) {
        this.error = "Please fill out all the required fields.";
        this.isLoading = false;
        window.scrollTo(0, 0);
        return;
      }
      // validate Email
      if (!validateEmail(this.email)) {
        this.error = "Please enter a valid email address.";
        this.isLoading = false;
        window.scrollTo(0, 0);
        return;
      }
      // validate username
      const usernameRegex = /^[a-z0-9_.]+$/;
      if (!usernameRegex.test(this.username.toLowerCase())) {
        this.error = "Username can't contain spaces or special characters.";
        window.scrollTo(0, 0);
        this.isLoading = false;
        return;
      }

      // make the request
      this.$axios
        .post("/api/edit_admin", this.$data, { headers: this.$authHeader() })
        .then((res) => {
          this.isLoading = false;
          this.success = res.data.msg;
          this.error = null;
          window.scrollTo(0, 0);
        })
        .catch((err) => {
          this.isLoading = false;
          this.success = null;
          this.error = err.response.data.msg;
          window.scrollTo(0, 0);
        });
    },
    grantFullAccess(e) {
      let checked = e.target.checked;
      if (checked) {
        this.access = [
          "view_autos",
          "add_autos",
          "edit_autos",
          "delete_autos",
          "view_containers",
          "add_containers",
          "edit_containers",
          "delete_containers",
          "view_loading_lists",
          "add_loading_lists",
          "edit_loading_lists",
          "delete_loading_lists",
          "view_shipping_information",
          "add_shipping_information",
          "edit_shipping_information",
          "delete_shipping_information",
          "view_invoices",
          "add_invoices",
          "edit_invoices",
          "delete_invoices",
          "view_clients",
          "add_clients",
          "edit_clients",
          "delete_clients",
        ];
        this.disabled = true;
      } else {
        this.access = [];
        this.disabled = false;
      }
    },
  },
};
</script>
