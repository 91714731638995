<template>
  <h1 class="subtitle mt-6">Autos</h1>
  <BarChart :chartData="chartData" v-if="loaded" />
</template>

<script>
import { BarChart } from "vue-chart-3";
import { Chart, registerables } from "chart.js";

Chart.register(...registerables);

export default {
  components: { BarChart },
  data() {
    return {
      loaded: false,
      chartData: {
        labels: [],
        datasets: [
          {
            label: "Autos Purchased",
            data: [],
            backgroundColor: [
              "#18ae93",
              "#18ae93",
              "#18ae93",
              "#18ae93",
              "#18ae93",
            ],
          },
        ],
      },
    };
  },
  mounted() {
    this.$axios
      .post(
        "/api/autos_purchased_count_client",
        {},
        {
          headers: this.$authHeader(),
        }
      )
      .then((res) => {
        this.loaded = true;
        this.chartData.labels = res.data.labels;
        this.chartData.datasets[0].data = res.data.autos;
      });
  },
};
</script>
