<template>
  <admin-side-nav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <admin-top-nav />
    <div class="mt-6">
      <router-view>
        <div class="box">
          <h1 class="title">Balance</h1>
          <hr />
          <div class="columns links p-3">
            <div
              @click="navigatePage('due')"
              :class="`column ${
                this.$route.query.type == 'due' ? 'active' : ''
              }`"
            >
              <p>Due</p>
            </div>
            <div
              @click="navigatePage('open')"
              :class="`column ${
                this.$route.query.type == 'open' ? 'active' : ''
              }`"
            >
              <p>Open Invoices</p>
            </div>
            <div
              @click="navigatePage('paid')"
              :class="`column ${
                this.$route.query.type == 'paid' ? 'active' : ''
              }`"
            >
              <p>Paid Invoices</p>
            </div>
            <div
              @click="navigatePage('all')"
              :class="`column ${
                this.$route.query.type == 'all' ? 'active' : ''
              }`"
            >
              <p>All Invoices</p>
            </div>
          </div>
          <hr />
          <BalanceFilter
            @search="fetchBalance"
            @clicked="toggleButton"
            :searchButtonDisabled="searchButtonDisabled"
          />
          <div v-if="this.$route.query.currency" class="total">
            <nav class="level">
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Total Amount</p>
                  <p class="title">{{ total_invoices }}</p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Paid</p>
                  <p class="title">{{ total_payments }}</p>
                </div>
              </div>
              <div class="level-item has-text-centered">
                <div>
                  <p class="heading">Balance</p>
                  <p class="title">{{ balance }}</p>
                </div>
              </div>
            </nav>
          </div>
          <div class="table-wraper">
            <table
              class="table is-fullwidth is-hoverable is-bordered is-relative"
            >
              <thead>
                <tr>
                  <th>Invoice</th>
                  <th>Date</th>
                  <th>Due Date</th>
                  <th>Client</th>
                  <th>Item</th>
                  <th>Amounts</th>
                  <th>Currency</th>
                  <th class="has-text-centered">Total</th>
                  <th class="has-text-centered">Paid</th>
                  <th class="has-text-centered">Balance</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="invoice in invoices" :key="invoice._id">
                  <td>
                    <router-link :to="invoice.link"
                      >{{ invoice.invoice_number
                      }}<i
                        class="fa-solid fa-lock has-text-dark ml-1"
                        v-if="invoice.locked"
                      ></i
                    ></router-link>
                  </td>
                  <td>{{ invoice.created }}</td>
                  <td>{{ invoice.due_date }}</td>
                  <td>{{ invoice.client[0].company }}</td>
                  <td>{{ invoice.description }}</td>
                  <td class="nowrap">
                    <span
                      class="tag ml-2 is-primary is-clickable"
                      v-for="f in invoice.fields"
                      :key="f._id"
                      v-tippy="{ content: f.description }"
                      >{{ f.amount }}</span
                    >
                  </td>
                  <td>
                    <span class="has-text-danger">
                      {{ invoice.currency }}
                    </span>
                  </td>
                  <td class="has-text-centered">{{ invoice.total }}</td>
                  <td class="has-text-centered">
                    {{ invoice.total_payments }}
                  </td>
                  <td class="has-text-centered">
                    {{ invoice.balance.toFixed(2) }}
                  </td>
                </tr>
              </tbody>
              <page-loader :isLoading="isLoading" />
            </table>
          </div>
        </div>
      </router-view>
    </div>
  </div>
</template>
<script>
import AdminSideNav from "@/components/nav/AdminSideNav.vue";
import AdminTopNav from "@/components/nav/AdminTopNav.vue";
import BalanceFilter from "./BalanceFilter.vue";
import dayjs from "dayjs";
export default {
  data() {
    return {
      invoices: [],
      isLoading: false,
      total_invoices: 0,
      total_payments: 0,
      balance: 0,
      searchButtonDisabled: false,
    };
  },
  components: { AdminSideNav, AdminTopNav, BalanceFilter },
  created() {
    this.fetchBalance();
  },
  methods: {
    toggleButton() {
      this.searchButtonDisabled = !this.searchButtonDisabled;
    },
    fetchBalance(q) {
      (this.total_invoices = 0), (this.total_payments = 0), (this.balance = 0);
      q = this.$route.query || {};
      let query = `?`;
      Object.keys(q).forEach(function (key, index) {
        query += key + "=" + q[key] + "&";
      });
      this.isLoading = true;
      this.$axios
        .get(`/api/retrieve_balance${query}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.invoices = res.data.invoices.map((i) => {
            i.link = "";
            if (i.created) {
              i.created = dayjs(i.created).add(1, "day").format("YYYY-MM-DD");
            }
            if (i.due_date) {
              i.due_date = dayjs(i.due_date).add(1, "day").format("YYYY-MM-DD");
            }
            if (i.auto[0]) {
              i.description = `${i.auto[0].year} ${i.auto[0].make} ${i.auto[0].model} vin#${i.auto[0].vin}`;
              i.link = `/admin/dashboard/auto_invoice/${i._id}`;
            }
            if (i.container[0]) {
              i.description = `Container Nr:${i.container[0].container_number} Booking Nr:${i.container[0].booking_number}`;
              i.link = `/admin/dashboard/container_invoice/${i._id}`;
            }
            if (i.part[0]) {
              i.description = `${i.part[0].description} qty:${i.part[0].qty}`;
              i.link = `/admin/dashboard/part_invoice/${i._id}`;
            }

            this.total_invoices += i.total;
            this.total_payments += i.total_payments;
            this.balance += i.balance;
            return i;
          });
          function numberWithCommas(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          }
          this.total_invoices = numberWithCommas(this.total_invoices);
          this.total_payments = numberWithCommas(this.total_payments);
          this.balance = numberWithCommas(this.balance.toFixed(2));
          this.isLoading = false;
          this.searchButtonDisabled = false;
        })
        .catch((err) => {
          this.searchButtonDisabled = false;
        });
    },
    navigatePage(type) {
      let q = this.$route.query;
      q.type = type;
      this.$router
        .push({ path: `/admin/dashboard/balance`, query: q })
        .then((done) => {
          this.fetchBalance();
        });
    },
  },
};
</script>
<style scoped>
.links div {
  border: 1px solid #ddd;
  text-align: center;
  font-weight: bold;
  cursor: pointer;
}
.active {
  background: #00b9a1;
}
.active * {
  color: #fff;
}
.total {
  margin: 20px auto;
}
</style>
