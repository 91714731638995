<template>
  <page-loader :isLoading="isLoading"></page-loader>
  <div class="box">
    <h1 class="subtitle is-4">Shipping Information</h1>
    <hr />
    <router-link to="/admin/dashboard/shippers_consignees/add"
      ><button class="button is-primary">Add</button></router-link
    >
    <br />
    <div class="columns mt-5">
      <div class="column is-">
        <div class="select">
          <select v-model="contact_type" @change="fetchData(contact_type)">
            <option value="Consignee">Consignees</option>
            <option value="Shipper">Shippers</option>
            <option value="Notify">Notify Parties</option>
            <option value="Forwarding Agent">Forwarding Agent</option>
          </select>
        </div>
      </div>
      <div class="column is-3">
        <input
          type="text"
          class="input is-rounded"
          :placeholder="`Search in ${contacts.length}`"
          v-model="search"
        />
      </div>
    </div>

    <table class="table is-fullwidth mt-5 is-hoverable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Contact</th>
          <th>Country</th>
          <th>City</th>
          <th>Address</th>
          <th>Postal Code</th>
          <th>Tax ID</th>
          <th>Addi. Info.</th>
          <th>Email/s</th>
          <th>Phone/s</th>
          <th>Clients</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="contact in filteredItems" :key="contact._id">
          <td>{{ contact.name }}</td>
          <td>{{ contact.contact }}</td>
          <td>{{ contact.country }}</td>
          <td>{{ contact.city }}</td>
          <td>{{ contact.address }}</td>
          <td>{{ contact.postal_code }}</td>
          <td>{{ contact.tax_id }}</td>
          <td>{{ contact.additional_info }}</td>
          <td>{{ contact.email }}</td>
          <td>{{ contact.phone }}</td>
          <td>
            <button
              class="button is-warning is-small"
              @click="viewLinkedClients(contact)"
            >
              View
            </button>
          </td>
          <td>
            <div class="dropdown is-hoverable is-right">
              <div class="dropdown-trigger">
                <button
                  class="button is-danger is-small"
                  aria-haspopup="true"
                  aria-controls="dropdown-menu3"
                >
                  <span>Actions</span>
                  <span class="icon is-small">
                    <i class="fas fa-angle-down" aria-hidden="true"></i>
                  </span>
                </button>
              </div>
              <div class="dropdown-menu" id="dropdown-menu3" role="menu">
                <div class="dropdown-content">
                  <router-link
                    :to="`/admin/dashboard/shippers_consignees/edit?id=${contact._id}`"
                    class="dropdown-item"
                    ><i class="fas fa-edit mr-1" aria-hidden="true"></i> Edit
                  </router-link>
                  <a
                    @click.stop.prevent="deleteContact(contact._id)"
                    class="dropdown-item"
                    ><i class="fas fa-trash mr-1" aria-hidden="true"></i> Delete
                  </a>
                </div>
              </div>
            </div>
          </td>
        </tr>
        <tr v-if="contacts.length === 0">
          <td colspan="12" style="text-align: center">No Data</td>
        </tr>
      </tbody>
    </table>
    <ViewLinkedClients
      @closeModal="closeModal"
      v-if="viewClients"
      :id="id"
      :contact="selected"
    />
  </div>
</template>
<script>
import ViewLinkedClients from "@/components/modals/ViewLinkedClients.vue";
export default {
  components: { ViewLinkedClients },
  data() {
    return {
      isLoading: false,
      contact_type: this.$route.query.contact_type,
      contacts: [],
      search: "",
      viewClients: false,
      id: "",
      selected: {},
      error: null,
    };
  },
  computed: {
    filteredItems() {
      return this.contacts.filter((o) =>
        Object.keys(o).some((k) =>
          o[k].toString().toLowerCase().includes(this.search.toLowerCase())
        )
      );
    },
  },
  created() {
    this.fetchData(this.contact_type);
  },
  methods: {
    fetchData(contact_type) {
      this.isLoading = true;
      this.$router.push(
        `/admin/dashboard/shippers_consignees?contact_type=${this.contact_type}`
      );
      this.$axios
        .post(
          "/api/retrieve_contacts",
          {
            contact_type,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.isLoading = false;
          this.contacts = res.data.contacts;
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = err.response.data.msg;
        });
    },
    deleteContact(id) {
      if (confirm("Are you sure you want to delete?") == true) {
        this.isLoading = true;
        this.$axios
          .post(
            "/api/delete_contact",
            {
              id,
            },
            { headers: this.$authHeader() }
          )
          .then(() => {
            this.isLoading = false;
            this.fetchData(this.contact_type);
          })
          .catch(() => {
            this.isLoading = false;
            alert("Something went wrong!");
          });
      }
    },
    closeModal() {
      this.viewClients = false;
      this.id = "";
      this.selected = {};
    },
    viewLinkedClients(selected) {
      this.id = selected._id;
      this.viewClients = true;
      this.selected = selected;
    },
  },
};
</script>
<style scoped>
.table {
  font-size: 13px;
}
</style>
