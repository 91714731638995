<template>
  <div class="invoice-container p-6">
    <page-loader :isLoading="pageLoading"></page-loader>
    <div class="invoice-header">
      <h1 class="subtitle">Container Invoice</h1>
      <hr />
      <button
        class="button is-warning"
        @click.stop.prevent="print"
        v-tippy="{ content: 'Print Invoice' }"
      >
        <i class="fa-solid fa-print"></i>
      </button>
      <i
        class="fa-regular fa-circle-xmark close-button is-size-3 is-clickable"
        @click="$router.go(-1)"
      ></i>

      <div class="notification is-danger" v-if="error">{{ error }}</div>
    </div>

    <div :id="`invoice`" class="invoice box-shadow p-6">
      <img
        :src="`/api/files/${this.$store.state.authUser.logo}`"
        alt="logo"
        class="logo"
      />
      <h1 class="title has-text-right">Invoice</h1>
      <table style="max-width: 300px" class="is-pulled-right mb-2">
        <tr>
          <td><strong>Invoice Nr</strong></td>
          <td>{{ invoice.invoice_number }}</td>
        </tr>
        <tr>
          <td><strong>Invoice Date</strong></td>
          <td>{{ invoice.invoice_date }}</td>
        </tr>
        <tr>
          <td><strong>Due Date</strong></td>
          <td>{{ invoice.due_date }}</td>
        </tr>
        <tr>
          <td><strong>Print Date</strong></td>
          <td>{{ printDate }}</td>
        </tr>
      </table>
      <table class="has-text-centered mt-3">
        <tr>
          <th>From</th>
          <th>To</th>
        </tr>
        <tr>
          <td>
            <p
              style="white-space: pre-line"
              v-html="invoice.user ? invoice.user.settings.invoice_address : ''"
            ></p>
          </td>
          <td>
            <p
              style="white-space: pre-line"
              v-html="invoice.client.billing_address"
            ></p>
          </td>
        </tr>
      </table>
      <table class="has-text-centered mt-3 info">
        <tr>
          <th colspan="4">Container Details</th>
        </tr>
        <tr>
          <td><strong>Container Nr.</strong></td>
          <td class="has-text-left">
            {{ `${invoice.container.container_number}` }}
          </td>
          <td><strong>Booking Nr.</strong></td>
          <td>{{ invoice.container.booking_number }}</td>
        </tr>
        <tr>
          <td><strong>Loading Point</strong></td>
          <td class="has-text-left">
            {{ invoice.container.loading_point }}
          </td>
          <td><strong>Destination</strong></td>
          <td>{{ invoice.container.destination }}</td>
        </tr>
        <tr>
          <td><strong>Loading Date</strong></td>
          <td class="has-text-left">
            {{ invoice.container.loading_date }}
          </td>
          <td><strong>Shipping Line</strong></td>
          <td>{{ invoice.container.shipping_line }}</td>
        </tr>
        <tr>
          <th colspan="4" class="has-text-centered">Loaded Items</th>
        </tr>
        <tr>
          <td class="nowrap" colspan="4">
            <div v-for="auto in invoice.container.autos" :key="auto._id">
              <p class="item">
                <strong>{{ `-${auto.year} ${auto.make} ${auto.model}` }}</strong
                ><span>{{ ` VIN: ${auto.vin} LOT#${auto.lot || ""}` }}</span>
                <span v-if="auto.city" class="block"
                  >(City:{{ auto.city
                  }}<span v-if="auto.sublot">
                    Sublot : {{ auto.sublot_location }}</span
                  >
                  )
                </span>
              </p>
            </div>
            <div v-for="part in invoice.container.parts" :key="part._id">
              <p class="item">
                <strong>{{ `-${part.description} ID#${part.part_id}` }}</strong>
              </p>
            </div>
          </td>
        </tr>
      </table>
      <table class="mt-5 has-text-left">
        <thead>
          <tr>
            <th>Description</th>
            <th width="150px">Amount</th>
          </tr>
        </thead>
        <tbody class="desc">
          <tr v-for="f in fields" :key="fields.indexOf(f)">
            <td colspan="1">
              <b>{{ f.description }}</b>
              <p v-for="r in f.rows" :key="f.rows.indexOf(r)" class="f">
                <span v-if="f.item_id !== 'n/a'"
                  >{{ r.description }} / {{ r.amount }}</span
                >
                <span v-else>
                  {{ r.description }}
                </span>
              </p>
            </td>
            <td style="vertical-align: middle">{{ f.total }}</td>
          </tr>
        </tbody>
        <tfoot>
          <tr>
            <td class="has-text-right" style="border: none">
              <strong
                >Total (<span class="has-text-danger">{{
                  invoice.currency
                }}</span
                >):</strong
              >
            </td>
            <td style="border: none">
              <strong> {{ invoice_total }}</strong>
            </td>
          </tr>
          <tr>
            <td class="has-text-right" style="border: none">
              <strong
                class="is-clickable"
                @click="
                  () => {
                    this.paymentsModalOpen = true;
                  }
                "
                >Payments:</strong
              >
            </td>
            <td style="border: none">
              <strong>-{{ payments_total }}</strong>
            </td>
          </tr>
          <tr>
            <td class="has-text-right" style="border: none">
              <strong>Balance :</strong>
            </td>
            <td style="border: none">
              <strong> {{ balance }}</strong>
            </td>
          </tr>
        </tfoot>
      </table>
      <hr />
      <h1 class="subtitle has-text-centered">Thank you for your business!</h1>
    </div>
  </div>
  <PaymentsModal
    :payments="invoice.payments"
    v-if="paymentsModalOpen"
    @closed="
      () => {
        this.paymentsModalOpen = false;
      }
    "
  />
</template>
<script>
import dayjs from "dayjs";
window.$ = window.jQuery = require("jquery");
import PaymentsModal from "../Autos/PaymentsModal.vue";
const printThis = require("@/assets/printThis");
export default {
  data() {
    return {
      due_date: null,
      invoice_date: null,
      amount: null,
      description: null,
      other: null,
      showOther: false,
      id: this.$route.params.id,
      invoice_fields: [],
      invoice: {},
      invoice_total: 0,
      payments_total: 0,
      balance: 0,
      error: null,
      pageLoading: false,
      quotes: [],
      items: [],
      item_id: null,
      fields: [],
      paymentsModalOpen: false,
    };
  },
  computed: {
    printDate() {
      return dayjs().format("YYYY-MM-DD");
    },
  },
  watch: {
    description(v) {
      if (v == "Other") {
        this.showOther = true;
      } else {
        this.showOther = false;
      }
    },
  },
  created() {
    this.fetchInvoice();
    this.$axios
      .post("/api/retrieve_user_settings", {}, { headers: this.$authHeader() })
      .then((res) => {
        this.invoice_fields = res.data.settings.invoice_fields || [];
        this.invoice_fields.push("Other");
      })
      .catch(() => {});
  },
  components: { PaymentsModal },
  methods: {
    fetchInvoice() {
      this.items = [];
      let item_ids = [];
      let fields = [];
      this.pageLoading = true;
      this.invoice_total = 0;
      this.payments_total = 0;
      this.balance = 0;
      this.$axios
        .get(`/api/retrieve_auto_invoice_client/${this.id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.invoice = res.data.invoice;
          if (res.data.invoice.created) {
            this.invoice.invoice_date = dayjs(res.data.invoice.created)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
          if (res.data.invoice.due_date) {
            this.invoice.due_date = dayjs(res.data.invoice.due_date)
              .add(1, "day")
              .format("YYYY-MM-DD");
          }
          if (res.data.invoice.container.loading_date) {
            this.invoice.container.loading_date = dayjs(
              res.data.invoice.container.loading_date
            ).format("YYYY-MM-DD");
          }
          if (this.invoice) {
            this.invoice.fields.map((f) => {
              // add the amoount to the total amount for the invoice
              this.invoice_total += f.amount;
              //***********************/
              // push items to item ids array to filter them
              if (!item_ids.includes(f.item_id)) {
                item_ids.push(f.item_id || "n/a");
                fields.push({
                  item_id: f.item_id || "n/a",
                  total: f.amount,
                  rows: [
                    { amount: f.amount, description: f.description, id: f._id },
                  ],
                });
              } else if (item_ids.includes(f.item_id)) {
                fields = fields.map((v) => {
                  if (v.item_id !== f.item_id) {
                    return v;
                  } else if (v.item_id == f.item_id) {
                    // found the field, make changes now
                    v.rows.push({
                      amount: f.amount,
                      description: f.description,
                      id: f._id,
                    });
                    v.total += f.amount;
                    return v;
                  }
                });
              }
            });
            this.invoice.payments.map((p) => {
              this.payments_total += p.amount;
            });
            this.balance = (this.invoice_total - this.payments_total).toFixed(
              2
            );
          }
          if (this.invoice.container.autos) {
            this.invoice.container.autos.map((a) => {
              this.items.push({
                label: `${a.year} ${a.make} ${a.model} vin:${a.vin}`,
                code: a._id,
              });
              // add auto details to fields
              fields = fields.map((v) => {
                if (v.item_id == a._id) {
                  v.description = `${a.year} ${a.make} ${a.model} vin:${a.vin}`;
                  return v;
                } else {
                  return v;
                }
              });
            });
          }
          if (this.invoice.container.parts) {
            this.invoice.container.parts.map((a) => {
              this.items.push({
                label: `${a.description}`,
                code: a._id,
              });
              // add part details to fields
              fields = fields.map((v) => {
                if (v.item_id == a._id) {
                  v.description = `${a.description} qty:${a.qty}`;
                  return v;
                } else {
                  return v;
                }
              });
            });
          }
          this.fields = fields;
          this.$axios
            .post(
              `/api/retrieve_quotes_for_invoice/`,
              {
                client: this.invoice.client._id,
                loading_point: this.invoice.container.loading_point,
                destination: this.invoice.container.destination,
                currency: this.invoice.currency,
              },
              { headers: this.$authHeader() }
            )
            .then((res) => {
              this.quotes = res.data.quotes;
            })
            .catch((err) => {
              console.log(err);
            });
          this.pageLoading = false;
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
    print() {
      let pageTitle = document.title;
      window.$("#invoice").printThis({
        beforePrint: () => {
          document.title = `Invoice#${this.invoice.invoice_number}`;
        },
        afterPrint: () => {
          document.title = pageTitle;
        },
      });
    },
  },
};
</script>
<style scoped>
.invoice-container {
  background: #f9f9f9;
  min-height: 100vh;
  width: 100%;
  position: relative;
}
.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
}
.invoice {
  min-height: 900px;
  width: 900px;
  border-radius: 10px;
  font-size: 0.8rem;
  color: #000;
  margin: auto;
  background: #fff;
}

.invoice table,
.actions table {
  border-collapse: collapse;
  width: 100%;
}
.invoice td,
.invoice th,
.actions td,
.actions th {
  border: 1px solid rgb(206, 206, 206);
  padding: 5px;
  color: #000;
}
.invoice th,
.actions th {
  background: #eee;
}
.desc td {
  border: none;
  border-bottom: 1px solid #ddd;
}
.actions {
  min-height: 500px;
  border-radius: 5px;
  background: #fff;
  border: 1px solid #ddd;
}
.columns {
  max-width: 2500px;
  margin: auto;
}
strong {
  color: #000;
}
.info td {
  border: none;
  border-bottom: 1px solid #ddd;
}
.f i {
  display: none;
}
.f:hover i {
  display: inline-block;
}
.block {
  display: block;
}
</style>
<style>
@media print {
  @page {
    margin: 15px;
  }
  .invoice {
    box-shadow: none !important;
  }
  body {
    background: #fff;
  }
}
</style>
