<template>
  <admin-side-nav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <admin-top-nav />
    <div class="mt-6">
      <router-view> </router-view>
    </div>
  </div>
</template>
<script>
import AdminSideNav from "@/components/nav/AdminSideNav.vue";
import AdminTopNav from "@/components/nav/AdminTopNav.vue";
export default {
  components: { AdminSideNav, AdminTopNav },
};
</script>
