<template>
  <article :class="messageClass">
    <div class="message-header">
      <p>{{ messageType == "error" ? "Error" : "Success" }}</p>
    </div>
    <div class="message-body">{{ msg }}</div>
  </article>
</template>
<script>
export default {
  props: {
    msg: String,
    messageType: String,
  },
  computed: {
    messageClass() {
      if (this.messageType == "error") {
        return "message is-danger";
      } else {
        return "message is-success";
      }
    },
  },
};
</script>
<style scoped>
article {
  transition: 1000ms;
}
.message-header p {
  color: #fff;
}
</style>
