<template>
  <div class="modal is-active auto-info">
    <div class="modal-background" @click="$emit('closeModal')"></div>
    <div class="modal-content">
      <div class="box">
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <div class="notification is-primary is-light" v-if="success">
          {{ success }}
        </div>
        <div class="block">
          <div class="field has-addons">
            <div class="control">
              <input
                class="input"
                style="height: 36px"
                type="text"
                placeholder="Email"
                v-model="email"
              />
            </div>
            <div class="control">
              <a
                :class="`button is-info ${isLoading ? 'is-loading' : ''}`"
                @click.stop="sendMail"
              >
                Send
              </a>
            </div>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth">
              <tr>
                <th colspan="2" class="has-text-centered has-background-light">
                  Auto Information
                </th>
              </tr>
              <tr>
                <th>Client</th>
                <td class="has-text-danger">{{ auto.client.company }}</td>
              </tr>
              <tr>
                <th>Auto</th>
                <td>{{ `${auto.year} ${auto.make} ${auto.model}` }}</td>
              </tr>
              <tr>
                <th>VIN</th>
                <td>{{ auto.vin }}</td>
              </tr>
              <tr>
                <th>Type</th>
                <td>{{ auto.vehicle_type }}</td>
              </tr>
              <tr>
                <th>Color</th>
                <td>{{ auto.color }}</td>
              </tr>
              <tr>
                <th>Engine</th>
                <td>{{ auto.engine }}</td>
              </tr>
            </table>
          </div>
          <div class="column">
            <table class="table is-fullwidth">
              <tr>
                <th colspan="2" class="has-text-centered has-background-light">
                  Shipping Information
                </th>
              </tr>
              <tr>
                <th>Loading Point</th>
                <td>{{ auto.loading_point }}</td>
              </tr>
              <tr>
                <th>Destination</th>
                <td>{{ auto.destination }}</td>
              </tr>
              <tr>
                <th>Shipping Method</th>
                <td>{{ auto.shipping_method }}</td>
              </tr>
              <tr>
                <th>Container Number</th>
                <td>
                  {{
                    auto.container && auto.container.container_number
                      ? auto.container.container_number
                      : null
                  }}
                </td>
              </tr>
              <tr>
                <th>Booking Number</th>
                <td>
                  {{
                    auto.container && auto.container.booking_number
                      ? auto.container.booking_number
                      : null
                  }}
                </td>
              </tr>
              <tr>
                <th>Arrival Date</th>
                <td>
                  {{ eta }}
                </td>
              </tr>
            </table>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <table class="table is-fullwidth">
              <tr>
                <th colspan="2" class="has-text-centered has-background-light">
                  Sale Info
                </th>
              </tr>
              <tr>
                <th>Lot</th>
                <td>{{ auto.lot }}</td>
              </tr>
              <tr>
                <th>Auction</th>
                <td>{{ auto.auction }}</td>
              </tr>
              <tr>
                <th>Buyer Number</th>
                <td>{{ auto.buyer_number }}</td>
              </tr>
              <tr>
                <th>City</th>
                <td>{{ auto.city }}</td>
              </tr>
              <tr>
                <th>Sublot Location</th>
                <td>{{ auto.sublot_location }}</td>
              </tr>
              <tr>
                <th>Purchase Date</th>
                <td>{{ purchase_date }}</td>
              </tr>
            </table>
            <div class="docs mt-2">
              <p class="mb-2">
                <strong class="has-text-danger">Uploaded Documents:</strong>
              </p>
              <p v-for="d in auto.documents" :key="d.document">
                <a
                  class="link"
                  target="__blank"
                  :href="`/api/files/${d.document}`"
                  >{{ d.type }} <i class="fa-solid fa-file"></i
                ></a>
              </p>
            </div>
          </div>
          <div class="column">
            <table class="table is-fullwidth">
              <tr>
                <th colspan="2" class="has-text-centered has-background-light">
                  Other Info
                </th>
              </tr>
              <tr>
                <th>Keys</th>
                <td>{{ auto.keys }}</td>
              </tr>
              <tr>
                <th>Title</th>
                <td>{{ auto.title_status }}</td>
              </tr>
              <tr>
                <th>Title received</th>
                <td>{{ title_received }}</td>
              </tr>
              <tr>
                <th>Arrived Terminal</th>
                <td>{{ delivery_date }}</td>
              </tr>
            </table>
          </div>
        </div>
        <div class="block" v-if="auto.notes">
          <div class="notification is-warning">
            {{ auto.notes }}
          </div>
        </div>
        <hr />
        <div class="images-container">
          <div class="columns">
            <div class="column">
              <a target="_blank" :href="`/api/downloadimages/${auto._id}`"
                ><button>Download Images</button></a
              >
              <br />
              <br />
              <div class="images">
                <img
                  v-for="i in auto.images"
                  :key="i"
                  :src="`/api/files/${i}`"
                  alt="Auto Image"
                  class="small-image"
                  @click="showImg(auto.images.indexOf(i))"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('closeModal')"
    ></button>
  </div>
  <vue-easy-lightbox
    :visible="lightboxVisible"
    :imgs="images"
    :index="index"
    @hide="hidePics"
  ></vue-easy-lightbox>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    auto: Object,
  },
  data() {
    return {
      index: 0,
      email: null,
      lightboxVisible: false,
      images: [],
      error: null,
      success: null,
      isLoading: false,
    };
  },
  computed: {
    purchase_date() {
      return dayjs(this.auto.purchase_date).format("YYYY-MM-DD");
    },
    delivery_date() {
      if (this.auto.delivery_date) {
        return dayjs(this.auto.delivery_date).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    title_received() {
      if (this.auto.title_received) {
        return dayjs(this.auto.title_received).format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
    eta() {
      if (this.auto.container && this.auto.container.eta) {
        return dayjs(this.auto.container.eta)
          .add(1, "day")
          .format("YYYY-MM-DD");
      } else {
        return "";
      }
    },
  },
  methods: {
    showImg(index, images) {
      this.images = this.auto.images.map((i) => {
        return document.location.origin + "/api/files/" + i;
      });
      this.index = index;
      this.lightboxVisible = true;
    },
    hidePics() {
      this.lightboxVisible = false;
    },
    sendMail() {
      if (!this.email) {
        return (this.error = "Enter an email address");
      }
      this.isLoading = true;
      this.$axios
        .get(`/api/email_vehicle/${this.$props.auto._id}/${this.email}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.error = null;
          this.success = res.data.msg;
          this.isLoading = false;
        })
        .catch((err) => {
          this.success = null;
          this.error = err.response.data.msg;
          this.isLoading = false;
        });
    },
  },
};
</script>
<style>
.auto-info .modal-content {
  min-width: 70%;
}

.table td,
.table th {
  color: #666;
  border-bottom: 1px solid #ddd;
}

.small-image {
  display: inline-block;
  width: 100px;
  height: 100px;
  margin-right: 5px;
  cursor: pointer;
}
.small-image:hover {
  border: 2px solid red;
}
.images {
  max-height: 500px;
  display: flex;
  overflow: auto;
}
@media only screen and (max-width: 600px) {
  .modal-content {
    width: 100% !important;
  }
}
</style>
