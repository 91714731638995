<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content">
      <!-- Any other Bulma elements you want -->
      <div class="box">
        <div class="notification is-danger is-light" v-if="error">
          {{ errorMessage }}
        </div>
        <div class="notification is-success is-light" v-if="success">
          {{ successMessage }}
        </div>
        <div class="columns">
          <div class="column">
            <div class="select is-fullwidth">
              <select v-model="currency">
                <currencies-list />
              </select>
            </div>
          </div>
          <div class="column is-2">
            <button
              :class="`button is-primary ${isLoading ? 'is-loading' : ''}`"
              @click.prevent="submit"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeModal"
    ></button>
  </div>
</template>
<script>
import CurrenciesList from "@/components/forms/CurrenciesList.vue";
export default {
  data() {
    return {
      currency: "",
      error: false,
      success: false,
      errorMessage: "",
      successMessage: "",
      isLoading: false,
    };
  },
  components: { CurrenciesList },
  methods: {
    closeModal() {
      this.$emit("closed");
    },
    submit() {
      if (!this.currency) {
        this.error = true;
        this.errorMessage = "Please fill out the required inputs.";
        this.isLoading = false;
        return;
      }
      this.error = false;
      this.success = false;
      this.isLoading = true;
      this.$axios
        .post(
          "/api/settings/add_currency",
          {
            currency: this.currency,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.success = true;
          this.successMessage = res.data.msg;
          this.isLoading = false;
        })
        .catch((err) => {
          this.error = true;
          this.errorMessage = err.response.data.msg;
          this.isLoading = false;
        });
    },
  },
};
</script>
