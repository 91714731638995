<template>
  <div class="modal is-active">
    <page-loader :isLoading="pageLoading"></page-loader>
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <div class="box">
        <h1 class="subtitle">Edit {{ this.buyer_number }}</h1>
        <hr />
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <div class="notification is-success" v-if="success">{{ success }}</div>

        <div class="columns">
          <div class="column">
            <label class="label"
              >Buyer Number <span class="has-text-danger">*</span>
            </label>
            <input
              type="text"
              class="input"
              placeholder="Buyer Number"
              v-model="buyer_number"
            />
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <label class="label">Auction</label>
            <v-select
              :options="auctions"
              placeholder="Auction"
              v-model="auction"
            ></v-select>
          </div>
        </div>
        <div class="columns">
          <div class="column">
            <label class="label">Client</label>
            <v-select
              :options="clients"
              placeholder="Client"
              v-model="client"
            ></v-select>
          </div>
        </div>
        <div class="button is-primary" @click.stop.prevent="handleEdit">
          Submit
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
  },
  data() {
    return {
      buyer_number: null,
      client: null,
      auction: null,
      clients: [],
      auctions: [],
      pageLoading: true,
      error: null,
      success: null,
    };
  },
  created() {
    // fetch clients and auctions
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        let a = [];
        res.data.clients.map((c) => {
          a.push({ label: c.company, code: c._id });
        });
        this.clients = a;
        // fetch auctions
        this.$axios
          .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
          .then((selections) => {
            selections.data.selections.map((s) => {
              if (s.type === "Auctions") {
                this.auctions.push(s.value);
              }
            });
            this.pageLoading = false;
          });
      });
    // fetch buyer info
    this.$axios
      .post(
        "/api/retrieve_buyers",
        { id: this.$props.id },
        { headers: this.$authHeader() }
      )
      .then((res) => {
        this.buyer_number = res.data.buyers[0].buyer_number;
        this.auction = res.data.buyers[0].auction;
        if (res.data.buyers[0].client) {
          this.client = {
            label: res.data.buyers[0].client.company,
            code: res.data.buyers[0].client._id,
          };
        }
      })
      .catch((err) => {});
  },
  methods: {
    handleEdit() {
      if (!this.buyer_number) {
        this.success = null;
        this.error = "Buyer number is required";
        return;
      }
      let b = {
        buyer_number: this.buyer_number,
        auction: this.auction,
        id: this.id,
      };
      if (this.client) {
        b.client = this.client.code;
      }
      if (!this.client) {
        b.client = null;
      }
      this.$axios
        .post("/api/edit_buyer", b, { headers: this.$authHeader() })
        .then((res) => {
          this.error = null;
          this.success = res.data.msg;
        })
        .catch((err) => {
          this.error = err.response.data.msg;
          this.success = null;
        });
    },
  },
};
</script>
<style scoped>
.box {
  overflow-x: auto;
}
</style>
