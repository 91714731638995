<template>
  <page-loader :isLoading="pageLoading"></page-loader>
  <div class="box p-6">
    <router-link to="/client/dashboard/autos/">
      <button class="button mb-6">
        <i class="fa-solid fa-angles-left mr-1"></i>Go Back to Autos
      </button>
    </router-link>
    <h1 class="title">Add New Auto</h1>
    <hr />
    <div class="notification is-danger" v-if="error">{{ error }}</div>
    <div class="notification is-primary" v-if="success">{{ success }}</div>
    <!-- VEHICLE INFO-->
    <div class="columns">
      <div class="column">
        <strong class="has-text-primary">Vehicle Information</strong>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Vin <span class="has-text-danger">*</span></label>
        <input
          type="text"
          class="input vin"
          placeholder="VIN"
          v-model="vin"
          maxlength="17"
        />
        <button
          class="button mt-1 is-warning"
          v-if="vin"
          @click.stop.prevent="decode"
        >
          Decode
          <i class="fa-solid fa-barcode ml-2"></i>
        </button>
      </div>
      <div class="column">
        <label class="label">Make <span class="has-text-danger">*</span></label>
        <v-select
          taggable
          v-model="make"
          :options="makes"
          placeholder="Make"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Model <span class="has-text-danger">*</span></label
        >
        <v-select
          taggable
          v-model="model"
          :options="models"
          placeholder="Model (manually type if nothing shows)"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Year <span class="has-text-danger">*</span></label>
        <v-select v-model="year" :options="years" placeholder="Year"></v-select>
      </div>
      <div class="column">
        <label class="label">Type <span class="has-text-danger">*</span></label>
        <v-select
          :options="[
            'Automobile',
            'Motorcycle',
            'Boat',
            'Heavy Vehicle',
            'ATV',
            'RV',
            'Trailer',
          ]"
          v-model="vehicle_type"
          placeholder="Vehicle Type"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Color</label>
        <v-select
          v-model="color"
          :options="colors"
          placeholder="Color"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <label class="label">Engine</label>
        <input
          type="text"
          class="input"
          placeholder="Engine"
          v-model="engine"
        />
      </div>
    </div>
    <!-- SALE INFO-->
    <div class="columns">
      <div class="column">
        <hr />
        <strong class="has-text-primary">Sale Info</strong>
        <div class="notification is-warning" v-if="client">
          <p><strong>Client Notes:</strong></p>
          {{ client.notes ? client.notes : "N/A" }}
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Buyer Number</label>
        <v-select
          :options="buyers"
          placeholder="Buyer Number"
          v-model="buyer_number"
          taggable
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Lot</label>
        <input type="text" class="input" placeholder="Lot" v-model="lot" />
      </div>
      <div class="column is-one-third">
        <label class="label">Auction</label>
        <v-select
          :options="auctions"
          placeholder="Auction"
          v-model="auction"
        ></v-select>
      </div>
    </div>
    <div class="columns">
      <div class="column is-one-third">
        <label class="label"
          >Purchase Date <span class="has-text-danger">*</span></label
        >

        <DatePicker
          v-model="purchase_date"
          placeholder="Pick a Date"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
      <div class="column is-one-third">
        <label class="label">Location</label>
        <v-select
          :options="cities"
          placeholder="Location"
          v-model="city"
        ></v-select>
        <label class="checkbox">
          <input type="checkbox" :value="true" v-model="isSubLot" />
          Sublot
        </label>
      </div>
    </div>
    <div class="columns">
      <div class="column" v-if="isSubLot">
        <label class="label">Sublot</label>
        <textarea
          class="textarea"
          rows="2"
          v-model="sublot_location"
        ></textarea>
      </div>
    </div>
    <!-- SHIPPING INFO-->
    <div class="columns">
      <div class="column">
        <hr />
        <strong class="has-text-primary">Shipping Info</strong>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label"
          >Loading Point <span class="has-text-danger">*</span></label
        >
        <v-select
          :options="loading_points"
          placeholder="Loading Point"
          v-model="loading_point"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Destination <span class="has-text-danger">*</span></label
        >
        <v-select
          :options="destinations"
          placeholder="Destination"
          v-model="destination"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Shipping Method <span class="has-text-danger">*</span></label
        >
        <v-select
          :options="['RoRo', 'Container']"
          placeholder="Shipping Method"
          v-model="shipping_method"
        ></v-select>
      </div>
    </div>
    <!-- OTHER INFO-->
    <div class="columns">
      <div class="column">
        <hr />
        <strong class="has-text-primary">Other</strong>
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <label class="label"
          >Delivery <span class="has-text-danger">*</span></label
        >
        <div class="control">
          <label class="radio">
            <input
              type="radio"
              name="answer"
              v-model="self_delivery"
              :value="true"
            />
            Self Delivery
          </label>
          <label class="radio">
            <input
              type="radio"
              name="answer"
              v-model="self_delivery"
              :value="false"
            />
            To Be Dispatched
          </label>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Notes</label>
        <textarea
          v-model="notes"
          class="textarea"
          placeholder="Notes"
        ></textarea>
      </div>
    </div>
    <!-- UPLOAD DOCUMENTS-->
    <div class="columns">
      <div class="column">
        <hr />
        <strong class="has-text-primary">Documents</strong>
      </div>
    </div>
    <!-- SELECTED FILES TABLE-->
    <div class="columns">
      <table
        class="table is-fullwidth docs is-bordered"
        v-if="files.length > 0"
      >
        <thead>
          <tr class="has-background-primary">
            <th>Type</th>
            <th>File Name</th>
            <th width="100px">Remove</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="file in files" :key="file.name">
            <td>{{ file.file_type }}</td>
            <td>{{ file.name }}</td>
            <td>
              <button
                class="button is-danger is-small"
                @click="handleRemoveFile(file.name)"
              >
                <i class="fa-regular fa-trash-can"></i>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- UPLOAD MODAL -->
    <div :class="`modal ${docsModalOpen ? 'is-active' : ''}`">
      <div class="modal-background" @click="closeDocsModal"></div>
      <div class="modal-content">
        <div class="box">
          <h1 class="subtitle">Upload</h1>
          <hr />
          <v-select
            placeholder="Type"
            v-model="file_type"
            taggable
            :options="['Report', 'BOS', 'Gate Pass', 'Title']"
          ></v-select>
          <div class="file mt-4" v-if="file_type">
            <label class="file-label">
              <input class="file-input" type="file" @change="handleDocUpload" />
              <span class="file-cta">
                <span class="file-icon">
                  <i class="fas fa-upload"></i>
                </span>
                <span class="file-label"> Choose a file… </span>
              </span>
            </label>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeDocsModal"
      ></button>
    </div>
    <div class="columns">
      <div class="column">
        <button class="button" @click="openDocsModal">
          Upload <i class="fa-solid fa-arrow-up-from-bracket ml-2"></i>
        </button>
      </div>
    </div>

    <hr />
    <button class="button is-primary" @click.stop.prevent="handleSubmit">
      Submit
    </button>
  </div>
</template>
<script>
import colors from "@/components/helpers/colors";
import makes from "@/components/helpers/makes";
import getYears from "@/components/helpers/getYears";

export default {
  data() {
    return {
      isSubLot: false,
      subLotLocation: null,
      vin: null,
      models: [],
      make: null,
      model: null,
      years: [],
      pageLoading: false,
      year: null,
      color: null,
      error: null,
      engine: null,
      vehicle_type: null,
      clients: [],
      client: null,
      auctions: [],
      cities: [],
      notes: null,
      destinations: [],
      loading_points: [],
      destination: null,
      loading_point: null,
      shipping_method: null,
      title_status: null,
      keys: null,
      status: "New purchase",
      self_delivery: null,
      city: null,
      lot: null,
      buyer_number: null,
      sublot_location: null,
      weight: null,
      customs1: [],
      customs2: [],
      custom1: null,
      custom2: null,
      purchase_date: null,
      title_received: null,
      delivery_date: null,
      auction: null,
      buyers: [],
      buyersList: [],
      files: [],
      file_types: [],
      thumbnail: null,
      images: [],
      changed: false,
      file_type: null,
      docsModalOpen: false,
      success: false,
    };
  },
  computed: {
    makes() {
      return makes;
    },
    colors() {
      return colors;
    },
  },
  watch: {
    // UPDATE MODEL WHEN MAKE CHANGES
    make(selected_make, prev) {
      if (selected_make !== prev) {
        this.model = null;
      }
      if (selected_make) {
        this.$axios
          .get(
            `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${selected_make.toLowerCase()}?format=json`
          )
          .then((res) => {
            this.models = res.data.Results.map((m) => m.Model_Name);
          });
      }
      if (!selected_make) {
        this.models = [];
      }
    },
  },
  // RETRIEVE REQUIRED FIELDS
  created() {
    // generate Years
    this.years = getYears();
    // Get Selections
    this.$axios
      .post(
        "/api/retrieve_selections_client",
        {},
        { headers: this.$authHeader() }
      )
      .then((res) => {
        let auctions = [];
        let destinations = [];
        let cities = [];
        let loading_points = [];
        res.data.selections.map((s) => {
          if (s.type == "Auctions") {
            auctions.push(s.value);
          }
          if (s.type == "Loading Points") {
            loading_points.push(s.value);
          }
          if (s.type == "Destinations") {
            destinations.push(s.value);
          }
          if (s.type == "Cities") {
            cities.push(s.value);
          }
          this.auctions = auctions;
          this.cities = cities;
          this.destinations = destinations;
          this.loading_points = loading_points;
        });
      });
    // get buyers
    this.$axios
      .post("/api/retrieve_buyers_client", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.buyers.map((b) => {
          this.buyers.push(b.buyer_number);
        });
        this.buyersList = res.data.buyers;
      });
  },
  methods: {
    // decode vin
    decode() {
      this.pageLoading = true;
      this.error = null;
      this.$axios
        .get(
          `https://vpic.nhtsa.dot.gov/api/vehicles/decodevinvalues/${this.vin}?format=json`
        )
        .then((res) => {
          // convert to lower case
          let make = res.data.Results[0].Make.toLowerCase();
          make = make.charAt(0).toUpperCase() + make.slice(1);
          this.make = make;
          // get models (to double check tt=hat models are pulled before changing state)
          this.$axios
            .get(
              `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${this.make}?format=json`
            )
            .then((data) => {
              this.pageLoading = false;
              this.models = data.data.Results.map((m) => m.Model_Name);
              this.model = res.data.Results[0].Model;
              this.year = Number(res.data.Results[0].ModelYear);
              this.engine = `${res.data.Results[0].EngineCylinders}Cyls / ${res.data.Results[0].DisplacementL}L`;
              // fillout vehicle type
              if (res.data.Results[0].VehicleType == "MOTORCYCLE") {
                this.vehicle_type = "Motorcycle";
              }
              if (
                res.data.Results[0].VehicleType == "PASSENGER CAR" ||
                res.data.Results[0].VehicleType ==
                  "MULTIPURPOSE PASSENGER VEHICLE (MPV)"
              ) {
                this.vehicle_type = "Automobile";
              }
              if (res.data.Results[0].VehicleType == "TRAILER") {
                this.vehicle_type = "Trailer";
              }
              if (res.data.Results[0].GVWR) {
                this.weight =
                  res.data.Results[0].GVWR.split("(")[1].split(" ")[0] + "kg";
              }
              if (!res.data.Results[0].GVWR) {
                this.weight = null;
              }
            })
            .catch((err) => {
              this.make = null;
              this.pageLoading = false;
              this.error =
                "Failed to decode this VIN, please double-check or enter the information manually";
            });
        })
        .catch((err) => {
          this.pageLoading = false;
          this.make = null;
          this.error =
            "Failed to decode this VIN, please double-check or enter the information manually";
        });
    },
    // Handle Documents Upload
    openDocsModal() {
      this.docsModalOpen = true;
    },
    closeDocsModal() {
      this.docsModalOpen = false;
      this.file_type = null;
    },
    handleDocUpload(e) {
      let file = e.target.files[0];
      let type = file.type.split("/")[1];
      if (type == "jpeg" || type == "pdf" || type == "png") {
        this.file_types.push(this.file_type + "/" + file.name);
        file.file_type = this.file_type || "N/A";
        this.files.push(file);
        this.file_type = null;
        this.closeDocsModal();
      } else {
        return alert("Only images and PDF files are allowed");
      }
    },
    handleRemoveFile(file_name) {
      this.files = this.files.filter((f) => {
        return f.name !== file_name;
      });
    },
    // HANDLE FORM SUBMIT
    handleSubmit() {
      // validate inputs
      if (
        !this.vin ||
        !this.year ||
        !this.make ||
        !this.model ||
        !this.vehicle_type ||
        !this.purchase_date ||
        !this.loading_point ||
        !this.destination ||
        !this.status ||
        !this.shipping_method ||
        this.self_delivery == null
      ) {
        this.success = null;
        window.scrollTo(0, 0);
        return (this.error = "Please fill out all the required fields");
      }
      this.pageLoading = true;
      // get files
      let fd = new FormData();
      // get Images
      this.files.map((f) => fd.append("documents", f));
      this.images.map((f) => fd.append("images", f));
      fd.append("thumbnail", this.thumbnail);
      fd.append("file_types[]", this.file_types);
      fd.append("vin", this.vin.trim().toUpperCase());
      fd.append("make", this.make);
      fd.append("model", this.model);
      fd.append("year", this.year);
      fd.append("vehicle_type", this.vehicle_type);
      if (this.color) {
        fd.append("color", this.color);
      }
      if (this.engine) {
        fd.append("engine", this.engine);
      }
      if (this.notes) {
        fd.append("notes", this.notes);
      }
      if (this.buyer_number) {
        fd.append("buyer_number", this.buyer_number);
      }
      if (this.lot) {
        fd.append("lot", this.lot);
      }
      if (this.auction) {
        fd.append("auction", this.auction);
      }
      if (this.purchase_date) {
        fd.append("purchase_date", this.purchase_date);
      }
      if (this.delivery_date) {
        fd.append("delivery_date", this.delivery_date);
      }
      if (this.city) {
        fd.append("city", this.city);
      }
      fd.append("sublot", this.isSubLot);
      if (this.sublot_location) {
        fd.append("sublot_location", this.sublot_location);
      }
      fd.append("loading_point", this.loading_point);
      fd.append("destination", this.destination);
      fd.append("shipping_method", this.shipping_method);
      if (this.title_status) {
        fd.append("title_status", this.title_status);
      }
      if (this.keys) {
        fd.append("keys", this.keys);
      }
      fd.append("status", this.status);
      if (this.title_received) {
        fd.append("title_received", this.title_received);
      }
      if (this.custom1) {
        fd.append("custom1", this.custom1);
      }
      if (this.custom2) {
        fd.append("custom2", this.custom2);
      }
      fd.append("self_delivery", this.self_delivery);
      let headers = this.$authHeader();
      // changing the headers to include files
      headers["Content-Type"] = "multipart/form-data";
      this.$axios
        .post("/api/add_auto_client", fd, {
          headers,
        })
        .then((res) => {
          this.pageLoading = false;
          this.error = null;
          window.scrollTo(0, 0);
          this.success = res.data.msg;
          // reset state
          (this.isSubLot = false),
            (this.subLotLocation = null),
            (this.vin = null),
            (this.models = []),
            (this.make = null),
            (this.model = null),
            (this.year = null),
            (this.color = null),
            (this.engine = null),
            (this.vehicle_type = null),
            (this.client = null),
            (this.destination = null),
            (this.loading_point = null),
            (this.shipping_method = null),
            (this.title_status = null),
            (this.keys = null),
            (this.status = "New purchase"),
            (this.self_delivery = null),
            (this.city = null),
            (this.lot = null),
            (this.buyer_number = null),
            (this.sublot_location = null),
            (this.weight = null),
            (this.custom1 = null),
            (this.custom2 = null),
            (this.purchase_date = null),
            (this.title_received = null),
            (this.auction = null),
            (this.files = []),
            (this.thumbnail = null),
            (this.images = []),
            (this.changed = false),
            (this.delivery_date = null),
            (this.notes = null),
            ((this.file_types = []), (this.file_type = null));
        })
        .catch((err) => {
          this.pageLoading = false;
          this.success = null;
          window.scrollTo(0, 0);
          this.error = err.response.data.msg;
        });
    },
  },
};
</script>
<style scoped>
.container {
  background: #fff;
}

.modal .box {
  padding: 20px;
  max-width: 1000px;
  margin: auto;
  height: 300px;
}
.docs th {
  color: #fff;
}
.docs {
  text-align: center;
}
.docs td {
  font-weight: bold;
}
.vin {
  text-transform: uppercase;
}
</style>
