<template>
  <admin-side-nav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <admin-top-nav />
    <div class="mt-6">
      <div class="notification is-danger" v-if="error">{{ error }}</div>
      <div class="notification is-primary" v-if="success">{{ success }}</div>
      <div class="box">
        <h1 class="title is-size-5">Profile</h1>
        <hr />
        <p v-if="user.user_type == 'account_owner'">
          <b>Company Name :</b> {{ user.company }}
        </p>
        <p v-if="user.user_type == 'account_owner'">
          <b>Account ID :</b> {{ user.customer_id }}
        </p>
        <p><b>Name :</b> {{ user.name }}</p>
        <p><b>Email :</b> {{ user.email }}</p>
        <p><b>Username :</b> {{ user.username }}</p>
        <p><b>Joined :</b> {{ user.created_at }}</p>
        <p v-if="user.user_type == 'account_owner'">
          <b>Account Status : </b>
          <span :class="user.active ? 'has-text-primary' : 'has-text-danger'">{{
            user.active ? "Active" : "Inactive"
          }}</span>
        </p>
        <p v-if="user.user_type == 'account_owner'">
          <b>Your Login Page Link: </b>
          <span class="has-text-info">{{
            `https://app.otomago.com/login?customer_id=${user.customer_id}`
          }}</span>
        </p>
        <hr />
        <div class="notification is-warning">
          Contact your account manager if you want to make any changes.
        </div>
      </div>
      <div class="box">
        <h1 class="title is-size-5">Update Password</h1>
        <hr />
        <div class="field">
          <label class="label">New Password</label>
          <input
            v-model="password"
            type="password"
            class="input"
            placeholder="New Password"
          />
        </div>
        <div class="field">
          <label class="label">Confirm New Password</label>
          <input
            v-model="confirmPassword"
            type="password"
            class="input"
            placeholder="New Password"
          />
        </div>
        <button class="button is-primary" @click.stop="updatePassword">
          Save
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import AdminSideNav from "@/components/nav/AdminSideNav.vue";
import AdminTopNav from "@/components/nav/AdminTopNav.vue";
import dayjs from "dayjs";
export default {
  components: { AdminSideNav, AdminTopNav },
  data() {
    return {
      user: {},
      isLoading: true,
      error: null,
      password: null,
      confirmPassword: null,
      success: null,
    };
  },
  created() {
    // get profile information
    this.$axios
      .get("/api/retrieve_customer_information", {
        headers: this.$authHeader(),
      })
      .then((res) => {
        if (res.data.user.created_at) {
          res.data.user.created_at = dayjs(res.data.user.created_at).format(
            "YYYY-MM-DD hh:MM A"
          );
        }
        this.user = res.data.user;
      });
  },
  methods: {
    updatePassword() {
      if (!this.password || !this.confirmPassword) {
        return (this.error = "All fields are required");
      }
      if (this.password !== this.confirmPassword) {
        return (this.error = "Passwords don't match");
      }
      this.$axios
        .post(
          "/api/update_password_admin",
          { password: this.password },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.error = null;
          this.success = res.data.msg;
        })
        .catch((err) => {
          this.success = null;
          this.error = err.response.data.msg;
        });
    },
  },
};
</script>
