<template>
  <admin-side-nav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <admin-top-nav />
    <div class="mt-6">
      <div class="box">
        <h1 class="subtitle is-4">Settings</h1>
        <hr />
        <div class="columns">
          <div class="column is-2">
            <aside class="menu">
              <ul class="menu-list">
                <li>
                  <router-link to="/admin/dashboard/settings/selections"
                    >Selections Fields</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/dashboard/settings/invoices"
                    >Invoices Settings</router-link
                  >
                </li>
                <li>
                  <router-link to="/admin/dashboard/settings/logo"
                    >Logo</router-link
                  >
                </li>
              </ul>
            </aside>
          </div>
          <div class="column">
            <router-view />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import AdminSideNav from "@/components/nav/AdminSideNav.vue";
import AdminTopNav from "@/components/nav/AdminTopNav.vue";

export default {
  components: { AdminSideNav, AdminTopNav },
};
</script>
<style scoped>
.menu-list .is-active {
  background: #00d1b2;
}
.router-link-active {
  background: rgb(248, 248, 248);
  border-right: 4px solid #00d1b2;
}
</style>
