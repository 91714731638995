<template>
  <tr :class="`${auto.onHold ? 'hold' : ''}`">
    <td>
      <img
        class="thumbnail"
        :src="`/api/files/${auto.thumbnail}`"
        alt="auto thumbnail"
        v-if="auto.thumbnail"
        @click="$emit('viewAutoDetails', auto)"
      />
      <img
        class="thumbnail"
        :src="`/api/files/no_photo.png`"
        alt="auto thumbnail"
        v-if="!auto.thumbnail"
        @click="$emit('viewAutoDetails', auto)"
      />
      <br />
      <span
        class="tag is-warning is-clickable is-small"
        v-if="!auto.by_admin"
        v-tippy="{
          placement: 'top',
          content: `By ${auto.entered_by} at ${auto.created_at}`,
        }"
        >Entered by client</span
      >
      <span
        class="tag is-primary is-clickable is-small"
        v-if="auto.by_admin"
        v-tippy="{
          placement: 'top',
          content: `By ${auto.entered_by} at ${auto.created_at}`,
        }"
        >Entered by Admin</span
      >
    </td>
    <td>
      <li>
        <span class="view_details" @click="$emit('viewAutoDetails', auto)">{{
          `${auto.year} ${auto.make} ${auto.model}`
        }}</span>
      </li>
      <li><strong>VIN : </strong>{{ auto.vin }}</li>
    </td>
    <td>
      <li><strong>Auction :</strong> {{ auto.auction }}</li>
      <li><strong>Buyer :</strong> {{ auto.buyer_number }}</li>
      <li><strong>Lot :</strong> {{ auto.lot }}</li>
      <li v-if="auto.city"><strong>City :</strong> {{ auto.city }}</li>
    </td>
    <td>
      <li><strong>Purchased :</strong> {{ auto.purchase_date }}</li>
      <li><strong>Arrived Terminal :</strong> {{ auto.delivery_date }}</li>
      <li><strong>Title Received :</strong> {{ auto.title_received }}</li>
      <li>
        <span
          class="tag is-danger"
          v-if="auto.sublot"
          v-tippy="{
            content: auto.sublot_location,
            placement: 'top',
          }"
          >Sublot</span
        >
      </li>
    </td>
    <td>
      <li>
        <strong>Container :</strong>
        <span v-if="auto.container && auto.container.container_number">
          <router-link
            :to="`/client/dashboard/containers?container_number=${auto.container.container_number}`"
            >{{ auto.container.container_number }}</router-link
          >
        </span>
        <span
          class="view_details"
          v-if="
            auto.status !== 'Loaded' && auto.inLoadingList && auto.loading_list
          "
          @click="
            loadingListLink(auto.loading_list.list_id, auto.loading_list.status)
          "
        >
          {{
            auto.status !== "Loaded" && auto.inLoadingList && auto.loading_list
              ? `Loading List#` + auto.loading_list.list_id
              : ""
          }}</span
        >
      </li>
      <li>
        <strong>Booking : </strong
        ><span v-if="auto.container && auto.container.booking_number">
          <router-link
            :to="`/client/dashboard/containers?booking_number=${auto.container.booking_number}`"
            >{{ auto.container.booking_number }}</router-link
          >
        </span>
      </li>
      <li>
        <strong>Status : </strong>
        <span
          :class="`tag ${
            auto.status == 'Loaded'
              ? 'is-primary'
              : auto.status == 'At terminal'
              ? 'is-warning'
              : auto.status == 'Left terminal'
              ? 'is-danger'
              : auto.status == 'New purchase'
              ? 'is-info'
              : ''
          }`"
        >
          {{ auto.status }}</span
        >
      </li>
      <li v-if="auto.onHold" class="has-text-danger text-bold">
        <i class="fa-solid fa-triangle-exclamation"></i> ON HOLD
      </li>
    </td>
    <td>
      <li><strong>From :</strong> {{ auto.loading_point }}</li>
      <li>
        <strong>To : </strong>
        <a
          class="change_destination"
          title="Destination can't be changed if the vehicle is loaded"
          v-if="auto.status == 'Loaded'"
          >{{ auto.destination }}
        </a>
        <a
          class="change_destination"
          title="click to change"
          v-else
          @click.prevent="$emit('changeDestination', auto)"
          >{{ auto.destination }} <i class="fa-regular fa-pen-to-square"></i
        ></a>
      </li>
      <li class="is-clickable" @click="changeReceiver(auto._id)">
        <strong>Receiver: </strong>
        <span class="has-text-danger">{{
          auto.receiver ? auto.receiver.name : null
        }}</span>
      </li>
      <li v-if="auto.status == 'At terminal'">
        <strong>Days at terminal : </strong>
        <span class="has-text-danger">{{ daysCounter }}</span>
      </li>
      <li v-if="auto.notes">
        <span
          class="tag is-warning"
          v-tippy="{
            content: auto.notes,
            placement: 'top',
          }"
          >Notes</span
        >
      </li>
    </td>
    <td>
      <li>
        <i class="fa-solid fa-key"></i> <strong>Keys : </strong> {{ auto.keys }}
      </li>
      <li>
        <i class="fa-regular fa-file-lines mr-1"></i> <strong>Title : </strong>
        {{ auto.title_status }}
      </li>
      <li>
        <span class="messages tag" @click="$emit('viewMessages', auto)"
          >Messages :
          <span class="has-text-danger">{{ auto.messages.length }}</span></span
        >
      </li>
    </td>
    <td>
      <p v-for="invoice in auto.invoices" :key="invoice._id">
        <router-link :to="`/client/dashboard/auto_invoice/${invoice._id}`"
          ><span class="has-text-dark">{{ invoice.invoice_number }}</span>
          <span class="has-text-danger"> ({{ invoice.currency }})</span>
        </router-link>
      </p>
    </td>
    <td>
      <button class="button mt-3 is-small" @click="$emit('viewUpdates', auto)">
        Updates<strong class="has-text-danger ml-1">{{
          auto.updates ? auto.updates.length : 0
        }}</strong>
      </button>
      <router-link
        v-if="auto.report"
        :to="`/client/dashboard/autos/condition_report/${auto.report}`"
        ><button
          class="button is-small is-info mt-1"
          v-tippy="{ content: 'Condition Report' }"
        >
          CR
        </button></router-link
      >
    </td>
  </tr>
</template>
<script>
import dayjs from "dayjs";
export default {
  props: {
    auto: Object,
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    daysCounter() {
      var x = dayjs();
      if (this.auto.delivery_date) {
        x = dayjs(this.auto.delivery_date);
      }
      var y = dayjs();
      var duration = y.diff(x, "days");
      if (!this.auto.delivery_date) {
        return "";
      }
      if (duration < 0) {
        return "";
      } else {
        return duration + 1;
      }
    },
  },
  methods: {
    changeStatus(e, id) {
      this.$axios
        .post(
          "/api/autos/update_info",
          { auto_id: id, status: e.target.value },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          let new_class =
            e.target.value == "New purchase"
              ? "new_purchase"
              : e.target.value == "Loaded"
              ? "loaded"
              : e.target.value == "At terminal"
              ? "at_terminal"
              : e.target.value == "Left terminal"
              ? "left_terminal"
              : "";

          e.target.classList = [];
          e.target.classList.add(new_class);
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
    loadingListLink(id, status) {
      if (status == "pending") {
        this.$router.push(`/client/dashboard/loading_lists?list_id=${id}`);
      }
      if (status == "ready") {
        this.$router.push(
          `/client/dashboard/loading_lists/ready?list_id=${id}`
        );
      }
    },
    changeReceiver(id) {
      this.$emit("changeReceiver", id);
    },
    changeDestination() {},
    createReport(id) {
      // send request to server to create report
      this.$axios
        .post(
          "/api/autos/create_condition_report/",
          { auto_id: id },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          // redirect to condition report page
          this.$router.push(
            `/admin/dashboard/autos/condition_report/${res.data.report_id}`
          );
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped>
.thumbnail {
  max-width: 100px;
  cursor: pointer;
  border-radius: 5px;
}

.table li {
  list-style: none;
  font-size: 0.8rem;
  margin-top: 5px;
  color: #757575;
}
.table strong {
  color: #757575;
}
.table li span i {
  color: #00d1b2;
  width: 15px;
}
.client {
  color: #f14668 !important;
}

.tag.is-primary,
.tag.is-danger {
  color: #fff;
}
.new_invoice {
  cursor: pointer;
  font-weight: bold;
}
.messages {
  cursor: pointer;
  font-size: 0.8rem;
}

.view_details {
  cursor: pointer !important;
  color: #3399cc;
  font-weight: 500;
}
.view_details:hover {
  text-decoration: underline;
}
.fa-crown {
  color: rgb(255, 183, 0);
}
select {
  padding: 2px;
  font-size: 0.8rem;
  border: 1px solid #ddd;
}
.hold {
  background-color: #f7dedc !important;
}
.change_destination i {
  opacity: 0;
  transition: 200ms;
}
.change_destination:hover i {
  opacity: 1;
}
.invoice {
  text-decoration: underline;
}
</style>
