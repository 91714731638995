import OverviewPage from '@/client_views/Overview/OverviewPage'
import ClientProfile from '@/client_views/Profile/ClientProfile'
import AutosPage from '@/client_views/Autos/AutosPage'
import AutosList from '@/client_views/Autos/AutosList'
import AddAuto from '@/client_views/Autos/AddAuto'
import AutoReport from '@/client_views/Autos/AutoReport'
import AutoInvoice from '@/client_views/Autos/AutoInvoice'
import PartsList from '@/client_views/Parts/PartsList'
import PartInvoice from '@/client_views/Parts/PartInvoice'
import ContainersPage from '@/client_views/Containers/ContainersPage'
import ContainersList from '@/client_views/Containers/ContainersList'
import DockReceipt from '@/client_views/Containers/DockReceipt'
import TrackingPage from '@/client_views/Containers/TrackingPage'
import ContainerInvoice from '@/client_views/Containers/ContainerInvoice'
import MessagesPage from '@/client_views/Messages/MessagesPage'
import QuotesPage from '@/client_views/Quotes/QuotesPage'
import QuotesList from '@/client_views/Quotes/QuotesList'
import ViewQuote from '@/client_views/Quotes/ViewQuote'
import DocsForms from '@/client_views/DocsForms'
import BalancePage from '@/client_views/Balance/BalancePage'
import PaymentsPage from '@/client_views/Payments/PaymentsPage'
import PaymentStatement from '@/client_views/Payments/PaymentStatement'
import LoadingListsPage from '@/client_views/LoadingLists/LoadingListsPage'
import PendingLists from '@/client_views/LoadingLists/PendingLists'
import LoadedLists from '@/client_views/LoadingLists/LoadedLists'
import RejectedLists from '@/client_views/LoadingLists/RejectedLists'
import ReadyLists from '@/client_views/LoadingLists/ReadyLists'
import NewLoadingList from '@/client_views/LoadingLists/NewLoadingList'

// Client Routes
// Contain all code for Admin side Navigations
const routes = [
    // Client Dashboard Page
    {
        path: "/client/dashboard/overview",
        name: "ClientOverviewPage",
        component: OverviewPage,
        meta: { requiresAuth: true },
    },
    // Client Profile Page
    {
        path: "/client/dashboard/profile",
        name: "clientProfile",
        component: ClientProfile,
        meta: { requiresAuth: true },
    },
    // Client Autos Page
    {
        path: "/client/dashboard/autos",
        name: "AutosPage",
        component: AutosPage,
        meta: { requiresAuth: true },
        children: [{ path: "", component: AutosList },
        { path: 'add', component: AddAuto },
        { path: 'condition_report/:id', component: AutoReport }]
    },
    // Auto Invoice
    {
        path: "/client/dashboard/auto_invoice/:id",
        name: "AutoInvoiceClient",
        component: AutoInvoice,
        meta: { requiresAuth: true },
    },
    // Parts
    {
        path: "/client/dashboard/parts",
        name: "PartsPageClient",
        component: PartsList,
        meta: { requiresAuth: true },
    },
    // Parts Invoice
    {
        path: "/client/dashboard/part_invoice/:id",
        name: "PartInvoiceClient",
        component: PartInvoice,
        meta: { requiresAuth: true },
    },
    // Containers
    {
        path: "/client/dashboard/containers",
        name: "ContainersPageClient",
        component: ContainersPage,
        meta: { requiresAuth: true },
        children: [{ path: "", component: ContainersList },
        { path: "dock_receipt/:id", component: DockReceipt }]
    },
    // Container Tracking Page
    {
        path: "/client/dashboard/containers/tracking/:container_number",
        name: "TrackingPageClient",
        component: TrackingPage,
        meta: { requiresAuth: true },
    },
    // Container Invoice
    {
        path: "/client/dashboard/container_invoice/:id",
        name: "ContainerInvoiceClient",
        component: ContainerInvoice,
        meta: { requiresAuth: true },
    },
    // Messages Page
    {
        path: "/client/dashboard/messages",
        name: "MessagesPageClient",
        component: MessagesPage,
        meta: { requiresAuth: true },
    },
    // Quotes
    {
        path: "/client/dashboard/quotes",
        name: "QuotesPageClient",
        component: QuotesPage,
        meta: { requiresAuth: true },
        children: [{ path: "", component: QuotesList }, { path: "view/:id", component: ViewQuote }]
    },
    // Form Page
    {
        path: "/client/dashboard/forms",
        name: "FormsPageCLient",
        component: DocsForms,
        meta: { requiresAuth: true },
    },
    // Balance Page
    {
        path: "/client/dashboard/balance",
        name: "BalancePageCLient",
        component: BalancePage,
        meta: { requiresAuth: true },
    },
    // Payments Page
    {
        path: "/client/dashboard/payments",
        name: "PaymentsPageCLient",
        component: PaymentsPage,
        meta: { requiresAuth: true },
    },
    // Payment Statement
    {
        path: "/client/dashboard/transaction/:id",
        name: "PaymentStatementClient",
        component: PaymentStatement,
        meta: { requiresAuth: true },
    },
    // Loading Lists
    {
        path: "/client/dashboard/loading_lists",
        name: "LoadingListsClient",
        component: LoadingListsPage,
        meta: { requiresAuth: true, },
        children: [
            { path: "", component: PendingLists },
            { path: "pending", component: PendingLists },
            { path: "ready", component: ReadyLists },
            { path: "rejected", component: RejectedLists },
            { path: "loaded", component: LoadedLists },
            { path: "add", component: NewLoadingList },
        ],
    },
];

export default {
    client_routes: routes,
};
