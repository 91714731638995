<template>
  <page-loader :isLoading="isLoading"></page-loader>
  <div class="print">
    <button
      @click.stop.prevent="print"
      class="button is-warning"
      v-tippy="{
        content: `Print`,
        placement: 'left',
      }"
    >
      <i class="fa-solid fa-print"></i>
    </button>
  </div>
  <div class="box quote" id="quote">
    <div class="header">
      <div class="columns">
        <div class="column">
          <img
            :src="`/api/files/${this.$store.state.authUser.logo}`"
            alt="logo"
            class="logo"
          />
        </div>
        <div class="column is-3">
          <h1 class="subtitle has-text-right">Quote</h1>
        </div>
      </div>
      <hr />
      <div class="columns">
        <div class="column is-7">
          <table class="table is-fullwidth">
            <tr>
              <th>From</th>
              <th v-if="this.client">To</th>
            </tr>
            <tr>
              <td>
                <p
                  style="white-space: pre-line"
                  v-html="quote.user ? quote.user.settings.invoice_address : ''"
                ></p>
              </td>
              <td v-if="this.client">
                <p
                  style="white-space: pre-line"
                  v-html="quote.client ? quote.client.billing_address : ''"
                ></p>
              </td>
            </tr>
          </table>
        </div>
        <div class="column">
          <table class="table is-fullwidth">
            <tr>
              <th>Quote Number</th>
              <td>
                {{ quote.quote_id }}
              </td>
            </tr>
            <tr>
              <th>Created At</th>
              <td>
                {{ quote.created_at }}
              </td>
            </tr>
            <tr>
              <th>Updated</th>
              <td>
                {{ quote.updated_at }}
              </td>
            </tr>
            <tr>
              <th>Quoted By</th>
              <td>
                {{ quote.created_by }}
              </td>
            </tr>
            <tr>
              <th>Currency</th>
              <td>
                {{ quote.currency }}
              </td>
            </tr>
          </table>
        </div>
      </div>
    </div>
    <div class="mt-6"></div>
    <span class="icon mr-2">
      <i class="fa-solid fa-ship"></i>
    </span>
    <span
      ><strong class="has-text-danger">{{ quote.loading_point }}</strong></span
    >
    <span class="icon mr-2">
      <i class="fas fa-arrow-right"></i>
    </span>
    <span
      ><strong class="has-text-danger">{{ quote.destination }}</strong></span
    >
    <div class="body mt-3">
      <table class="table is-fullwidth">
        <thead>
          <tr>
            <th>Shipping Lines</th>
            <th>Size</th>
            <th>Description</th>
            <th width="150px">Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="d in details" :key="d._id">
            <td>{{ d.shipping_lines }}</td>
            <td>{{ d.size }}</td>
            <td>{{ d.description }}</td>
            <td>
              {{ d.amount }}
              <span class="has-text-danger">{{ quote.currency }}</span>
            </td>
          </tr>
        </tbody>
      </table>
      <p><strong>Notes:</strong></p>
      <p>{{ quote.notes }}</p>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
window.$ = window.jQuery = require("jquery");
const printThis = require("@/assets/printThis");
export default {
  data() {
    return {
      id: this.$route.params.id,
      quote: {},
      isLoading: true,
      client: {},
      details: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$axios
        .post(`/api/quotes/${this.id}`, {}, { headers: this.$authHeader() })
        .then((res) => {
          this.client = res.data.quote[0].client
            ? res.data.quote[0].client
            : null;
          this.quote = res.data.quote[0];
          this.quote.created_at = dayjs(this.quote.created_at).format(
            "YYYY-MM-DD"
          );
          this.quote.updated_at = dayjs(this.quote.updated_at).format(
            "YYYY-MM-DD"
          );
          this.details = res.data.quote[0].details;
          this.isLoading = false;
        })
        .catch((err) => {
          alert(err.response.data.msg);
          this.isLoading = false;
        });
    },
    print() {
      window.$("#quote").printThis();
    },
  },
};
</script>
<style>
.quote {
  max-width: 900px;
  margin: auto;
  min-height: 1000px;
}
.logo {
  max-width: 100px;
}
.table th {
  font-weight: bold !important;
}

.body .table td,
.body .table th {
  font-size: 0.8rem !important;
}
.print {
  max-width: 900px;
  margin: auto;
  margin-bottom: 20px;
  text-align: right;
}
@media print {
  @page {
    margin: 15px;
  }
  .box {
    box-shadow: none !important;
  }
  body {
    background: #fff;
  }
}
</style>
