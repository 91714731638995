<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">Reset Your Password</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close')"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <div class="notification is-primary is-light" v-if="success">
          {{ success }}
        </div>
        <div class="field">
          <label class="label">Enter Your Username</label>
          <input
            type="text"
            v-model="username"
            class="input"
            placeholder="Username"
          />
        </div>
        <div class="field">
          <label class="label">Enter Your Email Address</label>
          <input
            v-model="email"
            type="email"
            class="input"
            placeholder="Email"
          />
        </div>
      </section>
      <footer class="modal-card-foot">
        <button
          :class="`button is-success ${isLoading ? 'is-loading' : ''}`"
          @click.stop="handleSubmit"
        >
          Submit
        </button>
        <button class="button" @click="$emit('close')">Cancel</button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      username: null,
      email: null,
      error: null,
      success: null,
      isLoading: false,
    };
  },
  methods: {
    handleSubmit() {
      if (!this.username || !this.email) {
        return (this.error = "Please fill out all the required fields");
      }
      this.isLoading = true;
      this.$axios
        .post("/api/forget_password", {
          username: this.username,
          email: this.email,
        })
        .then((res) => {
          this.error = null;
          this.success = res.data.msg;
          this.isLoading = false;
        })
        .catch((err) => {
          this.success = null;
          this.error = err.response.data.msg;
          this.isLoading = false;
        });
    },
  },
};
</script>
