<template>
  <div class="box">
    <h1 class="title is-size-5">Notification Settings</h1>
    <hr />
    <div class="notification is-danger" v-if="error">{{ error }}</div>
    <div class="notification is-primary is-light" v-if="success">
      {{ success }}
    </div>

    <p>Notify me when:</p>
    <table class="table mt-3">
      <tr>
        <td>Vehicle status changes</td>
        <td>
          <input
            type="checkbox"
            @change.stop="handleChange('status')"
            v-model="status"
            id="switch"
          /><label for="switch">Toggle</label>
        </td>
      </tr>
      <tr>
        <td>Invoices are created</td>
        <td>
          <input
            type="checkbox"
            @change.stop="handleChange('invoices')"
            v-model="invoices"
            id="switch2"
          /><label for="switch2">Toggle</label>
        </td>
      </tr>
      <tr>
        <td>A new loading list is created</td>
        <td>
          <input
            type="checkbox"
            @change.stop="handleChange('loading_list')"
            v-model="loading_list"
            id="switch3"
          /><label for="switch3">Toggle</label>
        </td>
      </tr>
      <tr>
        <td>A payment is received</td>
        <td>
          <input
            type="checkbox"
            @change.stop="handleChange('payment')"
            v-model="payment"
            id="switch4"
          /><label for="switch4">Toggle</label>
        </td>
      </tr>
      <tr>
        <td>There is new a message to my account</td>
        <td>
          <input
            type="checkbox"
            @change.stop="handleChange('message')"
            v-model="message"
            id="switch5"
          /><label for="switch5">Toggle</label>
        </td>
      </tr>
      <tr>
        <td>A new update is added to my vehicles</td>
        <td>
          <input
            type="checkbox"
            @change.stop="handleChange('update')"
            v-model="update"
            id="switch6"
          /><label for="switch6">Toggle</label>
        </td>
      </tr>
      <tr>
        <td>A container is loaded</td>
        <td>
          <input
            type="checkbox"
            @change.stop="handleChange('container')"
            v-model="container"
            id="switch7"
          /><label for="switch7">Toggle</label>
        </td>
      </tr>
    </table>
    <div class="field has-addons">
      <div class="control" style="min-width: 350px">
        <input
          class="input"
          style="height: 36px"
          type="text"
          v-model="email"
          placeholder="Notifcation Email/s (use ; to separate them)"
        />
      </div>
      <div class="control">
        <a class="button is-primary" @click="handleEmailChange()"> Save </a>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      status: null,
      invoices: null,
      loading_list: null,
      payment: null,
      message: null,
      update: null,
      container: null,
      email: null,
      error: null,
      success: null,
    };
  },
  created() {
    // get client notification settings
    this.$axios
      .get("/api/get_client_settings", { headers: this.$authHeader() })
      .then((res) => {
        let n = res.data.notifications;
        this.status = n.status;
        this.invoices = n.invoices;
        this.loading_list = n.loading_list;
        this.payment = n.payment;
        this.message = n.message;
        this.update = n.update;
        this.container = n.container;
        this.email = n.email;
      })
      .catch((err) => {
        this.success = null;
        this.error = err.resposne.data.msg;
      });
  },
  methods: {
    handleChange(v) {
      this.$axios
        .get(`/api/update_client_settings/${v}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {})
        .catch((err) => {
          this.success = null;
          this.error = err.resposne.data.msg;
        });
    },
    handleEmailChange() {
      this.$axios
        .get(`/api/update_client_email/${this.email}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.success = res.data.msg;
          this.error = null;
        })
        .catch((err) => {
          this.success = null;
          this.error = err.resposne.data.msg;
        });
    },
  },
};
</script>
<style scoped>
.table td {
  vertical-align: middle;
}
input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

label {
  cursor: pointer;
  text-indent: -9999px;
  width: 60px;
  height: 30px;
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
  margin-top: -20px;
}

label:after {
  content: "";
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

input:checked + label {
  background: #00b9a1;
}

input:checked + label:after {
  left: calc(100% - 5px);
  transform: translateX(-100%);
}

label:active:after {
  width: 20px;
}
</style>
