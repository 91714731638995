<template>
  <div class="print">
    <button
      @click.stop.prevent="print"
      class="button is-warning"
      v-tippy="{
        content: `Print`,
        placement: 'left',
      }"
    >
      <i class="fa-solid fa-print"></i>
    </button>
  </div>
  <div class="report box-shadow" id="report">
    <img
      :src="`/api/files/${this.$store.state.authUser.logo}`"
      alt="Logo"
      class="logo"
    />
    <h1 class="subtitle has-text-centered">Condition Report</h1>
    <hr />
    <table class="table is-fullwidth is-bordered has-text-centered">
      <tr class="has-background-dark">
        <th class="has-text-white">Make</th>
        <th class="has-text-white">Model</th>
        <th class="has-text-white">Year</th>
        <th class="has-text-white">VIN</th>
        <th class="has-text-white">Color</th>
      </tr>
      <tr>
        <td>{{ auto.make }}</td>
        <td>{{ auto.model }}</td>
        <td>{{ auto.year }}</td>
        <td>{{ auto.vin }}</td>
        <td>{{ auto.color }}</td>
      </tr>
    </table>
    <table class="table is-fullwidth is-bordered has-text-centered">
      <tr class="has-background-dark">
        <th class="has-text-white">Mileage</th>
        <th class="has-text-white">Keys</th>
        <th class="has-text-white">Title / Ownership</th>
      </tr>
      <tr>
        <td><input type="text" v-model="mileage" /></td>
        <td>
          <input type="text" v-model="keys" />
        </td>
        <td><input type="text" v-model="title" /></td>
      </tr>
    </table>
    <!--NOTES-->
    <table class="table is-fullwidth is-bordered has-text-centered">
      <tr class="has-background-dark">
        <th class="has-text-white">Notes</th>
      </tr>
      <tr>
        <td><textarea class="textarea" rows="2" v-model="notes"></textarea></td>
      </tr>
    </table>
    <div class="columns">
      <!-- Exterior-->
      <div class="column">
        <table class="table is-fullwidth">
          <tr class="has-background-dark">
            <th colspan="4" class="has-text-white">Exterior</th>
          </tr>
          <tr class="has-background-light">
            <th>Item</th>
            <th width="20px">Ok</th>
            <th width="20px">None</th>
            <th>Comments</th>
          </tr>
          <tr>
            <td>Roof</td>
            <td><input type="checkbox" :value="true" v-model="roof.ok" /></td>
            <td><input type="checkbox" :value="true" v-model="roof.none" /></td>
            <td>
              <input type="text" v-model="roof.comments" />
            </td>
          </tr>
          <tr>
            <td>Windshield</td>
            <td>
              <input type="checkbox" :value="true" v-model="windshield.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="windshield.none" />
            </td>
            <td>
              <input type="text" v-model="windshield.comments" />
            </td>
          </tr>
          <tr>
            <td>Grill</td>
            <td><input type="checkbox" :value="true" v-model="grill.ok" /></td>
            <td>
              <input type="checkbox" :value="true" v-model="grill.none" />
            </td>
            <td>
              <input type="text" v-model="grill.comments" />
            </td>
          </tr>
          <tr>
            <td>Front Lights</td>
            <td>
              <input type="checkbox" :value="true" v-model="front_lights.ok" />
            </td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="front_lights.none"
              />
            </td>
            <td>
              <input type="text" v-model="front_lights.comments" />
            </td>
          </tr>
          <tr>
            <td>Tail Lights</td>
            <td>
              <input type="checkbox" :value="true" v-model="tail_lights.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="tail_lights.none" />
            </td>
            <td>
              <input type="text" v-model="tail_lights.comments" />
            </td>
          </tr>
          <tr>
            <td>Wheels</td>
            <td><input type="checkbox" :value="true" v-model="wheels.ok" /></td>
            <td>
              <input type="checkbox" :value="true" v-model="wheels.none" />
            </td>
            <td>
              <input type="text" v-model="wheels.comments" />
            </td>
          </tr>
          <tr>
            <td>Hood</td>
            <td><input type="checkbox" :value="true" v-model="hood.ok" /></td>
            <td><input type="checkbox" :value="true" v-model="hood.none" /></td>
            <td>
              <input type="text" v-model="hood.comments" />
            </td>
          </tr>
          <tr>
            <td>Front Bumper</td>
            <td>
              <input type="checkbox" :value="true" v-model="front_bumper.ok" />
            </td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="front_bumper.none"
              />
            </td>
            <td>
              <input type="text" v-model="front_bumper.comments" />
            </td>
          </tr>
          <tr>
            <td>Rear Bumper</td>
            <td>
              <input type="checkbox" :value="true" v-model="rear_bumper.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="rear_bumper.none" />
            </td>
            <td>
              <input type="text" v-model="rear_bumper.comments" />
            </td>
          </tr>
          <tr>
            <td>Fenders</td>
            <td>
              <input type="checkbox" :value="true" v-model="fenders.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="fenders.none" />
            </td>
            <td>
              <input type="text" v-model="fenders.comments" />
            </td>
          </tr>
          <tr>
            <td>Rear Glass</td>
            <td>
              <input type="checkbox" :value="true" v-model="rear_glass.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="rear_glass.none" />
            </td>
            <td>
              <input type="text" v-model="rear_glass.comments" />
            </td>
          </tr>
          <tr>
            <td>Spare Tire</td>
            <td>
              <input type="checkbox" :value="true" v-model="spare_tire.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="spare_tire.none" />
            </td>
            <td>
              <input type="text" v-model="spare_tire.comments" />
            </td>
          </tr>
          <tr>
            <td>Windows</td>
            <td>
              <input type="checkbox" :value="true" v-model="windows.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="windows.none" />
            </td>
            <td>
              <input type="text" v-model="windows.comments" />
            </td>
          </tr>
          <tr>
            <td>Trunk Lid</td>
            <td>
              <input type="checkbox" :value="true" v-model="trunk_lid.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="trunk_lid.none" />
            </td>
            <td>
              <input type="text" v-model="trunk_lid.comments" />
            </td>
          </tr>
          <tr>
            <td>Side Mirrors</td>
            <td>
              <input type="checkbox" :value="true" v-model="side_mirrors.ok" />
            </td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="side_mirrors.none"
              />
            </td>
            <td>
              <input type="text" v-model="side_mirrors.comments" />
            </td>
          </tr>
          <tr>
            <td>Doors</td>
            <td><input type="checkbox" :value="true" v-model="doors.ok" /></td>
            <td>
              <input type="checkbox" :value="true" v-model="doors.none" />
            </td>
            <td>
              <input type="text" v-model="doors.comments" />
            </td>
          </tr>
          <tr>
            <td>Bed Liner</td>
            <td>
              <input type="checkbox" :value="true" v-model="bedliner.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="bedliner.none" />
            </td>
            <td>
              <input type="text" v-model="bedliner.comments" />
            </td>
          </tr>
        </table>
      </div>
      <div class="column">
        <!-- Interior-->
        <table class="table is-fullwidth">
          <tr class="has-background-dark">
            <th colspan="4" class="has-text-white">Interior</th>
          </tr>
          <tr class="has-background-light">
            <th>Item</th>
            <th width="20px">Ok</th>
            <th width="20px">None</th>
            <th>Comments</th>
          </tr>
          <tr>
            <td>Front Door Panels</td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="front_door_panels.ok"
              />
            </td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="front_door_panels.none"
              />
            </td>
            <td>
              <input type="text" v-model="front_door_panels.comments" />
            </td>
          </tr>
          <tr>
            <td>Rear Door Panels</td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="rear_door_panels.ok"
              />
            </td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="rear_door_panels.none"
              />
            </td>
            <td>
              <input type="text" v-model="rear_door_panels.comments" />
            </td>
          </tr>
          <tr>
            <td>Front Seats</td>
            <td>
              <input type="checkbox" :value="true" v-model="front_seats.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="front_seats.none" />
            </td>
            <td>
              <input type="text" v-model="front_seats.comments" />
            </td>
          </tr>
          <tr>
            <td>Rear Seats</td>
            <td>
              <input type="checkbox" :value="true" v-model="rear_seats.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="rear_seats.none" />
            </td>
            <td>
              <input type="text" v-model="rear_seats.comments" />
            </td>
          </tr>
          <tr>
            <td>Carpets</td>
            <td>
              <input type="checkbox" :value="true" v-model="carpets.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="carpets.none" />
            </td>
            <td>
              <input type="text" v-model="carpets.comments" />
            </td>
          </tr>
          <tr>
            <td>3rd Row Seats</td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="third_row_seats.ok"
              />
            </td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="third_row_seats.none"
              />
            </td>
            <td>
              <input type="text" v-model="third_row_seats.comments" />
            </td>
          </tr>
          <tr>
            <td>Dashboard</td>
            <td>
              <input type="checkbox" :value="true" v-model="dashboard.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="dashboard.none" />
            </td>
            <td>
              <input type="text" v-model="dashboard.comments" />
            </td>
          </tr>
          <tr>
            <td>Center Console</td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="center_console.ok"
              />
            </td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="center_console.none"
              />
            </td>
            <td>
              <input type="text" v-model="center_console.comments" />
            </td>
          </tr>
        </table>
        <!-- Mechanical-->
        <table class="table is-fullwidth">
          <tr class="has-background-dark">
            <th colspan="4" class="has-text-white">Mechanical</th>
          </tr>
          <tr class="has-background-light">
            <th>Item</th>
            <th width="20px">Ok</th>
            <th width="20px">None</th>
            <th>Comments</th>
          </tr>
          <tr>
            <td>Engine</td>
            <td>
              <input type="checkbox" :value="true" v-model="engine.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="engine.none" />
            </td>
            <td>
              <input type="text" v-model="engine.comments" />
            </td>
          </tr>
          <tr>
            <td>Transmission</td>
            <td>
              <input type="checkbox" :value="true" v-model="transmission.ok" />
            </td>
            <td>
              <input
                type="checkbox"
                :value="true"
                v-model="transmission.none"
              />
            </td>
            <td>
              <input type="text" v-model="transmission.comments" />
            </td>
          </tr>
          <tr>
            <td>Brakes</td>
            <td>
              <input type="checkbox" :value="true" v-model="brakes.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="brakes.none" />
            </td>
            <td>
              <input type="text" v-model="brakes.comments" />
            </td>
          </tr>
          <tr>
            <td>Clutch</td>
            <td>
              <input type="checkbox" :value="true" v-model="clutch.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="clutch.none" />
            </td>
            <td>
              <input type="text" v-model="clutch.comments" />
            </td>
          </tr>
          <tr>
            <td>Battery</td>
            <td>
              <input type="checkbox" :value="true" v-model="battery.ok" />
            </td>
            <td>
              <input type="checkbox" :value="true" v-model="battery.none" />
            </td>
            <td>
              <input type="text" v-model="battery.comments" />
            </td>
          </tr>
        </table>

        <div class="control">
          <span class="mr-4">Run & Drive</span>
          <label class="radio">
            <input type="radio" :value="true" v-model="run_and_drive" />
            Yes
          </label>
          <label class="radio">
            <input type="radio" :value="false" v-model="run_and_drive" />
            No
          </label>
        </div>
      </div>
    </div>
    <table class="table is-fullwidth">
      <tr class="has-background-dark">
        <th class="has-text-white">Completed By</th>
        <th class="has-text-white">Date</th>
      </tr>
      <tr>
        <td><input type="text" v-model="completed_by" /></td>
        <td><input type="text" v-model="date" /></td>
      </tr>
    </table>
  </div>
</template>
<script>
window.$ = window.jQuery = require("jquery");
const printThis = require("@/assets/printThis");
export default {
  data() {
    return {
      mileage: null,
      keys: null,
      title: null,
      notes: null,
      roof: {},
      windshield: {},
      grill: {},
      front_lights: {},
      tail_lights: {},
      wheels: {},
      hood: {},
      tires: {},
      front_bumper: {},
      rear_bumper: {},
      fenders: {},
      rear_glass: {},
      spare_tire: {},
      windows: {},
      trunk_lid: {},
      side_mirrors: {},
      doors: {},
      bedliner: {},
      front_door_panels: {},
      rear_door_panels: {},
      front_seats: {},
      rear_seats: {},
      carpets: {},
      third_row_seats: {},
      dashboard: {},
      center_console: {},
      auto: {},
      engine: {},
      transmission: {},
      brakes: {},
      clutch: {},
      battery: {},
      completed_by: null,
      date: null,
      run_and_drive: null,
    };
  },
  created() {
    this.$axios
      .post(
        "/api/autos/retrieve_report_information_client",
        { report_id: this.$route.params.id },
        { headers: this.$authHeader() }
      )
      .then((res) => {
        let report = res.data.report;
        this.auto = res.data.report.auto;
        this.mileage = report.mileage;
        this.keys = report.keys;
        this.title = report.title;
        this.notes = report.notes;
        this.roof = report.roof;
        this.windshield = report.windshield;
        this.grill = report.grill;
        this.front_lights = report.front_lights;
        this.tail_lights = report.tail_lights;
        this.wheels = report.wheels;
        this.hood = report.hood;
        this.front_bumper = report.front_bumper;
        this.rear_bumper = report.rear_bumper;
        this.fenders = report.fenders;
        this.rear_glass = report.rear_glass;
        this.spare_tire = report.spare_tire;
        this.windows = report.windows;
        this.trunk_lid = report.trunk_lid;
        this.side_mirrors = report.side_mirrors;
        this.doors = report.doors;
        this.bedliner = report.bedliner;
        this.front_door_panels = report.front_door_panels;
        this.rear_door_panels = report.rear_door_panels;
        this.front_seats = report.front_seats;
        this.rear_seats = report.rear_seats;
        this.carpets = report.carpets;
        this.third_row_seats = report.third_row_seats;
        this.dashboard = report.dashboard;
        this.center_console = report.center_console;
        this.engine = report.engine;
        this.transmission = report.transmission;
        this.brakes = report.brakes;
        this.clutch = report.clutch;
        this.battery = report.battery;
        this.completed_by = report.completed_by;
        this.date = report.date;
        this.run_and_drive = report.run_and_drive;
        Array.from(document.getElementsByTagName("input")).map((e) => {
          e.disabled = true;
        });
        Array.from(document.getElementsByTagName("textarea")).map((e) => {
          e.disabled = true;
        });
      });
    // disable all inputs
  },
  methods: {
    print() {
      this.changed = false;
      window.$("#report").printThis();
    },
  },
};
</script>
<style scoped>
th {
  background: #666 !important;
  color: #fff !important;
  font-weight: normal !important;
}
.report {
  border: 1px solid rgb(240, 240, 240);
  max-width: 900px;
  margin: auto;
  border-radius: 5px;
  padding: 1rem;
  background: #fff;
}
.report .table td {
  font-size: 0.8rem;
  padding: 0px;
}
.textarea {
  font-size: 0.8rem;
  background: #eef5ff;
  border: none !important;
}
.report .table th {
  font-size: 0.8rem;
  padding: 3px;
}
.report .table td input,
.report .table td textarea {
  width: 100%;
  border: 1px solid #ddd;
}
.print {
  max-width: 900px;
  margin: auto;
  margin-bottom: 20px;
  text-align: right;
}
@media print {
  @page {
    margin: 15px;
  }
  .report {
    box-shadow: none !important;
    border: none;
  }
}
.logo {
  max-width: 100px;
}
input {
  background: #eef5ff;
  border: none !important;
}
</style>
