<template>
  <ClientSideNav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <ClientTopNav />
    <div class="mt-6 p-6">
      <div>
        <h1 class="title">Overview</h1>
        <hr />
        <AnnouncemntsNotification />

        <NeedsAttention />
      </div>
      <LoadingPoints />
      <hr />
      <AutosStats />
    </div>
  </div>
</template>
<script>
import ClientSideNav from "../ClientSideNav.vue";
import ClientTopNav from "../ClientTopNav.vue";
import NeedsAttention from "./NeedsAttention.vue";
import AnnouncemntsNotification from "@/components/messages/AnnouncemntsNotification.vue";
import LoadingPoints from "./LoadingPoints.vue";
import AutosStats from "./AutosStats.vue";
export default {
  components: {
    ClientSideNav,
    ClientTopNav,
    NeedsAttention,
    AnnouncemntsNotification,
    LoadingPoints,
    AutosStats,
  },
};
</script>
