<template>
  <page-loader :isLoading="isLoading"></page-loader>
  <div class="print">
    <button
      @click.stop.prevent="print"
      class="button is-warning"
      v-tippy="{
        content: `Print`,
        placement: 'left',
      }"
    >
      <i class="fa-solid fa-print"></i>
    </button>
  </div>
  <div class="box delivery_receipt" id="delivery_receipt">
    <div class="header">
      <div class="columns">
        <div class="column">
          <img
            :src="`/api/files/${this.$store.state.authUser.logo}`"
            alt="logo"
            class="logo"
          />
          <br />
          <strong>{{ this.$store.state.authUser.company }}</strong>
        </div>
        <div class="column is-3">
          <h1 class="subtitle has-text-right">Delivery Receipt</h1>
        </div>
      </div>
    </div>
    <hr />
    <div class="columns">
      <div class="column">
        <table class="table is-fullwidth has-text-centered is-bordered">
          <thead>
            <tr class="has-background-light">
              <th>Auto</th>
              <th>VIN</th>
              <th>Lot</th>
              <th>Delivery Date</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>{{ `${auto.year} ${auto.make} ${auto.model}` }}</td>
              <td>{{ auto.vin }}</td>
              <td>{{ auto.lot }}</td>
              <td>{{ auto.delivery_date }}</td>
            </tr>
          </tbody>
        </table>
        <textarea
          @keyup="() => (this.changed = true)"
          class="textarea"
          placeholder="Delivery Notes...."
          v-model="notes"
        ></textarea>
        <hr />
        <p class="has-text-centered">Thank you for your business!</p>
        <button
          class="button is-primary save"
          v-if="changed"
          @click.prevent="updateDeliveryNotes"
        >
          Save
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
window.$ = window.jQuery = require("jquery");
const printThis = require("@/assets/printThis");
export default {
  data() {
    return {
      id: this.$route.params.id,
      auto: {},
      isLoading: true,
      changed: false,
      notes: null,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.$axios
        .post(
          "/api/retrieve_auto",
          { id: this.id },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          if (res.data.auto.delivery_date) {
            res.data.auto.delivery_date = dayjs(
              res.data.auto.delivery_date
            ).format("YYYY-MM-DD");
          }
          this.auto = res.data.auto;
          this.notes = res.data.auto.delivery_notes;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
        });
    },
    print() {
      this.changed = false;
      this.updateDeliveryNotes();
      window.$("#delivery_receipt").printThis();
    },
    updateDeliveryNotes() {
      this.$axios
        .post(
          "/api/autos/update_info",
          {
            auto_id: this.id,
            delivery_notes: this.notes,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.changed = false;
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
  },
};
</script>
<style scoped>
.delivery_receipt {
  max-width: 900px;
  margin: auto;
}
.logo {
  max-width: 100px;
}
.table th {
  font-weight: bold !important;
}
.body .table th {
  background: #f9f9f9;
}
.body .table td,
.body .table th {
  font-size: 0.8rem !important;
}
.print {
  max-width: 900px;
  margin: auto;
  margin-bottom: 20px;
  text-align: right;
}
@media print {
  @page {
    margin: 15px;
  }
  .box {
    box-shadow: none !important;
  }
}
</style>
