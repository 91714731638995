<template>
  <page-loader :isLoading="this.isLoading" />
  <form @submit.prevent="" class="box">
    <div class="columns">
      <div class="column">
        <div class="field">
          <div class="notification is-danger" v-if="error">{{ error }}</div>
          <label class="label">Address on Invoices</label>
          <div class="control">
            <textarea
              class="textarea has-fixed-size"
              v-model="settings.invoice_address"
              placeholder="Type the address you want to appear on your invoices"
              @keyup="listenForAddressChange"
            >
            </textarea>
            <button
              v-if="addressChanged"
              class="button mt-2 is-small is-primary"
              @click="saveAddress"
            >
              <i class="fa-solid fa-pen-to-square mr-2"></i> Save Changes
            </button>
          </div>
        </div>
        <div class="field">
          <label class="label mt-5">Currencies</label>
          <div class="control">
            <a
              v-for="currency in settings.currencies"
              :key="currency"
              class="button is-light mr-4"
              >{{ currency
              }}<span class="delete-item" @click="deleteCurrency(currency)"
                ><i class="fa-solid fa-trash-can"></i></span
            ></a>
            <button class="button is-primary" @click="openCurrencyModal">
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
        </div>
        <div class="field">
          <label class="label mt-5">Invoice Fields</label>
          <div class="control">
            <a
              v-for="invoice_field in settings.invoice_fields"
              :key="settings.invoice_fields.indexOf(invoice_field)"
              class="button is-light mr-4"
              >{{ invoice_field
              }}<span class="delete-item" @click="deleteField(invoice_field)"
                ><i class="fa-solid fa-trash-can"></i></span
            ></a>
            <button class="button is-primary" @click="openFieldModal">
              <i class="fa-solid fa-plus"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
  <AddCurrencyModal v-if="addCurrencyModalOpen" @closed="closeModal" />
  <AddInvoiceFieldModal v-if="addFieldModalOpen" @closed="closeModal" />
</template>
<script>
import AddCurrencyModal from "@/views/Settings/AddCurrencyModal.vue";
import AddInvoiceFieldModal from "@/views/Settings/AddInvoiceFieldModal.vue";

export default {
  components: { AddCurrencyModal, AddInvoiceFieldModal },
  data() {
    return {
      isLoading: false,
      addCurrencyModalOpen: false,
      addFieldModalOpen: false,
      error: null,
      settings: {
        invoice_address: "",
        currencies: [],
        invoice_fields: [],
      },
      addressChanged: false,
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$axios
        .post(
          "/api/retrieve_user_settings",
          {},
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.isLoading = false;
          this.settings.invoice_address = res.data.settings.invoice_address;
          this.settings.currencies = res.data.settings.currencies;
          this.settings.invoice_fields = res.data.settings.invoice_fields;
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = err.response.data.msg;
        });
    },
    listenForAddressChange() {
      this.addressChanged = true;
    },
    openCurrencyModal() {
      this.addCurrencyModalOpen = true;
    },
    openFieldModal() {
      this.addFieldModalOpen = true;
    },

    closeModal() {
      this.addCurrencyModalOpen = false;
      this.addFieldModalOpen = false;
      this.fetchData();
    },
    deleteCurrency(currency) {
      if (confirm("Are you sure you want to delete?") == true) {
        this.isLoading = true;
        this.$axios
          .post(
            "/api/settings/delete_currency",
            { currency },
            { headers: this.$authHeader() }
          )
          .then(() => {
            this.error = null;
            this.isLoading = false;
            this.fetchData();
          })
          .catch((err) => {
            this.isLoading = false;
            this.error = err.response.data.msg;
          });
      }
    },
    deleteField(field) {
      if (confirm("Are you sure you want to delete?") == true) {
        this.isLoading = true;
        this.$axios
          .post(
            "/api/settings/delete_invoice_field",
            { invoice_field: field },
            { headers: this.$authHeader() }
          )
          .then(() => {
            this.error = null;
            this.isLoading = false;
            this.fetchData();
          })
          .catch((err) => {
            this.isLoading = false;
            this.error = err.response.data.msg;
          });
      }
    },
    saveAddress() {
      this.isLoading = true;
      this.$axios
        .post(
          "/api/settings/update_invoice_address",
          { invoice_address: this.settings.invoice_address },
          { headers: this.$authHeader() }
        )
        .then(() => {
          this.error = null;
          this.addressChanged = false;
          this.isLoading = false;
          this.fetchData();
        })
        .catch((err) => {
          this.addressChanged = false;
          this.isLoading = false;
          this.error = err.response.data.msg;
        });
    },
  },
};
</script>
<style scoped>
.button.is-light {
  position: relative;
}
.button.is-light:hover .delete-item {
  opacity: 1;
}
.delete-item {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  background: #f14668;
  border-radius: 3px;
  opacity: 0;
  transition: 50ms;
}
</style>
