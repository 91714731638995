<template>
  <div class="box">
    <h1 class="subtitle is-4">Autos</h1>
    <hr />
    <router-link class="add_auto_button" to="/admin/dashboard/autos/add"
      ><button class="button is-primary level-right">
        Add New Auto
      </button></router-link
    >
    <pageLoader :isLoading="isLoading" />
    <div class="notification is-danger mt-3" v-if="error">{{ error }}</div>
    <AutosFilter @search="handleSearch" />
    <p class="block">Total Vehicles : {{ totalAutos }}</p>
    <!-- Paginating-->
    <nav
      class="pagination is-small is-centered"
      role="navigation"
      aria-label="pagination"
    >
      <!-- PREV Page-->
      <a
        class="pagination-previous"
        v-if="hasPrevPage"
        @click.prevent="changePage(prevPage)"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <a class="pagination-previous" disabled v-if="!hasPrevPage"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <!-- NEXT Page-->
      <a
        class="pagination-next"
        @click.prevent="changePage(nextPage)"
        v-if="hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <a class="pagination-next" disabled v-if="!hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <ul class="pagination-list">
        <!-- FIRST Page-->
        <li>
          <a
            class="pagination-link"
            aria-label="Goto page 1"
            @click.prevent="changePage(1)"
            >First</a
          >
        </li>
        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- CURRENT Page-->
          <a
            class="pagination-link is-current"
            aria-label="Page 46"
            aria-current="page"
            >{{ page }}</a
          >
        </li>

        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- LAST Page-->
          <a
            class="pagination-link"
            aria-label="Goto page 86"
            @click.prevent="changePage(totalPages)"
            >Last</a
          >
        </li>
      </ul>
    </nav>
    <div class="table-wraper">
      <table class="mt-5 table is-fullwidth is-hoverable">
        <thead>
          <tr>
            <th colspan="2" class="has-text-centered">Vehicle</th>
            <th>Sale Info</th>
            <th>Dates</th>
            <th>Shipping</th>
            <th>Ports</th>
            <th>Other</th>
            <th>Invoices</th>
            <th style="width: 100px">Actions</th>
          </tr>
        </thead>
        <tbody>
          <AutoRow
            v-for="a in autos"
            :key="a"
            :auto="a"
            @viewAutoDetails="openViewAutoModal"
            @viewMessages="openViewMessagesModal"
            @viewUpdates="openUpdatesMessagesModal"
            @changeDestination="openNewDestinationModal"
            @delete="handleDelete"
            @hold="toggleHold"
            @createInvoice="openInvoiceModal"
          />
          <tr v-if="autos.length == 0">
            <td class="has-text-centered" colspan="10">No Data Available</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Paginating-->
    <nav
      class="pagination is-small is-centered mt-4"
      role="navigation"
      aria-label="pagination"
    >
      <!-- PREV Page-->
      <a
        class="pagination-previous"
        v-if="hasPrevPage"
        @click.prevent="changePage(prevPage)"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <a class="pagination-previous" disabled v-if="!hasPrevPage"
        ><i class="fa-solid fa-arrow-left mr-2"></i>Previous</a
      >
      <!-- NEXT Page-->
      <a
        class="pagination-next"
        @click.prevent="changePage(nextPage)"
        v-if="hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <a class="pagination-next" disabled v-if="!hasNextPage"
        >Next page <i class="fa-solid fa-arrow-right ml-2"></i
      ></a>
      <ul class="pagination-list">
        <!-- FIRST Page-->
        <li>
          <a
            class="pagination-link"
            aria-label="Goto page 1"
            @click.prevent="changePage(1)"
            >First</a
          >
        </li>
        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- CURRENT Page-->
          <a
            class="pagination-link is-current"
            aria-label="Page 46"
            aria-current="page"
            >{{ page }}</a
          >
        </li>

        <li><span class="pagination-ellipsis">&hellip;</span></li>
        <li>
          <!-- LAST Page-->
          <a
            class="pagination-link"
            aria-label="Goto page 86"
            @click.prevent="changePage(totalPages)"
            >Last</a
          >
        </li>
      </ul>
    </nav>
    <AutoInfo
      v-if="viewAutoModalOpen"
      @closeModal="closeModal"
      :auto="openedAuto"
    />
    <MessagesModal
      v-if="viewMessagesModalOpen"
      @closeModal="closeModal"
      :auto="openedAuto"
      @changed="fetchAutos"
    />
    <UpdatesModal
      v-if="viewUpdatesModalOpen"
      @close="closeModal"
      :auto="openedAuto"
      @changed="fetchAutos"
    />
    <ChangeDestination
      v-if="viewNewDestination"
      @close="closeModal"
      :auto="openedAuto"
      @changed="fetchAutos"
    />
    <AddInvoiceModal
      v-if="viewNewInvoice"
      :auto_id="invoiceAutoId"
      @close="
        () => {
          this.viewNewInvoice = false;
          this.invoiceAutoId = null;
        }
      "
    />
  </div>
</template>
<script>
import AutosFilter from "@/views/Autos/AutosFilter.vue";
import AutoRow from "./AutoRow.vue";
import AutoInfo from "./AutoInfo.vue";
import MessagesModal from "./MessagesModal.vue";
import UpdatesModal from "./UpdatesModal.vue";
import ChangeDestination from "./ChangeDestination.vue";
import AddInvoiceModal from "./AddInvoiceModal";
import dayjs from "dayjs";
export default {
  data() {
    return {
      isLoading: false,
      modalOpen: false,
      currentlyOpened: {},
      errorMessage: "",
      successMessage: "",
      errorAlert: false,
      successAlert: false,
      autos: [],
      page: this.$route.query.page || 1,
      prevPage: null,
      hasPrevPage: null,
      totalAutos: null,
      nextPage: null,
      hasNextPage: null,
      totalPages: null,
      error: null,
      openedAuto: null,
      viewAutoModalOpen: false,
      viewMessagesModalOpen: false,
      viewUpdatesModalOpen: false,
      viewNewDestination: false,
      viewNewInvoice: false,
      invoiceAutoId: null,
    };
  },
  components: {
    AutosFilter,
    AutoRow,
    AutoInfo,
    MessagesModal,
    UpdatesModal,
    ChangeDestination,
    AddInvoiceModal,
  },
  watch: {
    page(p) {
      this.fetchAutos();
    },
  },
  created() {
    // initial fetch
    this.fetchAutos();
  },
  methods: {
    // grab autos
    fetchAutos(q) {
      this.isLoading = true;
      if (!q) {
        q = this.$route.query;
      }
      // fetch autos
      this.$axios
        .post(
          "/api/retrieve_autos",
          { page: Number(this.page), query: q },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.error = null;
          this.autos = res.data.autos.map((a) => {
            if (a.purchase_date) {
              a.purchase_date = dayjs(a.purchase_date)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            if (a.title_received) {
              a.title_received = dayjs(a.title_received)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            if (a.delivery_date) {
              a.delivery_date = dayjs(a.delivery_date)
                .add(1, "day")
                .format("YYYY-MM-DD");
            }
            if (a.created_at) {
              a.created_at = dayjs(a.created_at).format("YYYY-MM-DD hh:mm A");
            }
            return a;
          });
          this.totalPages = res.data.totalPages;
          this.totalAutos = res.data.totalAutos;
          this.nextPage = res.data.nextPage;
          this.prevPage = res.data.prevPage;
          this.hasNextPage = res.data.hasNextPage;
          this.hasPrevPage = res.data.hasPrevPage;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          this.error = err.response.data.msg;
        });
    },
    // Change Page
    changePage(number) {
      this.page = number;
      this.$router.push({
        path: this.$route.path,
        query: { ...this.$route.query, page: number },
      });
    },
    // open View Auto Modal
    openViewAutoModal(auto) {
      this.openedAuto = auto;
      this.viewAutoModalOpen = true;
    },
    openViewMessagesModal(auto) {
      this.openedAuto = auto;
      this.viewMessagesModalOpen = true;
    },
    openUpdatesMessagesModal(auto) {
      this.openedAuto = auto;
      this.viewUpdatesModalOpen = true;
    },
    openNewDestinationModal(auto) {
      this.openedAuto = auto;
      this.viewNewDestination = true;
    },
    closeModal() {
      this.viewAutoModalOpen = false;
      this.viewMessagesModalOpen = false;
      this.viewUpdatesModalOpen = false;
      this.viewNewDestination = false;
      this.opened = null;
    },
    handleSearch(q) {
      this.fetchAutos(q);
    },
    handleDelete(id) {
      if (
        confirm(
          "Are you sure you want to delete this vehicle? This action can't be undone."
        )
      ) {
        this.$axios
          .get(`/api/autos/delete_auto/${id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchAutos();
          })
          .catch((err) => {
            alert(err.response.data.msg);
          });
      }
    },
    toggleHold(id) {
      this.$axios
        .get(`/api/autos/toggleHold/${id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.fetchAutos();
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
    openInvoiceModal(id) {
      this.invoiceAutoId = id;
      this.viewNewInvoice = true;
    },
  },
};
</script>
<style scoped>
.featured {
  margin: 20px 0px;
  border: 1px solid #ddd;
  padding: 10px;
}
.add_auto_button {
  display: inline-block;
}
</style>
