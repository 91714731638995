<template>
  <div class="modal is-active">
    <page-loader :isLoading="isLoading"></page-loader>
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-card">
      <section class="modal-card-body">
        <h1 class="subtitle">Linked Clients</h1>
        <hr />
        <table class="table is-fullwidth">
          <thead>
            <tr class="has-background-light">
              <th>Name</th>
              <th>Company</th>
              <th>Display Name</th>
              <th>Remove</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="clients.length <= 0">
              <td colspan="4" class="has-text-centered">No Linked Clients</td>
            </tr>
            <tr v-for="c in clients" :key="c._id">
              <td>{{ c.name }}</td>
              <td>{{ c.company }}</td>
              <td>{{ c.display_name }}</td>
              <td>
                <button
                  class="button is-danger is-small"
                  @click.stop.prevent="removeClient(c._id)"
                >
                  Remove
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <h1 class="subtitle">Link a Client</h1>
        <table class="table is-fullwidth">
          <thead>
            <tr class="has-background-light">
              <th></th>
              <th>Name</th>
              <th>Company</th>
              <th>Display Name</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="clients_list.length <= 0">
              <td colspan="4" class="has-text-centered">No Clients</td>
            </tr>
            <tr v-for="c in clients_list" :key="c._id">
              <td>
                <input type="checkbox" v-model="selected" :value="c._id" />
              </td>
              <td>{{ c.name }}</td>
              <td>{{ c.company }}</td>
              <td>{{ c.display_name }}</td>
            </tr>
          </tbody>
        </table>
        <button class="button is-primary" @click.stop.prevent="handleSubmit">
          Submit
        </button>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    id: String,
  },
  data() {
    return {
      isLoading: false,
      clients: [],
      clients_list: [],
      selected: [],
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData() {
      this.isLoading = true;
      this.$axios
        .post(`/api/quotes/${this.id}`, {}, { headers: this.$authHeader() })
        .then((res) => {
          this.clients = res.data.quote[0].clients;
          let ids = this.clients.map((v) => v._id);
          this.$axios
            .post(`/api/retrieve_clients`, {}, { headers: this.$authHeader() })
            .then((res) => {
              this.clients_list = res.data.clients.filter((v) => {
                return !ids.includes(v._id);
              });
              this.isLoading = false;
            })
            .catch((err) => {
              alert("Something went wrong while retrieving clients");
            });
        })
        .catch((err) => {
          alert("Something went wrong while retrieving the quote info.");
        });
    },
    handleSubmit() {
      this.$axios
        .post(
          "/api/add_clients_to_quote",
          { id: this.id, clients: this.selected },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.selected = [];
          this.fetchData();
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
    removeClient(id) {
      this.$axios
        .post(
          "/api/remove_client_from_quote",
          { quote_id: this.id, client_id: id },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.fetchData();
        })
        .catch((err) => {
          alert(err.response.data.msg);
        });
    },
  },
};
</script>
