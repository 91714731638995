import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
  state: {
    authUser: null,
    navActive: true,
    filterExpanded: false,
    containerFilterExpanded: false
  },
  getters: {},
  mutations: {
    // Authenticate User
    authenticate_user(state, user) {
      state.authUser = user;
    },
    // Toggle Nav
    toggle_nav(state) {
      state.navActive = !state.navActive;
    },
    // Hide Nav
    hide_nav(state) {
      state.navActive = false;
    },
    // Expand Filter (autos page)
    expand_filter(state) {
      state.filterExpanded = !state.filterExpanded;
    },
    // Expand Filter (containers page)
    expand_container_filter(state) {
      state.containerFilterExpanded = !state.containerFilterExpanded;
    },
    // logout
    log_out(state) {
      state.authUser = "";
    },
    // change logo (when client updates settings)
    change_logo(state, logo) {
      state.authUser.logo = logo;
    },
  },
  actions: {
    // authenticate User
    authenticateUser({ commit }, user) {
      commit("authenticate_user", user);
    },
  },
  modules: {},
  plugins: [
    createPersistedState({
      storage: localStorage,
    }),
  ],
});
