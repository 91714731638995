<template>
  <page-loader :isLoading="isLoading"></page-loader>

  <form class="container">
    <h1 class="subtitle">Add a New Announcement</h1>
    <hr />
    <div class="notification is-danger" v-if="error">
      {{ errorMessage }}
    </div>
    <div class="notification is-success" v-if="success">
      {{ successMessage }}
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Title</label>
        <input
          type="text"
          class="input"
          v-model="title"
          placeholder="Enter the Title of the Announcement"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Content</label>
        <textarea
          id="sample"
          v-model="body"
          placeholder="Enter the text content for the announcement"
          rows="10"
        ></textarea>
      </div>
    </div>
    <button class="button is-primary" @click.stop.prevent="handleSubmit">
      Publish
    </button>
  </form>
</template>
<script>
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
let editor;
export default {
  data() {
    return {
      isLoading: false,
      title: "",
      body: "",
      error: false,
      success: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  methods: {
    handleSubmit() {
      let body = editor.getContents();
      this.body = body;
      this.isLoading = true;
      if (!this.title || !editor.getText().trim()) {
        this.error = true;
        (this.success = false),
          (this.errorMessage = "Please fill out both inputs");
        this.isLoading = false;
        return;
      }
      // send request to add
      this.$axios
        .post(
          "/api/add_announcement",
          { title: this.title, body: this.body },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          (this.title = ""), (this.body = "");
          editor.setContents("");
          this.success = true;
          (this.error = false), (this.successMessage = res.data.msg);
          this.isLoading = false;
        })
        .catch((err) => {
          this.success = false;
          (this.error = true), (this.errorMessage = err.response.data.msg);
          this.isLoading = false;
        });
    },
  },
  mounted() {
    editor = suneditor.create("sample", {
      plugins: plugins,
      buttonList: [["link", "bold", "strike", "italic"]],
    });
    editor.setDefaultStyle("font-family: Arial; font-size: 13px;");
  },
};
</script>
<style scoped>
@import "suneditor/dist/css/suneditor.min.css";

form {
  max-width: 600px;
  margin: auto;
}
#sample {
  width: 100%;
}
</style>
