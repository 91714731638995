<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('closed')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">New Payment</p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('closed')"
        ></button>
      </header>
      <section class="modal-card-body">
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <div class="field">
          <label class="label"
            >Amount <span class="has-text-danger">*</span>
          </label>
          <input
            type="number"
            class="input"
            placeholder="Amount"
            v-model="amount"
          />
        </div>
        <div class="field">
          <label class="label"
            >Date <span class="has-text-danger">*</span></label
          >
          <DatePicker
            v-model="date"
            placeholder="Pick a Date"
            format="yyyy-MM-dd"
            :enableTimePicker="false"
            :autoApply="true"
          />
        </div>
        <div class="field">
          <label class="label"
            >Client <span class="has-text-danger">*</span></label
          >
          <v-select
            v-model="client"
            placeholder="Client"
            :options="clients"
          ></v-select>
        </div>
        <div class="field">
          <label class="label"
            >Payment Type <span class="has-text-danger">*</span></label
          >
          <div class="select is-fullwidth">
            <select v-model="type">
              <option value="payment" selected>Payment</option>
              <option value="credit">Credit</option>
            </select>
          </div>
        </div>
        <div class="field">
          <label class="label"
            >Payment Method <span class="has-text-danger">*</span></label
          >
          <v-select
            v-model="payment_method"
            placeholder="Payment Method"
            :options="[
              'Cash',
              'Check',
              'Credit Card',
              'Debit Card',
              'Mobile Payment',
              'Elctronic Bank Transfer',
              'Wire Transfer',
            ]"
          ></v-select>
        </div>
        <div class="field">
          <label class="label"
            >Payment Currency <span class="has-text-danger">*</span></label
          >
          <v-select
            v-model="currency"
            placeholder="Payment Currency"
            :options="currencies"
          ></v-select>
        </div>
        <div class="field">
          <label class="label">Notes</label>
          <textarea
            class="textarea"
            v-model="notes"
            placeholder="Notes"
          ></textarea>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button is-success" @click.stop.prevent="handleSubmit">
          Submit
        </button>
        <button class="button" @click="$emit('closed')">Cancel</button>
      </footer>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      amount: null,
      date: null,
      currency: null,
      currencies: [],
      client: null,
      clients: [],
      payment_method: null,
      type: "payment",
      notes: null,
      error: null,
    };
  },
  created() {
    // fetch required selections
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        let a = [];
        res.data.clients.map((c) => {
          a.push({ label: c.company, code: c._id });
        });
        this.clients = a;
        // fetch currencies
        this.$axios
          .post(
            "/api/retrieve_user_settings",
            {},
            { headers: this.$authHeader() }
          )
          .then((res) => {
            this.currencies = res.data.settings.currencies;
          })
          .catch((err) => {
            this.error = err.response.data.msg;
          });
      });
  },
  methods: {
    handleSubmit() {
      // check for valid amount
      if (this.amount <= 0) {
        return (this.error = "Invalid Amount");
      }
      // checkk for required inputs
      if (
        !this.amount ||
        !this.date ||
        !this.client ||
        !this.type ||
        !this.payment_method ||
        !this.currency
      ) {
        return (this.error = "Please fill out all the required fields");
      }
      // submit payment
      this.$axios
        .post(
          "/api/new_payment",
          {
            amount: this.amount,
            date: this.date,
            client: this.client.code,
            type: this.type,
            payment_method: this.payment_method,
            currency: this.currency,
            notes: this.notes,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.$router.push(
            `/admin/dashboard/transaction/${res.data.transaction_id}`
          );
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
    },
  },
};
</script>
<style scoped>
.modal,
.modal-card,
.modal-card-body {
}
</style>
