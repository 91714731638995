<template>
  <aside :class="`${this.$store.state.navActive ? '' : 'nav-toggled'}`">
    <div class="links">
      <router-link to="/admin/dashboard/overview" class="link"
        ><i class="fa-solid fa-chart-pie"></i>Overview</router-link
      >
      <router-link to="/admin/dashboard/autos" class="link"
        ><i class="fa-solid fa-car"></i>Autos</router-link
      >
      <router-link to="/admin/dashboard/parts" class="link"
        ><i class="fa-solid fa-boxes-stacked"></i>Other Cargo</router-link
      >
      <router-link to="/admin/dashboard/containers" class="link"
        ><i class="fa-solid fa-box"></i>Containers</router-link
      >
      <router-link to="/admin/dashboard/loading_lists" class="link"
        ><i class="fa-solid fa-clipboard-list"></i>Loading Lists</router-link
      >
      <router-link to="/admin/dashboard/clients" class="link"
        ><i class="fa-solid fa-user-group"></i>Clients</router-link
      >
      <router-link to="/admin/dashboard/dispatch" class="link"
        ><i class="fa-solid fa-truck-arrow-right"></i>Dispatch</router-link
      >
      <router-link to="/admin/dashboard/balance?type=due" class="link"
        ><i class="fa-solid fa-hand-holding-dollar"></i>Balance</router-link
      >
      <router-link to="/admin/dashboard/payments" class="link"
        ><i class="fa-solid fa-money-check-dollar"></i>Payments</router-link
      >
      <router-link to="/admin/dashboard/messages" class="link"
        ><i class="fa-regular fa-message"></i>Messages
        <span class="tag is-danger" v-if="messageCount > 0">{{
          messageCount
        }}</span>
      </router-link>
      <router-link
        to="/admin/dashboard/admins"
        class="link"
        v-if="this.$store.state.authUser.user_type == 'account_owner'"
        ><i class="fa-solid fa-users-gear"></i>Admins</router-link
      >
      <router-link to="/admin/dashboard/announcements" class="link"
        ><i class="fa-solid fa-bullhorn"></i>Announcements</router-link
      >
      <router-link to="/admin/dashboard/forms" class="link"
        ><i class="fa-brands fa-wpforms"></i>Docs & Forms</router-link
      >
      <router-link
        v-if="this.$store.state.authUser.user_type == 'account_owner'"
        to="/admin/dashboard/settings/selections"
        class="link"
        ><i class="fa-solid fa-gears"></i>Settings</router-link
      >
      <router-link to="/admin/dashboard/quotes" class="link"
        ><i class="fa-solid fa-clipboard-list"></i>Quotes</router-link
      >
      <router-link
        to="/admin/dashboard/shippers_consignees?contact_type=Consignee"
        class="link"
        ><i class="fa-solid fa-users-rectangle"></i>Shipping Info</router-link
      >
      <router-link to="/admin/dashboard/buyers" class="link"
        ><i class="fa-regular fa-rectangle-list"></i>Buyer Accounts</router-link
      >

      <hr />
      <a @click="logOut" class="link"
        ><i class="fa-solid fa-arrow-right-from-bracket"></i>Logout</a
      >
    </div>
  </aside>
</template>
<script>
export default {
  data() {
    return {
      messageCount: 0,
    };
  },
  created() {
    if (window.innerWidth < 600) {
      this.$store.commit("hide_nav");
    }
    // get messages count
    this.$axios
      .get("/api/get_messages_count_admin", { headers: this.$authHeader() })
      .then((res) => {
        this.messageCount = res.data.count;
      });
  },
  methods: {
    logOut() {
      localStorage.removeItem("token");
      this.$store.commit("log_out");
      this.$router.push("/login");
    },
  },
};
</script>
<style scoped>
aside {
  width: 260px;
  height: 100vh;
  position: fixed;
  padding: 0;
  transition: 500ms;
  top: 0;
  overflow-y: scroll !important;
  background: #2f2c2c;
}

.links {
  margin-top: 100px;
  transition: 100ms;
}
.link {
  width: 80%;
  font-size: 0.8rem;
  padding: 8px 12px;
  color: #fff;
  display: block;
  margin: auto;
  border-radius: 5px;
}
.link i {
  margin-right: 10px;
  width: 23px;
  color: #fff;
  font-size: 1.1rem;
}
.link:hover {
  background: #eee;
  cursor: pointer;
  color: #333;
}
.link:hover i {
  color: #333;
}
.router-link-active {
  background: #eee;
  color: #333;
}
.router-link-active i {
  color: #333;
}
.nav-toggled {
  margin-left: -260px;
}
.nav-toggled #close {
  border: none;
  top: 0px;
  left: 0px;
}
.footer {
  text-align: center;
  background: #333;
}
.footer img {
  margin-top: 15px;
  max-width: 100px;
}
.tag.is-danger {
  padding: 0px 8px;
}
hr {
  background: #ddd;
  height: 1px;
  width: 90%;
  margin: 20px auto;
}
@media (max-width: 600px) {
  aside {
    width: 100%;
    position: fixed;
    overflow: auto;
    padding-bottom: 200px !important;
    z-index: 9;
  }
  .links {
    margin-top: 70px;
  }
  .nav-toggled {
    margin-left: 0px;
    display: none;
  }
  hr {
    margin: 0;
  }
  .link {
    width: 100%;
    border-radius: 0;
    padding-left: 30px;
    cursor: pointer;
  }
}
</style>
