<template>
  <div class="modal is-active">
    <div
      class="modal-background"
      @click="
        () => {
          $emit('closed');
        }
      "
    ></div>
    <div class="modal-content">
      <div class="box">
        <table class="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>View</th>
              <th width="120px">Applied Amount</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="p in payments" :key="p._id">
              <td>
                <router-link
                  :to="`/admin/dashboard/transaction/${p.transaction}`"
                >
                  View Payment
                </router-link>
              </td>
              <td>{{ p.amount }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="
        () => {
          $emit('closed');
        }
      "
    ></button>
  </div>
</template>
<script>
export default {
  props: {
    payments: Array,
  },
  data() {},
  created() {
    // fetch payments
  },
};
</script>
