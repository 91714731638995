<template>
  <div class="dock-receipt mb-4">
    <button
      @click.stop.prevent="print"
      class="button is-warning"
      v-tippy="{
        content: `Print`,
        placement: 'left',
      }"
    >
      <i class="fa-solid fa-print"></i>
    </button>
  </div>

  <div class="box dock-receipt" id="dock-receipt">
    <h1 class="has-text-centered has-text-weight-bold">Dock Receipt</h1>
    <hr />
    <table>
      <tr>
        <td colspan="2" rowspan="2" width="50%">
          <strong>SHIPPER/EXPORTER</strong>
          <textarea
            type="text"
            class="full"
            style="height: 140px"
            v-model="shipper"
          ></textarea>
        </td>
        <td>
          <strong>BOOKING NO.</strong>
          <p>{{ booking_number }}</p>
        </td>
      </tr>
      <tr>
        <td>
          <strong>EXPORT REFERENCES</strong>
          <textarea
            type="text"
            class="full"
            style="height: 60px"
            v-model="export_refrences"
          ></textarea>
        </td>
      </tr>
      <tr>
        <td rowspan="2" colspan="2">
          <strong>CONSIGNEE</strong>
          <textarea
            type="text"
            class="full"
            style="height: 150px"
            v-model="consignee"
          ></textarea>
        </td>
        <td>
          <strong>FORWARDING AGENT REFERENCES</strong>
          <textarea
            type="text"
            class="full"
            style="height: 110px"
            v-model="forwarder"
          ></textarea>
        </td>
      </tr>
      <tr>
        <td>
          <strong>POINT AND COUNTRY OF ORIGIN</strong>
          <p><input type="text" class="full" v-model="country" /></p>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <strong>NOTIFY PARTY</strong>
          <textarea
            type="text"
            class="full"
            style="height: 140px"
            v-model="notify"
          ></textarea>
        </td>
        <td rowspan="2">
          <strong>DOMESTIC ROUTING EXPORT INSTRUCTIONS</strong>
          <textarea
            type="text"
            class="full"
            style="height: 140px"
            v-model="domestic_routing"
          ></textarea>
        </td>
      </tr>
      <tr>
        <td colspan="2">
          <strong>PIER OR AIRPORT</strong>
          <p><input type="text" class="full" v-model="pier" /></p>
        </td>
      </tr>
      <tr>
        <td>
          <strong>EXPORTING CARRIER (Vessel / Airline)</strong>
          <p><input type="text" class="full" v-model="vessle" /></p>
        </td>
        <td>
          <strong>PORT OF LOADING</strong>
          <p><input type="text" class="full" v-model="pol" /></p>
        </td>
        <td>
          <strong>ONWARD INLAND ROUTING</strong>
          <p>
            <textarea
              type="text"
              class="full"
              style="height: 40px"
              v-model="inland_routing"
            ></textarea>
          </p>
        </td>
      </tr>
      <tr>
        <td>
          <strong>AIR/SEA PORT OF DISCHARGE</strong>
          <p><input type="text" class="full" v-model="pod" /></p>
        </td>
        <td>
          <strong>VOYAGE NO</strong>
          <p><input type="text" class="full" v-model="voyage" /></p>
        </td>
        <td>
          <strong>SAILING DATE</strong>
          <p><input type="text" class="full" v-model="sailing_date" /></p>
        </td>
      </tr>
    </table>
    <table>
      <tr style="border-top: 3px solid #000">
        <td class="has-text-centered" colspan="5">
          <strong>PARTICULARS FURNISHED BY SHIPPER</strong>
        </td>
      </tr>
      <tr>
        <td><strong>MARKS AND NUMBERS</strong></td>
        <td><strong>NO. OF PKGS.</strong></td>
        <td><strong>DESCRIPTION OF PACKAGES AND GOODS</strong></td>
        <td><strong>GROSS WEIGHT</strong></td>
        <td><strong>MEASUREMENTS</strong></td>
      </tr>
      <tr>
        <td class="has-text-centered">
          <textarea
            type="text"
            class="full"
            style="height: 350px"
            v-model="marks"
            disabled
          ></textarea>
        </td>
        <td class="has-text-centered">
          <textarea
            type="text"
            class="full"
            style="height: 350px"
            v-model="pkgs"
          ></textarea>
        </td>
        <td>
          <textarea
            type="text"
            class="full"
            style="height: 350px"
            v-model="description"
            disabled
          ></textarea>
        </td>
        <td>
          <textarea
            type="text"
            class="full"
            style="height: 350px"
            v-model="weight"
          ></textarea>
        </td>
        <td>
          <textarea
            type="text"
            class="full"
            style="height: 350px"
            v-model="measurements"
          ></textarea>
        </td>
      </tr>
    </table>
    <table>
      <tr>
        <td width="50%">
          <p>
            DELIVERED BY:<input
              type="text"
              style="display: inline-block"
              v-model="delivered_by"
              class="border_bottom"
            />
          </p>
          <p>
            LIGHTER TRUCK:<input
              type="text"
              style="display: inline-block"
              v-model="lighter_truck"
              class="border_bottom"
            />
          </p>
          <p>
            ARRIVED: DATE:<input
              type="text"
              style="display: inline-block"
              v-model="arrived_date"
              class="border_bottom"
            />
          </p>
          <p>
            UNLOADED:
            <input
              type="text"
              style="display: inline-block"
              v-model="unloaded"
              class="border_bottom"
            />
          </p>
          <p>
            CHECKED BY:
            <input
              type="text"
              style="display: inline-block"
              v-model="checked_by"
              class="border_bottom"
            />
          </p>
          <p>
            DATE:
            <input
              class="border_bottom"
              type="text"
              style="display: inline-block"
              v-model="date"
            />
          </p>
          <p>
            PLACED ON DOCK LOCATION:
            <input
              type="text"
              class="border_bottom"
              style="display: inline-block"
              v-model="dock_location"
            />
          </p>
        </td>
        <td>
          RECEIVED THE ABOVE DESCRIBED GOODS OR PACKAGES SUBJECT TO ALL THE
          TERMS OF THE UNDERSIGNED'S REGULAR FORM OF DOCK RECEIPT AND BILL OF
          LADING WHICH SHALL CONSTITUTE THE CONTRACT UNDER WHICH THE GOODS ARE
          RECEIVED, COPIES OF WHICH ARE AVAILABLE FROM THE CARRIER ON REQUEST
          AND MAY BE INSPECTED AT ANY OF ITS OFFICES
          <p class="mt-2">FOR THE MASTER</p>
          <p>
            BY:
            <input
              type="text"
              style="display: inline-block"
              class="border_bottom"
              v-model="by"
            />
          </p>
        </td>
      </tr>
    </table>
  </div>
</template>
<script>
window.$ = window.jQuery = require("jquery");
const printThis = require("@/assets/printThis");
export default {
  data() {
    return {
      shipper: null,
      booking_number: "",
      consignee: null,
      export_refrences: null,
      forwarder: null,
      country: null,
      notify: null,
      pier: null,
      domestic_routing: null,
      inland_routing: null,
      vessle: null,
      pod: null,
      pol: null,
      voyage: null,
      delivered_by: null,
      lighter_truck: null,
      arrived_date: null,
      unloaded: null,
      checked_by: null,
      date: null,
      dock_location: null,
      by: null,
      sailing_date: "",
      shippers: [],
      consignees: [],
      notifies: [],
      forwarders: [],
      container_number: null,
      seal: null,
      size: null,
      description: "",
      pkgs: null,
      marks: "",
      measurements: "",
      weight: "",
      saving: false,
      modalOpen: false,
      contact_type: "",
    };
  },
  components: {},
  created() {
    // fetch container
    this.fetchContainer();
  },

  methods: {
    print() {
      let temp = document.title;
      document.title = this.container_number || this.booking_number;
      window
        .$("#dock-receipt")
        .printThis()
        .then((done) => {
          document.title = temp;
        });
    },
    fetchContainer() {
      this.$axios
        .get(`/api/retrieve_container_client/${this.$route.params.id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          let c = res.data.container;
          this.booking_number = c.booking_number;
          this.pol = c.loading_point;
          this.pod = c.destination;
          this.container_number = c.container_number;
          this.seal = c.seal;
          this.size = c.size;
          this.autos = c.autos;
          this.parts = c.parts;
          this.marks = `${c.container_number} \nSeal#${c.seal || ""} \nSize: ${
            c.size || ""
          } `;
          this.description = "";
          c.autos.map((a) => {
            this.description += `-${a.year} ${a.make} ${a.model} vin#${a.vin} color: ${a.color} \n`;
          });
          c.parts.map((p) => {
            this.description += `-${p.description} \n`;
          });
          if (c.dock_receipt) {
            this.pier = c.dock_receipt.pier;
            this.export_refrences = c.dock_receipt.export_refrences;
            this.country = c.dock_receipt.country;
            this.domestic_routing = c.dock_receipt.domestic_routing;
            this.vessle = c.dock_receipt.vessle;
            this.pol = c.dock_receipt.pol;
            this.pod = c.dock_receipt.pod;
            this.inland_routing = c.dock_receipt.inland_routing;
            this.sailing_date = c.dock_receipt.sailing_date;
            this.voyage = c.dock_receipt.voyage;
            this.pkgs = c.dock_receipt.pkgs;
            this.weight = c.dock_receipt.weight;
            this.measurements = c.dock_receipt.measurements;
            this.delivered_by = c.dock_receipt.delivered_by;
            this.lighter_truck = c.dock_receipt.lighter_truck;
            this.arrived_date = c.dock_receipt.arrived_date;
            this.unloaded = c.dock_receipt.unloaded;
            this.checked_by = c.dock_receipt.checked_by;
            this.date = c.dock_receipt.date;
            this.dock_location = c.dock_receipt.dock_location;
            this.by = c.dock_receipt.by;
          }
          if (c.shipper) {
            this.shipper = `${c.shipper.name}\n${c.shipper.contact}\n${
              c.shipper.address
            }\n${c.shipper.city},${c.shipper.country}\nEmail:${
              c.shipper.email
            } Tel:${c.shipper.phone} \n${
              c.shipper.tax_id ? `Tax ID:${c.shipper.tax_id}` : ""
            }`;
          }
          if (c.consignee) {
            this.consignee = `${c.consignee.name}\n${c.consignee.contact}\n${
              c.consignee.address
            }\n${c.consignee.city},${c.consignee.country}\nEmail:${
              c.consignee.email
            } Tel:${c.consignee.phone} \n${
              c.consignee.tax_id ? `Tax ID:${c.consignee.tax_id}` : ""
            }`;
          }
          if (c.forwarder) {
            this.forwarder = `${c.forwarder.name}\n${c.forwarder.contact}\n${
              c.forwarder.address
            }\n${c.forwarder.city},${c.forwarder.country}\nEmail:${
              c.forwarder.email
            } Tel:${c.forwarder.phone} \n${
              c.forwarder.tax_id ? `Tax ID:${c.forwarder.tax_id}` : ""
            }`;
          }
          if (c.notify) {
            this.notify = `${c.notify.name}\n${c.notify.contact}\n${
              c.notify.address
            }\n${c.notify.city},${c.notify.country}\nEmail:${
              c.notify.email
            } Tel:${c.notify.phone} \n${
              c.notify.tax_id ? `Tax ID:${c.notify.tax_id}` : ""
            }`;
          }
          Array.from(document.getElementsByTagName("input")).map((e) => {
            e.disabled = true;
          });
          Array.from(document.getElementsByTagName("textarea")).map((e) => {
            e.disabled = true;
          });
        });
    },
  },
};
</script>
<style scoped>
.dock-receipt {
  width: 1100px;
  margin: auto;
}
table {
  width: 100%;
  border-collapse: collapse;
}
table * {
  color: #000;
}
td {
  border: 1px solid #000;
  padding: 1px;
}
colgroup {
  width: 50%;
}
table p {
  font-weight: bold;
  font-family: serif;
}
.border_bottom {
  border-bottom: 1px solid #000;
}
table input,
textarea {
  display: block;
  border: none;
  background: #eef5ff;
  outline: none;
  resize: none;
}
.full {
  width: 100%;
}
@media print {
  @page {
    margin: 15px;
  }
  .box {
    box-shadow: none !important;
    border: none;
  }
  body {
    background: #fff;
  }
  table input,
  table textarea {
    background: none;
  }
}
</style>
