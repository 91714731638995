let colors;
export default colors = [
    "Red",
    "Blue",
    "Green",
    "Orange",
    "White",
    "Black",
    "Yellow",
    "Purple",
    "Silver",
    "Brown",
    "Gray",
    "Pink",
    "Olive",
    "Maroon",
    "Violet",
    "Charcoal",
    "Magenta",
    "Bronze",
    "Cream",
    "Gold",
    "Tan",
    "Teal",
    "Mustard",
    "Navy Blue",
    "Coral",
    "Burgundy",
    "Lavender",
    "Mauve",
    "Peach",
    "Rust",
    "Indigo",
    "Ruby",
    "Clay",
    "Cyan",
    "Azure",
    "Beige",
    "Off White",
    "Turquoise",
    "Amber",
    "Mint",
];
