<template>
  <div style="border: none">
    <div class="columns mt-3">
      <div class="column">
        <h2 class="subtitle">Loading Points</h2>
      </div>
    </div>
    <div class="columns mt-1 loading_points">
      <page-loader :isLoading="isLoading"></page-loader>
      <div class="column is-3" v-for="lp in loading_points" :key="lp">
        <div class="card">
          <div class="card-content">
            <div class="content">
              <h1 class="subtitle is-6">
                {{ lp.loading_point
                }}<span class="has-text-danger">{{ lp.all }}</span>
              </h1>
              <hr />
              <p>
                <router-link
                  :to="`/admin/dashboard/autos/?loading_point=${lp.loading_point}&status=New purchase`"
                  >New Purchase :</router-link
                >
                <span class="has-text-danger">{{ lp.new_purchase }}</span>
              </p>
              <p>
                <router-link
                  :to="`/admin/dashboard/autos/?loading_point=${lp.loading_point}&status=Dispatched`"
                  >Dispatched :</router-link
                >
                <span class="has-text-danger">{{ lp.dispatched }}</span>
              </p>
              <p>
                <router-link
                  :to="`/admin/dashboard/autos/?loading_point=${lp.loading_point}&status=At terminal`"
                  >At Terminal :</router-link
                >
                <span class="has-text-danger">{{ lp.arrived }}</span>
              </p>
              <p>
                <router-link
                  :to="`/admin/dashboard/autos/?loading_point=${lp.loading_point}&status=Left terminal`"
                  >Left Terminal :</router-link
                >
                <span class="has-text-danger">{{ lp.left }}</span>
              </p>
              <p>
                <router-link
                  :to="`/admin/dashboard/autos/?loading_point=${lp.loading_point}&status=Loaded`"
                  >Loaded :</router-link
                >
                <span class="has-text-danger">{{ lp.loaded }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading_points: [],
      isLoading: true,
    };
  },
  created() {
    this.$axios
      .get("/api/get_loading_points_stats", {
        headers: this.$authHeader(),
      })
      .then((res) => {
        this.loading_points = res.data.result;
        this.isLoading = false;
      })
      .catch((err) => {
        this.isLoading = false;
      });
  },
};
</script>
<style scoped>
.card {
  border-radius: 8px;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-top: 4px solid #18ae93;
  color: #666;
  -webkit-box-shadow: 1px 2px 5px 2px rgba(163, 163, 163, 0.48);
  box-shadow: 1px 2px 5px 2px rgba(163, 163, 163, 0.48);
}
.card:hover {
  background-color: #f9f9f9;
}
.card span {
  float: right;
  font-weight: bold;
}
.columns {
  overflow: auto;
}
.loading_points {
  position: relative;
}
a {
  color: #666;
}
a:hover {
  text-decoration: underline;
}
</style>
