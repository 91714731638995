<template>
  <div class="tracking-info container">
    <page-loader :isLoading="isLoading" />

    <h1 class="title">
      Tracking
      <i
        class="fa-solid fa-circle-xmark is-clickable is-size-4 is-pulled-right"
        @click="$router.go(-1)"
      ></i>
    </h1>
    <hr />
    <div class="notification is-danger" v-if="error">{{ error }}</div>
    <div class="container-info box">
      <div class="container-number">
        <b>{{ container.number }}</b>
      </div>
      <hr />
      <div class="columns">
        <div class="column">
          <p>
            <b>{{
              prepol.location_name || pol.location_name || "POL Unavailable"
            }}</b>
          </p>
          <p>
            <small>{{ events.length > 0 ? events[0].date : "N/A" }}</small>
          </p>
        </div>
        <div class="column">
          <p>
            <b>{{
              postpod.location_name || pod.location_name || "POD Unavailable"
            }}</b>
          </p>
          <p>
            <small>{{ pod.date || "N/A" }}</small>
          </p>
        </div>
      </div>
    </div>
    <hr />
    <h1 class="subtitle">Activity</h1>
    <div class="activity">
      <div
        class="a"
        v-for="(a, i) in events"
        :key="i"
        :class="a.actual ? 'done' : ''"
      >
        <p :class="a.actual ? '' : 'light'">
          <b :class="a.actual ? '' : 'light'">{{ a.location_name }}</b>
        </p>
        <p :class="a.actual ? '' : 'light'">
          {{ a.description }} - {{ a.date }}
        </p>
        <p :class="a.actual ? '' : 'light'">
          <span v-if="a.vessel">Vessel: {{ a.vessel }}</span>
        </p>
      </div>
      <p v-if="events.length == 0">No Events to Show</p>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      trackingInfo: {},
      container: {},
      events: [],
      pol: {},
      pod: {},
      prepol: {},
      postpod: {},
      error: null,
      container_number: this.$route.params.container_number,
      isLoading: true,
    };
  },
  created() {
    // get container tracking info
    this.$axios
      .get(`/api/track_container/${this.container_number}`, {
        headers: this.$authHeader(),
      })
      .then((res) => {
        this.isLoading = false;
        let data = res.data.data.data;
        if (data && data.container) {
          this.events = data.container.events;
          this.container = data.container;
          // add location name to events,pod,pol
          this.events.map((e) => {
            // add locations
            data.locations.map((location) => {
              if (location.id == e.location) {
                e.location_name = location.name + ", " + location.country;
              }
              if (location.id == data.route.pod.location) {
                data.route.pod.location_name = location.name;
              }
              if (location.id == data.route.prepol.location) {
                data.route.prepol.location_name = location.name;
              }
              if (location.id == data.route.pol.location) {
                data.route.pol.location_name = location.name;
              }
              if (location.id == data.route.postpod.location) {
                data.route.postpod.location_name = location.name;
              }
            });
            this.pol = data.route.pol;
            this.pod = data.route.pod;
            this.prepol = data.route.prepol;
            this.postpod = data.route.postpod;
            // add vessles
            data.vessels.map((v) => {
              if (v.id == e.vessel) {
                e.vessel = v.name;
              }
            });
          });
        }
      })
      .catch((err) => {
        alert("Something went wrong while tracking this container");
        this.isLoading = false;
        this.error = err.response.data.msg;
      });
  },
};
</script>
<style>
.tracking-info {
  width: 100%;
  height: 100vh;
  padding: 20px;
  padding: 25px;
  overflow: scroll;
}
.done {
  border-left: 6px solid #00b9a1;
}

.container-info {
  border: 1px solid #ddd;
}
.a {
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  padding: 15px;
  border-radius: 5px;
  margin-top: 15px;
  background: #fff;
}
b,
p {
  color: #000;
}
.light {
  color: rgb(129, 129, 129);
}
</style>
