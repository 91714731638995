<template>
  <client-side-nav />
  <div
    :class="`${
      this.$store.state.navActive
        ? 'content_container'
        : 'content_container push_container'
    }`"
  >
    <client-top-nav />
    <div class="mt-6">
      <router-view></router-view>
    </div>
  </div>
</template>
<script>
import ClientSideNav from "../ClientSideNav";
import ClientTopNav from "../ClientTopNav.vue";
export default {
  components: { ClientSideNav, ClientTopNav },
};
</script>
