<template>
  <div class="box">
    <page-loader :isLoading="isLoading"></page-loader>
    <h1 class="subtitle">Announcements</h1>
    <hr />
    <router-link to="/admin/dashboard/announcements/add"
      ><button class="button is-primary">
        New <i class="fa-solid fa-plus ml-3"></i></button
    ></router-link>
    <div class="notification is-danger mt-3" v-if="error">
      {{ errorMessage }}
    </div>
    <div class="notification is-success mt-3" v-if="success">
      {{ successMessage }}
    </div>
    <div class="table-wraper">
      <table class="table mt-5 is-fullwidth">
        <thead>
          <tr>
            <th>Title</th>
            <th>Body</th>
            <th style="width: 250px">Date Published</th>
            <th style="width: 150px">Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td
              colspan="4"
              class="has-text-centered"
              v-if="announcements.length === 0"
            >
              No Files
            </td>
          </tr>
          <tr v-for="a in announcements" :key="a._id">
            <td>{{ a.title }}</td>
            <td v-html="a.body"></td>
            <td>{{ a.created_at }}</td>
            <td>
              <button
                class="button is-danger is-small mr-2"
                v-tippy="{
                  content: `Delete`,
                  placement: 'bottom',
                }"
                @click.stop.prevent="deleteAnnouncement(a._id)"
              >
                <i class="fa-solid fa-trash"></i>
              </button>
              <router-link
                :to="{
                  path: '/admin/dashboard/announcements/edit',
                  query: { id: a._id },
                }"
              >
                <button
                  class="button is-small"
                  v-tippy="{
                    content: `Edit`,
                    placement: 'bottom',
                  }"
                >
                  <i class="fa-solid fa-pen-to-square"></i></button
              ></router-link>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      isLoading: true,
      announcements: [],
      error: false,
      success: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  created() {
    this.fetchData();
  },
  methods: {
    deleteAnnouncement(id) {
      if (confirm("Are you sure you want to delete?")) {
        this.$axios
          .post(
            "/api/delete_announcement",
            { id },
            { headers: this.$authHeader() }
          )
          .then((res) => {
            this.fetchData();
            this.success = true;
            this.successMessage = res.data.msg;
            this.error = false;
          })
          .catch((err) => {
            this.success = false;
            this.errorMessage = err.response.data.msg;
          });
      }
    },
    fetchData() {
      this.isLoading = true;
      // retrieve announcements
      this.$axios
        .post(
          "/api/retrieve_announcements",
          {},
          {
            headers: this.$authHeader(),
          }
        )
        .then((res) => {
          // format date
          let a = res.data.announcements.map((v) => {
            v.created_at = dayjs(v.created_at).format("DD/MM/YYYY (h:mm A)");
            return v;
          });
          this.announcements = a;
          this.isLoading = false;
        })
        .catch((err) => {
          alert(err.response.data.msg);
          this.isLoading = false;
        });
    },
  },
};
</script>
