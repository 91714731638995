<template>
  <form class="filter mt-3">
    <h1 class="subtitle">
      Filters
      <button
        class="button is-small is-rounded is-pulled-right"
        type="button"
        @click.prevent="this.$store.commit('expand_filter')"
      >
        Expand Filters <i class="fa-solid fa-angles-down ml-2"></i>
      </button>
    </h1>

    <div class="columns is-variable is-1">
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="VIN (Full or last 6 digits)"
          v-model="vin"
          @keyup.enter="search"
        />
      </div>
      <div class="column">
        <input
          type="text"
          class="input"
          placeholder="Lot"
          v-model="lot"
          @keyup.enter="search"
        />
      </div>

      <div class="column">
        <v-select placeholder="Make" :options="makes" v-model="make"></v-select>
      </div>
      <div class="column">
        <v-select
          placeholder="Model"
          :options="models"
          v-model="model"
        ></v-select>
      </div>
      <div class="column">
        <v-select placeholder="Year" :options="years" v-model="year"></v-select>
      </div>
    </div>
    <div :class="`${!this.$store.state.filterExpanded ? 'hidden' : ''}`">
      <div :class="`columns is-variable is-1`">
        <div class="column">
          <v-select
            placeholder="Loading Point"
            :options="loading_points"
            v-model="loading_point"
          ></v-select>
        </div>
        <div class="column">
          <v-select
            placeholder="Destination"
            :options="destinations"
            v-model="destination"
          ></v-select>
        </div>
        <div class="column">
          <v-select
            placeholder="Status"
            :options="[
              'New purchase',
              'Dispatched',
              'At terminal',
              'Loaded',
              'Left terminal',
            ]"
            v-model="status"
          ></v-select>
        </div>
        <div class="column">
          <DatePicker
            v-model="purchase_date_from"
            placeholder="Purchased date (from)"
            format="yyyy-MM-dd"
            :enableTimePicker="false"
            :autoApply="true"
          />
        </div>
        <div class="column">
          <DatePicker
            v-model="purchase_date_to"
            placeholder="Purchased date (to)"
            format="yyyy-MM-dd"
            :enableTimePicker="false"
            :autoApply="true"
          />
        </div>
      </div>
      <div class="columns is-variable is-1">
        <div class="column">
          <v-select
            placeholder="Custom 1"
            :options="customs1"
            v-model="custom1"
            multiple
          ></v-select>
        </div>
        <div class="column">
          <v-select
            placeholder="Custom 2"
            :options="customs2"
            v-model="custom2"
            multiple
          ></v-select>
        </div>

        <div class="column">
          <v-select
            placeholder="Client"
            :options="clients"
            v-model="client"
          ></v-select>
        </div>
        <div class="column">
          <input
            type="text"
            class="input"
            v-model="container_number"
            placeholder="Container#"
            @keyup.enter="search"
          />
        </div>
        <div class="column">
          <v-select
            placeholder="Title Status"
            :options="['Present', 'Pending', 'No Title']"
            v-model="title_status"
          ></v-select>
        </div>
      </div>
      <div class="columns is-variable is-1">
        <div class="column">
          <v-select
            placeholder="Shipping Method"
            :options="['RoRo', 'Container']"
            v-model="shipping_method"
          ></v-select>
        </div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
        <div class="column"></div>
      </div>
    </div>

    <div class="mt-4 has-text-right">
      <div>
        <button class="button is-primary is-small" @click.prevent="search">
          Search
        </button>
        <button class="button is-danger ml-3 is-small" @click.prevent="reset">
          Clear
        </button>
      </div>
    </div>
  </form>
</template>
<script>
import makes from "@/components/helpers/makes";
import getYears from "@/components/helpers/getYears";
import dayjs from "dayjs";
export default {
  data() {
    return {
      vin: null,
      lot: null,
      container_number: null,
      auction: null,
      make: null,
      model: null,
      year: null,
      buyer_number: null,
      purchase_date_from: null,
      purchase_date_to: null,
      client: null,
      status: null,
      custom1: null,
      custom2: null,
      loading_point: null,
      destination: null,
      title_status: null,
      shipping_method: null,
      city: null,
      loading_points: [],
      destinations: [],
      makes: makes,
      models: [],
      clients: [],
      statuses: [],
      customs1: [],
      customs2: [],
      auctions: [],
      cities: [],
      years: getYears(),
    };
  },

  methods: {
    search() {
      let q = {};
      if (this.make) {
        q.make = this.make;
      }
      if (this.vin) {
        q.vin = this.vin;
      }
      if (this.model) {
        q.model = this.model;
      }
      if (this.shipping_method) {
        q.shipping_method = this.shipping_method;
      }
      if (this.title_status) {
        q.title_status = this.title_status;
      }
      if (this.lot) {
        q.lot = this.lot;
      }
      if (this.year) {
        q.year = this.year;
      }
      if (this.loading_point && this.loading_point.length !== 0) {
        q.loading_point = this.loading_point;
      }
      if (this.destination && this.destination.length !== 0) {
        q.destination = this.destination;
      }
      if (this.status) {
        q.status = this.status;
      }
      if (this.container_number) {
        q.container_number = this.container_number;
      }
      if (this.custom1 && this.custom1.length !== 0) {
        q.custom1 = this.custom1;
      }
      if (this.custom2 && this.custom2.length !== 0) {
        q.custom2 = this.custom2;
      }
      if (this.client) {
        q.client_id = this.client.code;
        q.client_company = this.client.label;
      }
      if (this.purchase_date_from) {
        q.purchase_date_from = dayjs(this.purchase_date_from).format(
          "YYYY-MM-DD"
        );
      }
      if (this.purchase_date_to) {
        q.purchase_date_to = dayjs(this.purchase_date_to).format("YYYY-MM-DD");
      }
      this.$router.push({
        path: "/admin/dashboard/autos",
        query: q,
      });
      this.$emit("search", q);
    },
    reset() {
      this.$router.push({
        path: "/admin/dashboard/autos",
        query: {},
      });
      this.$emit("search", {});
      (this.vin = null),
        (this.lot = null),
        (this.make = null),
        (this.year = null),
        (this.shipping_method = null),
        (this.container_number = null),
        (this.model = null),
        (this.destination = null),
        (this.status = null),
        (this.title_status = null),
        (this.purchase_date_from = null),
        (this.purchase_date_to = null),
        (this.custom1 = null),
        (this.custom2 = null),
        (this.loading_point = null),
        (this.client = null);
    },
  },
  watch: {
    make(make) {
      // fetch models when make changes
      this.$axios
        .get(
          `https://vpic.nhtsa.dot.gov/api/vehicles/GetModelsForMake/${make}?format=json`
        )
        .then((res) => {
          this.models = res.data.Results.map((model) => {
            return model.Model_Name;
          });
        });
    },
  },
  created() {
    // fill state with query values (to save filter after a page refresh)
    if (this.$route.query.vin) {
      this.vin = this.$route.query.vin;
    }
    if (this.$route.query.lot) {
      this.lot = this.$route.query.lot;
    }
    if (this.$route.query.client_id) {
      this.client = {
        label: this.$route.query.client_company,
        code: this.$route.query.client_id,
      };
    }
    if (this.$route.query.model) {
      this.model = this.$route.query.model;
    }
    if (this.$route.query.shipping_method) {
      this.shipping_method = this.$route.query.shipping_method;
    }
    if (this.$route.query.make) {
      this.make = this.$route.query.make;
    }
    if (this.$route.query.container_number) {
      this.container_number = this.$route.query.container_number;
    }
    if (this.$route.query.title_status) {
      this.title_status = this.$route.query.title_status;
    }
    if (this.$route.query.year) {
      this.year = this.$route.query.year;
    }
    if (this.$route.query.loading_point) {
      this.loading_point = this.$route.query.loading_point;
    }
    if (this.$route.query.destination) {
      this.destination = this.$route.query.destination;
    }
    this.status = this.$route.query.status;
    if (this.$route.query.purchase_date_from) {
      this.purchase_date_from = dayjs(
        this.$route.query.purchase_date_from
      ).format("YYYY-MM-DD");
    }
    if (this.$route.query.purchase_date_to) {
      this.purchase_date_to = dayjs(this.$route.query.purchase_date_to).format(
        "YYYY-MM-DD"
      );
    }
    if (this.$route.query.custom1) {
      this.custom1 = this.$route.query.custom1;
    }
    if (this.$route.query.custom2) {
      this.custom2 = this.$route.query.custom2;
    }

    // fetch selections
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.selections.map((s) => {
          if (s.type == "Cities") this.cities.push(s.value);
          if (s.type == "Custom Field 1") this.customs1.push(s.value);
          if (s.type == "Custom Field 2") this.customs2.push(s.value);
          if (s.type == "Loading Points") this.loading_points.push(s.value);
          if (s.type == "Destinations") this.destinations.push(s.value);
        });
      });
    // fetch clients
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.clients.map((c) => {
          this.clients.push({ code: c._id, label: c.company });
        });
      });
  },
};
</script>
<style>
.filter {
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 5px;
  background: #f7f8f9;
  border: 1px solid #ddd;
}

.filter form {
  margin-top: 25px;
}
.filter i {
  margin-right: 10px;
}

.filter .hidden {
  display: none;
}
.filter .columns .column {
  margin: 5px auto;
  padding: 0;
}
</style>
