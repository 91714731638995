<template>
  <div class="modal is-active">
    <div class="modal-background" @click="closeModal"></div>
    <div class="modal-content">
      <!-- Any other Bulma elements you want -->
      <div class="box">
        <div class="notification is-danger is-light" v-if="error">
          {{ errorMessage }}
        </div>
        <div class="notification is-success is-light" v-if="success">
          {{ successMessage }}
        </div>
        <div class="columns">
          <div class="column">
            <input
              type="text"
              class="input"
              v-model="invoice_field"
              placeholder="Enter Value"
            />
          </div>
          <div class="column is-2">
            <button
              type="submit"
              :class="`button is-primary ${isLoading ? 'is-loading' : ''}`"
              @click.stop.prevent="submit"
              @keyup.enter="submit"
            >
              Add
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="closeModal"
    ></button>
  </div>
</template>
<script>
export default {
  data() {
    return {
      invoice_field: "",
      error: false,
      success: false,
      errorMessage: "",
      successMessage: "",
      isLoading: false,
    };
  },
  methods: {
    closeModal() {
      this.$emit("closed");
    },
    submit() {
      if (!this.invoice_field) {
        this.error = true;
        this.errorMessage = "Please fill out the required inputs.";
        this.isLoading = false;
        return;
      }
      this.error = false;
      this.success = false;
      this.isLoading = true;
      this.$axios
        .post(
          "/api/settings/add_invoice_field",
          {
            invoice_field: this.invoice_field,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.success = true;
          this.successMessage = res.data.msg;
          this.isLoading = false;
        })
        .catch((err) => {
          this.error = true;
          this.errorMessage = err.response.data.msg;
          this.isLoading = false;
        });
    },
  },
};
</script>
