<template>
  <div class="box">
    <h1 class="title">Containers</h1>
    <hr />
    <router-link to="/admin/dashboard/containers/add"
      ><button class="button is-primary">New Container</button></router-link
    >
    <ContainersTable />
  </div>
</template>
<script>
import ContainersTable from "./ContainersTable.vue";
export default {
  data() {
    return {};
  },
  components: { ContainersTable },
  methods: {},
};
</script>
