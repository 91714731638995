<template>
  <div class="pt-6">
    <form
      class="box m-auto p-6"
      style="max-width: 500px"
      @submit.prevent="handleSubmit"
    >
      <div class="has-text-centered">
        <img
          :src="`/api/files/${logo}`"
          style="max-width: 100px"
          alt="Company's Logo"
          v-if="logo"
        />
      </div>
      <hr v-if="logo" />
      <h1 class="title is-4 has-text-centered">Sign in</h1>
      <!-- Alert Messages -->
      <div class="notification is-danger" v-if="errorAlert">
        {{ errorMessage }}
      </div>
      <div class="field">
        <label class="label">Username</label>
        <div class="control has-icons-left has-icons-right">
          <input
            class="input"
            type="text"
            placeholder="Enter your username"
            v-model="username"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-user"></i>
          </span>
        </div>
      </div>
      <div class="field">
        <label class="label">Password</label>
        <div class="control has-icons-left has-icons-right">
          <input
            class="input"
            type="password"
            placeholder="Enter your password"
            v-model="password"
          />
          <span class="icon is-small is-left">
            <i class="fas fa-lock"></i>
          </span>
        </div>
      </div>
      <button class="button is-primary is-rounded is-loading" v-if="isLoading">
        Login
      </button>
      <button class="button is-primary is-rounded" v-else>Login</button>
      <hr />
      <small
        ><a @click="() => (showModal = true)">Forget your password?</a></small
      >
    </form>
    <br />
    <div class="m-auto has-text-centered" style="max-width: 150px">
      <h4 class="subtitle is-6">Powered By</h4>
      <a href="https://www.otomago.com/"
        ><img :src="'/api/files/logo.png'" alt=""
      /></a>
    </div>
  </div>
  <ForgotPassword
    v-if="showModal"
    @close="
      () => {
        showModal = false;
      }
    "
  />
</template>
<script>
import ForgotPassword from "./ForgotPassword.vue";
export default {
  data() {
    return {
      username: "",
      password: "",
      errorAlert: false,
      successAlert: false,
      errorMessage: "",
      successMessage: "",
      isLoading: false,
      logo: null,
      showModal: false,
    };
  },
  created() {
    this.$axios
      .post("/api/get_customer_logo", {
        customer_id: Number(this.$route.query.customer_id),
      })
      .then((res) => {
        if (res.data.logo) {
          this.logo = res.data.logo;
        }
      });
  },
  methods: {
    handleSubmit() {
      this.isLoading = true;
      if (!this.password || !this.username) {
        this.errorAlert = true;
        this.errorMessage = "Please fill out the required inputs!";
        this.isLoading = false;
        return;
      }
      this.$axios
        .post("/api/login", {
          username: this.username,
          password: this.password,
        })
        .then((response) => {
          this.errorAlert = false;
          this.successMessage = response.data.msg;
          //save token
          localStorage.setItem("token", response.data.token);
          // save User is State
          this.$store.dispatch("authenticateUser", response.data.user);
          this.isLoading = false;
          if (response.data.type == "client") {
            this.$router.push("/client/dashboard/overview");
          } else if (response.data.type == "admin") {
            this.$router.push("/admin/dashboard/overview");
          }
        })
        .catch((err) => {
          this.errorAlert = true;
          this.errorMessage = err.response.data.msg;
          this.isLoading = false;
        });
    },
  },
  components: { ForgotPassword },
};
</script>
<style scoped>
@media (max-width: 600px) {
  .box {
    box-shadow: none;
  }
}
</style>
