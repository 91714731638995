<template>
  <page-loader :isLoading="pageLoading"></page-loader>
  <div class="box">
    <h1 class="title">Buyer Accounts</h1>
    <hr />
    <button class="button is-primary" @click.prevent="openAddBuyerModal">
      New
    </button>
    <div class="notification is-danger mt-5" v-if="error">{{ error }}</div>
    <div class="notification is-primary mt-5" v-if="success">{{ success }}</div>

    <table class="table mt-6 has-text-centered is-hoverable is-fullwidth">
      <thead>
        <tr>
          <th>Buyer Number</th>
          <th>Auction</th>
          <th>Client</th>
          <th width="250px">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="buyer in buyers" :key="buyer._id">
          <td>{{ buyer.buyer_number }}</td>
          <td>{{ buyer.auction }}</td>
          <td>{{ buyer.client ? buyer.client.company : "" }}</td>
          <td>
            <button
              class="button is-small is-danger"
              @click.stop.prevent="deleteBuyer(buyer._id)"
            >
              <i class="fa-regular fa-trash-can"></i>
            </button>
            <button
              class="button is-small ml-3"
              @click.stop.prevent="openEditBuyerModal(buyer._id)"
            >
              <i class="fa-regular fa-pen-to-square"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <AddBuyer v-if="addBuyerModalOpen" @close="closeModal" />
    <EditBuyer v-if="editBuyerModalOpen" @close="closeModal" :id="selected" />
  </div>
</template>
<script>
import AddBuyer from "@/views/Buyers/AddBuyer.vue";
import EditBuyer from "@/views/Buyers/EditBuyer.vue";

export default {
  components: { AddBuyer, EditBuyer },
  data() {
    return {
      addBuyerModalOpen: false,
      editBuyerModalOpen: false,
      buyers: [],
      pageLoading: true,
      error: null,
      success: null,
      selected: null,
    };
  },
  created() {
    // retrieve buyers
    this.fetchData();
  },
  methods: {
    closeModal() {
      this.addBuyerModalOpen = false;
      this.editBuyerModalOpen = false;
      this.selected = null;
      this.fetchData();
    },
    openAddBuyerModal() {
      this.addBuyerModalOpen = true;
    },
    openEditBuyerModal(id) {
      this.editBuyerModalOpen = true;
      this.selected = id;
    },
    fetchData() {
      this.pageLoading = true;
      this.$axios
        .post("/api/retrieve_buyers", {}, { headers: this.$authHeader() })
        .then((res) => {
          this.buyers = res.data.buyers;
          this.pageLoading = false;
        })
        .catch((err) => {
          this.pageLoading = false;
          this.error = err.response.data.msg || "Something went wrong";
        });
    },
    deleteBuyer(id) {
      if (confirm("Are your sure you want to delete this buyer?")) {
        this.$axios
          .post("/api/delete_buyer", { id }, { headers: this.$authHeader() })
          .then((res) => {
            this.buyers = res.data.buyers;
            this.success = res.data.msg;
            this.error = null;
            this.fetchData();
          })
          .catch((err) => {
            this.pageLoading = false;
            this.error = err.response.data.msg || "Something went wrong";
            this.success = null;
          });
      }
    },
  },
};
</script>
<style scoped>
.table td {
  padding: 5px 50px;
}
</style>
