<template>
  <div class="box">
    <page-loader :isLoading="isLoading"></page-loader>
    <h1 class="title">Messages</h1>
    <hr />
    <div>
      <div class="block">
        <v-select
          :options="[
            'Accounting',
            'Titles',
            'Bookings',
            'Loading',
            'Pictures',
            'Documentation',
            'Other',
          ]"
          v-model="department"
          placeholder="Department"
          style="max-width: 300px"
        ></v-select>
      </div>
      <div class="table-wraper">
        <table class="table is-fullwidth is-hoverable">
          <thead>
            <tr>
              <th>Category</th>
              <th>Auto</th>
              <th width="800px">Message</th>
              <th width="80px">View</th>
              <th width="50px">Read</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="message in messages" :key="message._id">
              <td>{{ message.department }}</td>
              <td>
                <b>{{
                  `${message.auto.year} ${message.auto.make} ${message.auto.model}`
                }}</b>
                <br />
                <b>{{
                  `VIN:${message.auto.vin} Lot#${message.auto.lot || ""}`
                }}</b>
              </td>
              <td>
                <p class="has-text-danger">Message:</p>
                {{ message.body }}
                <br />
                <a
                  v-if="message.attachment"
                  :href="`/api/files/${message.attachment}`"
                  target="_blank"
                  class="has-text-link"
                  >Attachment <i class="fa-solid fa-paperclip ml-1"></i
                ></a>
              </td>
              <td>
                <button
                  class="button is-warning is-small"
                  @click="openViewMessagesModal(message.auto)"
                >
                  <i class="fa-solid fa-eye"></i>
                </button>
              </td>
              <td>
                <button
                  class="button is-small"
                  @click.stop.prevent="markRead(message._id)"
                >
                  <i class="fa-regular fa-envelope-open"></i>
                </button>
              </td>
            </tr>
            <tr v-if="messages.length == 0">
              <td colspan="5" class="has-text-centered">No Messages</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <MessagesModal
      v-if="viewMessagesModalOpen"
      @closeModal="closeModal"
      :auto="openedAuto"
    />
  </div>
</template>
<script>
import MessagesModal from "@/views/Autos/MessagesModal.vue";
export default {
  data() {
    return {
      messages: [],
      isLoading: false,
      viewMessagesModalOpen: false,
      openedAuto: null,
      department: null,
    };
  },
  components: {
    MessagesModal,
  },
  created() {
    this.fetchMessages();
  },
  watch: {
    department() {
      this.fetchMessages();
    },
  },
  methods: {
    fetchMessages() {
      this.isLoading = true;
      let url = `/api/autos/retrieve_all_messages`;
      if (this.department) {
        url = url + `?department=${this.department}`;
      }
      // retrieve messages
      this.$axios
        .get(url, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          // return {} if auto or client don't exsist for some reason (to prevent errors)
          this.messages = res.data.messages.map((m) => {
            if (!m.auto) {
              m.auto = { client: {} };
            }
            return m;
          });
          this.isLoading = false;
        })
        .catch((err) => {
          alert("Something went wrong while retrieving the messages!");
          this.isLoading = false;
        });
    },
    markRead(id) {
      if (confirm("Are you sure")) {
        this.$axios
          .get(`/api/autos/mark_message_read/${id}`, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            this.fetchMessages();
          })
          .catch((err) => {
            alert("Something went wrong!");
          });
      }
    },
    openViewMessagesModal(auto) {
      this.openedAuto = auto;
      this.viewMessagesModalOpen = true;
    },
    closeModal() {
      this.viewMessagesModalOpen = false;
    },
  },
};
</script>
<style scoped>
.box {
  min-height: 300px;
}
</style>
