<template>
  <page-loader :isLoading="isLoading" />
  <div class="box">
    <div class="columns">
      <div class="column">
        <div v-if="error" class="notification is-danger">
          {{ errorMessage }}
        </div>
        <div v-if="success" class="notification is-success">
          {{ successMessage }}
        </div>
        <label class="label">Active Logo</label>
        <div class="img-container">
          <img v-if="logoUrl" :src="`/api/files/${logoUrl}`" alt="" />
          <p v-else>No Logo</p>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Replace Logo</label>
        <div class="file is-normal is-boxed">
          <label class="file-label">
            <input class="file-input" type="file" @change="handleUpload" />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fas fa-upload"></i>
              </span>
              <span class="file-label">Upload New Logo</span>
              <span v-if="logo.name">{{ logo.name }}</span>
            </span>
          </label>
        </div>
        <button class="button is-primary mt-2" @click.stop.prevent="submit">
          Submit
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import Compressor from "compressorjs";

export default {
  data() {
    return {
      isLoading: true,
      logo: {},
      error: false,
      errorMessage: "",
      success: false,
      successMessage: "",
      logoUrl: "",
    };
  },
  created() {
    this.isLoading = false;
    this.fetchData();
  },
  methods: {
    handleUpload(e) {
      this.logo = e.target.files[0];
    },
    submit() {
      // check for file upload
      if (
        Object.keys(this.logo).length === 0 &&
        this.logo.constructor === Object
      ) {
        this.error = true;
        this.success = false;
        this.errorMessage = "Please select a file.";
        return;
      }
      //check if logo is PNG
      if (this.logo.type.split("/")[1] !== "png") {
        this.error = true;
        this.success = false;
        this.errorMessage = "Only PNG files are accepted";
        this.logo = {};
        return;
      }
      let headers = this.$authHeader();
      // changing the headers to include files
      headers["Content-Type"] = "multipart/form-data";
      this.isLoading = true;
      // resize logo
      let fd = new FormData();
      new Compressor(this.logo, {
        maxWidth: 200,
        success: (result) => {
          fd.append("logo", result);
          this.$axios
            .post("/api/settings/upload_logo", fd, { headers })
            .then((res) => {
              this.error = false;
              this.success = true;
              this.successMessage = res.data.msg;
              this.isLoading = false;
              this.logo = {};
              this.fetchData();
              // change logo in state
              this.$store.commit("change_logo", res.data.logo);
              localStorage.removeItem("token");
              this.$store.commit("log_out");
              this.$router.push("/");
            })
            .catch((err) => {
              this.error = true;
              this.success = false;
              this.errorMessage = err.response.data.msg;
              this.isLoading = false;
            });
        },
      });
    },
    fetchData() {
      this.isLoading = true;
      this.$axios
        .post(
          "/api/retrieve_user_settings",
          {},
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.isLoading = false;
          this.logoUrl = res.data.settings.logo;
        })
        .catch(() => {
          this.isLoading = false;
          alert("Something went werong while retrieving your settings!");
        });
    },
  },
};
</script>
<style scoped>
.img-container {
  text-align: center;
  padding: 20px;
  border: 1px dashed #ddd;
  border-radius: 10px;
}
.img-container img {
  max-height: 100px;
}
</style>
