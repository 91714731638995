<template>
  <div class="box">
    <router-link to="/admin/dashboard/clients/">
      <button class="button mb-6">
        <i class="fa-solid fa-angles-left mr-1"></i>Go Back to Clients
      </button>
    </router-link>
    <h1 class="title">Edit Client</h1>
    <hr />
    <form>
      <page-loader :isLoading="pageLoading" />
      <!-- Alert Messages -->
      <MessageAlert
        :msg="this.errorMessage"
        messageType="error"
        v-if="this.errorAlert"
      />
      <MessageAlert
        :msg="this.successMessage"
        messageType="success"
        v-if="this.successAlert"
      />
      <div class="column">
        <div class="field">
          <label class="label">Full Name <span>(required)</span> </label>
          <input
            type="text"
            class="input"
            v-model="name"
            placeholder="Enter client's full name"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Username <span>(required)</span></label>
          <input
            type="text"
            class="input"
            v-model="username"
            placeholder="Select a username for the client"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Email <span>(required)</span></label>
          <input
            type="email"
            class="input"
            v-model="email"
            placeholder="example@example.com"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Company <span>(required)</span></label>
          <input
            type="text"
            class="input"
            v-model="company"
            placeholder="Client's company name"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Display Name </label>
          <input
            type="text"
            class="input"
            v-model="display_name"
            placeholder="Optional (will be used as a shorthand for company)"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Country</label>
          <div class="select is-fullwidth">
            <select v-model="country">
              <CountriesList />
            </select>
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">State</label>
          <input
            type="text"
            class="input"
            v-model="state"
            placeholder="State"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Street Address</label>
          <input
            type="text"
            class="input"
            v-model="address"
            placeholder="Street address"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">City</label>
          <input type="text" class="input" v-model="city" placeholder="City" />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Postal Code</label>
          <input
            type="text"
            class="input"
            v-model="postal_code"
            placeholder="Postal Code / Zip"
          />
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Phone Number</label>
          <input
            type="text"
            class="input"
            v-model="phone"
            placeholder="Phone number"
          />
        </div>
      </div>
      <div class="column">
        <div
          class="field"
          v-tippy="{
            content:
              'Please include the company name and full address, this will be shown in the invoices and quotes for this client.',
            placement: 'top',
          }"
        >
          <label class="label">Billing Info</label>
          <textarea
            class="textarea has-fixed-size"
            v-model="billing_address"
            placeholder="Billing Info"
          ></textarea>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Notes</label>
          <textarea
            class="textarea has-fixed-size"
            v-model="notes"
            placeholder="Notes about the client"
          ></textarea>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">VIP Client</label>
          <label class="checkbox mr-4">
            <input type="checkbox" v-model="vip_client" :value="true" />
            Yes
          </label>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="label">Active Currencies <span>*</span></label>
          <label
            class="checkbox mr-4"
            v-for="currency in allCurrencies"
            :key="currency"
          >
            <input type="checkbox" v-model="currencies" :value="currency" />
            {{ currency }}
          </label>
        </div>
      </div>
      <div class="column">
        <button
          :class="`button is-primary ${isLoading ? 'is-loading' : ''}`"
          @click.prevent="handleSubmit"
        >
          Save
        </button>
      </div>
    </form>
  </div>
</template>
<script>
import CountriesList from "@/components/forms/CountriesList.vue";
import MessageAlert from "@/components/messages/MessageAlert.vue";
export default {
  data() {
    return {
      name: "",
      email: "",
      company: "",
      username: "",
      country: "",
      city: "",
      address: "",
      state: "",
      phone: "",
      notes: "",
      postal_code: "",
      billing_address: "",
      vip_client: null,
      currencies: [],
      errorAlert: false,
      successAlert: false,
      errorMessage: "",
      successMessage: "",
      display_name: "",
      isLoading: false,
      pageLoading: true,
      allCurrencies: [],
    };
  },
  components: { CountriesList, MessageAlert },
  created() {
    this.fetchData();
    // get client info
    let client_id = this.$route.params.id;
    this.$axios
      .post(
        "/api/retrieve_clients",
        { client_id },
        { headers: this.$authHeader() }
      )
      .then((res) => {
        this.name = res.data.clients[0].name;
        this.billing_address = res.data.clients[0].billing_address;
        this.email = res.data.clients[0].email;
        this.company = res.data.clients[0].company;
        this.state = res.data.clients[0].state;
        this.currencies = res.data.clients[0].currencies;
        this.country = res.data.clients[0].country;
        this.city = res.data.clients[0].city;
        this.address = res.data.clients[0].address;
        this.phone = res.data.clients[0].phone;
        this.notes = res.data.clients[0].notes;
        this.postal_code = res.data.clients[0].postal_code;
        this.display_name = res.data.clients[0].display_name;
        this.username = res.data.clients[0].username;
        this.vip_client = res.data.clients[0].vip_client;
        this.pageLoading = false;
      })
      .catch(() => {
        alert("Something went wrong!");
        this.pageLoading = false;
      });
  },
  methods: {
    handleSubmit() {
      const validateEmail = (email) => {
        return String(email)
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          );
      };
      this.isLoading = !this.isLoading;
      // Validate the form
      // validate username
      const usernameRegex = /^[a-z0-9_.]+$/;
      if (!usernameRegex.test(this.username)) {
        this.errorAlert = true;
        this.errorMessage =
          "Username can't contain spaces or special characters.";
        window.scrollTo(0, 0);
        this.isLoading = !this.isLoading;
        return;
      }
      //check for required inputs
      if (
        !this.email ||
        !this.username ||
        !this.name ||
        !this.company ||
        this.currencies.length == 0
      ) {
        this.errorAlert = true;
        this.errorMessage = "Please fill out the required inputs.";
        window.scrollTo(0, 0);
        this.isLoading = !this.isLoading;
      }

      // validate email
      else if (!validateEmail(this.email)) {
        this.errorAlert = true;
        this.errorMessage = "Please Enter a valid email address.";
        window.scrollTo(0, 0);
        this.isLoading = !this.isLoading;
      } else {
        // submit the request
        this.$axios
          .post(`/api/edit_client/${this.$route.params.id}`, this.$data, {
            headers: this.$authHeader(),
          })
          .then((res) => {
            // Client Was updated
            this.errorAlert = false;
            this.successAlert = true;
            this.successMessage = res.data.msg;
            window.scrollTo(0, 0);
            this.isLoading = !this.isLoading;
            setTimeout(() => {
              this.successAlert = false;
              this.successMessage = "";
            }, 3000);
          })
          .catch((err) => {
            this.errorAlert = true;
            this.errorMessage = err.response.data.msg;
            window.scrollTo(0, 0);
            this.isLoading = !this.isLoading;
            setTimeout(() => {
              this.errorAlert = false;
              this.errorMessage = "";
            }, 3000);
          });
      }
    },
    fetchData() {
      this.$axios
        .post(
          "/api/retrieve_user_settings",
          {},
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.allCurrencies = res.data.settings.currencies;
        })
        .catch(() => {
          alert("Something went werong while retrieving your settings!");
        });
    },
  },
};
</script>
<style scoped>
span {
  color: rgb(236, 56, 56);
  font-weight: lighter;
}
</style>
