<template>
  <div class="box">
    <h1 class="title">Add Cargo</h1>
    <hr />
    <div class="notification is-danger" v-if="error">{{ error }}</div>
    <div class="notification is-primary" v-if="success">{{ success }}</div>

    <div class="columns">
      <div class="column">
        <label class="label"
          >Description <span class="has-text-danger">*</span></label
        >
        <input
          type="text"
          class="input"
          placeholder="Description"
          v-model="description"
        />
      </div>
      <div class="column">
        <label class="label">Qty <span class="has-text-danger">*</span></label>
        <input
          type="number"
          class="input"
          placeholder="QTY"
          step="1"
          v-model="qty"
        />
      </div>
      <div class="column">
        <label class="label"
          >Client <span class="has-text-danger">*</span></label
        >
        <v-select
          placeholder="Client"
          v-model="client"
          :options="clients"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Delivery Date <span class="has-text-danger">*</span></label
        >
        <DatePicker
          v-model="delivery_date"
          placeholder="Pick a Date"
          format="yyyy-MM-dd"
          :enableTimePicker="false"
          :autoApply="true"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label"
          >Loading Point <span class="has-text-danger">*</span></label
        >
        <v-select
          placeholder="Loading Point"
          v-model="loading_point"
          :options="loading_points"
        ></v-select>
      </div>
      <div class="column">
        <label class="label"
          >Destination <span class="has-text-danger">*</span></label
        >
        <v-select
          placeholder="Destination"
          v-model="destination"
          :options="destinations"
        ></v-select>
      </div>
      <div class="column">
        <label class="label">Tracking Number</label>
        <input
          type="text"
          class="input"
          placeholder="Tracking Number"
          v-model="tracking_number"
        />
      </div>
      <div class="column"></div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Notes</label>
        <textarea class="textarea" v-model="notes"></textarea>
      </div>
    </div>
    <div class="columns">
      <div class="column is-3">
        <div class="file is-boxed is-warning">
          <label class="file-label">
            <input
              class="file-input"
              type="file"
              multiple
              @change="handleImages"
            />
            <span class="file-cta">
              <span class="file-icon">
                <i class="fa-solid fa-images"></i>
              </span>
              <span class="file-label">Upload Images</span>
            </span>
          </label>
        </div>
      </div>
    </div>
    <hr />
    <button class="button is-primary" @click.stop.prevent="handleSubmit">
      Submit
    </button>
  </div>
</template>
<script>
import Compressor from "compressorjs";
export default {
  data() {
    return {
      description: null,
      qty: null,
      client: null,
      delivery_date: null,
      tracking_number: null,
      loading_point: null,
      destination: null,
      destinations: [],
      loading_points: [],
      clients: [],
      images: [],
      notes: null,
      success: null,
      error: null,
    };
  },
  created() {
    // fetch required selections
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.selections.map((s) => {
          if (s.type === "Loading Points") this.loading_points.push(s.value);
          if (s.type === "Destinations") this.destinations.push(s.value);
        });
      });

    // fetch clients
    this.$axios
      .post("/api/retrieve_clients", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.clients.map((c) => {
          this.clients.push({ code: c._id, label: c.company });
        });
      });
  },
  methods: {
    // handle submit
    handleSubmit() {
      //validate
      if (
        !this.description ||
        !this.qty ||
        !this.client ||
        !this.delivery_date ||
        !this.loading_point ||
        !this.destination
      ) {
        return (this.error = "Please fill out the required inputs");
      }
      // send request
      let fd = new FormData();
      if (this.description) fd.append("description", this.description);
      if (this.qty) fd.append("qty", this.qty);
      if (this.client) fd.append("client", this.client.code);
      if (this.delivery_date) fd.append("delivery_date", this.delivery_date);
      if (this.tracking_number)
        fd.append("tracking_number", this.tracking_number);
      if (this.loading_point) fd.append("loading_point", this.loading_point);
      if (this.destination) fd.append("destination", this.destination);
      if (this.notes) fd.append("notes", this.notes);
      this.images.map((f) => fd.append("images", f));

      this.$axios
        .post("/api/parts/add", fd, { headers: this.$authHeader() })
        .then((res) => {
          // reset form
          (this.description = null),
            (this.qty = null),
            (this.client = null),
            (this.weight = null),
            (this.delivery_date = null),
            (this.tracking_number = null),
            (this.loading_point = null),
            (this.destination = null),
            (this.notes = null);
          this.error = null;
          return (this.success = res.data.msg);
        })
        .catch((err) => {
          this.success = null;
          return (this.error = err.response.data.msg);
        });
    },
    // Handle Images Upload
    handleImages(e) {
      let files = e.target.files;
      this.images = [];
      let i = [];
      Array.from(files).map((f) => {
        if (
          f.type.split("/")[1] == "png" ||
          f.type.split("/")[1] == "jpg" ||
          f.type.split("/")[1] == "jpeg"
        ) {
          new Compressor(f, {
            quality: 0.7,
            maxWidth: 800,
            success(res) {
              i.push(res);
            },
          });
        } else {
          this.images = [];
          return alert("Only images and PDF files are allowed");
        }
      });
      this.images = i;
    },
  },
};
</script>
