<template>
  <div class="modal is-active">
    <div class="modal-background" @click="() => $emit('closed')"></div>
    <div class="modal-card">
      <page-loader :isLoading="loading"></page-loader>
      <header class="modal-card-head">
        <p class="modal-card-title">Invoices</p>
        <button
          class="delete"
          aria-label="close"
          @click="() => $emit('closed')"
        ></button>
      </header>
      <section class="modal-card-body">
        <h1 class="subtitle">
          Payment Total: <b class="has-text-danger">{{ amount }}</b>
          <span class="ml-6"
            >Amount Applied: <b class="has-text-danger">{{ used }}</b></span
          >
          <span class="ml-6"
            >Available Amount:
            <b class="has-text-primary">{{ available }}</b></span
          >
        </h1>
        <label class="checkbox">
          Show Invoices For All Clients
          <input type="checkbox" v-model="showAllInvoices" />
        </label>
        <table class="table is-hoverable is-fullwidth mt-2 is-bordered">
          <page-loader :isLoading="loading" />
          <thead>
            <tr>
              <th width="150px">Invoice Number</th>
              <th>Description</th>
              <th>Invoice Amounts</th>
              <th class="has-text-centered" width="150px">Invoice Total</th>
              <th class="has-text-centered">Balance</th>
              <th width="190px">Apply</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="i in invoices" :key="i._id">
              <td>{{ i.invoice_number }}</td>
              <td>{{ i.description }}</td>
              <td class="nowrap">
                <span
                  v-tippy="{ placement: 'top', content: f.description }"
                  class="tag is-primary ml-2 is-clickable"
                  v-for="f in i.fields"
                  :key="f._id"
                  @click="
                    () => {
                      i.applied = f.amount;
                    }
                  "
                  >{{ f.amount }}</span
                >
              </td>
              <td class="has-text-centered">
                <span
                  class="is-clickable tag"
                  @click="
                    () => {
                      i.applied = i.total;
                    }
                  "
                  >{{ i.total }}</span
                >
              </td>
              <td class="has-text-centered">
                <span
                  class="tag is-clickable"
                  @click="
                    () => {
                      i.applied = i.balance;
                    }
                  "
                  >{{ i.balance }}</span
                >
              </td>
              <td>
                <div class="field has-addons">
                  <div class="control">
                    <input
                      type="number"
                      class="input"
                      placeholder="Amount"
                      v-model="i.applied"
                      style="height: 36px"
                    />
                  </div>
                  <div class="control">
                    <a
                      class="button is-primary"
                      @click.stop.prevent="handleSubmit(i, transaction_id)"
                    >
                      <i class="fa-solid fa-check"></i>
                    </a>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
      <footer class="modal-card-foot">
        <button class="button" @click="() => $emit('closed')">Cancel</button>
      </footer>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    transaction_id: String,
    client_id: String,
    currency: String,
    amount: Number,
  },
  data() {
    return {
      used: 0,
      available: 0,
      payments: [],
      loading: false,
      showAllInvoices: false,
      invoices: [],
    };
  },
  watch: {
    showAllInvoices() {
      // toggle all invoices
      this.fetchInvoices();
    },
  },
  created() {
    this.fetchInvoices();
  },
  methods: {
    fetchInvoices() {
      this.used = 0;
      this.available = 0;
      this.loading = true;
      let body = { client: this.$props.client_id };
      if (this.showAllInvoices) {
        body = {};
      }
      body.currency = this.$props.currency;

      // fetch payment
      this.$axios
        .get(`/api/retrieve_transaction/${this.transaction_id}`, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.payments = res.data.transaction.payments;
          this.payments.map((p) => {
            this.used += p.amount;
          });
          this.available = (this.amount - this.used).toFixed(2);
        })
        .catch((err) => {
          this.error = err.response.data.msg;
        });
      //fetch invoices
      this.$axios
        .post("/api/fetch_invoices_for_payment", body, {
          headers: this.$authHeader(),
        })
        .then((res) => {
          this.invoices = res.data.invoices.filter((i) => {
            let amount = 0;
            let payments = 0;
            if (i.auto) {
              i.description = `${i.auto.year} ${i.auto.make} ${i.auto.model} vin#${i.auto.vin}`;
            }
            if (i.part) {
              i.description = `${i.part.description} qty:${i.part.qty}`;
            }
            if (i.container) {
              i.description = `Container: ${i.container.container_number} booking: ${i.container.booking_number}`;
            }
            i.fields.map((a) => {
              amount += a.amount;
            });
            i.payments.map((p) => {
              payments += p.amount;
            });
            i.total = amount;
            i.applied = null;
            i.payments = payments;
            i.balance = (amount - payments).toFixed(2);
            return i.balance > 0;
          });
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    handleSubmit(i, transaction_id) {
      if (Number(i.applied) <= 0) {
        return alert("Invalid Amount");
      }
      if (Number(i.applied) > Number(i.balance)) {
        return alert("Amount can't be higher than the invoice balance");
      }
      if (Number(i.applied) > Number(this.available)) {
        return alert("Amount can't be higher than the available amount");
      }
      this.$axios
        .post(
          "/api/apply_payment",
          { ...i, transaction_id },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.fetchInvoices();
        })
        .catch((err) => {});
    },
  },
};
</script>
<style scoped>
@media (min-width: 1200px) {
  .modal-card {
    min-width: 1200px;
    overflow: auto;
  }
}
@media (max-width: 1200px) {
  .modal-card {
    overflow: scroll;
  }
  .modal-card-body {
    min-width: 1200px;
    overflow: scroll;
  }
}
</style>
