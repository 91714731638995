<template>
  <h1 class="title">You you have to be logged in to view this page</h1>
</template>
<style scoped>
h1 {
  text-align: center;
  margin-top: 200px;
  color: #666;
}
body {
  background: #eee;
}
</style>
