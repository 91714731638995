<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <div class="box">
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <page-loader :isLoading="isLoading" />
        <h1 class="subtitle">New Invoice</h1>
        <hr />
        <label class="label">Currency</label>
        <v-select
          v-model="currency"
          placeholder="Currency"
          :options="currencies"
        ></v-select>
        <button
          :class="`button mt-4 is-primary is-small ${
            buttonLoading ? 'is-loading' : ''
          }`"
          @click="handleInvoice"
        >
          Create Invoice
        </button>
      </div>
    </div>
    <button
      :class="`modal-close is-large `"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>
<script>
export default {
  props: {
    part_id: String,
  },
  data() {
    return {
      currencies: [],
      isLoading: true,
      error: null,
      buttonLoading: false,
      currency: null,
    };
  },
  created() {
    // fetch currencies for this client
    this.$axios
      .get(`/api/retrive_currencies/part/${this.$props.part_id}`, {
        headers: this.$authHeader(),
      })
      .then((res) => {
        this.isLoading = false;
        this.currencies = res.data.currencies || [];
      })
      .catch((err) => {
        this.isLoading = false;
        this.error = err.response.data.msg;
      });
  },
  methods: {
    handleInvoice() {
      this.buttonLoading = true;
      // handle invoice creation
      // 1- validate input
      if (!this.currency) {
        this.buttonLoading = false;
        return (this.error = "Please select a currency");
      }
      this.$axios
        .post(
          "/api/new_part_invoice",
          {
            part_id: this.$props.part_id,
            currency: this.currency,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.$router.push(
            `/admin/dashboard/part_invoice/${res.data.invoice_id}`
          );
        })
        .catch((err) => {
          this.buttonLoading = false;
          this.error = err.response.data.msg;
        });
    },
  },
};
</script>
<style scoped>
.box,
.modal-content {
  overflow: visible;
}
</style>
