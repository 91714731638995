<template>
  <div class="a" v-for="a in announcements" :key="a._id">
    <div class="header">
      <span class="a-title"
        ><i class="fa-solid fa-triangle-exclamation mr-1"></i
        >{{ a.title }}</span
      >
      <span class="a-date">{{ a.created_at }}</span>
    </div>
    <div v-html="a.body" class="body mt-1"></div>
  </div>
</template>
<script>
import dayjs from "dayjs";
export default {
  data() {
    return {
      announcements: [],
    };
  },
  created() {
    this.$axios
      .post("/api/retrieve_announcements", {}, { headers: this.$authHeader() })
      .then((res) => {
        let a = [];
        a = res.data.announcements.map((v) => {
          v.created_at = dayjs(v.created_at).format("YYYY-MM-DD");
          return v;
        });
        this.announcements = a;
      });
  },
};
</script>
<style scoped>
.a {
  background: #fcf8e3;
  border: 1px solid #f7eec6;
  margin-top: 10px;
  padding: 10px;
  font-size: 13px;
  box-shadow: none;
}
.a-title {
  font-weight: bold;
}
.a-date {
  float: right;
}
</style>
