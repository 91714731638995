<template>
  <div class="modal is-active">
    <div class="modal-background" @click="$emit('close')"></div>
    <div class="modal-content">
      <div class="box">
        <h1 class="subtitle">Change Destination</h1>
        <hr />
        <div class="notification is-danger" v-if="error">{{ error }}</div>
        <div class="notification is-primary" v-if="success">{{ success }}</div>
        <v-select
          :options="destinations"
          placeholder="New Destination"
          v-model="destination"
        ></v-select>
        <hr />
        <button
          :class="`button is-primary ${isLoading ? 'is-loading' : ''}`"
          @click.stop.prevent="handleChangeDestination"
        >
          Save
        </button>
      </div>
    </div>
    <button
      class="modal-close is-large"
      aria-label="close"
      @click="$emit('close')"
    ></button>
  </div>
</template>
<script>
export default {
  props: { auto: Object },
  data() {
    return {
      isLoading: false,
      destinations: [],
      auto_id: this.auto._id,
      destination: null,
      error: null,
      success: null,
    };
  },
  created() {
    // fetch destinations
    this.$axios
      .post("/api/retrieve_selections", {}, { headers: this.$authHeader() })
      .then((res) => {
        res.data.selections.map((s) => {
          if (s.type == "Destinations") this.destinations.push(s.value);
        });
      });
  },
  methods: {
    handleChangeDestination() {
      if (!this.destination) {
        return (this.error = "Please select a destination");
      }
      this.isLoading = true;
      this.$axios
        .post(
          "/api/autos/update_info",
          { auto_id: this.auto._id, destination: this.destination },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.error = null;
          this.success = res.data.msg;
          this.isLoading = false;
          this.destination = null;
          this.$emit("changed");
        })
        .catch((err) => {
          this.success = null;
          this.error = err.response.data.msg;
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
.modal-content,
.box {
  overflow: visible !important;
}
</style>
