<template>
  <nav class="navbar" role="navigation" aria-label="main navigation">
    <i id="close" class="fa-solid fa-bars" @click="toggleNav"></i>

    <div class="navbar-brand">
      <a class="navbar-item">
        <img
          :src="`/api/files/${this.$store.state.authUser.logo}`"
          v-if="this.$store.state.authUser.logo"
          class="logo"
        />
      </a>
    </div>

    <div class="navbar-menu">
      <div class="navbar-end">
        <div class="navbar-item">
          {{ this.$store.state.authUser.company }}
        </div>
        <div class="navbar-item">
          <div class="buttons">
            <i
              @click="
                () => {
                  this.$router.push('/admin/dashboard/profile');
                }
              "
              class="fa-solid fa-circle-user profile"
              v-tippy="{
                content: 'Your Account',
                placement: 'bottom',
              }"
            ></i>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  methods: {
    toggleNav() {
      // update state
      this.$store.commit("toggle_nav");
    },
  },
};
</script>
<style scoped>
.navbar {
  position: fixed;
  width: 100%;
  left: 0;
  top: 0;
  padding-left: 50px;
  border-bottom: 1px solid #ddd;
  height: 60px;
}
.logo {
  max-height: 40px;
}
.profile {
  color: #666;
  cursor: pointer;
  font-size: 1.5rem;
  margin-right: 10px;
}
#close {
  color: rgb(150, 148, 148);
  font-size: 1.5rem;
  padding: 7px;
  position: fixed;
  top: 10px;
  left: 5px;
  cursor: pointer;
  transition: 500ms;
  z-index: 5;
}
</style>
