<template>
  <div class="columns mt-6">
    <div class="column">
      <h1 class="subtitle">Add</h1>
      <hr />
      <div class="columns">
        <div class="column" v-if="errorMessage">
          <p>
            {{ errorMessage }}
          </p>
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <v-select
            taggable
            placeholder="Shipping Lines"
            v-model="shipping_lines"
            :options="lines"
          >
          </v-select>
        </div>
        <div class="column">
          <v-select taggable v-model="size" :options="sizes" placeholder="Size">
          </v-select>
        </div>
        <div class="column">
          <input
            type="number"
            v-model="amount"
            class="input"
            placeholder="Amount"
            min="0"
          />
        </div>
      </div>
      <div class="columns">
        <div class="column">
          <textarea
            class="textarea"
            placeholder="Description"
            cols="30"
            rows="2"
            v-model="description"
          ></textarea>
          <button
            class="button is-success mt-2"
            @click.stop.prevent="handleAdd"
          >
            <i class="fa-solid fa-plus"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Sizes from "@/components/helpers/ContainerSizes";
import Lines from "@/components/helpers/ShippingLines";

export default {
  props: {
    id: String,
  },
  data() {
    return {
      loading_points: [],
      destinations: [],
      shipping_lines: null,
      description: null,
      amount: null,
      errorMessage: "",
      size: null,
    };
  },
  computed: {
    sizes() {
      return Sizes;
    },
    lines() {
      return Lines;
    },
  },

  methods: {
    handleAdd() {
      if (
        !this.amount ||
        !this.description ||
        !this.shipping_lines ||
        !this.size
      ) {
        this.errorMessage = "Please fill out all the fields";
        return;
      }
      if (this.amount < 0) {
        this.errorMessage = "Amount can't be less than 0";
        return;
      }
      // send requrest to update
      this.$axios
        .post(
          "/api/add_detail_to_quote",
          {
            loading_point: this.loading_point,
            destination: this.destination,
            shipping_lines: this.shipping_lines,
            size: this.size,
            id: this.id,
            amount: this.amount,
            description: this.description,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          (this.amount = null), (this.description = "");
          this.errorMessage = "";
          this.$emit("fetch");
        })
        .catch((err) => {
          console.log(err);
          this.errorMessage = err.response.data.msg;
        });
    },
  },
};
</script>
<style scoped>
p {
  color: #f14668;
}
</style>
