let makes;
export default makes = [
  "AC",
  "Acura",
  "Alfa Romeo",
  "AM General",
  "American Bantam",
  "American Motors",
  "Amphicar",
  "Aston Martin",
  "Asuna",
  "Auburn",
  "Audi",
  "Aurora",
  "Austin",
  "Austin-Healey",
  "Autobianchi",
  "Autozam",
  "Bentley",
  "BMW",
  "Bricklin",
  "Bugatti",
  "Buick",
  "Cadillac",
  "Chevrolet",
  "Chrysler",
  "Citroen",
  "Clenet",
  "Daihatsu",
  "Daimler",
  "Datsun",
  "De Soto",
  "De Tomaso",
  "DeLorean",
  "Dodge",
  "Dune Buggy",
  "Eagle",
  "Excalibur",
  "Factory Five Racing",
  "Ferrari",
  "Fiat",
  "Fisker",
  "Ford",
  "Freightliner",
  "Genesis",
  "Geo",
  "GMC",
  "Hino",
  "Honda",
  "Hudson",
  "Hummer",
  "Hupmobile",
  "Hyundai",
  "Infiniti",
  "International",
  "Isuzu",
  "Jaguar",
  "Jeep",
  "Kaiser",
  "Karma",
  "Kia",
  "Lamborghini",
  "Lancia",
  "Land Rover",
  "Lexus",
  "Lincoln",
  "Lotus",
  "Maserati",
  "Maybach",
  "Mazda",
  "McLaren",
  "McLaughlin-Buick",
  "Mercedes-Benz",
  "Mercury",
  "MG",
  "MINI",
  "Mitsubishi",
  "Morgan",
  "Morris",
  "MV-1",
  "Nash",
  "Nissan",
  "Oldsmobile",
  "Packard",
  "Peugeot",
  "Plymouth",
  "Polestar",
  "Pontiac",
  "Porsche",
  "Puma",
  "Qvale",
  "Ram",
  "Renault",
  "Rolls Royce",
  "Rover",
  "Saab",
  "Saturn",
  "Scion",
  "Shelby",
  "Smart",
  "Spyker",
  "Sterling",
  "Studebaker",
  "Subaru",
  "Sunbeam",
  "Suzuki",
  "Tesla",
  "Toyota",
  "Triumph",
  "TVR",
  "Volkswagen",
  "Volvo",
  "Willys",
  "Yugo"
];