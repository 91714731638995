<template>
  <div class="box">
    <page-loader :isLoading="isLoading" />
    <AddSelectionModal v-if="modalOpen" @closed="closeModal" />
    <form>
      <div class="columns">
        <div class="column is-3">
          <div class="select is-fullwidth">
            <select v-model="type" @change="retrieveSelections">
              <option value="">Select One</option>
              <option>Destinations</option>
              <option>Loading Points</option>
              <option>Auctions</option>
              <option>Cities</option>
              <option>Custom Field 1</option>
              <option>Custom Field 2</option>
            </select>
          </div>
        </div>
        <div class="column is-1">
          <button class="button is-primary" @click.prevent="openModal">
            Add Selection Field <i class="fa-solid fa-plus m-3"></i>
          </button>
        </div>
      </div>
    </form>
    <table class="table is-fullwidth mt-5 is-hoverable">
      <thead>
        <tr>
          <th>Value</th>
          <th width="150px">Actions</th>
        </tr>
      </thead>
      <tbody v-if="selections.length > 0">
        <tr v-for="selection in selections" :key="selection._id">
          <td>{{ selection.value }}</td>
          <td>
            <button
              class="button is-danger is-small mr-4"
              title="Delete"
              @click="deleteSelection(selection._id)"
            >
              <i class="fa-solid fa-trash"></i>
            </button>
            <button
              class="button is-small"
              title="Edit"
              @click="openEditModal(selection._id, selection.value)"
            >
              <i class="fa-solid fa-pen-to-square"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tbody v-else>
        <tr>
          <td colspan="2" class="center">No results</td>
        </tr>
      </tbody>
    </table>
    <div :class="`modal ${editModal ? 'is-active' : ''}`">
      <div class="modal-background" @click="closeModal"></div>
      <div class="modal-content">
        <div class="box">
          <div class="columns">
            <div class="column is-10">
              <input
                type="text"
                class="input"
                placeholder="Enter Value"
                v-model="edit.value"
              />
            </div>
            <div class="column is-1">
              <button
                class="button is-primary"
                @click="editSelection(edit.id, edit.value)"
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </div>
      <button
        class="modal-close is-large"
        aria-label="close"
        @click="closeModal"
      ></button>
    </div>
  </div>
</template>
<script>
import AddSelectionModal from "@/views/Settings/AddSelectionModal.vue";

export default {
  data() {
    return {
      modalOpen: false,
      editModal: false,
      edit: {
        value: "",
        id: "",
      },
      isLoading: false,
      selections: [],
      type: "",
    };
  },
  components: { AddSelectionModal },
  methods: {
    closeModal() {
      this.editModal = false;
      this.modalOpen = false;
      this.retrieveSelections();
    },
    openModal() {
      this.modalOpen = true;
    },
    openEditModal(id, value) {
      this.edit.value = value;
      this.edit.id = id;
      this.editModal = true;
    },
    retrieveSelections() {
      this.isLoading = true;
      if (this.type == "") {
        this.isLoading = false;
        return (this.selections = []);
      }
      this.$axios
        .post(
          "/api/retrieve_selections",
          {
            type: this.type,
          },
          { headers: this.$authHeader() }
        )
        .then((res) => {
          this.selections = res.data.selections;
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          alert(err.data.response.msg);
        });
    },
    deleteSelection(id) {
      if (confirm("Are you sure you want to delete this selection?") == true) {
        this.isLoading = true;
        this.$axios
          .post(
            "/api/delete_selection/",
            {
              id,
            },
            { headers: this.$authHeader() }
          )
          .then(() => {
            this.retrieveSelections();
            this.isLoading = false;
          })
          .catch((err) => {
            this.isLoading = false;
            alert(err.data.response.msg);
          });
      }
    },
    editSelection(id, value) {
      this.isLoading = true;
      this.$axios
        .post(
          "/api/edit_selection/",
          {
            id,
            value,
          },
          { headers: this.$authHeader() }
        )
        .then(() => {
          this.closeModal();
          this.isLoading = false;
        })
        .catch((err) => {
          this.isLoading = false;
          alert(err.data.response.msg);
        });
    },
  },
};
</script>
<style scoped>
.center {
  text-align: center;
}
</style>
