<template>
  <page-loader :isLoading="isLoading"></page-loader>

  <form class="container">
    <h1 class="subtitle">Add a New Announcement</h1>
    <hr />
    <div class="notification is-danger" v-if="error">
      {{ errorMessage }}
    </div>
    <div class="notification is-success" v-if="success">
      {{ successMessage }}
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Title</label>
        <input
          type="text"
          class="input"
          v-model="title"
          placeholder="Enter the Title of the Announcement"
        />
      </div>
    </div>
    <div class="columns">
      <div class="column">
        <label class="label">Content</label>

        <textarea
          id="sample"
          v-model="body"
          placeholder="Enter the text content for the announcement"
          rows="10"
        ></textarea>
      </div>
    </div>
    <button class="button is-primary" @click.stop.prevent="handleSubmit">
      Submit
    </button>
  </form>
</template>
<script>
import suneditor from "suneditor";
import plugins from "suneditor/src/plugins";
let editor;
export default {
  data() {
    return {
      isLoading: false,
      title: "",
      body: "",
      error: false,
      success: false,
      errorMessage: "",
      successMessage: "",
    };
  },
  mounted() {
    this.fetchData();
  },
  methods: {
    handleSubmit() {
      let body = editor.getContents();
      this.body = body;
      if (!this.title || !editor.getText().trim()) {
        this.error = true;
        this.success = false;
        this.errorMessage = "Please fill out both inputs";
        return;
      }
      this.$axios
        .post(
          "/api/edit_announcement",
          { id: this.$route.query.id, title: this.title, body: this.body },
          {
            headers: this.$authHeader(),
          }
        )
        .then((res) => {
          this.error = false;
          this.success = true;
          this.successMessage = res.data.msg;
        })
        .catch((err) => {
          this.error = true;
          this.success = false;
          this.errorMessage = err.response.data.msg;
        });
    },
    fetchData() {
      this.isLoading = true;
      // retrieve announcements
      this.$axios
        .post(
          "/api/retrieve_announcements",
          { id: this.$route.query.id },
          {
            headers: this.$authHeader(),
          }
        )
        .then((res) => {
          // format date
          this.body = res.data.announcements[0].body;
          this.title = res.data.announcements[0].title;
          this.isLoading = false;
          editor = suneditor.create("sample", {
            plugins: plugins,
            buttonList: [["link", "bold", "strike", "italic"]],
          });
          editor.setDefaultStyle("font-family: Arial; font-size: 13px;");
          editor.setContents(this.body);
        })
        .catch((err) => {
          alert(err.response.data.msg);
          this.isLoading = false;
        });
    },
  },
};
</script>
<style scoped>
form {
  max-width: 600px;
  margin: auto;
}
#sample {
  width: 100%;
}
</style>
